import React from 'react';
import is from 'classnames';
import styled from 'styled-components/macro';
import { AvatarContainer } from 'components/Login/style';
import { iconCss } from '../Icons';
import * as Avatar from '../Avatar/styles';
import isEmpty from 'lodash/isEmpty';
export const Badge = styled.div`
    --letting-x: 3px;
    --letting-y: 1px;
    --letting-height: calc(var(--letting-y) * 2);
    --letting-width: calc(var(--letting-x) * 2);
    --size: 14px;

    display: flex;
    background: var(--theme-color);
    border-radius: 30px;
    color: var(--mono-white-color);
    min-width: 12px;
    height: calc(var(--size) - var(--letting-height));
    font-size: 8px;
    font-weight: bold;
    padding: var(--letting-y) var(--letting-x) var(--letting-y) var(--letting-x);
    place-items: center;
    place-content: center;
    line-height: calc(var(--size) - var(--letting-height));
`;

export const Button = styled.div<any>`
    & {
        ${iconCss}
    }

    color: ${({ themeColor }) => (isEmpty(themeColor) ? 'var(--theme-color)' : themeColor)};
    cursor: pointer;
    display: flex;
    flex-shrink: 0;
    flex-grow: 0;
    height: 100%;
    place-content: center;
    place-items: center;
    position: relative;
    pointer-events: auto;
    user-select: none;
    width: var(--icon-button-width);

    & ${AvatarContainer}, & ${Avatar.Container} {
        --size: 28px;
    }

    ${Badge} {
        position: absolute;
        top: -1px;
        right: -2px;
    }
`;

export const SpaceHolder = styled.div`
    & {
        ${iconCss}
    }
    display: block;
    flex-shrink: 1;
    height: 10px;
    width: var(--icon-button-width);
`;
export const Dot = styled.div<React.PropsWithChildren<any>>`
    height: 10px;
    width: 10px;
    background-color: ${({ color }) => color ?? '#2acaa1'};
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    right: ${({ right }) => (right ? `${right}px` : '-1px')};
    top: ${({ top }) => (top ? `${top}px` : '4px')};
    border: 2px solid #fff;
`;
export const HeaderButton = (properties: React.PropsWithChildren<any>) => {
    const {
        children,
        className,
        icon: Icon,
        iconClicked,
        onClick,
        showNotification,
        color,
        themeColor,
        ...attributes
    } = properties;
    return (
        <Button className={is(className)} onClick={iconClicked || onClick} {...attributes} themeColor={themeColor}>
            {Icon && <Icon />}
            {children}
            {showNotification && <Dot top={-2} right={5} color={color} />}
        </Button>
    );
};
