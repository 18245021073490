import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Invalid, EmailPattern, FormHeader, Space } from '../common/index';
import * as selectors from 'modules/selectors';
import * as actions from 'modules/actions';
import { Input, Submit } from '../inputs';
import { Mode } from '../constants/constants';
import { Label } from '../fieldset/label';
import cloneDeep from 'lodash/cloneDeep';
import { Button, Horizontal, Vertical } from '../style';
import './initMode.scss';

const InitialMode = () => {
    const dispatch = useDispatch();
    const authData = useSelector(selectors.getloginData);
    const isUserRegister = useSelector(selectors.getUserRegister);
    const error = useSelector(selectors.getLoginErrors);
    const state = cloneDeep(authData);
    const [loginValidation, setError] = useState({
        email: '',
    });

    const checkEmailValidity = () => {
        let formIsValid = true;
        if (!state.email.length) {
            setError((val) => ({ ...val, email: 'Email is required' }));
            formIsValid = false;
        }
        if (!EmailPattern.test(state.email)) {
            setError((val) => ({
                ...val,
                email: 'Please enter a valid email address',
            }));
            formIsValid = false;
        } else
            setError((val) => ({
                ...val,
                email: '',
            }));
        dispatch(actions.authLoginErrors(''));
        return formIsValid;
    };

    useEffect(() => {
        if (isUserRegister === Mode.useNewEmail) {
            dispatch(actions.authMode(Mode.useNewEmail));
        } else if (isUserRegister === Mode.useEmail) {
            dispatch(actions.authMode(Mode.useEmail));
        }
    }, [isUserRegister, dispatch]);

    const EMAIL = {
        ERROR: 'Please enter a valid email address',
        LABEL: 'Email',
        PLACEHOLDER: 'Enter Email',
        PROPERTY: 'email',
    };

    const handleInputChange = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;
        const obj = {
            ...state,
            [name]: value,
        };
        dispatch(actions.setAuthEmail(obj));
    };

    useEffect(() => {
        if (error) {
            setError({ email: error.message });
        }
    }, [error]);

    const changePage = async (e: any) => {
        e.preventDefault();

        if (checkEmailValidity()) {
            if (state.email) {
                dispatch(actions.checkUserHasAccess());
            }
        }
    };

    const googleAuthClick = () => {
        dispatch(actions.authSigninUser());
    };

    return (
        <React.Fragment>
            <FormHeader title="Please select a login method" hasPoweredByLogo={true} />
            <Space height={0.75} />

            <div className="google-btn" onClick={googleAuthClick}>
                <div className="google-icon-wrapper">
                    <img
                        className="google-icon"
                        src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg"
                        alt="google login"
                    />
                </div>
                <p className="btn-text">
                    <b>Sign in with google</b>
                </p>
            </div>

            <Vertical>
                <Space height={0.5} />
                <div>
                    <Invalid messageOnly={loginValidation.email}>
                        <Label field={EMAIL.PROPERTY}>{EMAIL.LABEL}</Label>
                        <Input
                            type="text"
                            value={state.email}
                            id={EMAIL.PROPERTY}
                            name={'email'}
                            placeholder={EMAIL.PLACEHOLDER}
                            onChange={(event) => handleInputChange(event)}
                            autoFocus={true}
                        />
                    </Invalid>
                </div>
                <span style={{ color: 'red' }}>{loginValidation.email}</span>
                <Space height={0} />
            </Vertical>
            <Space />
            <Horizontal>
                <Button className="outline" onClick={() => dispatch(actions.closeLoginModal())}>
                    Cancel
                </Button>
                <Submit onClick={changePage}>Next</Submit>
            </Horizontal>
        </React.Fragment>
    );
};

export default InitialMode;
