import { useCallback, useMemo } from 'react';
import * as firebase from 'modules/utils/Firebase';
import { useSelector } from 'react-redux';
import * as Selectors from 'modules/selectors';
import get from 'lodash/get';
import omitBy from 'lodash/omitBy';
import isString from 'lodash/isString';
import isNil from 'lodash/isNil';

export function FirebaseAnalyticsClick(
    eventName: string,
    additionalEventParams: { [key: string]: any },
    onClickCallback?: () => void,
) {
    const { eventSlug, organizationId, eventId } = useSelector(Selectors.registrationSessionPropertyLink);

    const profile = useSelector(Selectors.getUserProfile);

    const stateParameters = useCallback(() => {
        const firstName = get(profile, `firstName`, null);
        const lastName = get(profile, `lastName`, null);
        // delete/omit all fields in object where values are equal to undefined | null
        const baseParamter = omitBy(
            {
                attendee_company_name: get(profile, `companyName`, null),
                attendee_job_title: get(profile, `jobTitle`, null),
                attendee_email: get(profile, `email`, null),
                attendee_name: [firstName, lastName].filter(isString).join(' ').trim() || null,
                attendee_uid: get(profile, `uid`, null),
                event_id: eventId,
                event_slug: eventSlug,
                organization_id: organizationId,
                ...additionalEventParams,
            },
            isNil,
        );

        return baseParamter;
    }, [profile, organizationId, eventSlug, eventId, additionalEventParams]);

    const parameters = useMemo(() => stateParameters(), [stateParameters]);

    return () => {
        firebase.analytics.logEvent(`${eventName}_click`, parameters);
        if (onClickCallback) onClickCallback();
    };
}
