import { IStoreState } from 'modules/types';

export const getAssets = (state: IStoreState) => state.organization.assets;
export const getEvent = (state: IStoreState) => state.organization.event;
export const getGlobalLink = (state: IStoreState) => state.organization.globalLink;
export const getHeaderImage = (state: IStoreState) => state.organization.event.header.imageUrl;
export const getLobby = (state: IStoreState) => state.organization.event.lobby;
export const getLobbyDisplayMode = (state: IStoreState) => state.organization.event.lobbyDisplayMode;
export const getLounge = (state: IStoreState) => state.organization.event?.lounges;
export const getLoungeActiveState = (state: IStoreState) => state.organization.event?.lounges?.isActive;
export const getOrganization = (state: IStoreState) => state.organization.organization;
export const getOrganizationLogoUrl = (state: IStoreState) => state.organization.organization.logoUrl;
export const getOrganizationLanding = (state: IStoreState) => state.organization.organization.landing;
export const getOrganizationEvents = (state: IStoreState) => state.organization.organization.events;
export const getOrganizationSocialLinks = (state: IStoreState) => state.organization.organization.social;
export const getSponsorLoungeActiveState = (state: IStoreState) =>
    state.organization.event?.lounges?.isSponsorLoungeActive;
export const organizationLoader = (state: IStoreState) => state.organization.fetching;

export const getSponsorRouteToBreakoutState = (state: IStoreState) =>
    state.organization.event?.sponsor.page.sponsorLoungeRedirectToBreakout;
export const missingOrganization = (state: IStoreState) =>
    !state.organization.fetching && state.organization.assets.baseUrl.length === 0;

export const getEventThemeColor = (state: IStoreState) => state.organization.event?.theme?.buttonColor;
export const getIsChatNotification = (state: IStoreState) => state.organization.event?.isChatNotification;
export const getVideoPlayerColor = (state: IStoreState) => state.organization.organization.landing.videoPlayerColor;
