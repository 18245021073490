import { createReducer } from 'redux-act';
import { IAttendeeProfile } from 'modules/schemas';
import {
    setRoomId,
    setMainRoomId,
    resetState,
    addBreakoutRecordingStates,
    addWaitingAttendees,
    setRoomRole,
    setRoomPresence,
    addWaitingRoomType,
    removeUserFromRoom,
    addVideoControlStates,
    setIsNetworkRoom,
    stopContentShare,
    allowAllUsersInRoom,
    removeBreakoutWaitingListner,
    setBreakoutMeetingId,
    setCollenctionType,
    setBgImageForLiveStream,
} from 'modules/actions';

type Role = 'moderator' | 'presenter' | '';
export type BreakoutRoomsState = {
    selectedRoomId: string | null;
    selectedMainRoomId: string | null;
    meetingId: string;
    isWaitingRoom: boolean;
    allowAllUsersInRoom: boolean;
    role: Role;
    isUserPresent: boolean;
    waitingRoomAttendees: IAttendeeProfile[];
    breakoutRecording: BreakoutRecordingState;
    breakoutVideoStates: BreakoutVideoState;
    isNetworkingRoom: boolean;
    stopContentShare: boolean;
    collectionType: string | null;
    liveStreamBgImage: string | null;
};

export type BreakoutRecordingState = {
    isRecording: boolean;
    startedAt: number;
    stopAt: number;
    userId: string;
    intializeRecording: boolean;
};

export type BreakoutVideoState = {
    active: boolean;
    currentVideo: string;
    play: boolean;
    playbackProgress: number;
    playbackUser: string;
    playState?: boolean;
};

const initialState: BreakoutRoomsState = {
    selectedRoomId: '',
    selectedMainRoomId: '',
    meetingId: '',
    isWaitingRoom: false,
    allowAllUsersInRoom: false,
    isUserPresent: false,
    role: '',
    waitingRoomAttendees: [],
    breakoutRecording: {} as any,
    breakoutVideoStates: null as any,
    isNetworkingRoom: false,
    stopContentShare: false,
    collectionType: null,
    liveStreamBgImage: null,
};

export const breakoutRooms = createReducer<BreakoutRoomsState>({}, initialState);

breakoutRooms.on(setRoomId, (state: BreakoutRoomsState, payload: string | null) => ({
    ...state,
    selectedRoomId: payload,
}));
breakoutRooms.on(setMainRoomId, (state: BreakoutRoomsState, payload: string | null) => ({
    ...state,
    selectedMainRoomId: payload,
}));

breakoutRooms.on(setRoomRole, (state: BreakoutRoomsState, payload: Role) => ({
    ...state,
    role: payload,
}));

breakoutRooms.on(setRoomPresence, (state: BreakoutRoomsState) => ({
    ...state,
    isUserPresent: true,
}));

breakoutRooms.on(addWaitingAttendees, (state: BreakoutRoomsState, payload: IAttendeeProfile[]) => ({
    ...state,
    waitingRoomAttendees: payload,
}));
breakoutRooms.on(addWaitingRoomType, (state: BreakoutRoomsState) => ({
    ...state,
    isWaitingRoom: true,
}));
breakoutRooms.on(allowAllUsersInRoom, (state: BreakoutRoomsState, payload: { hasNoWaitingQueue: boolean }) => ({
    ...state,
    allowAllUsersInRoom: payload.hasNoWaitingQueue,
}));

breakoutRooms.on(resetState, () => initialState);
breakoutRooms.on(removeUserFromRoom, (state: BreakoutRoomsState) => ({
    isWaitingRoom: false,
    isUserPresent: false,
    allowAllUsersInRoom: false,
    role: '',
    waitingRoomAttendees: [],
    breakoutRecording: {} as any,
    selectedRoomId: state.selectedRoomId,
    breakoutVideoStates: null as any,
    isNetworkingRoom: false,
    stopContentShare: false,
    meetingId: '',
    selectedMainRoomId: '',
    collectionType: null,
    liveStreamBgImage: null,
}));

breakoutRooms.on(removeBreakoutWaitingListner, (state: BreakoutRoomsState) => ({ ...state, isWaitingRoom: false }));

breakoutRooms.on(addBreakoutRecordingStates, (state: BreakoutRoomsState, payload: any) => {
    return {
        ...state,
        breakoutRecording: payload,
    };
});
breakoutRooms.on(addVideoControlStates, (state: BreakoutRoomsState, payload: any) => {
    return {
        ...state,
        breakoutVideoStates: payload,
    };
});
breakoutRooms.on(setIsNetworkRoom, (state: BreakoutRoomsState, payload: boolean) => {
    return {
        ...state,
        isNetworkingRoom: payload,
    };
});

breakoutRooms.on(stopContentShare, (state: BreakoutRoomsState, payload: boolean) => {
    return {
        ...state,
        stopContentShare: payload,
    };
});

breakoutRooms.on(setBreakoutMeetingId, (state: BreakoutRoomsState, payload: string) => {
    return {
        ...state,
        meetingId: payload,
    };
});

breakoutRooms.on(setCollenctionType, (state: BreakoutRoomsState, payload: string | null) => {
    return {
        ...state,
        collectionType: payload,
    };
});

breakoutRooms.on(setBgImageForLiveStream, (state: BreakoutRoomsState, payload: string | null) => ({
    ...state,
    liveStreamBgImage: payload,
}));
