import { BreakoutPresence, Components, BreakoutBlockedUsers } from 'modules/utils/Firebase/Api';
import { eventChannel } from 'redux-saga';
import firebase from 'modules/utils/Firebase';

interface IAttendeeDetails {
    organizationId: string;
    eventId: string;
}

interface IWaitingDetails {
    organizationId: string;
    eventId: string;
    roomId: string;
}

export const checkIfBreakoutRoomDocExists = (props: IAttendeeDetails) => {
    const ref = BreakoutPresence.reference(props.organizationId, props.eventId);
    return ref.once('value');
};

export const getRoomUserList = (props: IAttendeeDetails) => {
    const connectionRef = BreakoutPresence.reference(props.organizationId, props.eventId);
    const roomUserListListner = eventChannel((emit) => {
        connectionRef.on('value', (snapshot: any) => {
            if (snapshot.val()) return emit(snapshot.val());
            else return emit(false);
        });
        return () => {
            connectionRef.off();
        };
    });
    return roomUserListListner;
};

export const getSelectedRoomUserList = (props: IWaitingDetails) => {
    const connectionRef = BreakoutPresence.reference(props.organizationId, props.eventId);
    const roomConnectionRef = connectionRef.child(props.roomId);
    const connectionRoomListner = eventChannel((emit) => {
        roomConnectionRef.on('value', (snapshot: any) => {
            if (snapshot.val()) return emit(snapshot.val());
            else return emit(false);
        });
        return () => {
            roomConnectionRef.off();
        };
    });
    return connectionRoomListner;
};

export const getBreakoutBlockedList = (organizationId: string, eventId: string, userId: string) => {
    const connectionRef = BreakoutBlockedUsers.ref(organizationId, eventId, userId);

    const connectionRoomListner = eventChannel((emit) => {
        connectionRef.on('value', (snapshot: any) => {
            if (snapshot.val()) return emit(snapshot.val());
            else return emit(false);
        });
        return () => {
            connectionRef.off();
        };
    });
    return connectionRoomListner;
};

export const getWaitingRoomUserList = (props: IWaitingDetails) => {
    const connectionRef = BreakoutPresence.waitingRef(props.organizationId, props.eventId, props.roomId);
    const connectionRoomListner = eventChannel((emit) => {
        connectionRef.on('child_added', (snapshot: firebase.database.DataSnapshot) => {
            if (snapshot.val()) {
                return emit(snapshot.val());
            } else return emit(false);
        });
        connectionRef.on('child_changed', (snapshot: firebase.database.DataSnapshot) => {
            if (snapshot.val()) {
                return emit({ ...snapshot.val(), isExisting: true });
            } else return emit(false);
        });
        connectionRef.on('child_removed', (snapshot: firebase.database.DataSnapshot) => {
            if (snapshot.val()) {
                return emit({ ...snapshot.val(), isRemoved: true });
            } else return emit(false);
        });
        return () => {
            connectionRef.off('child_added');
            connectionRef.off('child_changed');
            connectionRef.off('child_removed');
        };
    });
    return connectionRoomListner;
};

export const getCurrentUserWaitingRoomList = async (props: IWaitingDetails) => {
    const connectionRef = BreakoutPresence.waitingRef(props.organizationId, props.eventId, props.roomId);
    const snapShot = await connectionRef.get();

    if (snapShot.exists()) {
        const userData = snapShot.val();
        const users = Object.values(userData);
        return users;
    }
    return [];
};

export const allowAllRoomUser = async (props: IWaitingDetails & { hasNoWaitingQueue: boolean }) => {
    const connectionRef = Components.reference(props.organizationId, props.eventId);
    return connectionRef.doc(props.roomId).update({ hasNoWaitingQueue: props.hasNoWaitingQueue });
};
