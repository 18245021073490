export const ONE_SECOND_IN_MS = 1000;
export const ONE_MINUTE_IN_MS = ONE_SECOND_IN_MS * 60;
export const GENERAL_LOUNGE = 'generalLounge';
export const RESTRICTED_LOUNGE = 'restrictedLounge';

export const EVENT_DISPLAY_TYPES = {
    KEYNOTE: 'keynotes',
    TRACKS: 'tracks',
    KEYNOTE_TRACKS: 'keynoteTracks',
};

export const EVENT_TYPES = {
    KEYNOTE: 'keynote',
    TRACK: 'track',
};

export const LOUNGE_TYPES = {
    GENERAL: 'GeneralLounge',
    SPONSOR: 'SponsorLounge',
    RESTRICTED: 'RestrictedLounge',
};

export const ATTENDEE_TYPES = {
    RoundTable: ['LP', 'GP', 'Staff'],
    VIPLounge: ['Sponsor', 'LP', 'Staff'],
};

// https://events-assets.sharedstudios.com/dynamic/images/vhBBdmj5EpKczKJ5k6oZ/favicon.ico
export const defaultFavicon = 'https://events-assets.sharedstudios.com/assets/images/default/favicon.png';
export const defaultCompanyLogo = '/images/default/shared-studios-logo.svg'