import { createAction } from 'redux-act';

export const setGlobalUsers = createAction<any>('set global users');
export const setRequestMeeting = createAction<any>('set request meeting');
export const setPrivateChatState = createAction<any>('open private chat');
export const setEventChatState = createAction<any>('open event chat');
export const sendMeetInvite = createAction<any>('send meet invite');
export const setBlockedByUsers = createAction<string[]>('send meet invite');
export const setBlockedUsers = createAction<string[]>('send meet invite');
export const setBlockId = createAction<any>('send meet invite');
export const setSaveProfileId = createAction<any>('send meet invite');
export const setQuesAnsState = createAction<any>('open private chat');
