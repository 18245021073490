import { getSpeakerList, getConversationSpeakerList } from 'modules/utils/Firebase/EventSpeakers';
import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { getSpeakerListSuccess } from 'modules/actions';
import * as Sentry from '@sentry/react';

export const getSpeakerListSaga = function* (action: any): SagaIterator {
    try {
        let speakerList
        const event = action.payload
        if (event.type === 'conversation' && event.videoPlatform === '3rd_party' && !!event.thirdPartyUrl) {
            speakerList = yield call(getConversationSpeakerList, event.id, event.organizationId)
        }
        else {
            speakerList = yield call(getSpeakerList, event.id);
        }
        yield put(getSpeakerListSuccess(speakerList));
    } catch (error) {
        console.error(error);
        Sentry.captureException(error);
    }
};
