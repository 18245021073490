import { createReducer } from 'redux-act';
import { setNetworkingRoomdata, removeNetworkingRoom } from 'modules/actions';

export type NetworkingReducerState = { isFetching: boolean; networkingRoom: any | null };

const initialState: NetworkingReducerState = {
    isFetching: true,
    networkingRoom: null,
};

export const networking = createReducer<NetworkingReducerState>({}, initialState);

networking.on(setNetworkingRoomdata, (state: NetworkingReducerState, payload: any) => ({
    ...state,
    networkingRoom: payload,
    isFetching: false,
}));

networking.on(removeNetworkingRoom, () => initialState);
