import { Routing } from 'modules/utils/Firebase/Api';

export const getRoutingDocument = async (friendlyUrl: string) => {
    const routingRef = Routing.reference();
    const snapshot = await routingRef.where('friendlyUrl', '==', friendlyUrl).get();

    if (!snapshot.empty) {
        let route: any = null;
        snapshot.forEach((doc) => {
            route = { ...doc.data() };
        });

        if (route?.type === 'discovery') {
            const obj = {
                organizationId: route?.organizationId,
                isRoutingDocument: true,
                type: 'discovery',
            };
            return obj;
        } else if (route && Object.values(route?.days).length > 0) {
            const link: string[] = Object.values(route?.days);
            const eventSlug = link[0].split('/')[link[0].split('/').length - 1];

            const obj = {
                eventId: route?.eventId,
                organizationId: route?.organizationId,
                link: link[0],
                eventSlug,
                isRoutingDocument: true,
                type: 'event',
            };
            return obj;
        }
    }
    return false;
};
