import React from 'react';

export const ScheduleConfig = {
    settings: {
        layout: {
            config: {
                header: { display: true },
                footer: { display: true },
                navbar: { display: true },
            },
        },
    },
    isPrivate: true,
    eventName: 'schedule',
    routes: [
        {
            path: '/:organizationId/:eventSlug/schedule',
            component: React.lazy(() => import('./SchedulesNew')),
        },
    ],
};
