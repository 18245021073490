import React from 'react';
import { NavLink as RouterNavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { registrationSessionPropertyLink } from 'modules/selectors';
import { buildSluggedRoute } from 'modules/utils/CommonService';
import pickBy from 'lodash/pickBy';

export const NavLink = (props: any) => {
    const { eventSlug, organizationId } = useSelector(registrationSessionPropertyLink);
    const to = props.to;
    const filteredProps = pickBy(props, (_, k) => {
        return k !== 'to';
    });
    return (
        <RouterNavLink to={buildSluggedRoute(organizationId, eventSlug, to)} {...filteredProps}>
            {props.children}
        </RouterNavLink>
    );
};
export default NavLink;
