import { createReducer } from 'redux-act';
import * as actions from 'modules/actions';

export type ModalReducerState = {
    isLoginOptionModalOpen: boolean;
    isLoginModalOpen: boolean;
    isRegisterModalOpen: boolean;
    isLoungeModalOpen: boolean;
    isLoungeOtherModalOpen: boolean;
    isMediaPermissionModalOpen: boolean;
    isInviteeAuthenticateModalOpen: boolean;
    isInviteeRegisterModalOpen: boolean;
    isErrorCapacityModalOpen: boolean;
    message?: string;
    isSubmitBtn: boolean;
    breakoutRoomId: string;
    isConfirmModalOpen: boolean;
    isResetPassWordModalOpen: boolean;
    isChatModalOpen: boolean;
    isSchedulesModalOpen: boolean;
    isParticipantState: boolean;
    isPollResultModalOpen: boolean;
    isEditorState: boolean;
    isProfileModal: boolean;
    isOutsideClickAllowed : boolean;
    isCropperModal: boolean;
    cropperData: any;
};

const initialState: ModalReducerState = {
    isRegisterModalOpen: false,
    isLoginOptionModalOpen: false,
    isLoginModalOpen: false,
    isLoungeModalOpen: false,
    isOutsideClickAllowed : true,
    isLoungeOtherModalOpen: false,
    isMediaPermissionModalOpen: false,
    isInviteeAuthenticateModalOpen: false,
    isInviteeRegisterModalOpen: false,
    isSubmitBtn: false,
    isErrorCapacityModalOpen: false,
    breakoutRoomId: '',
    isConfirmModalOpen: false,
    isResetPassWordModalOpen: false,
    isChatModalOpen: false,
    isSchedulesModalOpen: false,
    isParticipantState: false,
    isPollResultModalOpen: false,
    isEditorState: false,
    isProfileModal: false,
    isCropperModal: false,
    cropperData: null,
};

export const modal = createReducer<ModalReducerState>({}, initialState);
modal.on(actions.openLoginModal, (state: ModalReducerState) => ({
    ...state,
    isLoginModalOpen: true,
}));

modal.on(actions.closeLoginModal, (state: ModalReducerState) => ({
    ...state,
    isLoginModalOpen: false,
}));

modal.on(actions.allowOutsideClick, (state: ModalReducerState,payload: boolean) => ({
    ...state,
    isOutsideClickAllowed: payload,
}));

modal.on(actions.openRegisterModal, (state: ModalReducerState) => ({
    ...state,
    isRegisterModalOpen: true,
}));

modal.on(actions.closeRegisterModal, (state: ModalReducerState) => ({
    ...state,
    isRegisterModalOpen: false,
}));

modal.on(actions.openLoginOptionModal, (state: ModalReducerState) => ({
    ...state,
    isLoginOptionModalOpen: true,
}));

modal.on(actions.closeLoginOptionModal, (state: ModalReducerState) => ({
    ...state,
    isLoginOptionModalOpen: false,
}));

modal.on(actions.openLoungeProfileModal, (state: ModalReducerState, payload: boolean) => ({
    ...state,
    isLoungeModalOpen: true,
    isSubmitBtn: payload,
    breakoutRoomId: '',
}));

modal.on(actions.openBreakoutProfileModal, (state: ModalReducerState, payload: string) => ({
    ...state,
    isLoungeModalOpen: true,
    isSubmitBtn: true,
    breakoutRoomId: payload,
}));

modal.on(actions.closeLoungeProfileModal, (state: ModalReducerState) => ({
    ...state,
    isLoungeModalOpen: false,
}));

modal.on(actions.openLoungeOtherModal, (state: ModalReducerState) => ({
    ...state,
    isLoungeOtherModalOpen: true,
}));

modal.on(actions.closeLoungeOtherModal, (state: ModalReducerState) => ({
    ...state,
    isLoungeOtherModalOpen: false,
}));

modal.on(actions.openMediaPermissionModal, (state: ModalReducerState, payload: string) => ({
    ...state,
    isMediaPermissionModalOpen: true,
    message: payload,
}));

modal.on(actions.closeMediaPermissionModal, (state: ModalReducerState) => ({
    ...state,
    isMediaPermissionModalOpen: false,
    message: '',
}));

modal.on(actions.openInviteeAuthenticateModal, (state: ModalReducerState) => ({
    ...state,
    isInviteeAuthenticateModalOpen: true,
}));

modal.on(actions.closeInviteeAuthenticateModal, (state: ModalReducerState) => ({
    ...state,
    isInviteeAuthenticateModalOpen: false,
}));

modal.on(actions.openInviteeRegisterModal, (state: ModalReducerState) => ({
    ...state,
    isInviteeRegisterModalOpen: true,
}));

modal.on(actions.closeInviteeRegisterModal, (state: ModalReducerState) => ({
    ...state,
    isInviteeRegisterModalOpen: false,
}));

modal.on(actions.openErrorCapacityModal, (state: ModalReducerState) => ({
    ...state,
    isErrorCapacityModalOpen: true,
}));

modal.on(actions.closeErrorCapacityModal, (state: ModalReducerState) => ({
    ...state,
    isErrorCapacityModalOpen: false,
}));

modal.on(actions.resetState, () => initialState);

modal.on(actions.authLogOut, () => initialState);

modal.on(actions.openConfirmModal, (state: ModalReducerState) => ({
    ...state,
    isConfirmModalOpen: true,
}));

modal.on(actions.closeConfirmModal, (state: ModalReducerState) => ({
    ...state,
    isConfirmModalOpen: false,
}));

modal.on(actions.openResetPassWordModal, (state: ModalReducerState) => ({
    ...state,
    isResetPassWordModalOpen: true,
}));

modal.on(actions.closeResetPassWordModal, (state: ModalReducerState) => ({
    ...state,
    isResetPassWordModalOpen: false,
}));

modal.on(actions.toggleChatComponent, (state: ModalReducerState, payload: boolean) => ({
    ...state,
    isChatModalOpen: payload,
}));

modal.on(actions.toggleSchedulesComponent, (state: ModalReducerState, payload: boolean) => ({
    ...state,
    isSchedulesModalOpen: payload,
}));

modal.on(actions.toggleParticipant, (state: ModalReducerState, payload: boolean) => ({
    ...state,
    isParticipantState: payload,
}));

modal.on(actions.openPollResultModal, (state: ModalReducerState) => ({
    ...state,
    isPollResultModalOpen: true,
}));

modal.on(actions.closePollResultModal, (state: ModalReducerState) => ({
    ...state,
    isPollResultModalOpen: false,
}));

modal.on(actions.toggleEditor, (state: ModalReducerState, payload: boolean) => ({
    ...state,
    isEditorState: payload,
}));

modal.on(actions.openProfileModal, (state: ModalReducerState, payload: boolean) => ({
    ...state,
    isProfileModal: payload,
}));

modal.on(actions.toggleCropperModal, (state: ModalReducerState, payload: boolean) => ({
    ...state,
    isCropperModal: payload
}));

modal.on(actions.setCropperImageData, (state: ModalReducerState, payload: any) => ({
    ...state,
    cropperData: payload
}));


