import { ICard } from 'modules/schemas/template';

/* ------- VIRTUAL CONFERENCE ------- */
export interface IVirtualConference {
    capacity: number;
    caption: string;
    about: string;
    durationMinutes: number;
    id: string;
    imageSrc: string;
    roomUuid: string;
    title: string;
    type: IBreakoutType;
    utcStartTimeMillis: number;
    uuid: string;
    subTitle: string;
}

export enum IBreakoutType {
    keynote = 'keynote',
    interactive = 'interactive',
    stream = 'stream',
    breakout = 'breakout',
    waiting = 'waiting',
    broadcast = 'broadcast',
    informative = 'informative',
}

export interface IKeynote extends IVirtualConference {
    presenters: IPresenter[];
    moderators?: IPresenter[];
    presenterList?: IPresenter[];
    card: ICard;
    liveStreamSrc?: string;
    showSpeakers: boolean;
    _startDate: string;
    description: string;
    visible?: boolean;
    autoRecord?: boolean;
    componentType?: string;
    waitingImageSrc?: string;
    backgroundImageSrc?: string;
    typeLabel?: string;
    order?: number;
    isPrivate?: boolean;
    redirectUrl?: string;
    roomEndTime?: number;
    additionalMinutes?: number;
    waitingNotifyTitle?: string;
    hasModeratorRights?: boolean;
    hasNoWaitingQueue?: boolean;
    promoteSession?: boolean;
    isRoomOpen?: boolean;
    breakoutRoom?: {
        type: string;
        attendeeReJoin: boolean;
    };
    isLiveStream?: boolean;
    autoBreakoutGroupRecord?: boolean;
}

export type IUser = {
    friendlyName: string;
    identity: string;
    firstName: string;
    lastName: string;
    jobTitle: string;
    companyName: string;
    workLocation: string;
    description: string;
    uid: string;
    twitter?: string;
    linkedin?: string;
    role: string;
    avatarUrl: string | undefined;
};

export interface IPresenter {
    company: string;
    description: string;
    id: string;
    imageSrc: string;
    name: string;
    title: string;
    className: string;
}

export interface IAttendeeProfile {
    firstName: string;
    lastName: string;
    avatar: string;
    companyName: string;
    companyTitle: string;
    role?: string[];
    tags?: string;
    uid?: string;
}

export interface IBreakoutData {
    capacity?: number;
    durationMinutes: number;
    id: string;
    title: string;
    type: string;
    utcStartTimeMillis: number;
    visible: boolean;
    presenters: any[];
    moderators: any[];
}

export interface ISponsorsData {
    eventId: string;
    html?: string;
    id: string;
    isSponsorWelcome: boolean;
    logoUrl?: string;
    mediaUrl?: string;
    name: string;
    organizationId: string;
    sponsorLoungeRedirectToBreakout?: boolean;
    type?: string;
    websiteUrl?: string;
}

export interface ICallToAction {
    card: {
        background?: {
            imageUrl: string;
        };
        html: string;
        openUrl: string;
    };
    component: string;
    id: string;
    name?: string;
    order: number;
}
export interface IEventLobby {
    header?: IEventHeaderProps;
    social?: ISocialTags;
    keynotes?: IKeynotesProps;
    sponsors?: {
        action: {
            label: string;
        };
        page: {
            title: string;
            bgImage: string;
        };
    };
    // sponsor?: {
    //     page: {
    //         followUp: {
    //             label: string;
    //         };
    //         visitWebsite: {
    //             label: string;
    //         };
    //         sponsorLoungeBtn: {
    //             label: string;
    //         };
    //     };
    // };
    conference?: {
        sponsorTypeText: string;
        isChatActive: boolean;
        sponsorDisplayInKeynote: string;
    };
    invite?: {
        label: string;
        placeholder: string;
    };
    action?: {
        label: string;
    };
    schedule?: {
        page: {
            title: string;
        };
    };
}

export interface IEventHeaderProps {
    titleHtml: string;
    imageUrl: any;
}
export interface ISocialTags {
    label: string;
    twitter?: string;
    facebook?: string;
    linkedin?: string;
    youtube?: string;
    instagram?: string;
}
export interface IKeynotesProps {
    activeKeynoteText: string;
    expiredKeynoteText: string;
    lobbyDescription: string;
    _startDate: string;
    utcStartTimeMillis: number;
    lobbyTitle: string;
    // lobbyTitleBackground: string;
    showSpeakers: string;
    showTimer: string;
    durationMinutes: number;
    card: {
        background: {
            imageUrl: string;
        };
        description: string;
    };
    showKeynoteSection: boolean;
}
