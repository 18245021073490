import { IStoreState } from 'modules/types';

export const getLoungeModal = (state: IStoreState) => state.modal.isLoungeModalOpen;
export const getMediaPermissionModal = (state: IStoreState) => state.modal.isMediaPermissionModalOpen;
export const getModalMessage = (state: IStoreState) => state.modal.message;
export const getErrorCapacityModal = (state: IStoreState) => state.modal.isErrorCapacityModalOpen;
export const getChatComponentDisplayMode = (state: IStoreState) => state.modal.isChatModalOpen;
export const getSchedulesComponentDisplayMode = (state: IStoreState) => state.modal.isSchedulesModalOpen;
export const getChatParticipantState = (state: IStoreState) => state.modal.isParticipantState;
export const getEditorState = (state: IStoreState) => state.modal.isEditorState;
export const getLoginModalState = (state: IStoreState) => state.modal.isLoginModalOpen;
export const getOutsideClickAllowed = (state: IStoreState) => state.modal.isOutsideClickAllowed;
export const getProfileMOdalState = (state: IStoreState) => state.modal.isProfileModal;

export const getCropperModalState = (state: IStoreState) =>
    state.modal.isCropperModal;

export const getCropperData = (state: IStoreState) => state.modal.cropperData;

