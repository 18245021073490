import { Group } from 'modules/utils/Firebase/Api';
import { eventChannel } from 'redux-saga';
import firebase from 'modules/utils/Firebase';

interface IAttendeeDetails {
    organizationId: string;
    eventId: string;

    groupId: string;
}

export const getBreakoutGroupDetail = async (props: IAttendeeDetails) => {
    const groupRef = Group.reference(props.organizationId, props.eventId);
    const snapshot = await groupRef.doc(props.groupId).get();

    if (snapshot.exists) {
        const user = snapshot.data();
        return user;
    }
    return false;
};

export const getBreakoutGroupDetailListener = (props: IAttendeeDetails) => {
    const connectionRef = Group.reference(props.organizationId, props.eventId).doc(props.groupId);
    const connectionComponentsListner = eventChannel((emit) => {
        const unSubscribe = connectionRef.onSnapshot((doc: firebase.firestore.DocumentSnapshot) => {
            const data = doc.data();
            if (doc.exists && data) {
                emit(data);
            } else {
                emit(false);
            }
        });
        return () => {
            unSubscribe();
        };
    });
    return connectionComponentsListner;
};

export const getSelectedBreakoutGroupDetailApi = async (props: IAttendeeDetails) => {
    const groupRef = Group.reference(props.organizationId, props.eventId).doc(props.groupId);
    const snapshot = await groupRef.get();
    if (snapshot.exists) {
        return snapshot.data();
    }
    return false;
};

export const getPollsUserGroupListner = async (payload: any) => {
    const groupResultRef = Group.reference(payload.organizationId, payload.eventId);
    const connectionGroupRef = groupResultRef
        .where('users', 'array-contains', payload.email)
        .orderBy('groupExpiryTimeStamp', 'desc')
        .limit(1);

    const connectionGroupListner = eventChannel((emit) => {
        const unSubscribe = connectionGroupRef.onSnapshot((querySnapshot: firebase.firestore.QuerySnapshot) => {
            if (!querySnapshot.empty) {
                const groupData: any[] = [];
                querySnapshot.forEach((doc: any) => {
                    groupData.push({ ...doc.data(), id: doc.id });
                });
                emit(groupData?.[0] ?? false);
            } else {
                emit(false);
            }
        });
        return () => {
            unSubscribe();
        };
    });
    return connectionGroupListner;
};
