import React, { PropsWithChildren, useEffect, useState, useCallback } from 'react';
import kebabCase from 'lodash/kebabCase';
import styled, { css } from 'styled-components/macro';
import Header from 'components/Header';
import { Container, Col, Row } from 'react-bootstrap';
import { RoundArrowButton } from 'components';
import { useSelector } from 'react-redux';
// import { getEvent } from 'modules/selectors';
import * as selectors from 'modules/selectors';

const gradient = css`
    background-image: linear-gradient(302deg, #00d7ef, #00afef);
    background-position: center center;
    background-size: 120% 120%;
`;

interface IViewProps extends PropsWithChildren<any> {
    gradient?: boolean;
}

const View = styled.div<IViewProps>`
    ${(props) => (props.gradient ? gradient : '')};
    &.experts-container {
        background: white;
    }
`;

const Title = styled(Col)`
    color: var(--gray-shade-color);
    font-size: 1.35em;
    font-weight: 700;
    text-decoration: none;
    position: relative;
    text-align: center;
`;

const PageHeader = styled(Row)`
    && {
        align-items: flex-end;
    }
`;

interface IProps extends PropsWithChildren<any> {
    identifier?: string;
    gradient?: boolean;
    left?: React.ReactNode;
    title?: string;
    right?: React.ReactNode;
    showTitle?: boolean;
    isLounge?: boolean;
    loungeTitle?: string;
    loungeClass?: boolean;
    sponsorId?: string | null;
}

export function Page(props: IProps) {
    const { sponsorId, isLounge, loungeTitle } = props;
    const identifier = props.identifier || kebabCase(props.title);
    const showTitle = props?.showTitle ?? true;
    const [title, setTitle] = useState('');
    // const event = useSelector(getEvent);
    // const isLoadingData = useSelector(organizationLoader);
    const lobby = useSelector(selectors.getLobbyData);
    const isLobbyDataLoading = useSelector(selectors.getLobbyLoading);

    useEffect(() => {
        if (!isLobbyDataLoading) {
            setTitle(lobby.action.label);
        }
    }, [lobby.action.label, isLobbyDataLoading]);

    const rednderBackButton = useCallback(() => {
        if (isLounge) {
            const routeName = sponsorId ? `/lounge?sponsor=${sponsorId}` : '/lounge';
            return <RoundArrowButton to={routeName} title={loungeTitle} direction="left" />;
        } else if (sponsorId) {
            return <RoundArrowButton to={`/sponsor?id=${sponsorId}`} title={loungeTitle} direction="left" />;
        } else if (identifier === 'sponsor' && lobby.sponsors && lobby.sponsors.page && lobby.sponsors.page.title) {
            return (
                <>
                    {' '}
                    <RoundArrowButton to="/lobby" title={title} direction="left" />{' '}
                    <RoundArrowButton
                        to="/sponsors"
                        title={`Back to ${lobby.sponsors.page.title}`}
                        direction="left"
                        padding
                    />{' '}
                </>
            );
        }
        return <RoundArrowButton to="/lobby" title={title} direction="left" />;
    }, [sponsorId, isLounge, title, loungeTitle, identifier, lobby.sponsors]);

    const loungeClassName = props.loungeClass ? 'align-items-end my-4 lounge-row' : 'my-4';
    return (
        <View gradient={props.gradient} id={identifier} className={`${props.className || ''} ${identifier}-container`}>
            {/*   APP HEADER  */}
            <Header />
            {/* PAGE CONTENT */}
            <Container className="my-4">
                {/*  PAGE HEADER */}
                <PageHeader md={3} className="align-items-start">
                    <Col className="">{rednderBackButton()}</Col>
                    {showTitle && <Title>{props.title}</Title>}
                    <Col className="px-0">
                        <div>{props.right}</div>
                    </Col>
                </PageHeader>
                {/*  PAGE BODY */}

                <Row className={loungeClassName}>{props.children}</Row>

                {/*  PAGE FOOTER */}
                <div className="d-flex flex-row">{props.footer}</div>
            </Container>
        </View>
    );
}
