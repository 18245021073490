import { createReducer } from 'redux-act';
import * as actions from 'modules/actions';

export type EventDetailReducerState = {
    eventData: any
};

const initialState: EventDetailReducerState = {
    eventData: {}
};

export const eventDetail = createReducer<EventDetailReducerState>({}, initialState);

eventDetail.on(actions.setEventDetail, (state: EventDetailReducerState, payload: any) => ({
    ...state,
    eventData: { ...payload },
}));
