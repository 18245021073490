import React from 'react';
import * as ReactRedux from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import * as actions from 'modules/actions';
import * as selectors from 'modules/selectors';
import * as StyledLayout from './styles';
import { AvatarContainer } from 'components/Login/style';
import { UserIcon } from '../Icons';
import { avatarUrl, initials as profileInitials } from 'components/Login/common';

/** The current user's avatar can be used from `user.profile.avatar` as long as the value is refreshed in case user has edited or updated an existing image. */
export const ProfileAvatar = (properties: React.PropsWithChildren<any>) => {
    const reduxDispatch = ReactRedux.useDispatch();
    const profile: any = ReactRedux.useSelector(selectors.getProfileData);
    const avatar = ReactRedux.useSelector(selectors.getAvatar);
    const initials = React.useMemo(() => profileInitials(profile), [profile]);
    const event = ReactRedux.useSelector(selectors.getEvent);
    const getInitials = function (str: string) {
        const names = str.split(' ');
        let initials = names[0].substring(0, 1).toUpperCase();

        if (names.length > 1) {
            initials += names[names.length - 1].substring(0, 1).toUpperCase();
        }
        return initials;
    };
    // In case this value is deleted or url expires, either resolve url or remove image
    const onError = React.useCallback(() => {
        if (avatar) {
            avatarUrl(avatar)
                .then((resolved) => reduxDispatch(actions.registrationProfileUpdate({ avatar: resolved })))
                .catch(() => reduxDispatch(actions.registrationProfileUpdate({ avatar: undefined })));
        } else reduxDispatch(actions.registrationProfileUpdate({ avatar: undefined }));
    }, [avatar, reduxDispatch]);

    return (
        <StyledLayout.Container className={properties.className}>
            {!avatar ? (
                <StyledLayout.Initials className="initials" strokeWidth={2} themeColor={event?.theme?.buttonColor}>
                    {isEmpty(initials) ? (
                        <UserIcon />
                    ) : (
                        getInitials(
                            `${profile?.firstName} ${profile?.lastName}` || profile?.fullName || profile?.name || '',
                        )
                    )}
                </StyledLayout.Initials>
            ) : (
                <StyledLayout.Image className="image">
                    <img alt="avatar" src={avatar} onError={onError} />
                </StyledLayout.Image>
            )}
        </StyledLayout.Container>
    );
};

interface Participant extends React.Props<any> {
    email?: string;
    firstName?: string;
    fullName?: string;
    lastName?: string;
    name?: string;
    uid?: string;
}
/** Avatar with collected image cache.  Refreshes every 30 minutes.  Use method above for currentUser's profile. */
export const ParticipantAvatar = ({ uid, ...properties }: Participant) => {
    const reduxDispatch = ReactRedux.useDispatch();
    /** Pass the participant's uid to the `getParticipantAvatar(uid)` selector. */
    const avatar = ReactRedux.useSelector(selectors.getParticipantAvatar(uid));
    React.useEffect(() => {
        /** Add missing avatar to the cache/queue to get fresh url. */
        if (!avatar && uid) reduxDispatch(actions.participantAvatar(uid));
    }, [uid, avatar, reduxDispatch]);

    const initials = profileInitials(properties);
    return (
        <AvatarContainer>
            {/* <div className="initials">{isEmpty(initials) ? <UserIcon /> : initials}</div> */}
            {avatar ? (
                <div className="image">
                    <img id={uid} alt="avatar" src={avatar} onError={onError} />
                </div>
            ) : (
                <div className="initials">{isEmpty(initials) ? <UserIcon /> : initials}</div>
            )}
        </AvatarContainer>
    );
};

/** Hide invalid images on error */
function onError(event: React.SyntheticEvent<HTMLImageElement, Event>) {
    const currentTarget = get(event, 'currentTarget', null);
    const id = get(currentTarget, 'id', null);
    if (id) console.error('Error avatar.img', id);
    if (currentTarget) currentTarget.style.display = 'none';
}
