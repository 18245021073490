import React, { useState } from 'react';
import is from 'classnames';
import Cropper from 'react-easy-crop';
import * as ReactRedux from 'react-redux';
import { Spinner } from 'react-bootstrap';
import { FaMinus, FaPlus } from 'react-icons/fa';
import { useMount } from 'components/Cropper/useMount';
import { cropImage, ImageFile } from 'components/Cropper/image';
import { ThumbnailComponent, ThumbnailContainer } from 'components/Login/style';
import { Button, Horizontal } from 'components/Login/inputs';
import { Mode } from 'components/Login/constants/constants';
import { Space } from 'components/Login/common';
import * as selectors from 'modules/selectors';
import * as actions from 'modules/actions';
import { useDispatch } from 'react-redux';
import moment from 'moment-timezone';

export function EditThumbnail() {
    const { isMounted } = useMount();
    const [loading, setLoading] = useState(false);
    const avatar = ReactRedux.useSelector(selectors.getAvatar);
    const avatarFile = ReactRedux.useSelector(selectors.getAvatarFileData);
    const [image, setImage] = React.useState<ImageFile | null>(null);
    const [crop, setCrop] = React.useState({ x: 0, y: 0 });
    const [zoom, setZoom] = React.useState(1);
    const dispatch = useDispatch();
    const now = new Date();
    const dateStringWithTime = moment(now).format('YYYY-MM-DD_HH:mm').toString();
    const onCropComplete = (croppedArea: any, croppedAreaPixels: any) => {
        const source = avatarFile?.source;
        const type = avatarFile?.type;
        if (!source) return;
        cropImage({ source, type }, croppedAreaPixels)
            .then((image: ImageFile | null) => {
                if (isMounted()) setImage(image);
            })
            .catch((error) => {
                console.error('EditThumbnail', error.message);
            });
    };

    const saveProfileImage = async () => {
        if (image) {
            try {
                setLoading(true);
                if (image?.blob) {
                    image.blob.name = avatarFile?.name ? avatarFile.name : `${dateStringWithTime}.${avatarFile?.extension}`
                }
                dispatch(actions.saveAvatarData({ avatar: image.blob }));
            } catch (e) {
                setLoading(false);
            }
        }
    };

    const cancelBtn = () => {
        dispatch(actions.authMode(Mode.profile));
    };

    return (
        <React.Fragment>
            <ThumbnailComponent>
                <ThumbnailContainer>
                    <Cropper
                        image={avatarFile?.source ? avatarFile?.source : avatar}
                        crop={crop}
                        zoom={zoom}
                        aspect={3 / 3}
                        cropShape="round"
                        showGrid={false}
                        onCropChange={setCrop}
                        onCropComplete={onCropComplete}
                        onZoomChange={setZoom}
                    />
                </ThumbnailContainer>
                <Space />
                <div className="controls d-flex" style={{ color: 'var(--slate-color)', alignItems: 'center' }}>
                    <FaMinus />
                    <Space />
                    <input
                        aria-labelledby="Zoom"
                        type="range"
                        min="1"
                        max="3"
                        step={0.1}
                        value={zoom}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setZoom(Number(event.currentTarget.value));
                        }}
                    />
                    <Space />
                    <FaPlus />
                </div>
                <Space />
            </ThumbnailComponent>
            <Horizontal>
                <div style={{ flexGrow: 1 }} />
                <Button className={is({ outline: true })} onClick={cancelBtn}>
                    Cancel
                </Button>
                <Space />
                <Button
                    className={is({ disabled: image === null })}
                    onClick={(event: React.MouseEvent) => {
                        event.preventDefault();
                        saveProfileImage();
                    }}
                >
                    {loading && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}
                    {loading && 'Saving...'}
                    {!loading && 'Save'}
                </Button>
            </Horizontal>
        </React.Fragment>
    );
}
