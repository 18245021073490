import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FormHeader, Space } from '../common/index';
import * as actions from "modules/actions"
import * as selectors from "modules/selectors"
// import { Mode } from "../constants/constants"

import {
    Button,
    Horizontal,
    Vertical,
    Text,
    Line
} from '../style';
// import { Link } from '../inputs';
import { isEmpty } from 'lodash';
import { Mode } from '../constants/constants';

export const NotRegisteredMode = () => {
    const dispatch = useDispatch()
    const onBack = () => {
        dispatch(actions.authMode(Mode.initial))
        dispatch(actions.signOutUser());
    }

    const onLinkClick = () => (event: React.SyntheticEvent) => {
        event.preventDefault();
        const url = websiteUrl;
        if (url) {
            const tab = window.open();
            if (tab != null) {
                tab.opener = null;
                tab.location.href = url;
            }
        }
    }
    const { websiteUrl } = useSelector(selectors.getEvent);
    return (
        <React.Fragment>
            <FormHeader title="You are not registered to this event" />
            <Space height={0.5} />
            <Vertical className="center text-center">
                <Text size={16}>
                    Sorry, the user account you selected does not have a permission to access this event. Please select
                    another account or{' '}
                    <button style={isEmpty(websiteUrl) ? { border: 'none', backgroundColor: "white" } : {}} onClick={onLinkClick}>
                        contact the host.
                    </button>
                </Text>
            </Vertical>
            <Space height={2} />
            <Line />
            <Space height={1.5} />
            <Horizontal>
                <Space />
                <Button onClick={onBack}>Back</Button>
                <Space />
            </Horizontal>
        </React.Fragment>
    );

}