import React, { PropsWithChildren } from 'react';
import styled from 'styled-components/macro';
import { useSelector } from 'react-redux';
import { getEventThemeColor } from 'modules/selectors';
import isEmpty from 'lodash/isEmpty';
interface IProps {
    btnName: string;
    onClick?: () => void;
}

const RouteButton = styled.button<PropsWithChildren<any>>`
    margin-top: 15px;
    background: ${({ themeColor }) => (!isEmpty(themeColor) ? themeColor : 'var(--theme-color)')} !important;
    font-size: 14px;
    font-weight: 600;
    color: #ffffff !important;
    width: 100%;
    border: none;
    height: 40px;
    border-radius: 4px;

    &:hover,
    &:active,
    &:visited,
    &:link {
        color: #ffffff;
    }
`;

export const RoundTableButton = (props: IProps) => {
    // const event = useSelector(getEvent);
    const themeColor = useSelector(getEventThemeColor);

    return (
        <RouteButton themeColor={themeColor} className="btn" onClick={props.onClick}>
            {props.btnName}
        </RouteButton>
    );
};
export default RoundTableButton;
