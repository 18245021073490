import { database } from 'modules/utils/Firebase';
import { NetworkingLoungeUser } from 'modules/utils/Firebase/Api';
import { eventChannel } from 'redux-saga';

interface IAttendeeDetails {
    organizationId: string;
    eventId: string;
    uid: string;
    name?: string;
}

export const getUserNetworkLoungeConnected = (props: IAttendeeDetails) => {
    const connectionRef = NetworkingLoungeUser.userRef(props.uid, props.organizationId, props.eventId);
    const connectionBrListner = eventChannel((emit) => {
        const ref = database.ref('.info/connected');
        ref.on('value', (snapshot: any) => {
            if (snapshot.val() === false) {
                return emit(snapshot.val());
            }
            connectionRef
                .onDisconnect()
                .remove()
                .then(() => {
                    return emit(true);
                });
        });
        return () => {
            ref.off();
        };
    });
    return connectionBrListner;
};

export const removeUserNetworkLoungeConnection = async (props: IAttendeeDetails) => {
    const connectionRef = NetworkingLoungeUser.userRef(props.uid, props.organizationId, props.eventId);
    await connectionRef.remove();
};

export const addUserNetworkLoungeConnection = async (props: IAttendeeDetails) => {
    try {
        if (props.uid && props.organizationId && props.eventId) {
            const connectionRef = NetworkingLoungeUser.userRef(props.uid, props.organizationId, props.eventId);
            const payload: any = { available: true };
            if (props.name) {
                payload.name = props.name;
            }
            await connectionRef.set(payload);
        }
    } catch (e) {
        console.error('Error ', e);
    }
};

export const getNetworkingLoungeUserData = (props: IAttendeeDetails) => {
    const connectionRef = NetworkingLoungeUser.ref(props.organizationId, props.eventId);
    const connectionRoomListner = eventChannel((emit) => {
        connectionRef.on('value', (snapshot: any) => {
            if (snapshot.val()) {
                return emit(snapshot.val());
            } else return emit(false);
        });
        return () => {
            connectionRef.off();
        };
    });
    return connectionRoomListner;
};
