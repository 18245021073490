import { createReducer } from 'redux-act';
import { IUser } from 'modules/schemas';
import {
    setGlobalUsers,
    setRequestMeeting,
    setPrivateChatState,
    sendMeetInvite,
    setBlockedByUsers,
    setBlockedUsers,
    setBlockId,
    setSaveProfileId,
    setQuesAnsState,
    setEventChatState,
} from 'modules/actions';

export type chatReducerState = {
    globalUsers: IUser[];
    requestMeetingIdentity: string | null;
    meetInviteIndentity: string | null;
    openPrivateChat: boolean;
    openEventChat: boolean;
    blockedByIds: string[];
    blockedIds: string[];
    blockId: any;
    saveProfileId: any;
    openQAChat: boolean;
};

const initialState: chatReducerState = {
    globalUsers: [],
    requestMeetingIdentity: null,
    meetInviteIndentity: null,
    openPrivateChat: false,
    openEventChat: false,
    blockedByIds: [],
    blockedIds: [],
    blockId: null,
    saveProfileId: null,
    openQAChat: false,
};

export const chat = createReducer<chatReducerState>({}, initialState);

chat.on(setGlobalUsers, (state: chatReducerState, payload: IUser[]) => ({
    ...state,
    globalUsers: payload,
}));

chat.on(setRequestMeeting, (state: chatReducerState, payload: string) => ({
    ...state,
    requestMeetingIdentity: payload,
}));

chat.on(sendMeetInvite, (state: chatReducerState, payload: string) => ({
    ...state,
    meetInviteIndentity: payload,
}));

chat.on(setPrivateChatState, (state: chatReducerState, payload: boolean) => ({
    ...state,
    openPrivateChat: payload,
}));

chat.on(setEventChatState, (state: chatReducerState, payload: boolean) => ({
    ...state,
    openEventChat: payload,
}));


chat.on(setBlockedByUsers, (state: chatReducerState, payload: string[]) => ({
    ...state,
    blockedByIds: payload,
}));

chat.on(setBlockedUsers, (state: chatReducerState, payload: string[]) => ({
    ...state,
    blockedIds: payload,
}));

chat.on(setBlockId, (state: chatReducerState, payload: any) => ({
    ...state,
    blockId: payload,
}));

chat.on(setSaveProfileId, (state: chatReducerState, payload: any) => ({
    ...state,
    saveProfileId: payload,
}));

chat.on(setQuesAnsState, (state: chatReducerState, payload: boolean) => ({
    ...state,
    openQAChat: payload,
}));
