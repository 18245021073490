import { SagaIterator } from 'redux-saga';
import { all, call, put, take, select, fork, cancel, cancelled } from 'redux-saga/effects';
import * as actions from 'modules/actions';
import * as selectors from 'modules/selectors';
import Api from 'modules/utils/Api';
import FireBaseAuth from 'modules/utils/Firebase/auth';
import { identify } from 'react-fullstory';
import firebase from 'modules/utils/Firebase';
import { ISagaAction } from 'modules/types';
import { getAdminPeopleUser, getLookup } from 'modules/utils/Firebase/UserManagement/UserLookupListner';
import { getAdminRoleUser } from 'modules/utils/Firebase/UserManagement/UserRole';
import { getAdminEventRole } from 'modules/utils/Firebase/UserManagement/UserEventRole';

import * as Sentry from '@sentry/react';
const ASSETS_URL: string = process.env.REACT_APP_TOCCA_ASSETS_URL || '';

const { authUserFailed, authUserSuccess, setLoading, authLogOut } = actions;
const { getFirebaseAccessToken } = selectors;

export const signInSessionUserSaga = function* (): SagaIterator {
    yield put(setLoading());
    const FirseBaseAuthClient = new FireBaseAuth();
    const channel = yield call(FirseBaseAuthClient.getAuthChannel);
    try {
        while (true) {
            const { user: firebaseUser } = yield take(channel);
            if (firebaseUser) {
                firebase.analytics().setUserId(firebaseUser.uid, firebaseUser);
                const firebaseToken = yield call(FirseBaseAuthClient.fetchCurrentUserToken);
                const isOnSignUp = yield select(selectors.getIsOnSignUp);

                yield all([
                    put(actions.registrationEvent()),
                    put(
                        authUserSuccess({
                            firebaseToken,
                            userUid: firebaseUser.uid,
                            firebaseUser,
                            email: firebaseUser.email,
                        }),
                    ),
                ]);
                if (!isOnSignUp) {
                    yield put(actions.registrationProfileSynchronize());
                }

                const fsObj = {
                    email: firebaseUser.email,
                    uid: firebaseUser.uid,
                };
                yield call(identify, firebaseUser.uid, fsObj);
            } else {
                yield put(authUserFailed('User not signed in'));
                yield put(actions.setAuthFailureLayout());
            }
        }
    } catch (e: any) {
        console.warn('signInSessionUserSaga: ', e);
        yield put(authUserFailed(e.error));
        return;
    } finally {
        // unregister listener if the saga was cancelled
        if (yield take(authLogOut)) channel.close();
    }
};

export const signOutUser = function* (): SagaIterator {
    try {
        const FirseBaseAuthClient = new FireBaseAuth();
        yield call(FirseBaseAuthClient.logOutUser);
        yield put(authLogOut());
        yield put(actions.registrationSessionRestore());
    } catch (e) {
        console.warn(e);
        Sentry.captureException(e);
    }
};

export const fetchLookupSaga = function* (): SagaIterator {
    while (yield take(actions.setActiveListner)) {
        const connectionTask = yield fork(FetchLookupListner);
        // wait for the user stop action
        yield take([actions.signOutUser, actions.removeActiveListner]);

        yield cancel(connectionTask);
    }
};

export const fetchAdminPeopleEmailDetail = function* (): SagaIterator {
    const { email } = yield select(selectors.registrationSessionPropertyLink);
    const organizationId = yield select(selectors.getOrganizationId);

    try {
        const isTestUserHasAccess = yield call(getAdminPeopleUser, { organizationId, email });

        yield put(actions.setTestUserToOpenEvent({ isTestUserHasAccess }));
        yield put(actions.resetUserId());
    } catch (e) {
        console.error(String(e));
        Sentry.captureException(e);
    }
};

const FetchLookupListner = function* (): SagaIterator {
    try {
        const eventId = yield select(selectors.getEventId);
        const organizationId = yield select(selectors.getOrganizationId);
        const userId = yield select(selectors.getUserUid);
        const obj = {
            organizationId,
            eventId,
            userId,
        };

        const channel = yield call(getLookup, obj);
        try {
            while (true) {
                const result = yield take(channel);
                if (result && typeof result === 'object') {
                    interface EventAccess {
                        [key: string]: any[];
                    }
                    const eventAccess: EventAccess = {};
                    for (const type in result) {
                        const value = result[type];
                        const items = Array.isArray(value) ? value : [value];
                        eventAccess[type] = items.map((item) => {
                            if (item instanceof firebase.firestore.DocumentReference) {
                                return item.path;
                            }
                            return item;
                        });
                    }
                    const userSession = {
                        roles: [],
                        roomAccess: [],
                        eventAccess: result,
                    };

                    yield put(actions.authSetProfile(userSession));
                }
            }
        } catch (e) {
            console.warn(e);
        } finally {
            // unregister listener if the saga was cancelled
            if (yield cancelled()) {
                channel.close();
            }
        }
    } catch (error) {
        console.error(error);
        Sentry.captureException(error);
    }
};

export const registerParticipantSaga = function* (action: ISagaAction<any>): SagaIterator {
    try {
        const organizationId = yield select(selectors.getOrganizationId);
        const eventId = yield select(selectors.getEventId);
        const userId = yield select(selectors.getUserUid);
        const params = {
            firstName: action.payload.firstName,
            lastName: action.payload.lastName,
            email: action.payload.email,
            companyName: action.payload.companyName,
            jobTitle: action.payload.jobTitle,
            workLocation: action.payload.workLocation,
            organizationId,
            eventId,
            uid: userId,
        };
        const token = yield select(getFirebaseAccessToken);
        yield call(Api.registerParticipant, params, token);
    } catch (e) {
        console.warn(e);
        Sentry.captureException(e);
    }
};

export const participantProfileSaga = function* (): SagaIterator {
    try {
        const organizationId = yield select(selectors.getOrganizationId);
        const eventId = yield select(selectors.getEventId);
        const token = yield select(getFirebaseAccessToken);
        yield call(Api.fetchParticipant, organizationId, eventId, token);
    } catch (error) {
        console.warn('participantProfileSaga: ', error);
        Sentry.captureException(error);
    }
};

export const userAdminRolesSaga = function* (): SagaIterator {
    try {
        const organizationId = yield select(selectors.getOrganizationId);
        const userId = yield select(selectors.getUserUid);

        const isAdmin = yield call(getAdminRoleUser, organizationId, userId);

        yield put(actions.setUserRoles(isAdmin));
    } catch (error) {
        console.warn('participantProfileSaga: ', error);
        Sentry.captureException(error);
    }
};

export const signInSessionWithCustomTokenUserSaga = function* (): SagaIterator {
    yield take(actions.authLinkSuccess);
    yield put(setLoading());
    const FirseBaseAuthClient = new FireBaseAuth();
    const params = new URLSearchParams(window.location.search);
    const customToken = params.get('customToken');
    try {
        if (!customToken) {
            return;
        }
        yield call(FirseBaseAuthClient.signInWithCustomToken, customToken);
    } catch (e) {
        console.warn('signInSessionUserSaga: ', e);
        Sentry.captureException(e);
    }
};

export const updateUserDetailsFromAdminSaga = function* (action: ISagaAction<any>): SagaIterator {
    try {
        const organizationId = yield select(selectors.getOrganizationId);
        const response = yield call(getAdminEventRole, { email: action.payload });
        if (response && response.docs && response.docs.length > 0) {
            const profileData: any = response.docs.map((x: any) => x.data()).filter((x: any) => !x.isDeleted);
            if (profileData && profileData.length > 0) {
                const profile = {
                    companyName: profileData[0].companyName,
                    firstName: profileData[0].firstName,
                    description: profileData[0].description,
                    lastName: profileData[0].lastName,
                    jobTitle: profileData[0].jobTitle,
                    email: profileData[0].email,
                    avatar: profileData[0].avatar
                        ? avatarUrlFromAdminEventPeople(profileData[0].avatar, organizationId)
                        : undefined,
                    workLocation: profileData[0].workLocation,
                    isUserRefFromAdmin: true,
                };
                yield put(actions.updateProfileChangeSucess(profile));
                yield put(actions.authSetProfile({ ...profile }));
                return profile;
            } else {
                return {};
            }
        } else {
            return {};
        }
    } catch (e) {
        Sentry.captureException(e);
        return {};
    }
};

export function avatarUrlFromAdminEventPeople(url: string, organizationId: string) {
    return url.startsWith('https://') ? url : `${ASSETS_URL}/dynamic/images/${organizationId}${url}`;
}

export default signInSessionUserSaga;
