import { SagaIterator } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';
import * as actions from 'modules/actions';
import Api from 'modules/utils/Api';
import { ISagaAction } from 'modules/types';
import { getFirebaseAccessToken } from 'modules/selectors';
// import { toaster } from 'components/Notification';

export const fetchSwappedCardSaga = function* (action: ISagaAction<object>): SagaIterator {
    const token = yield select(getFirebaseAccessToken);
    const swappedCard = yield call(Api.fetchSwappedCard, action.payload, token);

    if (swappedCard.error) {
        yield put(actions.fetchSwappedCardFailure());
        return;
    }

    yield put(actions.fetchSwappedCardSuccess(swappedCard.data));
};

// export const saveSwappedcardsaga = function* (action: ISagaAction<object>): SagaIterator {
//     const token = yield select(getFirebaseAccessToken);
//     const organizationId = yield select(getOrganizationId);
//     const eventId = yield select(getEventId);
//     const payload = { ...action.payload, eventId, organizationId };
//     const response = yield call(Api.swappedCardApi, payload, token);
//     if (response.error) {
//         yield put(actions.fetchSwappedCardFailure());
//         return;
//     }
//     if (response && response.swapped) {
//         toaster('Saved Successfully', 'success', 15000);
//     }
// };

export default fetchSwappedCardSaga;
