import React from 'react';
import styled from 'styled-components/macro';
import isEmpty from 'lodash/isEmpty';
const InitialsNode = styled.div<any>`
    font-weight: bold;
    text-align: center;
    position: relative;
    z-index: 0;
    text-transform: uppercase;
    color: ${({ themeColor }) => (!isEmpty(themeColor) ? themeColor : ' var(--theme-color)')};
    font-size: calc(var(--avatar-ratio) * var(--avatar-size));
`;

export const Initials = (ie: React.PropsWithChildren<any>) => <InitialsNode {...ie} />;

export const Container = styled.div`
    --avatar-ratio: var(--ratio, 0.4);
    --avatar-size: var(--size, 80px);
    background-color: var(--theme-light-color);
    border-radius: var(--avatar-size);
    display: flex;
    flex-shrink: 0;
    flex-grow: 0;

    place-items: center;
    place-content: center;
    position: relative;

    height: var(--avatar-size);
    width: var(--avatar-size);
    & > ${InitialsNode} {
        svg {
            max-width: 66%;
            width: var(--avatar-size);
            height: auto;
            object-position: center;
            object-fit: contain;
        }
    }
`;

export const Image = styled.div`
    border-radius: var(--avatar-size);
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    overflow: hidden;

    img {
        object-position: center;
        object-fit: contain;
        height: auto;
        width: 100%;
    }
`;
