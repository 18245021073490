import get from 'lodash/get';
import isString from 'lodash/isString';
import has from 'lodash/has';

export interface ITextObject {
    [key: string]: any;
}

export function getText(object: ITextObject, notation: string): string {
    return (isString(get(object, notation)) ? get(object, notation) : '').trim();
}

export function hasText(object: ITextObject, notation: string): boolean {
    return has(object, notation) && getText(object, notation).length > 0;
}
