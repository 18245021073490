import React from 'react';

export const DiscoveryConfig = {
    settings: {
        layout: {
            config: {
                header: { display: false },
                footer: { display: false },
                navbar: { display: false },
            },
        },
    },
    isPrivate: false,
    eventName: 'organization',
    routes: [
        {
            path: '/org/:organizationId',
            component: React.lazy(() => import('./index')),
        },
    ],
};

export const DiscoveryMobileConfig = {
    settings: {
        layout: {
            config: {
                header: { display: false },
                footer: { display: false },
                navbar: { display: false },
            },
        },
    },
    isPrivate: false,
    eventName: 'organization',
    routes: [
        {
            path: '/org/:organizationId',
            component: React.lazy(() => import('./index')),
        },
    ],
};
