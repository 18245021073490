import { Polls, Group, BreakoutPresence, PollTrigger } from 'modules/utils/Firebase/Api';
import { eventChannel } from 'redux-saga';
import firebase, { fieldValue } from 'modules/utils/Firebase';
import isEmpty from 'lodash/isEmpty';
import * as Sentry from '@sentry/react';
interface IAttendeeDetails {
    organizationId: string;
    eventId?: string;
    roomId?: string;
}

export const getPollsFormsApi = async (props: IAttendeeDetails) => {
    const connectionRef = Polls.reference(props.organizationId);
    const connectionRoomListner = eventChannel((emit) => {
        const unSubscribe = connectionRef.onSnapshot((querySnapshot: any) => {
            const pollsDataBasedRoomId: any[] = [];
            querySnapshot.docs.forEach((snap: any) => pollsDataBasedRoomId.push(snap.data()));
            return emit(pollsDataBasedRoomId);
        });
        return () => {
            unSubscribe();
        };
    });
    return connectionRoomListner;
};

export const getSelectedPollsDetail = async (props: IAttendeeDetails) => {
    const connectionRef = Polls.realtimeRef(props.organizationId, props?.eventId || '', props?.roomId || '');
    const connectionBrListner = eventChannel((emit) => {
        connectionRef.on('value', (snapshot: any) => {
            if (snapshot.val() !== null && snapshot.val()?.isActive) return emit(snapshot.val());
            else
                return emit({
                    selectedFormId: '',
                    triggeredTime: 0,
                    selectedDocumentId: '',
                    isStopped: true,
                    pollTriggerId: '',
                });
        });
        return () => {
            connectionRef.off();
        };
    });
    return connectionBrListner;
};

export const addPollDataToUserPresence = async (payload: any, props: any, pollTriggerId?: string) => {
    const userPresenceReference = BreakoutPresence.userPresnceRef(props.organizationId, props.eventId, props.roomId);
    const snapshot = await userPresenceReference.get();
    const allUsers = snapshot.val();
    const userIds = allUsers ? Object.keys(snapshot.val()) : [];
    let users = userIds.map((id) => allUsers[id].email).filter((email) => email);

    // if (props.groupId) {
    const groupUser = await getGroupUser(props);
    users.push(...groupUser);
    users = [...new Set(users)];
    // }

    if (isEmpty(payload)) {
        payload = {
            isStopped: true,
            stoppedTime: firebase.database.ServerValue.TIMESTAMP,
        };
    } else if (payload && payload.displayResult) {
        payload = {
            isStopped: true,
            displayResults: true,
            stoppedTime: firebase.database.ServerValue.TIMESTAMP,
        };
    } else if (payload && payload.removePoll) {
        payload = {
            isActive: false,
            roundOneResult: null,
            roundTwoResult: null,
            stoppedTime: firebase.database.ServerValue.TIMESTAMP,
        };
    }

    // if (snapshot.exists()) {
    //     if (props.groupId) {
    //         const groupSnapShot = await Group.reference(props.organizationId, props.eventId).doc(props.groupId).get();
    //         if (groupSnapShot.exists) {
    //             const group = groupSnapShot.data();
    //             // users = group?.users ?? users;

    //             // const promises = users.map((data: any) => {
    //             //     return BreakoutPresence.userRef(data.uid, props.organizationId, props.eventId, props.roomId).update(
    //             //         payload,
    //             //     );
    //             // });
    //             // await Promise.allSettled(promises);
    //         }
    //     } else {
    //         // const promises = userPresnce.map((data: any) => {
    //         //     return BreakoutPresence.userRef(data.uid, props.organizationId, props.eventId, props.roomId).update(
    //         //         payload,
    //         //     );
    //         // });
    //         // await Promise.allSettled(promises);
    //     }
    // }

    await addPollInTriggerCollection({ props, payload, users, pollTriggerId });
    return [];
};

export const getGroupUser = async (props: any) => {
    const groupResultRef = Group.reference(props.organizationId, props.eventId);
    const groupSnapshot = await groupResultRef.get();
    const groupData = groupSnapshot.docs.map((doc) => {
        return doc.data();
    });
    const groupUsers: any = [];
    if (groupData && groupData.length) {
        groupData.map((doc) => {
            if (doc.users && doc.users.length) {
                groupUsers.push(...doc.users);
            }
            return doc;
        });
    }
    return groupUsers;
};

type IPollTrigger = {
    payload: any;
    props: any;
    users: any[];
    pollTriggerId?: string;
};

export const addPollInTriggerCollection = async ({ payload, props, users = [], pollTriggerId }: IPollTrigger) => {
    try {
        const pollId = payload?.pollTriggerId || pollTriggerId;

        const pollRef = PollTrigger.reference(props.organizationId, props.eventId);

        if (pollId) {
            if (payload.stoppedTime) {
                payload.stoppedTime = fieldValue.serverTimestamp();
            }
            if (payload.triggeredTime) {
                payload.triggeredTime = fieldValue.serverTimestamp();
            }

            const updatePollObj = {
                ...payload,
                ...props,
                updatedAt: fieldValue.serverTimestamp(),
                createdAt: fieldValue.serverTimestamp(),
            };

            JSON.stringify(updatePollObj, function (k, v) {
                if (v === undefined) {
                    Sentry.captureMessage(`Something went wrong+ ${k}`);
                    return v;
                }
                if (k === undefined) {
                    Sentry.captureMessage(`Something went wrong+ ${k}`);
                    return v;
                }
                return v;
            });
            if (payload.displayResults === false) {
                updatePollObj.users = users;
            }
            await pollRef.doc(pollId).set(updatePollObj, { merge: true });
        }
    } catch (error) {
        Sentry.captureException(error);
    }
};

export const getPollTriggerListner = async (props: any) => {
    const groupResultRef = PollTrigger.reference(props.organizationId, props.eventId);
    const connectionPollRef = groupResultRef.orderBy('updatedAt', 'desc').limit(1);

    const connectionPollTriggerListner = eventChannel((emit) => {
        const unSubscribe = connectionPollRef.onSnapshot((querySnapshot: firebase.firestore.QuerySnapshot) => {
            if (!querySnapshot.empty) {
                const groupData: any[] = [];
                querySnapshot.forEach((doc: any) => {
                    groupData.push({ ...doc.data(), id: doc.id });
                });
                if (groupData?.[0]?.groupId && !groupData?.[0]?.displayResults) {
                    if (groupData?.[0]?.users.includes(props.email) || props.moderator) {
                        return emit(groupData?.[0]);
                    } else {
                        return emit(false);
                    }
                }
                emit(groupData?.[0] ?? false);
            } else {
                emit(false);
            }
        });
        return () => {
            unSubscribe();
        };
    });
    return connectionPollTriggerListner;
};

export const addSelectedPollIdApi = async (payload: any, props: IAttendeeDetails) => {
    if (props?.eventId && props?.roomId) {
        const connectionRef = Polls.realtimeRef(props.organizationId, props?.eventId, props?.roomId);
        if (payload && payload.doRemove) {
            await connectionRef.update({
                isStopped: true,
                displayResults: true,
                stoppedTime: firebase.database.ServerValue.TIMESTAMP,
            });
        } else if (isEmpty(payload))
            await connectionRef.update({
                isStopped: true,
                stoppedTime: firebase.database.ServerValue.TIMESTAMP,
            });
        else {
            await connectionRef.set(payload);
        }
    }
};

export const removePollResultApi = async (props: IAttendeeDetails) => {
    if (props?.eventId && props?.roomId) {
        const connectionRef = Polls.realtimeRef(props.organizationId, props?.eventId, props?.roomId);
        await connectionRef.update({
            isActive: false,
        });
    }
};

export const fetchPollResultListnerApi = async (organizationId: string, docId: string, meetingId: string) => {
    const pollResultRef = Polls.reference(organizationId).doc(docId).collection('results');
    const connectionRef = pollResultRef.where('meeting_id', '==', meetingId);

    const connectionRoomsListner = eventChannel((emit) => {
        const unSubscribe = connectionRef.onSnapshot((querySnapshot: firebase.firestore.QuerySnapshot) => {
            const pollResult: any[] = [];

            querySnapshot.forEach((doc: any) => {
                pollResult.push({ ...doc.data() });
            });
            return emit(pollResult);
        });
        return () => {
            unSubscribe();
        };
    });
    return connectionRoomsListner;
};

export const fetchPollResultApi = async (organizationId: string, docId: string, meetingId: string) => {
    if (organizationId && docId) {
        const pollResultRef = Polls.reference(organizationId).doc(docId).collection('results');
        const querySnapshot = await pollResultRef.where('meeting_id', '==', meetingId).get();

        const pollResult: any[] = [];
        querySnapshot.forEach((doc: any) => {
            pollResult.push({ ...doc.data() });
        });
        return pollResult;
    }
};

export const fetchAllPollResultApi = async (organizationId: string, docId: string, roomId: string) => {
    if (organizationId && docId) {
        const pollResultRef = Polls.reference(organizationId).doc(docId).collection('results');
        const querySnapshot = await pollResultRef.where('roomId', '==', roomId).get();

        let pollResult: any[] = [];
        querySnapshot.forEach((doc: any) => {
            pollResult.push({ ...doc.data() });
        });

        if (pollResult.length) {
            pollResult = pollResult.sort((a, b) => {
                return ((new Date(b.submitted_at)).getTime() - (new Date(a.submitted_at)).getTime())
            })
            pollResult = pollResult.filter(item => item.meeting_id === pollResult[0].meeting_id)
        }

        return pollResult;
    }
};

export const getPollsGroup = async (payload: any) => {
    const groupResultRef = Group.reference(payload.organizationId, payload.eventId);
    const snapshot = await groupResultRef.doc(payload.groupId).get();
    const poll = snapshot.data();

    return poll;
};

export const getPollsUserGroupListner = async (payload: any) => {
    const groupResultRef = Group.reference(payload.organizationId, payload.eventId);
    const connectionGroupRef = groupResultRef.where('users', 'array-contains', payload.email);

    const connectionGroupListner = eventChannel((emit) => {
        const unSubscribe = connectionGroupRef.onSnapshot((querySnapshot: any) => {
            const groupData: any[] = [];
            querySnapshot.forEach((doc: any) => {
                groupData.push({ ...doc.data(), id: doc.id });
            });
            return emit(groupData);
        });
        return () => {
            unSubscribe();
        };
    });
    return connectionGroupListner;
};

export const getMaxUserGroupListner = async (payload: any) => {
    const groupResultRef = Group.reference(payload.organizationId, payload.eventId);
    const connectionGroupRef = groupResultRef
        .where('users', 'array-contains', payload.email)

    const connectionGroupListner = eventChannel((emit) => {
        const unSubscribe = connectionGroupRef.onSnapshot((querySnapshot: firebase.firestore.QuerySnapshot) => {
            if (!querySnapshot.empty) {
                const groupData: any[] = [];
                querySnapshot.forEach((doc: any) => {
                    groupData.push({ ...doc.data(), id: doc.id });
                });
                emit(groupData?.[0] ?? false);
            } else {
                emit(false);
            }
        });
        return () => {
            unSubscribe();
        };
    });
    return connectionGroupListner;
};


export const getMaxPollGroup = async (payload: any) => {
    const groupResultRef = Group.reference(payload.organizationId, payload.eventId);
    const snapshot = groupResultRef.where('users', 'array-contains', payload.email);
    const pollResult = await snapshot.get();
    const groupData: any[] = [];
    pollResult.forEach((doc: any) => {
        groupData.push({ ...doc.data() });
    });
    groupData.forEach((group) => {
        if (!group?.priority) {
            group.priority = 0
        }
    })
    const maxPriority = Math.max(...groupData.map(group => group?.priority))
    const maxGroup = groupData.find((group) => {
        return group?.priority.toString() === maxPriority.toString()
    })
    return maxGroup
}