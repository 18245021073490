import React, { useEffect, useRef } from 'react';
import { Container, Row, Col, Button, Nav } from 'react-bootstrap';
import { useSwipeable } from 'react-swipeable';
import Logout from 'assets/img/icons/logout.svg';
import Userwhite from 'assets/img/icons/user-white.svg';
import '../sidebar.scss';
import { getUserProfile, getLayout } from 'modules/selectors';
import { useSelector, useDispatch } from 'react-redux';
import { signOutUser, setLayout } from 'modules/actions';
import { ErrorBoundary, ProfileAvatar } from 'components';
// import { SignInSignUp } from 'components/Access/UserAccess';

import { StyledMobileSidebar } from './style';
export const MobileSidebarMenu = () => {
    const wrapperRef = useRef<HTMLDivElement>(null);
    const dispatch = useDispatch();
    const user = useSelector(getUserProfile);
    const layout = useSelector(getLayout);

    const logoutUser = (e: any) => {
        e.preventDefault();
        dispatch(signOutUser());
    };

    const handleClickOutside = (event: any) => {
        if (wrapperRef && wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            toggleSidebarMenu();
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    });

    const profileModal = () => {
        toggleSidebarMenu();
        // SignInSignUp.modalOpen();

        // dispatch(openLoungeProfileModal(true));
    };

    const toggleSidebarMenu = () => {
        const defaultSettings = {
            navbar: { display: !layout.navbar.display },
        };
        dispatch(setLayout(defaultSettings));
    };
    const handlers = useSwipeable({
        onSwipedLeft: () => toggleSidebarMenu(),
        preventDefaultTouchmoveEvent: true,
        trackMouse: true,
    });
    return (
        <ErrorBoundary>
            <StyledMobileSidebar {...handlers}>
                <Container>
                    <Row className="justify-content-sm-center">
                        <Col xs={12}>
                            <Nav className="col-md-12 sidebar" ref={wrapperRef}>
                                <div className="profile-wrap">
                                    <div className="profile-pic">
                                        <ProfileAvatar />
                                    </div>
                                    <div className="profile-name">
                                        {(user.firstName || '') + ' ' + (user.lastName || '')}
                                    </div>
                                </div>

                                <div className="seperator" />

                                <div className="sidebar-sticky" />
                                <div className="navigation-wrap">
                                    <Nav.Item onClick={profileModal}>
                                        <Nav.Link eventKey="link-1">
                                            <span>
                                                <img
                                                    src={Userwhite}
                                                    width="17"
                                                    className="d-inline-block align-center"
                                                    alt=""
                                                />
                                            </span>
                                            Profile
                                        </Nav.Link>
                                    </Nav.Item>
                                </div>

                                <div className="nav-bottom">
                                    <Button variant="link" onClick={logoutUser}>
                                        <img src={Logout} width="20" className="d-inline-block align-center" alt="" />
                                        Logout
                                    </Button>
                                </div>
                            </Nav>
                        </Col>
                    </Row>
                </Container>
            </StyledMobileSidebar>
        </ErrorBoundary>
    );
};
export default MobileSidebarMenu;
