import { IStoreState } from 'modules/types';

export const getGlobalUsers = (state: IStoreState) => state.chat.globalUsers;
export const getRequestMeetingIdentity = (state: IStoreState) => state.chat.requestMeetingIdentity;
export const privateChatState = (state: IStoreState) => state.chat.openPrivateChat;
export const eventChatState = (state: IStoreState) => state.chat.openEventChat;
export const getInviteeMeetingIdentity = (state: IStoreState) => state.chat.meetInviteIndentity;
export const getBlockedByIds = (state: IStoreState) => state.chat.blockedByIds;
export const getBlockedIds = (state: IStoreState) => state.chat.blockedIds;
export const getBlockId = (state: IStoreState) => state.chat.blockId;
export const getSaveProfileId = (state: IStoreState) => state.chat.saveProfileId;
export const getQAChatState = (state: IStoreState) => state.chat.openQAChat;
