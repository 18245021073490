import React, { lazy, Suspense, useEffect } from 'react';
import { LoadingSpinner, Analytics, ErrorBoundary } from 'components';
import { isMobileOnly, withOrientationChange } from 'react-device-detect';
import { setMobileOrientation } from 'modules/actions';
import Router from '../router';
import { useDispatch } from 'react-redux';
import { useAppContext } from 'context/AppContext';
import DesktopRoutes from 'router/DesktopRoutes';
import MobileRoutes from 'router/MobileRoutes';
// import { UserAccess } from 'components/Login/UserAccess';
import Authorization from 'components/Authorization';
import Modal from 'components/Login/Modal';

const Layout = lazy(() => import('./Layout'));
const MobileLayout = lazy(() => import('./MobileLayout'));

const RenderLayout = (props: any) => {
    const { isLandscape, isPortrait } = props;
    const { setRoutes, routes } = useAppContext();
    const dispatch = useDispatch();
    useEffect(() => {
        const defaultSettings = {
            isLandscape,
            isPortrait,
        };
        dispatch(setMobileOrientation(defaultSettings));
    }, [dispatch, isLandscape, isPortrait]);

    useEffect(() => {
        if (isMobileOnly) {
            setRoutes(MobileRoutes);
        } else {
            setRoutes(DesktopRoutes);
        }
    }, [setRoutes]);

    return (
        <React.Fragment>
            <ErrorBoundary>
                {' '}
                <Analytics />
            </ErrorBoundary>
            {/* <ErrorBoundary>
                <UserAccess />
            </ErrorBoundary> */}
            <Modal />
            <Suspense fallback={<LoadingSpinner withCoverBg position="center" />}>
                {routes.length && (
                    <Authorization>
                        <ErrorBoundary>
                            {isMobileOnly ? (
                                <MobileLayout>
                                    <Router />
                                </MobileLayout>
                            ) : (
                                <Layout>
                                    <Router />
                                </Layout>
                            )}
                        </ErrorBoundary>
                    </Authorization>
                )}
            </Suspense>
        </React.Fragment>
    );
};
export default withOrientationChange(RenderLayout);
