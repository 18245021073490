import styled, { css } from 'styled-components/macro';

export const LoginButtonStyled = css`
    width: 160px;
    height: 52px;
    border-radius: 20px;
    box-shadow: 10px 30px 30px 3px rgba(177, 188, 199, 0.15);
    background-color: var(--mono-white-color, white);
    color: var(--mono-black-color);
    font-size: 16px;
    font-weight: bold;

    &:hover,
    &:active {
        outline: none;
        text-decoration: none;
        background-color: var(--theme-color);
    }
    &:focus {
        outline: initial;
        text-decoration: underline;
    }
    &:active {
        filter: brightness(90%);
    }
`;

const disabledState = css`
    background: #9ca3bd;
    &:hover {
        color: var(--marine-dark-color);
    }
`;

export const LoginButton = styled.button`
    ${LoginButtonStyled};
    ${(props) => props.disabled && disabledState};
`;

export default LoginButton;
