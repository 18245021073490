import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as FramerMotion from 'framer-motion';
import { ComponentWrapper, ComponentModal, variants } from './style';
import styled from 'styled-components';
import Login from './index';
import * as actions from 'modules/actions';
import { Mode } from './constants/constants';
import * as selectors from 'modules/selectors';

export const Modal = () => {
    const dispatch = useDispatch();
    const opened = useSelector(selectors.getLoginModalState);
    const isOutsideClickAllowed = useSelector(selectors.getOutsideClickAllowed);
    const wrapperRef = useRef<HTMLDivElement>(null);
    const isUserRegister = useSelector(selectors.getUserRegister);
    const isNewAttendee = React.useMemo(
        () => isUserRegister === Mode.useNewEmail || isUserRegister === Mode.googleAuth,
        [isUserRegister],
    );
    const handleClickOutside = (event: any) => {
        if (isOutsideClickAllowed && wrapperRef && wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            dispatch(actions.closeLoginModal());
        }
    };
    useEffect(() => {
        if (isNewAttendee && !opened) {
            dispatch(actions.isUserRegistred(Mode.initial));
        }
    }, [isNewAttendee, dispatch, opened]);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    });

    return (
        <FramerMotion.AnimatePresence>
            {opened === true ? (
                <StyledProfileMobile>
                    <ComponentWrapper
                        initial="hidden"
                        animate="visible"
                        exit="hidden"
                        variants={variants.overlay}
                        key="modal"
                    >
                        <React.Fragment>
                            <ComponentModal
                                ref={wrapperRef}
                                initial="hidden"
                                animate="visible"
                                exit="exit"
                                variants={variants.modal}
                            >
                                <Login />
                            </ComponentModal>
                        </React.Fragment>
                    </ComponentWrapper>
                </StyledProfileMobile>
            ) : null}
        </FramerMotion.AnimatePresence>
    );
};
export default Modal;

export const StyledProfileMobile = styled('div')<any>`
    height: 100vh;
    width: 100vw;
    ~ div {
        touch-action: none;
    }
    position: fixed;
    z-index: 99999999;
    margin: 0;
    top: 0;
    padding: 0;
    bottom: 0;
    > div {
        padding: 0;
        width: 100%;
    }
    .logo {
        display: none !important;
    }
`;
