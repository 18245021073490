import { createReducer } from 'redux-act';
import {
    fetchSchedule,
    fetchScheduleFailed,
    fetchScheduleSuccess,
    checkRoomLimitSuccess,
    resetState,
    checkRoomUserSuccess,
    blockUserSuccess,
    removeActiveListner,
} from 'modules/actions';
import { IKeynote } from 'modules/schemas';

export interface ISchedule {
    detail: any;
    items: IKeynote[];
}

interface ILimit {
    [roomId: string]: {
        capacity: number;
        availableModerators: number;
        roomUserList: any[];
    };
}

interface IBlockedUser {
    roomId: string;
    isBlocked: boolean;
}

export type ScheduleReducerState = {
    fetching: boolean;
    list: IKeynote[];
    roomLimit: ILimit[];
    blockedUsers: IBlockedUser[];
    currentRoom?: {
        capacity: number;
        availableModerators: number;
    };
};

const initialState: ScheduleReducerState = {
    fetching: false,
    list: [],
    blockedUsers: [],
    roomLimit: [],
};

export const schedule = createReducer<ScheduleReducerState>({}, initialState);
schedule.on(fetchSchedule, (state: ScheduleReducerState) => ({
    ...state,
    fetching: true,
}));

schedule.on(fetchScheduleSuccess, (state: ScheduleReducerState, payload: IKeynote[]) => ({
    ...state,
    fetching: false,
    list: payload,
}));

schedule.on(fetchScheduleFailed, (state: ScheduleReducerState) => ({
    ...state,
    fetching: false,
}));

schedule.on(checkRoomLimitSuccess, (state: ScheduleReducerState, payload: ILimit[]) => ({
    ...state,
    roomLimit: payload,
}));

schedule.on(blockUserSuccess, (state: ScheduleReducerState, payload: IBlockedUser[]) => ({
    ...state,
    blockedUsers: payload,
}));

schedule.on(
    checkRoomUserSuccess,
    (
        state: ScheduleReducerState,
        payload: {
            capacity: number;
            availableModerators: number;
        },
    ) => ({
        ...state,
        currentRoom: payload,
    }),
);

schedule.on(resetState, () => initialState);

schedule.on(removeActiveListner, () => initialState);