import { ISagaAction } from 'modules/types';
import FireBaseAuth from 'modules/utils/Firebase/auth';
// import { toast } from "react-toastify";
import { SagaIterator } from 'redux-saga';
import * as selectors from 'modules/selectors';
import {
    updateAvatarProfile,
    updateUserProfile,
    deleteAvatarProfileApi,
    getUserProfileApi,
} from 'modules/utils/Firebase/Profile/index';

import { Mode } from 'components/Login/constants/constants';
import { authLoginErrors, authPasswordResetErrors } from 'modules/actions/login';
import * as actions from 'modules/actions';
import { call, put, select } from 'redux-saga/effects';
import * as Sentry from '@sentry/react';

export const loginUser = function* (action: ISagaAction<any>): SagaIterator {
    try {
        yield put(actions.authMode(Mode.showLoader));
        const FirseBaseAuthClient = new FireBaseAuth();
        yield call(FirseBaseAuthClient.signInUser, action.payload.email, action.payload.password, action.payload.keepLogin);
        yield put(actions.setAuthPassword(''));
        yield put(actions.closeLoginModal());
    } catch (err) {
        yield put(authLoginErrors(err));
        yield put(actions.authMode(Mode.useEmail));
        Sentry.captureException(err);
    }
};

export const authLoginUser = function* (): SagaIterator {
    try {
        const FirseBaseAuthClient = new FireBaseAuth();
        const response = yield call(FirseBaseAuthClient.signInWithGoogle);
        if (response && response?.user?.uid) {
            yield put(actions.authMode(Mode.showLoader));
            const isUserExist = yield call(getUserProfileApi, response.user.uid);
            const [firstName, lastName] = response.user.displayName.split(' ');
            if (!isUserExist) {
                yield put(actions.setIsOnSignUp(true));
                yield put(actions.isUserRegistred(Mode.googleAuth));
                const userId = yield select(selectors.getUserUid);
                const eventId = yield select(selectors.getEventId);
                const organizationId = yield select(selectors.getOrganizationId);
                const objData = yield call(updateUserProfile, {
                    email: response?.user?.email,
                    eventId,
                    organizationId,
                    modifiedByUid: userId,
                    firstName: firstName || '',
                    lastName: lastName || '',
                });
                yield put(actions.updateProfileChangeSucess(objData));
                yield put(actions.updateUserDetail(response?.user?.email));
                yield put(actions.updateUserRegistree(response?.user?.email));
                yield put(actions.setIsOnSignUp(false));
            } else {
                yield put(actions.authMode(Mode.initial));
                yield put(actions.closeLoginModal());
            }
        }
    } catch (e) {
        console.error(e);
        Sentry.captureException(e);
    }
};

export const sendMailToResetPassword = function* (action: ISagaAction<any>): SagaIterator {
    try {
        const FirseBaseAuthClient = new FireBaseAuth();
        const rolesResponse = yield call(FirseBaseAuthClient.checkEmailExists, action.payload.email);
        if (rolesResponse && rolesResponse.length) {
            const mailSent = yield call(FirseBaseAuthClient.resetPassword, action.payload.email);
            if (mailSent) yield put(actions.authMode(Mode.useEmail));
        } else {
            yield put(actions.authFailure('You are not registered with us'));
        }
    } catch (err) {
        yield put(authPasswordResetErrors(err));
        Sentry.captureException(err);
    }
};

export const updateProfileSaga = function* (action: ISagaAction<any>): SagaIterator {
    try {
        const userId = yield select(selectors.getUserUid);
        const isSkipProfile = yield select(selectors.getSkipProfileState)
        yield call(updateUserProfile, {
            ...action.payload,
            modifiedByUid: userId,
        });
        if (isSkipProfile) {
            yield put(actions.registrationProfileSynchronize());
            yield put(actions.closeLoginModal());
            yield put(actions.authMode(Mode.initial));
        }
        yield put(actions.setSkipProfile(true))
    } catch (e) {
        console.error(e);
        Sentry.captureException(e);
    }
};

export const updateAvatarSaga = function* (action: ISagaAction<any>): SagaIterator {
    try {
        const userId = yield select(selectors.getUserUid);
        const profileUpdated = yield call(updateAvatarProfile, {
            ...action.payload,
            modifiedByUid: userId,
        });

        yield put(actions.setAvatarData(profileUpdated.avatar));
        yield put(actions.authMode(Mode.profile));
        yield put(actions.authSetProfile({ avatar: profileUpdated.avatar }));
    } catch (e) {
        console.error(e);
        Sentry.captureException(e);
    }
};

export const deleteAvatarSaga = function* (): SagaIterator {
    try {
        const userId = yield select(selectors.getUserUid);
        yield call(deleteAvatarProfileApi, userId);
        yield put(actions.setAvatarData(null));
        yield put(actions.authSetProfile({ avatar: null }));
    } catch (e) {
        console.error(e);
        Sentry.captureException(e);
    }
};

export const checkUserRegisteredSaga = function* (): SagaIterator {
    try {
        const FirseBaseAuthClient = new FireBaseAuth();
        const authData = yield select(selectors.getloginData);

        const rolesResponse = yield call(FirseBaseAuthClient.checkEmailExists, authData.email);
        if (rolesResponse && rolesResponse.length) {
            if (rolesResponse.includes('google.com') && !rolesResponse.includes('password')) {
                yield put(actions.authSigninUser());
            } else {
                yield put(actions.isUserRegistred(Mode.useEmail));
            }
        } else {
            yield put(actions.isUserRegistred(Mode.useNewEmail));
        }
    } catch (error: any) {
        console.log('err', error);
        yield put(authLoginErrors(error));
        Sentry.captureException(error);
    }
};

export const signupUserSaga = function* (action: ISagaAction<any>): SagaIterator {
    try {
        const FirseBaseAuthClient = new FireBaseAuth();

        const response = yield call(FirseBaseAuthClient.createUser, action.payload.email, action.payload.password);
        if (response) {
            yield put(actions.setIsOnSignUp(true));
            yield put(actions.authMode(Mode.showLoader));
            const userId = yield select(selectors.getUserUid);
            const eventId = yield select(selectors.getEventId);
            const organizationId = yield select(selectors.getOrganizationId);
            yield call(updateUserProfile, {
                email: action.payload.email,
                eventId,
                organizationId,
                modifiedByUid: userId,
            });
            yield put(actions.updateUserDetail(action.payload.email));
            yield put(actions.updateUserRegistree(action.payload.email));
            yield put(actions.setIsOnSignUp(false));
        }
    } catch (err) {
        yield put(authLoginErrors(err));
        Sentry.captureException(err);
    }
};
