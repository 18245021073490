import React from 'react';
import get from 'lodash/get';
// import { getDataUriFile } from './image';
import { listMediaDevices, shouldRequestMediaStream, requestMediaStream } from 'modules/utils/CommonService';
import { useMount } from 'components/Cropper/useMount';
import { getDataUriFile } from 'components/Cropper/image';
// import { useMount } from '../hooks';
const CAPTURE_MIME_TYPE = 'image/png';
const CAPTURE_MEDIA_CONSTRAINTS = { video: true };

export function useCapture() {
    const { isMounted } = useMount();
    const [mediaDevices, setMediaDevices] = React.useState<Array<MediaDeviceInfo>>([]);
    const [mediaStream, setMediaStream] = React.useState<MediaStream | null>(null);
    const [mediaStreamError, setMediaStreamError] = React.useState<any>(null);

    const selectedMediaDevice = React.useCallback(() => {
        const track = mediaStream ? mediaStream.getTracks()[0] : undefined;
        return track ? get(track.getSettings(), 'deviceId') : undefined;
    }, [mediaStream]);

    const updateMediaDevices = React.useCallback(() => {
        listMediaDevices(CAPTURE_MEDIA_CONSTRAINTS).then((devices) =>
            isMounted() ? setMediaDevices(devices) : undefined,
        );
    }, [setMediaDevices, isMounted]);

    React.useEffect(() => {
        if (mediaStream) updateMediaDevices();
    }, [mediaStream, updateMediaDevices]);

    return {
        canvasFile,
        mediaDevices,
        mediaStream,
        mediaStreamError,
        selectedMediaDevice,
        setMediaStream,
        setMediaStreamError,
        // Start Streaming
        startStreaming: React.useCallback(
            (deviceId?: string) => {
                if ('mediaDevices' in navigator) {
                    const constraints = { video: deviceId ? { deviceId: { exact: deviceId } } : true };
                    if (
                        mediaStream === null ||
                        selectedMediaDevice() !== deviceId ||
                        shouldRequestMediaStream(mediaDevices, constraints)
                    ) {
                        requestMediaStream(constraints).then(setMediaStream).catch(setMediaStreamError);
                    }
                } else {
                    setMediaStreamError(new Error('navigator.mediaDevices not supported'));
                }
            },
            [mediaDevices, mediaStream, selectedMediaDevice, setMediaStream, setMediaStreamError],
        ),
        // Stop Streaming
        stopStreaming: React.useCallback(() => {
            if (mediaStream !== null) {
                const track = mediaStream.getTracks()[0];
                track.stop();
                setMediaStream(null);
            }
        }, [mediaStream, setMediaStream]),
        updateMediaDevices,
    };
}

function canvasFile(canvas: HTMLCanvasElement) {
    return getDataUriFile(canvas.toDataURL(CAPTURE_MIME_TYPE));
}
