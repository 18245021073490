import { Event, Events, Lobby, Organization } from 'modules/utils/Firebase/Api';
import firebase from 'modules/utils/Firebase';
import { eventChannel } from 'redux-saga';
import { ILobby } from 'modules/reducers/lobby';

export const getOrganizationData = (organizationId: string) => {
    const connectionRef = Organization.reference(organizationId);
    const connectionRoomListner = eventChannel((emit) => {
        const unSubscribe = connectionRef.onSnapshot((doc: firebase.firestore.DocumentSnapshot) => {
            if (doc.exists) {
                const obj = {
                    organization: doc.data(),
                };
                return emit(obj);
            } else {
                return emit(false);
            }
        });
        return () => {
            unSubscribe();
        };
    });
    return connectionRoomListner;
};

export const getEventsData = (organizationId: string) => {
    const connectionRef = Events.reference(organizationId);
    const connectionRoomListner = eventChannel((emit) => {
        const unSubscribe = connectionRef.onSnapshot((snapshot: firebase.firestore.QuerySnapshot) => {
            if (!snapshot.empty) {
                const eventsList: any[] = [];
                snapshot.forEach((doc) => {
                    if (!doc.data().isDeleted) eventsList.push(doc.data());
                });
                return emit(eventsList);
            } else {
                return emit(false);
            }
        });
        return () => {
            unSubscribe();
        };
    });
    return connectionRoomListner;
};

export const getEventData = (organizationId: string, eventId: string) => {
    const connectionRef = Event.reference(organizationId, eventId);
    const connectionRoomListner = eventChannel((emit) => {
        const unSubscribe = connectionRef.onSnapshot((doc: firebase.firestore.DocumentSnapshot) => {
            if (doc.exists) {
                const obj = {
                    assets: organizationAssets(organizationId),
                    event: doc.data(),
                    organization: { id: organizationId, name: '' },
                };
                return emit(obj);
            } else {
                return emit(false);
            }
        });
        return () => {
            unSubscribe();
        };
    });
    return connectionRoomListner;
};

export const getLobbyData = async (organizationId: string, eventId: string) => {
    const connectionLobbyRef = Lobby.reference(organizationId, eventId);
    const connectionLobbyListner = eventChannel((emit) => {
        const unSubscribe = connectionLobbyRef.onSnapshot(async (snapshot: firebase.firestore.QuerySnapshot) => {
            if (!snapshot.empty) {
                snapshot.forEach((result: firebase.firestore.QueryDocumentSnapshot) => {
                    if (result.exists) {
                        const lobby = result.data() as ILobby;
                        emit(lobby);
                    }
                });
            } else {
                emit(false);
            }
        });
        return () => {
            unSubscribe();
        };
    });
    return connectionLobbyListner;
};

export const organizationAssets = (organizationId: string) => {
    return {
        baseUrl: process.env.REACT_APP_TOCCA_ASSETS_URL,
        imagesUrl: `${process.env.REACT_APP_TOCCA_ASSETS_URL}/dynamic/images/${organizationId}`,
    };
};
