import { IStoreState } from 'modules/types';
import { IKeynote, IBreakoutType } from 'modules/schemas';

export const getKeynotesList = (state: IStoreState) => state.keynotes.list;
export const getHeadlineKeynote = (state: IStoreState) => state.keynotes.list.find(filterHeadline);
export const getKeynotePresenters = (state: IStoreState) => filterPresenters(state.keynotes.list);
export const getSidelineKeynote = (state: IStoreState) => state.keynotes.list.find(filterSideline);
export const missingKeynotesList = (state: IStoreState) => !state.keynotes.fetching && state.keynotes.list.length === 0;
export const keynotesLoader = (state: IStoreState) => state.keynotes.fetching;
export const getScheduleKeynotes = (state: IStoreState) => state.keynotes.list.filter(filterHeadline);

// filter keynote items targeting only the headlining conference of event
function filterHeadline(item: IKeynote) {
    return item.type === 'keynote';
}

// filter keynote items targeting only the headlining conference of event
function filterSideline(item: IKeynote) {
    return item.type === 'keynote';
}

function filterPresenters(list: any) {
    const presenters: any[] = [];
    list.map((keynote: any) => {
        if (keynote.type === IBreakoutType.keynote) {
            keynote.presenters.map((item: any) => {
                const presenterExists = presenters.find((sample) => {
                    return sample.id === item.id;
                });
                if (presenters.length <= 10 && !presenterExists) {
                    presenters.push(item);
                }
                return true;
            });
        }
        return true;
    });
    return presenters;
}
