import { IStoreState } from 'modules/types';

export const getAuthMode = (state: IStoreState) => state.login.mode;

export const getloginData = (state: IStoreState) => state.login.loginData;

export const getForgetPasswordData = (state: IStoreState) => state.login.forgetPasswordData;

export const AuthFailure = (state: IStoreState) => state.login.failure;

export const getProfileData = (state: IStoreState) => state.login.profile;

export const getLoginErrors = (state: IStoreState) => state.login.loginError;

export const getUserRegister = (state: IStoreState) => state.login.isRegistered;

export const getProceedValue = (state: IStoreState) => state.login.isProceed;

export const getAvatarFileData = (state: IStoreState) => state.login.avatarFile;

export const getAvatar = (state: IStoreState) => state.login.avatar;

export const getIsOnSignUp = (state: IStoreState) => state.login.isOnSignUp;

export const getSkipProfileState = (state: IStoreState) => state.login.isSkipProfile;
