import { RESTRICTED_LOUNGE } from 'modules/constants';

interface Config {
    [key: string]: boolean;
}

const config: Config = {
    [RESTRICTED_LOUNGE]: true,
};

export default config;
