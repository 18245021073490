import { BreakoutRecording } from 'modules/utils/Firebase/Api';
import { eventChannel } from 'redux-saga';

interface IAttendeeDetails {
    organizationId: string;
    eventId: string;
    roomId: string;
}

export const getRoomRecordingState = (props: IAttendeeDetails) => {
    const connectionRef = BreakoutRecording.reference(props.organizationId, props.eventId);
    const connectionRoomListner = eventChannel((emit) => {
        connectionRef.on('value', (snapshot: any) => {
            if (snapshot.val()) return emit(snapshot.val());
            else return emit(false);
        });
        return () => {
            connectionRef.off();
        };
    });
    return connectionRoomListner;
};

export const updateBreakoutRecordingState = async (props: IAttendeeDetails) => {
    const connectionRef = BreakoutRecording.refRoom(props.organizationId, props.eventId, props.roomId);
    await connectionRef.update({
        isRecording: false,
        stopAt: new Date().getTime(),
    });
};
