import { SagaIterator } from 'redux-saga';
import { call, all, put, select, take, cancel, cancelled, fork } from 'redux-saga/effects';
import * as actions from 'modules/actions';
import * as selector from 'modules/selectors';
import { getRoomId, getEventId, getOrganizationId, getUserUid } from 'modules/selectors';
import {
    allowAllRoomUser,
    getCurrentUserWaitingRoomList,
    getWaitingRoomUserList,
} from 'modules/utils/Firebase/UserManagement/BreakoutRoomUserList';
import {
    getUserRoomConnected,
    removeUserRoomConnection,
    // addUserRoomConnection,
    updateUserRoomConnection,
} from 'modules/utils/Firebase/UserManagement/BreakoutRoomUserPresence';
import {
    getRoomRecordingState,
    updateBreakoutRecordingState,
} from 'modules/utils/Firebase/UserManagement/BreakoutRecordingState';
import { ToccaUtils } from 'modules/utils';
import {
    getRoomVideoControlState,
    controlVideoPlayPauseStop,
} from 'modules/utils/Firebase/UserManagement/BreakoutVideoControlStates';
import { getAdminPeopleUserDetail } from 'modules/utils/Firebase/UserManagement/UserLookupListner';
import { ISagaAction } from '../types';
import Api from '../utils/Api';
import cloneDeep from 'lodash/cloneDeep';
import * as selectors from '../selectors';
import * as Sentry from '@sentry/react';

export const userPresenceInBreakoutConnection = function* (): SagaIterator {
    const uid = yield select(selector.getUserUid);
    const organizationId = yield select(selector.getOrganizationId);
    const eventId = yield select(selector.getEventId);
    const roomId = yield select(selector.getRoomId);
    const mainRoomId = yield select(selector.getMainRoomId);
    const profile = yield select(selector.getUserProfile);
    const profileAvatar = yield select(selector.getProfileAvatar);
    const collectionType = yield select(selectors.getCollectionType);

    if (!roomId) {
        return;
    }

    const room = yield select(selectors.getRoom(mainRoomId || roomId));

    const obj = {
        roomId,
        organizationId,
        eventId,
        uid,
    };

    const profileObj = {
        uid,
        email: profile.email,
        firstName: profile.firstName,
        lastName: profile.lastName,
        avatar: profileAvatar || '',
        companyName: profile?.companyName ?? '',
        companyTitle: profile?.jobTitle ?? '',
        description: profile?.description ?? '',
        workLocation: profile?.workLocation ?? '',
        twitter: profile?.twitter ?? '',
        linkedin: profile?.linkedin ?? '',
        mainRoomId: mainRoomId || '',
        roomId: roomId || '',
        roomAutoRecordState: mainRoomId ? room?.autoBreakoutGroupRecord ?? false : room?.autoRecord ?? false,
        collection: collectionType ?? 'components',
    };

    yield call(updateUserRoomConnection, obj, profileObj);

    const connectionTask = yield fork(RoomUserPresenceListner);
    // wait for the user stop action
    yield take([actions.removeUserFromRoom, actions.signOutUser]);
    yield cancel(connectionTask);
    yield call(removeUserFromRoomSaga);
};

export const RoomUserPresenceListner = function* (): SagaIterator {
    try {
        const uid = yield select(selector.getUserUid);
        const organizationId = yield select(selector.getOrganizationId);
        const eventId = yield select(selector.getEventId);
        const profile = yield select(selector.getUserProfile);
        const roomId = yield select(getRoomId);
        const profileAvatar = yield select(selector.getProfileAvatar);
        const obj = {
            roomId,
            organizationId,
            eventId,
            uid,
        };
        const moderator = ToccaUtils.hasEventAccess(profile, roomId, 'moderator');
        const presenter = ToccaUtils.hasEventAccess(profile, roomId, 'presenter');
        const room = yield select(selectors.getRoom(roomId));
        let tags = '';
        let hasModeratorTag = false;
        let hasPresenterTag = false;

        if (room && room.tags) {
            const documentId = yield call(getAdminPeopleUserDetail, { organizationId, email: profile.email });
            if (room.tags.moderators) hasModeratorTag = room.tags.moderators.find((x: any) => x.id === documentId);

            if (room.tags.presenters) hasPresenterTag = room.tags.presenters.find((x: any) => x.id === documentId);
        }

        if (hasModeratorTag) tags = 'moderator';
        if (hasPresenterTag) tags = 'presenter';
        if (!tags) tags = 'attendee';

        const role = [];
        if (moderator) role.push('moderator');
        if (presenter) role.push('presenter');
        if (!moderator && !presenter) {
            role.push('attendee');
        }

        const profileObj = {
            uid,
            email: profile.email,
            firstName: profile.firstName,
            lastName: profile.lastName,
            avatar: profileAvatar || '',
            role,
            tags,
            companyName: profile?.companyName ?? '',
            companyTitle: profile?.jobTitle ?? '',
            description: profile?.description ?? '',
            workLocation: profile?.workLocation ?? '',
            twitter: profile?.twitter ?? '',
            linkedin: profile?.linkedin ?? '',
            createdAt: new Date(),
        };

        const channel = yield call(getUserRoomConnected, obj);
        try {
            while (true) {
                const result = yield take(channel);
                if (!result && uid) {
                    yield call(removeUserRoomConnection, obj);
                } else if (result) {
                    yield call(updateUserRoomConnection, obj, profileObj);
                }
            }
        } catch (e) {
            console.warn(e);
        } finally {
            // unregister listener if the saga was cancelled
            if (yield cancelled()) channel.close();
        }
    } catch (error) {
        console.error(error);
        Sentry.captureException(error);
    }
};

export const removeUserFromRoomSaga = function* (): SagaIterator {
    try {
        const uid = yield select(selector.getUserUid);
        const organizationId = yield select(selector.getOrganizationId);
        const eventId = yield select(selector.getEventId);
        const roomId = yield select(getRoomId);
        const obj = {
            roomId,
            organizationId,
            eventId,
            uid,
        };
        console.count('removeUserFromRoomSaga');
        yield call(removeUserRoomConnection, obj);
        yield put(actions.setRoomId(''));
    } catch (error) {
        console.error(error);
        Sentry.captureException(error);
    }
};

export const breakoutWaitingRoomListner = function* (): SagaIterator {
    while (yield take(actions.addBreakoutWaitingListner)) {
        const connectionTask = yield fork(RoomUserListPresenceListner);
        // wait for the user stop action
        yield take([actions.removeBreakoutWaitingListner, actions.removeUserFromRoom, actions.signOutUser]);

        yield cancel(connectionTask);
    }
};

const RoomUserListPresenceListner = function* (): SagaIterator {
    try {
        const organizationId = yield select(getOrganizationId);
        const eventId = yield select(getEventId);
        const roomId = yield select(getRoomId);
        const obj = {
            organizationId,
            eventId,
            roomId,
        };

        const currentUserList = yield call(getCurrentUserWaitingRoomList, obj);

        const otherUsersReceivedData = currentUserList.filter(
            (x: any) => !(x.role && (x.role.includes('presenter') || x.role.includes('moderator'))) && !x.isAdmitted,
        );
        if (otherUsersReceivedData && otherUsersReceivedData.length > 0) {
            yield put(actions.addWaitingAttendees(otherUsersReceivedData));
        } else {
            yield put(actions.addWaitingAttendees([]));
        }
        const channel = yield call(getWaitingRoomUserList, obj);
        try {
            while (true) {
                const result = yield take(channel);

                if (result) {
                    const users = yield select(selector.getWaitingRoomAttendees);
                    const waitingRoomUsers = cloneDeep(users);
                    // const data = [];
                    if (result.isRemoved || result.isExisting) {
                        const userIndex: number = waitingRoomUsers.findIndex((x: any) => x.uid === result.uid);
                        if (result.isExisting) {
                            if (userIndex !== -1) {
                                waitingRoomUsers[userIndex] = result;
                            }
                        } else {
                            if (userIndex !== -1) {
                                waitingRoomUsers.splice(userIndex, 1);
                            }
                        }
                    } else {
                        waitingRoomUsers.push(result);
                    }
                    const otherUsers = waitingRoomUsers.filter(
                        (x: any) =>
                            !(x.role && (x.role.includes('presenter') || x.role.includes('moderator'))) &&
                            !x.isAdmitted,
                    );
                    if (otherUsers && otherUsers.length > 0) {
                        yield put(actions.addWaitingAttendees(otherUsers));
                    } else {
                        yield put(actions.addWaitingAttendees([]));
                    }
                }
            }
        } catch (e) {
            console.warn(e);
        } finally {
            // unregister listener if the saga was cancelled
            if (yield cancelled()) channel.close();
        }
    } catch (error) {
        console.error(error);
        Sentry.captureException(error);
    }
};

export const breakoutRecordingConnection = function* (): SagaIterator {
    while (yield take(actions.addBreakoutRecordingListner)) {
        const connectionTask = yield fork(BreakoutRecordingListerner);
        // wait for the user stop action
        yield take([actions.removeBreakoutRecordingListner, actions.signOutUser]);

        yield cancel(connectionTask);
    }
};

export const BreakoutRecordingListerner = function* (): SagaIterator {
    try {
        const organizationId = yield select(getOrganizationId);
        const eventId = yield select(getEventId);
        const roomId = yield select(getRoomId);

        const obj = {
            organizationId,
            eventId,
            roomId,
        };
        const channel = yield call(getRoomRecordingState, obj);
        try {
            while (true) {
                const result = yield take(channel);
                if (result) {
                    const keys = typeof result === 'object' ? Object.keys(result) : null;
                    if (keys && Array.isArray(keys)) {
                        for (const key of keys) {
                            if (result[key] && Object.keys(result[key])) {
                                if (key === roomId) {
                                    yield put(actions.addBreakoutRecordingStates(result[key]));
                                }
                            }
                        }
                    }
                } else {
                    yield put(actions.addBreakoutRecordingStates({}));
                }
            }
        } catch (e) {
            console.warn(e);
        } finally {
            // unregister listener if the saga was cancelled
            if (yield cancelled()) channel.close();
        }
    } catch (error) {
        console.error(error);
        Sentry.captureException(error);
    }
};

export const breakoutVideoControlConnection = function* (): SagaIterator {
    while (yield take(actions.addBreakoutVideoControlListerner)) {
        const connectionTask = yield fork(BreakoutVideoControlListerner);
        // wait for the user stop action
        yield take([actions.removeBreakoutRecordingListner, actions.signOutUser]);

        yield cancel(connectionTask);
    }
};

export const BreakoutVideoControlListerner = function* (): SagaIterator {
    try {
        const organizationId = yield select(getOrganizationId);
        const eventId = yield select(getEventId);
        const roomId = yield select(getRoomId);

        const obj = {
            organizationId,
            eventId,
            roomId,
        };
        const channel = yield call(getRoomVideoControlState, obj);
        try {
            while (true) {
                const result = yield take(channel);
                if (result) {
                    if (result) {
                        yield put(actions.addVideoControlStates(result));
                    }
                } else {
                    yield put(actions.addVideoControlStates(null));
                }
            }
        } catch (e) {
            console.warn(e);
        } finally {
            // unregister listener if the saga was cancelled
            if (yield cancelled()) channel.close();
        }
    } catch (error) {
        console.error(error);
        Sentry.captureException(error);
    }
};

export const breakoutVideoControlPlayPauseStop = function* (action: ISagaAction<any>): SagaIterator {
    try {
        const organizationId = yield select(getOrganizationId);
        const eventId = yield select(getEventId);
        const roomId = yield select(getRoomId);

        const obj = {
            organizationId,
            eventId,
            roomId,
        };
        yield call(controlVideoPlayPauseStop, action.payload, obj);
    } catch (error) {
        console.error(error);
        Sentry.captureException(error);
    }
};

export const breakoutStopRecordingSaga = function* (action: ISagaAction<any>): SagaIterator {
    try {
        const mainRoomId = yield select(getRoomId);
        const roomId = action.payload.roomId ? action.payload.roomId : mainRoomId
        const organizationId = yield select(getOrganizationId);
        const eventId = yield select(getEventId);
        const collection = action.payload.collection ? action.payload.collection : 'components';
        if (organizationId && eventId && roomId) {
            const obj = {
                organizationId,
                eventId,
                roomId,
            };

            yield all([
                call(Api.stopBrekaoutRecordingApi, organizationId, eventId, roomId, collection),
                call(updateBreakoutRecordingState, obj),
            ]);
        }
    } catch (error) {
        console.error(error);
        Sentry.captureException(error);
    }
};


export const allowAllUsersInRoom = function* (action: ISagaAction<any>): SagaIterator {
    try {
        const organizationId = yield select(getOrganizationId);
        const eventId = yield select(getEventId);
        const roomId = yield select(getRoomId);

        if (organizationId && eventId && (roomId || action.payload.roomId)) {
            const obj = {
                organizationId,
                eventId,
                roomId: roomId || action.payload.roomId,
                hasNoWaitingQueue: action.payload.hasNoWaitingQueue,
            };
            yield call(allowAllRoomUser, obj);
        }
    } catch (error) {
        console.error(error);
        Sentry.captureException(error);
    }
};
export const updateUserProfile = function* (action: ISagaAction<any>): SagaIterator {
    try {
        const organizationId = yield select(getOrganizationId);
        const eventId = yield select(getEventId);
        const roomId = yield select(getRoomId);
        const uid = yield select(getUserUid);
        if (organizationId && eventId && (roomId || action.payload.roomId) && action.payload.profile) {
            const obj = {
                organizationId,
                eventId,
                roomId: roomId || action.payload.roomId,
                uid: uid || action.payload.uid,
            };

            yield call(updateUserRoomConnection, obj, action.payload.profile);
        }
    } catch (error) {
        console.error(error);
        Sentry.captureException(error);
    }
};

export default userPresenceInBreakoutConnection;
