import { SagaIterator } from 'redux-saga';
import { call, select } from 'redux-saga/effects';
import Api from 'modules/utils/Api';
import { ISagaAction } from 'modules/types';
import { getOrganizationId, getFirebaseAccessToken } from 'modules/selectors/user';

export const sendInviteEmailSaga = function* (action: ISagaAction<object>): SagaIterator {
    const formattedDate = new Date().toISOString().split('T')[0];
    const organizationId = yield select(getOrganizationId);
    const token = yield select(getFirebaseAccessToken);
    yield call(Api.sendInviteEmail, action.payload, formattedDate, organizationId, token);
};

export default sendInviteEmailSaga;
