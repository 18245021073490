import { FC, useMemo, useState, useEffect } from "react";
import './Timer.scss';
import moment from 'moment';
import 'moment-duration-format';
import { Title, TimerDiv, Count, NumberDiv, Unit } from './styles';

interface Props {
    date: number;
    bannerTextColor: string;
}

export const Timer: FC<Props> = ({ date, bannerTextColor }) => {
    const eventDate = useMemo(() => moment(date), [date]);
    const [time, setTime] = useState([0, 0, 0, 0]);

    const formatter = (ms: number) => {
        const days = Math.floor(ms / (24 * 60 * 60 * 1000));
        const daysms = ms % (24 * 60 * 60 * 1000);
        const hours = Math.floor(daysms / (60 * 60 * 1000));
        const hoursms = ms % (60 * 60 * 1000);
        const minutes = Math.floor(hoursms / (60 * 1000));
        const minutesms = ms % (60 * 1000);
        const sec = Math.floor(minutesms / 1000);
        return [days, hours, minutes, sec]
    }

    useEffect(() => {
        const stopInterval = setInterval(() => {
            const difference = eventDate.diff(moment(), 'seconds');
            const timeLeft = moment.duration(difference, 'seconds');
            if (timeLeft.asSeconds() <= 0) {
                clearInterval(stopInterval);
            } else {
                // const timeSplit = moment(timeLeft.asMilliseconds()).format('DD:HH:MM:ss').split(':').map(Number);
                setTime(formatter(timeLeft.asMilliseconds()));
            }
        }, 1000);
        return () => clearInterval(stopInterval);
    }, [eventDate]);

    return (
        <div className="timer">
            {time.some((val) => val > 0) && <Title bannerTextColor={bannerTextColor}>Live event starts in</Title>}
            <TimerDiv>
                {time[0] > 0 && <Count className="count">
                    <NumberDiv className="number" bannerTextColor={bannerTextColor}>{time[0]}</NumberDiv>
                    <Unit className="unit" bannerTextColor={bannerTextColor}>{time[0] > 1 ? 'Days' : 'Day'}</Unit>
                </Count>}
                {time[1] > 0 && <Count className="count">
                    <NumberDiv className="number" bannerTextColor={bannerTextColor}>{time[1]}</NumberDiv>
                    <Unit className="unit" bannerTextColor={bannerTextColor}>{time[1] > 1 ? 'Hours' : 'Hour'}</Unit>
                </Count>}
                {time[2] > 0 && <Count className="count">
                    <NumberDiv className="number" bannerTextColor={bannerTextColor}>{time[2]}</NumberDiv>
                    <Unit className="unit" bannerTextColor={bannerTextColor}>{time[2] > 1 ? 'Minutes' : 'Minute'}</Unit>
                </Count>}
                {time[3] > 0 && <Count className="count">
                    <NumberDiv className="number" bannerTextColor={bannerTextColor}>{time[3]}</NumberDiv>
                    <Unit className="unit" bannerTextColor={bannerTextColor}>{time[3] > 1 ? 'Seconds' : 'Second'}</Unit>
                </Count>}
            </TimerDiv>
        </div>
    )
}

export default Timer