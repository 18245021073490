import './wdyr';
import './css/index.scss';
import 'url-search-params-polyfill';
import 'modules/utils/Firebase';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ErrorBoundary } from 'components';
import store, { persistor } from 'modules/store';
import * as serviceWorker from './serviceWorker';
import { PersistGate } from 'redux-persist/integration/react';
import PromptModal from 'components/PromptModal';
import { AppProvider } from 'context/AppContext';
import RenderLayout from './layouts/RenderLayout';
import FullStory from 'react-fullstory';

const ORG_ID = process.env.REACT_APP_FULLSTORY_ORG_ID || 'XAP7Z';

if (process.env.REACT_APP_ENVIRONMENT !== 'development') {
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        release: 'tocca-ve2@' + process.env.REACT_APP_VERSION,
        integrations: [
            new Integrations.BrowserTracing(),
            // This will give ability to see correct path in the dev tools
            new Sentry.Integrations.Breadcrumbs({
                console: false,
            }),
        ],
        tracesSampleRate: 1.0,
    });
}

ReactDOM.render(
    <AppProvider>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <BrowserRouter
                    basename={process.env.REACT_APP_BASE_URL}
                    getUserConfirmation={(message, callback) => {
                        ReactDOM.render(
                            <PromptModal message={message} callback={callback} />,
                            document.getElementById('modal'),
                        );
                    }}
                >
                    <FullStory org={ORG_ID} />

                    <ErrorBoundary>
                        <RenderLayout />
                    </ErrorBoundary>
                </BrowserRouter>
            </PersistGate>
        </Provider>
    </AppProvider>,
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
