import styled from 'styled-components/macro';

export const SectionsTitle = styled.div`
    color: #2c2543;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 39px;
    line-height: 28px;
    letter-spacing: 0.48px;
    display: flex;
    align-items: center;
`;
