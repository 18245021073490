import { AdminEventPeople, Speaker } from 'modules/utils/Firebase/Api';
import isEmpty from 'lodash/isEmpty';

export const getSpeakerList = async (eventId: string) => {
    const connectionRef = AdminEventPeople.reference();
    const peopleEmailRef = connectionRef.orderBy(`roles.${eventId}`);
    const snapshot = await peopleEmailRef.get();
    const adminPeopleData: any[] = [];
    const speakerList: any[] = [];
    snapshot.forEach((doc) => {
        adminPeopleData.push(doc.data());
    });
    if (adminPeopleData && adminPeopleData.length) {
        adminPeopleData.map((user) => {
            if (user && !isEmpty(user?.roles)) {
                Object.keys(user?.roles).forEach((id) => {
                    if (id.toString() === eventId.toString()) {
                        const roleData = user?.roles[id]
                        const value = roleData[Object.keys(roleData)[0]];
                        const exists = value && value.length !== 0 ? value.includes('presenter') : false;
                        if (exists) {
                            speakerList.push(user)
                        }
                    }
                })
            }
            return user;
        })
    }
    return speakerList;
};

export const getConversationSpeakerList = async (eventId: string, organizationId: string) => {
    const connectionRef = Speaker.reference(organizationId, eventId);
    const snapshot = await connectionRef.get();
    const speakersData: any[] = [];
    const userUpdatedList: any[] = [];
    snapshot.forEach((doc) => {
        speakersData.push(doc.data());
    });
    for (const userObj of speakersData) {
        const userRef = AdminEventPeople.reference().doc(userObj.adminPeopleRefId);
        const snapShot = await userRef.get();
        const userData = snapShot.data();
        if (userData) {
            userData.id = snapShot.id
            const obj = {
                ...userObj,
                firstName: userData.firstName,
                lastName: userData.lastName,
                jobTitle: userData.jobTitle,
                avatar: userData.avatar,
                companyName: userData.companyName
            };
            userUpdatedList.push(obj);
        }
    }
    return userUpdatedList;
};
