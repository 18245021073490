import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { formCheck, FormHeader, Invalid, Space } from '../common/index';
import * as selectors from 'modules/selectors';
import * as actions from 'modules/actions';
import { Input } from '../inputs';
import is from 'classnames';
import { NAME, EMAIL, WORK, LOCATION, SOCIAL, DESCRIPTION, Mode } from '../constants/constants';
import { Label } from '../fieldset/label';
import { LinkedinIcon, TwitterIcon } from 'components/Icons';
import { Button, Horizontal, Vertical, Area, Textarea } from '../style';
import { Avatar } from '../fieldset/Avatar';
import { isEmpty, isNil } from 'lodash';
import { Location } from 'components/Location';

export const EmailPattern =
    // eslint-disable-next-line
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

interface IState {
    firstName: string;
    lastName: string;
    email: string;
    companyName?: string;
    jobTitle?: string;
    description?: string;
    workLocation?: string;
    linkedin?: string;
    twitter?: string;
}

const ProfileMode = () => {
    const [state, setState] = useState<IState>({
        firstName: '',
        lastName: '',
        email: '',
        companyName: '',
        jobTitle: '',
        workLocation: '',
        description: '',
        linkedin: '',
        twitter: '',
    });

    const [backupStateData, setBackupStateData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        companyName: '',
        jobTitle: '',
        workLocation: '',
        description: '',
        linkedin: '',
        twitter: '',
    });

    const dispatch = useDispatch();
    // const Verified = verified();
    // const NeedsConfirmation = needsConfirmation();
    const isUserRegister = useSelector(selectors.getUserRegister);
    const profileData = useSelector(selectors.getProfileData);
    const [loginValidation, setError] = useState({
        firstName: '',
        lastName: '',
        email: '',
        companyName: '',
        jobTitle: '',
        description: '',
        workLocation: '',
        avatar: '',
        linkedin: '',
        twitter: '',
    });

    useEffect(() => {
        if (profileData) {
            const updatedData = {
                firstName: profileData.firstName ? profileData.firstName : '',
                lastName: profileData?.lastName ? profileData?.lastName : '',
                email: profileData?.email ? profileData?.email : '',
                companyName: profileData?.companyName ? profileData?.companyName : '',
                jobTitle: profileData?.jobTitle ? profileData?.jobTitle : '',
                workLocation: profileData?.workLocation ? profileData?.workLocation : '',
                description: profileData?.description ? profileData?.description : '',
                linkedin: profileData?.linkedin ? profileData?.linkedin : '',
                twitter: profileData?.twitter ? profileData?.twitter : '',
            };
            setState(updatedData);
            setBackupStateData(updatedData);
        }
    }, [profileData]);

    const handleInputChange = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;
        const obj = {
            ...state,
            [name]: value,
        };
        setState(obj);
    };

    const handleLocationField = (value: any) => {
        setState((val) => ({ ...val, [LOCATION.PROPERTY]: value }));
    };

    const checkFieldValidation = () => {
        let formIsValid = true;
        const errorObj = {
            firstName: '',
            lastName: '',
            email: '',
            companyName: '',
            jobTitle: '',
            description: '',
            workLocation: '',
            avatar: '',
            linkedin: '',
            twitter: '',
        };

        if (!state.firstName || !state.firstName.trim()) {
            errorObj.firstName = 'First Name must not empty';
            formIsValid = false;
        }
        if (!state.lastName || !state.lastName.trim()) {
            errorObj.lastName = 'Last Name must not empty';
            formIsValid = false;
        }
        if (!state.email || !state.email.trim() || !EmailPattern.test(state.email)) {
            errorObj.email = 'Enter a valid email address';
            formIsValid = false;
        }
        if (!state.jobTitle) {
            errorObj.jobTitle = 'Title must not empty';
            formIsValid = false;
        }
        if (!state.companyName) {
            errorObj.companyName = 'Organization must not empty';
            formIsValid = false;
        }
        if (state.linkedin && state.linkedin.indexOf('www.linkedin.com') === -1) {
            errorObj.linkedin = 'Correct Syntax Required ll';
            formIsValid = false;
        }
        if (state.twitter && !state.twitter.startsWith('@')) {
            errorObj.twitter = 'Correct Syntax Required tt';
            formIsValid = false;
        }
        setError(errorObj);
        return formIsValid;
    };

    const submitBtn = (e: any) => {
        e.preventDefault();
        if (checkFieldValidation()) {
            dispatch(actions.isUserRegistred(Mode.initial));
            dispatch(actions.saveProfileData(state));
        }
    };

    const closeBtn = () => {
        setState(backupStateData);
        dispatch(actions.closeLoginModal());
    };

    const modified = React.useMemo(() => {
        const formSegment = formCheck(state);
        const profileSegment = formCheck(backupStateData);
        for (const field in formSegment) {
            let a = formSegment[field]?.trim();
            a = isEmpty(a) || isNil(a) ? null : a;
            let b = profileSegment[field]?.trim();
            b = isEmpty(b) || isNil(b) ? null : b;
            if (a !== b) return true;
        }
        return false;
    }, [state, backupStateData]);

    const isNewAttendee = React.useMemo(() => isUserRegister === Mode.useNewEmail || isUserRegister === Mode.googleAuth, [isUserRegister]);

    useEffect(() => {
        !modified && !isNewAttendee
            ? dispatch(actions.allowOutsideClick(true))
            : dispatch(actions.allowOutsideClick(false));
    }, [modified, isNewAttendee, dispatch]);

    return (
        <React.Fragment>
            <FormHeader title={'Complete your Profile'} />
            <Horizontal>
                <Avatar />
                <Space />
                <div />
            </Horizontal>
            <Space />
            <Horizontal>
                <Invalid style={{ flexGrow: 1 }}>
                    <label htmlFor={NAME.FIRST.PROPERTY}>{NAME.FIRST.LABEL}</label>
                    <Input
                        // autoComplete="given-name"
                        name={NAME.FIRST.PROPERTY}
                        id={NAME.FIRST.PROPERTY}
                        placeholder={NAME.FIRST.PLACEHOLDER}
                        onChange={handleInputChange}
                        value={state.firstName}
                    />
                    <span style={{ color: 'red' }}>{loginValidation.firstName}</span>
                </Invalid>
                <Space />
                <Invalid style={{ flexGrow: 1 }}>
                    <label htmlFor={NAME.LAST.PROPERTY}>{NAME.LAST.LABEL}</label>
                    <Input
                        autoComplete="family-name"
                        name={NAME.LAST.PROPERTY}
                        id={NAME.LAST.PROPERTY}
                        placeholder={NAME.LAST.PLACEHOLDER}
                        onChange={handleInputChange}
                        value={state.lastName}
                    />
                    <span style={{ color: 'red' }}>{loginValidation.lastName}</span>
                </Invalid>
            </Horizontal>
            <Space />
            <Vertical>
                <Invalid messageOnly={loginValidation.email}>
                    <Label field={EMAIL.PROPERTY}>{EMAIL.LABEL}</Label>
                    <Input
                        type="text"
                        id={EMAIL.PROPERTY}
                        name={'email'}
                        placeholder={EMAIL.PLACEHOLDER}
                        onChange={handleInputChange}
                        autoFocus={true}
                        value={state.email}
                        disabled={true}
                    />
                    {/* {!loginValidation.email && (
                        <Area className="center end" y={15} height={38} style={{ right: 0 }}>
                            <Note failure={!Verified} success={Verified && !NeedsConfirmation}>
                                <strong style={{ fontSize: '14px' }}>
                                    {NeedsConfirmation
                                        ? 'Needs Confirmation'
                                        : Verified
                                        ? 'Active'
                                        : 'Needs Validation'}
                                </strong>
                            </Note>
                        </Area>
                    )}
                    {!Verified && (
                        <Horizontal>
                            <Space />
                            <Link onClick={() => sendEmailVerification()}>
                                <Vertical style={{ fontSize: '14px', fontWeight: 'bold', textDecoration: 'underline' }}>
                                    <Space height={0.3} />
                                    Send Validation Email
                                </Vertical>
                            </Link>
                        </Horizontal>
                    )} */}
                    <span style={{ color: 'red' }}>{loginValidation.email}</span>
                </Invalid>
            </Vertical>
            <Space />
            <Horizontal>
                <Invalid style={{ flexGrow: 1 }}>
                    <label htmlFor={WORK.COMPANY.PROPERTY}>{WORK.COMPANY.LABEL}</label>
                    <Input
                        name={WORK.COMPANY.PROPERTY}
                        id={WORK.COMPANY.PROPERTY}
                        placeholder={WORK.COMPANY.PLACEHOLDER}
                        onChange={handleInputChange}
                        value={state.companyName}
                    />
                    <span style={{ color: 'red' }}>{loginValidation.companyName}</span>
                </Invalid>
                <Space />
                <Invalid style={{ flexGrow: 1 }}>
                    <label htmlFor={WORK.TITLE.PROPERTY}>{WORK.TITLE.LABEL}</label>
                    <Input
                        name={WORK.TITLE.PROPERTY}
                        id={WORK.TITLE.PROPERTY}
                        placeholder={WORK.TITLE.PLACEHOLDER}
                        onChange={handleInputChange}
                        value={state.jobTitle}
                    />
                    <span style={{ color: 'red' }}>{loginValidation.jobTitle}</span>
                </Invalid>
            </Horizontal>
            <Space />
            <Horizontal>
                <Invalid messageOnly style={{ flexGrow: 1 }}>
                    <label htmlFor={SOCIAL.LINKEDIN.PROPERTY}>{SOCIAL.LINKEDIN.LABEL}</label>
                    <Input
                        name={SOCIAL.LINKEDIN.PROPERTY}
                        id={SOCIAL.LINKEDIN.PROPERTY}
                        placeholder={SOCIAL.LINKEDIN.PLACEHOLDER}
                        style={{ paddingRight: 27 }}
                        onChange={handleInputChange}
                        value={state.linkedin}
                    />
                    <span style={{ color: 'red' }}>{loginValidation.linkedin}</span>
                    <Area className="center end" y={17} height={38} style={{ right: 0 }}>
                        <LinkedinIcon fill="var(--theme-color)" />
                    </Area>
                </Invalid>
                <Space />
                <Invalid style={{ flexGrow: 1 }}>
                    <label htmlFor={SOCIAL.TWITTER.PROPERTY}>{SOCIAL.TWITTER.LABEL}</label>
                    <Input
                        name={SOCIAL.TWITTER.PROPERTY}
                        id={SOCIAL.TWITTER.PROPERTY}
                        placeholder={SOCIAL.TWITTER.PLACEHOLDER}
                        style={{ paddingRight: 27 }}
                        onChange={handleInputChange}
                        value={state.twitter}
                    />
                    <span style={{ color: 'red' }}>{loginValidation.twitter}</span>
                    <Area className="center end" y={17} height={38} style={{ right: 0 }}>
                        <TwitterIcon fill="var(--theme-color)" />
                    </Area>
                </Invalid>
            </Horizontal>
            <Space />
            <Location
                label={LOCATION.LABEL}
                autoComplete="off"
                id={LOCATION.PROPERTY}
                name={LOCATION.PROPERTY}
                placeholder={LOCATION.PLACEHOLDER}
                onChange={handleLocationField}
                value={state.workLocation}
            />
            <span style={{ color: 'red' }}>{loginValidation.workLocation}</span>
            <Space />
            <Invalid style={{ flexGrow: 1 }}>
                <label htmlFor={DESCRIPTION.PROPERTY}>{DESCRIPTION.LABEL}</label>
                <Textarea
                    autoComplete="off"
                    name={DESCRIPTION.PROPERTY}
                    id={DESCRIPTION.PROPERTY}
                    placeholder={DESCRIPTION.PLACEHOLDER}
                    onChange={handleInputChange}
                    value={state.description}
                />
                <span style={{ color: 'red' }}>{loginValidation.description}</span>
            </Invalid>
            <Space height={2} />
            <Space />
            <Horizontal>
                {modified && !isNewAttendee ? (
                    <Button onClick={closeBtn} className={is({ outline: true })}>
                        {'Discard Changes'}
                    </Button>
                ) : (
                    <Space />
                )}
                <Space />
                {modified || isNewAttendee ? (
                    <Button onClick={submitBtn}>Save</Button>
                ) : (
                    <Button onClick={closeBtn}>Done</Button>
                )}
            </Horizontal>
        </React.Fragment>
    );
};

export default ProfileMode;
