import React from 'react';

export const LiveStreamBroadcastConfig = {
    settings: {
        layout: {
            config: {
                header: { display: true },
                footer: { display: true },
                navbar: { display: true },
            },
        },
    },
    isPrivate: false,
    eventName: 'breakout_recorders',
    routes: [
        {
            path: '/live/broadcast',
            component: React.lazy(() => import('./BoradcastStreamRoute')),
        },
    ],
};
