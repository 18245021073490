import { createReducer } from 'redux-act';
import {
    fetchSponsors,
    fetchSponsorsFailed,
    fetchSponsorsSuccess,
    createRequestFollowUp,
    createRequestSucces,
    setSponsor,
    setSponsorTypes,
    getSponsorsTypes,
    resetState,
    setSponsorBoothUserPresenceList,
} from 'modules/actions';

export interface ISponsor {
    html: string;
    id: string;
    logoUrl: string;
    mediaUrl: string;
    name: string;
    scheduleCallUrl: string;
    videoCallUrl: string;
    websiteUrl: string;
    type: string;
    isKeynote: boolean;
    order?: string;
    sponsorLoungeRedirectToBreakout?: boolean;
    isSponsorWelcome?: boolean;
    bgSponImageUrl?: string;
    title?: string;
    sponsorVisitWebsiteLabel: string;
    sponsorFollowupLabel: string;
    sponsorLoungeBtn: string;
}

export interface ISponsorType {
    id: string;
    order?: number;
    theme: string;
    title: string;
    type: string;
}

interface ISponsorBoothList {
    [roomId: string]: {
        sponsorBoothUserList: any[];
    };
}

export type SponsorsReducerState = {
    fetching: boolean;
    list: ISponsor[];
    requestStatus: {
        userId?: undefined;
        duplicate?: {
            sponsorId: string;
            userId: string;
        };
        message: string;
        error?: undefined;
    };
    sponsorBoothUserPresenceData: ISponsorBoothList[];
    isRequestFetchingFinished: boolean;
    sponsorId: string;
    sponsorType: ISponsorType[];
    isFetchingSponsorTypes: boolean;
};

const initialState: SponsorsReducerState = {
    fetching: false,
    list: [],
    requestStatus: {
        userId: undefined,
        duplicate: {
            sponsorId: '',
            userId: '',
        },
        message: '',
        error: undefined,
    },
    isRequestFetchingFinished: false,
    sponsorId: '',
    sponsorType: [],
    isFetchingSponsorTypes: false,
    sponsorBoothUserPresenceData: [],
};

export const sponsors = createReducer<SponsorsReducerState>({}, initialState);
sponsors.on(fetchSponsors, (state: SponsorsReducerState) => ({
    ...state,
    fetching: true,
    isRequestFetchingFinished: false,
}));

sponsors.on(fetchSponsorsSuccess, (state: SponsorsReducerState, payload: ISponsor[]) => ({
    ...state,
    fetching: false,
    list: payload,
}));

sponsors.on(fetchSponsorsFailed, () => initialState);
sponsors.on(createRequestFollowUp, (state: SponsorsReducerState) => ({
    ...state,
    isRequestFetchingFinished: false,
}));
sponsors.on(createRequestSucces, (state: SponsorsReducerState, payload: any) => ({
    ...state,
    requestStatus: payload,
    isRequestFetchingFinished: true,
}));
sponsors.on(setSponsor, (state: SponsorsReducerState, payload: string) => ({
    ...state,
    sponsorId: payload,
}));

sponsors.on(setSponsorTypes, (state: SponsorsReducerState, payload: ISponsorType[]) => ({
    ...state,
    sponsorType: payload && payload.length > 0 ? payload : [],
    isFetchingSponsorTypes: false,
}));

sponsors.on(getSponsorsTypes, (state: SponsorsReducerState) => ({
    ...state,
    isFetchingSponsorTypes: true,
}));

sponsors.on(resetState, () => initialState);

sponsors.on(setSponsorBoothUserPresenceList, (state: SponsorsReducerState, payload: any) => ({
    ...state,
    sponsorBoothUserPresenceData: payload,
}));
