import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './DiscoveryHeader.scss';
import { Dropdown } from 'react-bootstrap';
import { ErrorBoundary, ProfileAvatar } from 'components';
import { HeaderButton } from 'components/Header/components';
// import { SignInSignUp } from 'components/Access/UserAccess';
import { authMode, openLoginModal, signOutUser } from 'modules/actions';
import { getIsUserAuth } from 'modules/selectors'
import { Container, Separator, BrandLogo } from './styles';
import { defaultCompanyLogo } from 'modules/constants';
import { Mode } from 'components/Login/constants/constants';

interface Props {
    logoUrl: string,
    fillColor: string
}

export const DiscoveryHeader: FC<Props> = ({ logoUrl, fillColor }) => {
    const dispatch = useDispatch();
    const authenticated = useSelector(getIsUserAuth);

    const onClickOpenLink = (event: any) => {
        event.preventDefault();
        window.open('https://app.tocca.io/terms-of-service');
    };

    const logoutUser = (e: any) => {
        e.preventDefault();
        dispatch(signOutUser());
    };

    const profileModal = () => {
        dispatch(openLoginModal());
        dispatch(authMode(Mode.profile));
    };
    return (
        <ErrorBoundary>
            <Container className="sticky-top" {...{ fillColor }}>
                <div className='logos-header'>
                    <BrandLogo
                        className='shared-studios-header'
                        src={defaultCompanyLogo}
                        alt="Shared Studios"
                    />
                    <Separator />
                    <img
                        height="48px"
                        className='partner-header'
                        src={logoUrl}
                        alt="Partner"
                    />
                </div>
                {authenticated && <Dropdown className="user-dd-discovery">
                    <Dropdown.Toggle id="dropdown-basic">
                        <HeaderButton strokeWidth={2}>
                            <ProfileAvatar />
                        </HeaderButton>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item className="droplist-item" onClick={profileModal}>
                            My profile
                        </Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item className="droplist-item" onClick={onClickOpenLink}>
                            Terms of Service
                        </Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item className="droplist-item" onClick={logoutUser}>
                            Logout
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>}
            </Container>
        </ErrorBoundary>
    )
}

export default DiscoveryHeader;