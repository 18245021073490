import firebase, { auth } from 'modules/utils/Firebase';
import { eventChannel, EventChannel } from 'redux-saga';
class FireBaseAuth {
    authChannel!: EventChannel<any>;

    signInUser = (email: string, password: string, keepLogin: boolean) => {
        return new Promise((resolve, reject) => {
            const persistenceScheme = keepLogin ? 'LOCAL' : 'SESSION';

            auth.setPersistence(firebase.auth.Auth.Persistence[persistenceScheme])
                .then(() =>
                    auth
                        .signInWithEmailAndPassword(email, password)
                        .then((response: any) => {
                            resolve(response);
                        })
                        .catch((err: any) => {
                            reject(err);
                        }),
                )
                .catch((err) => {
                    reject(err);
                });
        });
    };

    signInWithGoogle = async () => {
        return new Promise((resolve, reject) => {
            const persistenceScheme = 'SESSION';
            auth.setPersistence(firebase.auth.Auth.Persistence[persistenceScheme])
                .then(() =>
                    auth
                        .signInWithPopup(new firebase.auth.GoogleAuthProvider())
                        .then((response: any) => {
                            resolve(response);
                        })
                        .catch((err: any) => {
                            reject(err);
                        }),
                )
                .catch((err) => {
                    reject(err);
                });
        });
    };

    createUser = (email: string, password: string) => {
        return new Promise((resolve, reject) => {
            const persistenceScheme = 'SESSION';
            auth.setPersistence(firebase.auth.Auth.Persistence[persistenceScheme])
                .then(() =>
                    auth
                        .createUserWithEmailAndPassword(email, password)
                        .then((response: any) => {
                            resolve(response);
                        })
                        .catch((err: any) => {
                            reject(err);
                        }),
                )
                .catch((err) => {
                    reject(err);
                });
        });
    };

    checkExistingRoles = async (email: any) => {
        try {
            const rolesCheckByEmail = firebase.functions().httpsCallable('isAdminUserValid');
            const response = await rolesCheckByEmail({
                email: email,
            });
            return response;
        } catch (e) {
            return e;
        }
    };

    checkEmailExists = async (email: any) => {
        try {
            const response = await firebase.auth().fetchSignInMethodsForEmail(email);
            return response;
        } catch (e) {
            return e;
        }
    };

    getAuthChannel = () => {
        this.authChannel = eventChannel((emit) => {
            return auth.onIdTokenChanged((user: firebase.User | null) => {
                if (!user) return emit({ error: 'No user' });
                return emit({ user });
            });
        });
        return this.authChannel;
    };

    fetchCurrentUserToken = () => {
        return new Promise((resolve) => auth.currentUser?.getIdToken().then(resolve));
    };

    resetPassword = (email: string) => {
        return new Promise((resolve, reject) => {
            auth.sendPasswordResetEmail(email)
                .then(() => {
                    resolve(true);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };

    logOutUser = () => {
        return new Promise((resolve, reject) => {
            auth.signOut()
                .then(() => {
                    // this.authFlag = true;
                    resolve(true);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };

    signInWithCustomToken = (token: string) => {
        return new Promise((resolve, reject) => {
            auth.signInWithCustomToken(token)
                .then(() => {
                    // this.authFlag = true;
                    resolve(true);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };
}

export default FireBaseAuth;
