import React from 'react';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components/macro';
import { NavLink } from 'components/Route';
import Arrow from 'components/Buttons/Arrow';

const defaultBackground = css`linear-gradient(to bottom, #00d7ef, #00afef)`;

const link = css`
    color: var(--gray-dark-color);
    font-size: 1.07em;
    font-weight: 700;
    text-decoration: none;
    position: relative;
    &:hover,
    &:active {
        text-decoration: none;
        color: initial;
    }
    .header-navigation-container > & + & {
        margin-top: 5px;
    }
`;

interface IProps {
    direction?: 'left' | 'right';
    title?: string;
    to: string;
    arrowBackground?: FlattenSimpleInterpolation | string;
    padding?: boolean;
    size?: number;
}

interface IClickProps {
    direction?: 'left' | 'right';
    title?: string;
    arrowBackground?: FlattenSimpleInterpolation | string;
    onClick: () => void;
}

interface ILink {
    padding?: boolean;
}

const Link = styled(NavLink)<ILink>`
    ${link}
    padding-top: ${(props) => props.padding && '5px'};
    z-index: 1020;
`;

const Anchor = styled.a`
    & {
        ${link}
    }
    pointer-events: auto;
    cursor: pointer;
`;

export function RoundArrowButton({
    direction = 'right',
    title = '',
    to = '/',
    arrowBackground,
    padding,
    size,
}: IProps) {
    return (
        <Link to={to} className="d-flex flex-row align-items-center" padding={padding}>
            <Arrow direction={direction} background={arrowBackground || defaultBackground} size={size} />
            {title && <div className="title text-nowrap ml-2">{title}</div>}
        </Link>
    );
}

export function ArrowClick({ direction = 'right', title = '', onClick, arrowBackground }: IClickProps) {
    return (
        <Anchor onClick={onClick} className="d-flex flex-row align-items-center">
            <Arrow direction={direction} background={arrowBackground || defaultBackground} />
            {title && <div className="title text-nowrap ml-2">{title}</div>}
        </Anchor>
    );
}
