import styled from 'styled-components/macro';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import { Img } from 'react-image';
import isEmpty from 'lodash/isEmpty';
import { device } from 'modules/constants/deviceSize';
export const IconStyle = {
    width: 'auto',
    height: '20px',
    objectFit: 'contain',
    maxWidth: '25px',
};

interface IProps {
    themeColor?: string;
}
export const BrandLogo = styled(Img)`
    position: relative;
    margin-left: 30px;
    max-height: var(--header-height, 56px);
    height: 50px;
`;

export const ButtonContainer = styled.div`
    --spacing: 0.3rem;
    display: flex;
    align-items: center;
`;

export const DropItem = styled(Dropdown.Item)`
    --spaces: 40px;
    --sides: calc(30px + 40px);
    --width: calc(100% - (var(--spaces) + var(--sides)));
    position: relative;
    align-items: center;
    width: 340px;
    min-height: 40px;
    text-decoration: none;

    strong {
        font-size: 16px;
        font-weight: bold;
        color: #283747;
    }
    &:first-child {
        margin-top: 10px;
    }
    & + & {
        padding-bottom: 10px;
        padding-top: 10px;
    }
    &:last-of-type {
        margin-bottom: 20px;
    }

    & .sender,
    & .body,
    & .date {
        margin-top: 5px;
        margin-bottom: 5px;
    }
    & .sender {
        --size: 36px;
        width: 38px;
    }

    & .body {
        font-size: 14px;
        line-break: anywhere;
        width: var(--width);
        max-width: var(--width);
        text-overflow: ellipsis;
        overflow: hidden;
        max-lines: 3;
        max-height: 40px;
    }

    & .date {
        font-size: 12px;
        color: var(--slate-color);
        // white-space: nowrap;
        // width: 50px;
        // overflow: hidden;
        // text-overflow: ellipsis;
        display: inline-block;
    }

    & .space {
        display: block;
        flex-shrink: 0;
        width: 10px;
    }
`;

export const HeaderStyled = styled.div<any>`
    background: var(--header-background);

    @media screen and ${device.desktopS} {
        height: 3em;
    }
    height: var(--header-height);
    display: flex;
    align-items: center;
    padding: 0;
    height: 3.7rem;
    box-shadow: 0 5px 10px 0 #d7d5e5;
    -webkit-box-shadow: 0 5px 10px 0 #d7d5e5;
    -moz-box-shadow: 0 5px 10px 0 #d7d5e5;
    z-index: 1021;
    opacity: 1;
    .event-header-title {
        img {
            height: 40px !important;
            width: 40px !important;
        }
    }
    .event-header-title *{
        all: revert !important;
    }
    
    ${({ isBreakout, hideHeader }) =>
        isBreakout && hideHeader
            ? `
    opacity:0;
    transition: opacity 1s;
    `
            : `opacity:1`};
`;

export const Notifications = styled(DropdownButton)`
    & .dropdown-toggle::before {
        display: none;
    }
    & .bell-img {
        height: 20px;
    }
    & .header-notify {
        cursor: pointer;
        margin-top: 7px;
    }
    & .dropdown-menu {
        right: 0 !important;
        transform: inherit !important;
        top: 42px !important;
        padding: 0;
        border: none;
        box-shadow: 0 5px 10px 0 rgba(202, 202, 202, 0.44);
        background-color: var(--mono-white-color);
        padding-top: 10px;
    }

    button {
        opacity: 1 !important;
        background-color: transparent !important;
        border: none;
        padding: 0;
        &:hover {
            background-color: transparent;
            border: none;
            box-shadow: none;
            outline: none;
        }
        &:active,
        &:focus {
            background-color: transparent !important;
            border: none;
            box-shadow: none !important;
            outline: none;
        }
    }
    & .day-title {
        padding-left: 10px;
        font-size: 12px;
        font-weight: 500;
        color: var(--slate-color);
    }
`;

export const ScrollArea = styled.div`
    overflow: hidden;
    overflow-y: auto;
    max-height: 340px;
    position: relative;
    border-radius: 4px;
`;

export const SideBarIcon = styled.button<IProps>`
    border: none;
    border-radius: 6px;
    font-size: 30px;
    color: var(--theme-color);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--mono-white-color);
    border: none;
    outline: none;
    position: relative;
    outline: none !important;
    svg {
        color: ${({ themeColor }) => (isEmpty(themeColor) ? 'var(--theme-color)' : themeColor)} !important;
    }
`;

export const WrapperContainer = styled.div`
    max-width: 1620px;
    margin: 0 auto;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
`;

export const StyledMobileLogo = styled.div<any>`
    display: flex;
    flex-grow: 1;
    ${({ mobileView }) => mobileView && mobileView.isLandscape && `justify-content: center;`}
    img {
        max-height: 2.5rem;
        margin: 0.25rem;
        max-width: 5.44rem;
    }

    & .header-title {
        ${({ mobileView }) =>
        mobileView &&
        mobileView.isPortrait &&
        `width: 11rem;
        `}
        font-weight: bold;
        font-style: normal;
        color: var(--dark-grey);
    }
`;
