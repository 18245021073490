import * as ReduxSaga from 'redux-saga';
import { loadAvatars, reloadAvatars, toUrl, participantAvatar } from './functions';

const CACHE_INTERVAL_DURATION = 1000 * 60 * 30;
const CHANNEL_INTERVAL_DURATION = 1000;

export default {
    participantAvatar,
    participantAvatarsChannel,
    toUrl,
};

function participantAvatarsChannel() {
    return ReduxSaga.eventChannel(function subscribe(emit: (payload: any) => void) {
        const cacheInterval = window.setInterval(reloadAvatars, CACHE_INTERVAL_DURATION);
        const channelInterval = window.setInterval(() => {
            loadAvatars()
                .then(emit)
                .catch((error) => console.error('loadAvatars', error));
        }, CHANNEL_INTERVAL_DURATION);
        return function unsubscribe() {
            window.clearInterval(cacheInterval);
            window.clearInterval(channelInterval);
        };
    });
}
