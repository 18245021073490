import React from 'react';
import { useSelector } from 'react-redux';
import { Mode } from './constants/constants';
import * as selectors from 'modules/selectors';
import { FormContainer } from './style';
import InitialMode from './InitialMode';
import EmailMode from './PasswordMode';
import ForgotPasswordMode from './ForgotPasswordMode';
import ProfileMode from './Profile';
import FirstTimeMode from './FirstTimeMode';
import NewEmailMode from './NewEmailMode';
import { ProfileAvatarCaptureMode, ProfileAvatarMode } from './common';
import { NotRegisteredMode } from './NotRegiteredMode';
import { ProfileLoader } from 'components';

const Login = () => {
    const mode = useSelector(selectors.getAuthMode);
    return (
        <React.Fragment>
            <FormContainer>
                {mode === Mode.showLoader && <ProfileLoader />}
                {mode === Mode.initial && <InitialMode />}
                {mode === Mode.useEmail && <EmailMode />}
                {mode === Mode.forgotPassword && <ForgotPasswordMode />}
                {mode === Mode.useFirstTime && <FirstTimeMode />}
                {mode === Mode.profile && <ProfileMode />}
                {mode === Mode.useNewEmail && <NewEmailMode />}
                {mode === Mode.profileAvatar && <ProfileAvatarMode />}
                {mode === Mode.profileAvatarCapture && <ProfileAvatarCaptureMode />}
                {mode === Mode.notRegistered && <NotRegisteredMode />}
            </FormContainer>
        </React.Fragment>
    );
};
export default Login;
