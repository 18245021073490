import { SagaIterator } from 'redux-saga';
import { ISagaAction } from 'modules/types';
import {
    getEventDetailApi
} from 'modules/utils/Firebase/eventDetail';
import * as actions from 'modules/actions';
import { call, put } from 'redux-saga/effects';
import * as Sentry from '@sentry/react';

export function* getEventDetailSaga(action: ISagaAction<any>): SagaIterator {
    try {
        const eventId = action.payload.eventId
        const organizationId = action.payload.organizationId
        const payload = {
            eventId,
            organizationId
        }
        const eventData = yield call(getEventDetailApi, payload)
        yield put(actions.setEventDetail(eventData));
    } catch (error) {
        console.error(error);
        Sentry.captureException(error);
    }
};
