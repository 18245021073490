import { NetworkingRoom } from '../Api';
import { eventChannel } from 'redux-saga';
import omit from 'lodash/omit';
interface IAttendeeDetails {
    organizationId: string;
    eventId: string;
    roomId: string;
}
export const getNetworkingRoomState = (props: IAttendeeDetails) => {
    const connectionRef = NetworkingRoom.reference(props.organizationId, props.eventId, props.roomId);
    const connectionRoomListner = eventChannel((emit) => {
        const subscribe = connectionRef.onSnapshot((snapshot: any) => {
            if (snapshot.exists && snapshot.data()) return emit(snapshot.data());
            else return emit(false);
        });
        return () => {
            subscribe();
        };
    });
    return connectionRoomListner;
};

export const updateNetworkData = (props: IAttendeeDetails & any) => {
    const networkingRef = NetworkingRoom.reference(props.organizationId, props.eventId, props.roomId);
    try {
        const data = omit(props, ['organizationId', 'eventId', 'roomId']);
        networkingRef.update(data);
        return true;
    } catch (e) {
        console.error(String(e));
        return false;
    }
};
