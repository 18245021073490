import { IStoreState } from '../types';

export const isPollListFetching = (state: IStoreState) => state.polls.fetching;
export const getCurrentSelectedPollResponses = (state: IStoreState) => state.polls.selectedPollResponse;
export const getPollList = (state: IStoreState) => state.polls.list;
export const getShowPollModal = (state: IStoreState) => state.polls.showPollModal;
export const getCurrentPollModalType = (state: IStoreState) => state.polls.currentModalType;
export const getPollResults = (state: IStoreState) => state.polls.pollResults;
export const getDocumentId = (state: IStoreState) => state.polls.selectedDocumetId;
export const getPollLiveResults = (state: IStoreState) => state.polls.pollLiveResults;
export const getPollExclusiveFlag = (state: IStoreState) => state.polls.isPollExclusive;
export const getPollExclusiveId = (state: IStoreState) => state.polls.pollExclusiveId;
export const getUserPollGroupTag = (state: IStoreState) => state.polls.userPollGroupTag;
export const getEventGroupData = (state: IStoreState) => state.polls.groupData || state.polls.staticGroupData;




