import { useEffect, useState } from 'react';
import * as ReactRouter from 'react-router';
import {
    getAssets,
    getChatComponentDisplayMode,
    getBreakoutAttendeeForPrivateChat,
    getPrivateMsgNotification,
} from 'modules/selectors';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { useSelector, useDispatch } from 'react-redux';
import { ErrorBoundary, ProfileAvatar } from 'components';

import { BrandLogo, ButtonContainer, HeaderStyled, WrapperContainer } from './styles';
import {
    navigateTo,
    toggleChatComponent,
    setPrivateChatAttendeeCall,
    toggleParticipant,
    signOutUser,
    toggleEditor,
    authMode,
    openLoginModal,
    isProceedClick,
} from 'modules/actions';
import { HeaderButton, SpaceHolder } from './components';
import { CalendarIcon, ChatIconSvg, HomeIcon, ChatIconRed, ParticipantIconSvg } from 'components/Icons';
// import { usePrevious } from 'modules/hooks/useComponent';
import * as selectors from 'modules/selectors';
import { Dropdown } from 'react-bootstrap';
import { Mode } from 'components/Login/constants/constants';
import './header.scss';
// import Modal from 'components/Login/Modal';
// import Notification from 'assets/img/svg/notification';
export const Header = () => {
    const history = ReactRouter.useHistory();
    const assets = useSelector(getAssets);
    // const notification = useSelector(getNotification);
    const privateChatAttendee = useSelector(getBreakoutAttendeeForPrivateChat);
    // const layout = useSelector(getLayout);
    const dispatch = useDispatch();
    const chatDisplay = useSelector(getChatComponentDisplayMode);
    // const previousNotification: any = usePrevious(notification);
    // const [notified, setNotified] = useState(false);
    const privateNotifier = useSelector(getPrivateMsgNotification);
    const lobby = useSelector(selectors.getLobbyData);
    const isLobbyDataLoading = useSelector(selectors.getLobbyLoading);
    const [imageError, setImageError] = useState(false);
    const themeColor = useSelector(selectors.getEventThemeColor);
    const participantDisplay = useSelector(selectors.getChatParticipantState);
    const scheduleList = useSelector(selectors.getScheduleList);
    const editorState = useSelector(selectors.getEditorState);
    const Event = useSelector(selectors.getEvent);

    useEffect(() => {
        if (privateChatAttendee && !chatDisplay) dispatch(toggleChatComponent(!chatDisplay));
    }, [privateChatAttendee, chatDisplay, dispatch]);

    const toggleChat = () => {
        dispatch(toggleEditor(false));
        dispatch(setPrivateChatAttendeeCall(null));
        // dispatch(toggleChatComponent(!chatDisplay));
        if (participantDisplay) {
            dispatch(toggleParticipant(false));
            dispatch(toggleChatComponent(true));
        } else dispatch(toggleChatComponent(!chatDisplay));
    };

    const ChatIcon = () => ChatIconSvg(themeColor || 'var(--theme-color)');

    const toggleParticipantChat = () => {
        dispatch(toggleEditor(false));
        if (participantDisplay) {
            dispatch(toggleChatComponent(false));
            dispatch(toggleParticipant(false));
        } else {
            dispatch(toggleParticipant(true));
            dispatch(toggleChatComponent(true));
        }

        // dispatch(toggleChatComponent(!chatDisplay));
    };
    const ParticipantIcon = () => ParticipantIconSvg(themeColor || 'var(--theme-color)');
    const logoutUser = (e: any) => {
        e.preventDefault();
        dispatch(signOutUser());
        dispatch(isProceedClick(false));
    };

    const onClickOpenLink = (event: any) => {
        event.preventDefault();
        window.open('https://app.tocca.io/terms-of-service');
    };

    const checkComponentType = scheduleList.find((list) => {
        return list.componentType === 'AGENDA';
    });

    const toggleSchedule = () => {
        // dispatch(navigateTo({ history, path: '/schedule' }))
        dispatch(toggleChatComponent(false));
        if (checkComponentType && !editorState && Event.type === 'conversation') {
            dispatch(toggleEditor(true));
        } else if (!checkComponentType) {
            dispatch(navigateTo({ history, path: '/schedule' }));
        } else {
            dispatch(toggleEditor(false));
        }
    };

    const profileModal = () => {
        dispatch(openLoginModal());
        dispatch(authMode(Mode.profile));
    };

    return (
        <>
            <ErrorBoundary>
                {/* {mode === Mode.profile && <Modal />} */}
                <HeaderStyled className="sticky-top">
                    <BrandLogo
                        src={[
                            `${assets.imagesUrl}${get(lobby, 'header.imageUrl')}`,
                            '/images/default/shared_studio.png',
                        ]}
                        alt="Event Header"
                        onError={() => setImageError(true)}
                    />
                    {imageError && (
                        <BrandLogo src={'/images/default/shared_studio.png'} alt="Event Header" />
                    )}
                    <WrapperContainer>
                        <div className="d-flex flex-row align-items-center justify-content-between">
                            <ButtonContainer>
                                <SpaceHolder className="d-none d-sm-none d-md-block" />
                            </ButtonContainer>
                            <div className="d-flex">
                                {!isLobbyDataLoading && !isEmpty(lobby?.header?.titleHtml) && (
                                    <div
                                        className='event-header-title'
                                        // contentEditable="true"
                                        dangerouslySetInnerHTML={{ __html: lobby?.header?.titleHtml ? lobby?.header?.titleHtml : "" }}
                                    ></div>
                                )}
                            </div>
                            <ButtonContainer className='header-icon'>
                                {Event.type !== 'conversation' && (
                                    <HeaderButton
                                        icon={HomeIcon}
                                        onClick={() => dispatch(navigateTo({ history, path: '/lobby' }))}
                                        strokeWidth={2}
                                        themeColor={themeColor}
                                        disabled={Event.type === 'conversation'}
                                    />
                                )}
                                <HeaderButton
                                    icon={CalendarIcon}
                                    onClick={toggleSchedule}
                                    strokeWidth={2}
                                    themeColor={themeColor}
                                />

                                <HeaderButton
                                    icon={!privateNotifier ? ChatIcon : ChatIconRed}
                                    iconClicked={toggleChat}
                                    scale={1.28}
                                    strokeWidth={11}
                                    showNotification={privateNotifier}
                                    color={themeColor || 'var(--theme-color)'}
                                    themeColor={themeColor || 'var(--theme-color)'}
                                />
                                <HeaderButton
                                    icon={ParticipantIcon}
                                    iconClicked={toggleParticipantChat}
                                    scale={1.28}
                                    strokeWidth={11}
                                    color={themeColor || 'var(--theme-color)'}
                                    themeColor={themeColor || 'var(--theme-color)'}
                                />
                                <Dropdown className="user-dd">
                                    <Dropdown.Toggle id="dropdown-basic">
                                        <HeaderButton strokeWidth={2}>
                                            <ProfileAvatar />
                                        </HeaderButton>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item className="droplist-item" onClick={profileModal}>
                                            My profile
                                        </Dropdown.Item>
                                        <Dropdown.Divider />
                                        <Dropdown.Item className="droplist-item" onClick={onClickOpenLink}>
                                            Terms of Service
                                        </Dropdown.Item>
                                        <Dropdown.Divider />
                                        <Dropdown.Item className="droplist-item" onClick={logoutUser}>
                                            Logout
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </ButtonContainer>
                        </div>
                    </WrapperContainer>
                </HeaderStyled>
            </ErrorBoundary>
        </>
    );
};

export default Header;
