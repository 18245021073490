import { BreakoutVideoControl } from 'modules/utils/Firebase/Api';
import { eventChannel } from 'redux-saga';

interface IAttendeeDetails {
    organizationId: string;
    eventId: string;
    roomId: string;
}

export const getRoomVideoControlState = (props: IAttendeeDetails) => {
    const connectionRef = BreakoutVideoControl.reference(props.organizationId, props.eventId, props.roomId);
    const connectionRoomListner = eventChannel((emit) => {
        connectionRef.on('value', (snapshot: any) => {
            if (snapshot.val()) return emit(snapshot.val());
            else return emit(false);
        });
        return () => {
            connectionRef.off();
        };
    });
    return connectionRoomListner;
};

export const controlVideoPlayPauseStop = async (payload: any, props: IAttendeeDetails) => {
    const connectionRef = BreakoutVideoControl.reference(props.organizationId, props.eventId, props.roomId);
    await connectionRef.set(payload);
};
