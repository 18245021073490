import { SagaIterator } from 'redux-saga';
import * as actions from '../actions';
import {
    getOrganizationId,
    getRoomId,
    getEventId,
    getDocumentId,
    getEvent,
    getUserProfile,
    getCurrentSelectedPollResponses,
    getPollExclusiveId,
    getUserUid,
} from '../selectors';
import { getPollsFormsApi, getSelectedPollsDetail, getPollTriggerListner, getMaxUserGroupListner } from '../utils/Firebase/Polls';
import { call, put, select, take, cancelled, cancel, fork } from 'redux-saga/effects';
import {
    addSelectedPollIdApi,
    addPollDataToUserPresence,
    fetchPollResultApi,
    fetchPollResultListnerApi,
    removePollResultApi,
    getPollsGroup,
    fetchAllPollResultApi,
    getMaxPollGroup
} from 'modules/utils/Firebase/Polls';
import { ISagaAction } from 'modules/types';
import { v4 as uuidv4 } from 'uuid';
import firebase from 'modules/utils/Firebase';
import { getUserPresenceListner } from 'modules/utils/Firebase/UserManagement/BreakoutRoomUserPresence';
import { ToccaUtils } from 'modules/utils';
import * as Sentry from '@sentry/react';

export const fetchPollsListSaga = function* (): SagaIterator {
    try {
        while (yield take(actions.fetchPolls)) {
            const connectionTask = yield fork(FetchPollsListListener);
            yield take([actions.signOutUser, actions.removeUserFromRoom]);

            yield cancel(connectionTask);
        }
    } catch (error) {
        console.error(error, 'errror');
        yield put(actions.fetchScheduleFailed());
        Sentry.captureException(error);
    }
};

const FetchPollsListListener = function* (): SagaIterator {
    try {
        const organizationId = yield select(getOrganizationId);

        const obj = {
            organizationId,
        };
        const channel = yield call(getPollsFormsApi, obj);
        try {
            while (true) {
                const result = yield take(channel);
                if (result) {
                    yield put(actions.fetchPollSuccess(result));
                }
            }
        } catch (e) {
            console.warn(e);
        } finally {
            // unregister listener if the saga was cancelled
            if (yield cancelled()) channel.close();
        }
    } catch (error) {
        console.error(error);
        Sentry.captureException(error);
    }
};

export const fetchPollResultLiveSaga = function* (): SagaIterator {
    try {
        while (yield take(actions.fetchPollResultLive)) {
            const connectionTask = yield fork(fetchPollResultListner);
            yield take([actions.signOutUser, actions.removeUserFromRoom, actions.removePollResultLive]);

            yield cancel(connectionTask);
        }
    } catch (error) {
        console.error(error, 'errror');
        yield put(actions.fetchScheduleFailed());
        Sentry.captureException(error);
    }
};

export const fetchPollResultListner = function* (): SagaIterator {
    try {
        const organizationId = yield select(getOrganizationId);
        const documentId = yield select(getDocumentId);
        const selectedPollTrigger = yield select(getCurrentSelectedPollResponses);
        const channel = yield call(
            fetchPollResultListnerApi,
            organizationId,
            documentId,
            selectedPollTrigger?.pollTriggerId,
        );

        try {
            while (true) {
                const result = yield take(channel);

                if (result) {
                    yield put(actions.fetchPollResultLiveSuccess(result));
                }
            }
        } catch (e) {
            console.warn(e);
        } finally {
            // unregister listener if the saga was cancelled
            if (yield cancelled()) channel.close();
        }
    } catch (error) {
        console.error(error);
        Sentry.captureException(error);
    }
};
export const fetchSelectedRoomPollData = function* (): SagaIterator {
    try {
        while (yield take(actions.subscribePollListner)) {
            const connectionTask = yield fork(RoomPollTriggerListnerSaga);
            yield take([actions.removeUserFromRoom, actions.unsubscribeTriggeredData, actions.signOutUser]);
            yield cancel(connectionTask);
        }
    } catch (error) {
        console.error(error);
        Sentry.captureException(error);
    }
};

export const RoomPollTriggerListnerSaga = function* (): SagaIterator {
    try {
        const organizationId = yield select(getOrganizationId);
        const eventId = yield select(getEventId);
        const roomId = yield select(getRoomId);
        const uid = yield select(getUserUid);
        const profile = yield select(getUserProfile);
        const moderator = ToccaUtils.hasEventAccess(profile, roomId, 'moderator');
        const obj = {
            organizationId,
            eventId,
            roomId,
            uid,
            email: profile?.email,
            moderator,
        };

        const channel = yield call(getPollTriggerListner, obj);
        try {
            while (true) {
                const result = yield take(channel);

                if (result && result?.isActive) {
                    yield put(actions.selectedPollIdResponse(result || null));
                } else {
                    yield put(
                        actions.selectedPollIdResponse({
                            selectedFormId: '',
                            triggeredTime: 0,
                            selectedDocumentId: '',
                            isStopped: true,
                            pollTriggerId: result?.pollTriggerId,
                        }),
                    );
                    yield put(actions.showPollModal(false));
                }
            }
        } catch (e) {
            console.warn(e);
        } finally {
            // unregister listener if the saga was cancelled
            if (yield cancelled()) {
                channel.close();
            }
        }
    } catch (error) {
        console.error(error);
        Sentry.captureException(error);
    }
};

export const RoomPollListnerSaga = function* (): SagaIterator {
    try {
        const organizationId = yield select(getOrganizationId);
        const eventId = yield select(getEventId);
        const roomId = yield select(getRoomId);
        const uid = yield select(getUserUid);
        const obj = {
            organizationId,
            eventId,
            roomId,
            uid,
        };

        const channel = yield call(getUserPresenceListner, obj);
        try {
            while (true) {
                const result = yield take(channel);

                if (result && result?.isActive) {
                    yield put(actions.selectedPollIdResponse(result || null));
                } else {
                    yield put(
                        actions.selectedPollIdResponse({
                            selectedFormId: '',
                            triggeredTime: 0,
                            selectedDocumentId: '',
                            isStopped: true,
                        }),
                    );
                    yield put(actions.showPollModal(false));
                }
            }
        } catch (e) {
            console.warn(e);
        } finally {
            // unregister listener if the saga was cancelled
            if (yield cancelled()) {
                channel.close();
            }
        }
    } catch (error) {
        console.error(error);
        Sentry.captureException(error);
    }
};

export const fetchSelectedRoomPollDataGroup = function* (): SagaIterator {
    try {
        while (yield take(actions.subscribePollGroupListner)) {
            const connectionTask = yield fork(SelectedRoomPollData);
            yield take([actions.removeUserFromRoom, actions.unsubscribeTriggeredData, actions.signOutUser]);
            yield cancel(connectionTask);
        }
    } catch (error) {
        console.error(error);
        Sentry.captureException(error);
    }
};
export const SelectedRoomPollData = function* (): SagaIterator {
    try {
        const organizationId = yield select(getOrganizationId);
        const eventId = yield select(getEventId);

        const pollExlusiveId = yield select(getPollExclusiveId);

        const obj = {
            organizationId,
            eventId,
            roomId: pollExlusiveId,
        };

        const channel = yield call(getSelectedPollsDetail, obj);
        try {
            while (true) {
                const result = yield take(channel);
                if (result) {
                    yield put(actions.selectedPollIdResponse(result || null));
                    if (result && !result?.isActive) {
                        yield put(actions.showPollModal(false));
                    }
                }
            }
        } catch (e) {
            console.warn(e);
        } finally {
            // unregister listener if the saga was cancelled
            if (yield cancelled()) channel.close();
        }
    } catch (error) {
        console.error(error);
        Sentry.captureException(error);
    }
};

export const addSelectedPollSaga = function* (action: ISagaAction<any>): SagaIterator {
    try {
        const organizationId = yield select(getOrganizationId);
        const eventId = yield select(getEventId);
        const roomId = yield select(getRoomId);
        const pollExlusiveId = yield select(getPollExclusiveId);
        const triggerPoll = yield select(getCurrentSelectedPollResponses);
        const UUID = uuidv4();
        const obj = {
            organizationId,
            eventId,
            roomId: pollExlusiveId || roomId,
        };
        let isRanking = false;
        if (action.payload?.form?.fields) {
            isRanking = action.payload?.form?.fields.some((field: any) => field.type === 'ranking');
        }
        const params = action.payload
            ? {
                pollTriggerId: UUID,
                userId: action.payload.userId,
                selectedFormId: action.payload.formId,
                selectedDocumentId: action.payload.docId,
                roomId: roomId,
                triggeredTime: firebase.database.ServerValue.TIMESTAMP,
                isStopped: false,
                isActive: true,
                isRanking,
                displayResults: false,
            }
            : null;
        yield call(addSelectedPollIdApi, params, obj);
        const obj_1 = {
            organizationId,
            eventId,
            roomId: roomId,
            groupId: pollExlusiveId || null,
        };
        const pollTriggerId = triggerPoll?.pollTriggerId;

        yield call(addPollDataToUserPresence, params, obj_1, pollTriggerId);
        if (action.payload) yield put(actions.fetchPollResultLive());
    } catch (error) {
        console.error(error);
        Sentry.captureException(error);
    }
};

export const fetchAllResultSaga = function* (action: ISagaAction<any>): SagaIterator {
    try {
        const organizationId = yield select(getOrganizationId);
        const roomId = yield select(getRoomId);
        const pollExlusiveId = yield select(getPollExclusiveId);
        const selectedPollTrigger = yield select(getCurrentSelectedPollResponses);
        const documentId = yield select(getDocumentId);

        const result = yield call(fetchAllPollResultApi, organizationId, documentId, roomId);

        if (result) {
            yield put(actions.fetchPollResultSuccess(result));
        }

        if (
            selectedPollTrigger?.selectedDocumentId === documentId &&
            selectedPollTrigger?.isStopped &&
            !action.payload?.isAutomatic
        ) {
            const eventId = yield select(getEventId);
            const obj = {
                organizationId,
                eventId,
                roomId: pollExlusiveId || roomId,
            };

            yield call(addSelectedPollIdApi, { doRemove: true }, obj);
            const obj_1 = {
                organizationId,
                eventId,
                roomId: roomId,
                groupId: pollExlusiveId || null,
            };
            yield call(addPollDataToUserPresence, { displayResult: true }, obj_1, selectedPollTrigger?.pollTriggerId);
        }
    } catch (error) {
        console.error(error);
        Sentry.captureException(error);
    }
};

export const fetchPollResultSaga = function* (action: ISagaAction<any>): SagaIterator {
    try {
        const organizationId = yield select(getOrganizationId);
        const roomId = yield select(getRoomId);
        const pollExlusiveId = yield select(getPollExclusiveId);
        const selectedPollTrigger = yield select(getCurrentSelectedPollResponses);
        const documentId = yield select(getDocumentId);

        const result = yield call(fetchPollResultApi, organizationId, documentId, selectedPollTrigger?.pollTriggerId);

        if (result) {
            yield put(actions.fetchPollResultSuccess(result));
        }

        if (
            selectedPollTrigger?.selectedDocumentId === documentId &&
            selectedPollTrigger?.isStopped &&
            !action.payload?.isAutomatic
        ) {
            const eventId = yield select(getEventId);
            const obj = {
                organizationId,
                eventId,
                roomId: pollExlusiveId || roomId,
            };

            yield call(addSelectedPollIdApi, { doRemove: true }, obj);
            const obj_1 = {
                organizationId,
                eventId,
                roomId: roomId,
                groupId: pollExlusiveId || null,
            };
            const triggerPoll = yield select(getCurrentSelectedPollResponses);
            const pollTriggerId = triggerPoll?.pollTriggerId;

            yield call(addPollDataToUserPresence, { displayResult: true }, obj_1, pollTriggerId);
        }
    } catch (error) {
        console.error(error);
        Sentry.captureException(error);
    }
};

export const fetchPollResultCloseSaga = function* (): SagaIterator {
    try {
        const eventId = yield select(getEventId);
        const organizationId = yield select(getOrganizationId);
        const triggerPoll = yield select(getCurrentSelectedPollResponses);
        const roomId = yield select(getRoomId);
        const pollExlusiveId = yield select(getPollExclusiveId);
        const obj = {
            organizationId,
            eventId,
            roomId: pollExlusiveId || roomId,
        };
        yield call(removePollResultApi, obj);
        const obj_1 = {
            organizationId,
            eventId,
            roomId: roomId,
            groupId: pollExlusiveId || null,
        };

        const pollTriggerId = triggerPoll?.pollTriggerId;
        yield call(addPollDataToUserPresence, { removePoll: true }, obj_1, pollTriggerId);
    } catch (error) {
        console.error(error);
        Sentry.captureException(error);
    }
};

export const fetchPollGroupSaga = function* (): SagaIterator {
    try {
        const event = yield select(getEvent);
        const organizationId = yield select(getOrganizationId);
        const eventId = yield select(getEventId);
        const profile = yield select(getUserProfile);
        const uid = yield select(getUserUid);

        const payload = {
            organizationId,
            eventId,
            groupId: event?.pollExclusiveId,
        };
        let pollData
        yield put(actions.setPollExclusiveId(event?.pollExclusiveId ?? null));
        if (event?.pollExclusiveId && uid) {
            pollData = yield call(getPollsGroup, payload);
            if (pollData) {
                if (pollData && pollData.users) {
                    const isUserPresent = pollData.users.includes(profile.email);
                    yield put(actions.setGroupData(!!isUserPresent));
                }
            }
        } else {
            yield put(actions.setGroupData(true));
        }
    } catch (error) {
        console.error(error);
        Sentry.captureException(error);
    }
};

export const fetchMaxPollGroupLiveSaga = function* (): SagaIterator {
    while (yield take(actions.setActiveListner)) {
        const connectionTask = yield fork(fetchMaxPollGroupSaga);
        // wait for the user stop action
        yield take([actions.signOutUser, actions.removeActiveListner]);

        yield cancel(connectionTask);
    }
};

export const fetchMaxPollGroupSaga = function* (): SagaIterator {
    try {
        const eventId = yield select(getEventId);
        const organizationId = yield select(getOrganizationId);
        const profile = yield select(getUserProfile);
        const payload = {
            organizationId,
            eventId,
            email: profile.email
        };
        const channel = yield call(getMaxUserGroupListner, payload);
        try {
            while (true) {
                const result = yield take(channel);
                if (result && typeof result === 'object') {
                    const group = yield call(getMaxPollGroup, payload);
                    if (group) {
                        yield put(actions.setEventGroupData(group))
                    }
                }
            }
        } catch (e) {
            console.warn(e);
        } finally {
            if (yield cancelled()) {
                channel.close();
            }
        }
    } catch (error) {
        console.error(error);
        Sentry.captureException(error);
    }
}

export default fetchPollsListSaga;
