import { toast } from 'react-toastify';
type MyUnionType = 'success' | 'danger' | 'info' | 'default' | 'warning';

export const toaster = (message: string, type: MyUnionType, duration: number) => {
    const checkUrl = () => {
        const matches = message.match(/\bhttps?:\/\/\S+/gi);
        if (matches && Array.isArray(matches) && matches.length > 0) {
            window.location.assign(matches[0]);
        }
    };
    const options = {
        type: type === 'danger' ? toast.TYPE.ERROR : toast.TYPE.SUCCESS,
        position: toast.POSITION.TOP_RIGHT,
        pauseOnHover: true,
        autoClose: duration || 5000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        className: 'Toastify__toast-theme--colored',
        onClick: checkUrl,
    };

    toast(message, options);
};
