import { Event, RegistreeEventDocument } from 'modules/utils/Firebase/Api';

export const getEventDetailApi = async (payload: any) => {
    const response = await Event.reference(payload.organizationId, payload.eventId).get();
    const data = response.data()
    const registreeResponse = await RegistreeEventDocument.allRegistreeEventRef(payload.organizationId, payload.eventId).get()
    const registrees: Array<{}> = [];
    registreeResponse.docs.forEach((doc) => {
        const registreeData = doc.data();
        if (registreeData.uid && !registreeData.fromAdmin) {
            registrees.push(
                {
                    uid: registreeData.uid,
                    firstName: registreeData.firstName,
                    lastName: registreeData.lastName,
                    avatar: registreeData.avatar,
                    jobTitle: registreeData.jobTitle,
                    companyName: registreeData.companyName
                }
            )
        }
    })
    return { ...data, registrees };
};

