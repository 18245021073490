import React from 'react';

export const WelcomeConfig = {
    settings: {
        layout: {
            config: {
                header: { display: false },
                footer: { display: false },
                navbar: { display: false },
            },
        },
    },
    isPrivate: false,
    eventName: 'welcome',
    routes: [
        {
            path: '/',
            component: React.lazy(() => import('./index')),
        },
        {
            path: '/:organizationId/:eventSlug/',
            component: React.lazy(() => import('./index')),
        },
    ],
};

export const WelcomeMobileConfig = {
    settings: {
        layout: {
            config: {
                header: { display: false },
                footer: { display: false },
                navbar: { display: false },
            },
        },
    },
    isPrivate: false,
    eventName: 'welcome',
    routes: [
        {
            path: '/',
            component: React.lazy(() => import('./mobile')),
        },
        {
            path: '/:organizationId/:eventSlug/',
            component: React.lazy(() => import('./mobile')),
        },
    ],
};
