import { createAction } from 'redux-act';

export const registerRoomUser = createAction('Registerd Breakout');
export const removeUserFromRoom = createAction('remove user from room');
export const addWaitingRoomType = createAction('breakout room type');
export const allowAllUsersInRoom = createAction<{ hasNoWaitingQueue: boolean; roomId: string }>(
    'allow all users without showing waiting room',
);
export const addBreakoutWaitingListner = createAction('breakout waiting listner');
export const removeBreakoutWaitingListner = createAction('breakout waiting listner remove');
export const setRoomId = createAction<string | null>('set room');
export const setMainRoomId = createAction<string | null>('set main room');
export const setRoomRole = createAction<any>('set role');
export const setRoomPresence = createAction('set room presence');
export const addBreakoutRecordingListner = createAction('listen recording state data');
export const removeBreakoutRecordingListner = createAction('remove recording state data');
export const addBreakoutRecordingStates = createAction<any>('add breakout recording data');

export const addWaitingAttendees = createAction<any>('waiting room attendee');

export const addBreakoutVideoControlListerner = createAction('listen to video player controls');
export const addVideoControlStates = createAction<any>('Add breakout video playing states');
export const controlVideoRunningState = createAction<any>('play pause control on video');
export const stopRecording = createAction<any | null>('stop recording in breakout');
export const setIsNetworkRoom = createAction<boolean>('is room is of type networking');
export const stopContentShare = createAction<boolean>('stop content share');
export const updateBreakoutUserProfile = createAction<{ roomId?: string; uid?: string; profile: any }>(
    'allow all users without showing waiting room',
);
export const setBreakoutMeetingId = createAction<string>('set breakout meeting id');
export const setBgImageForLiveStream = createAction<string | null>('set image');
export const setCollenctionType = createAction<string | null>("ser room collectionType")




