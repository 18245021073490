/* eslint-disable no-sequences */
import { createReducer } from 'redux-act';
import { setNetLoungeSucces, setLoungeEmpty } from 'modules/actions';

export interface LoungeReducerState {
    lounge: ILoungeProps;
}

export interface ILoungeProps {
    capacity: number;
    isLimitedCapacity: boolean;
    meetingTables: {
        minTables: number;
        capacity: number;
        isNamedTable?:boolean;
        meetingTableNames?:string[],
    };
    bgImage?: string;
    bgMusic?: string;
    id?: string;
    cardImg?: string;
    groupDiscuss: {
        maxGroupParticipant: number;
        isLimitDiscussGroup?: boolean;
        isRandomMatch: boolean;
        limitDicussionGroupTime?: number;
    };
}

const initialState: LoungeReducerState = {
    lounge: {
        capacity: 100,
        isLimitedCapacity: false,
        meetingTables: {
            minTables: 5,
            capacity: 5,
            isNamedTable:false,
            meetingTableNames:[],
        },
        groupDiscuss: {
            maxGroupParticipant: 7,
            isLimitDiscussGroup: false,
            isRandomMatch: true,
            limitDicussionGroupTime: 10,
        },
    },
};

export const lounge = createReducer<LoungeReducerState>({}, initialState);

lounge.on(setNetLoungeSucces, (state: LoungeReducerState, payload: any) => ({
    ...state,
    lounge: payload && Object.keys(payload).length > 0 ? { ...payload } : null,
}));
lounge.on(setLoungeEmpty, (state: LoungeReducerState, payload: any) => ({
    ...state,
    lounge: payload,
}));
