import { createAction } from 'redux-act';

export const openLoginModal = createAction();
export const openInviteeAuthenticateModal = createAction();
export const openInviteeRegisterModal = createAction();
export const openRegisterModal = createAction();
export const closeLoginModal = createAction();
export const allowOutsideClick = createAction<boolean>();
export const closeRegisterModal = createAction();
export const closeInviteeAuthenticateModal = createAction();
export const closeInviteeRegisterModal = createAction();
export const openLoginOptionModal = createAction();
export const closeLoginOptionModal = createAction();
export const openLoungeProfileModal = createAction<boolean>();
export const closeLoungeProfileModal = createAction();
export const closeLoungeOtherModal = createAction();
export const openLoungeOtherModal = createAction();
export const openMediaPermissionModal = createAction<string>();
export const closeMediaPermissionModal = createAction();
export const openBreakoutProfileModal = createAction<string>();
export const openErrorCapacityModal = createAction();
export const closeErrorCapacityModal = createAction();
export const openConfirmModal = createAction();
export const closeConfirmModal = createAction();
export const openResetPassWordModal = createAction();
export const closeResetPassWordModal = createAction();
export const toggleChatComponent = createAction<boolean>('Show hide Chat ');
export const toggleSchedulesComponent = createAction<boolean>('Show hide Schedules ');
export const toggleParticipant = createAction<boolean>('toggle participant');
export const openPollResultModal = createAction('poll result open');
export const closePollResultModal = createAction('poll result close');
export const toggleEditor = createAction<boolean>('toggle editor');
export const openProfileModal = createAction<boolean>('profile modal')
export const toggleCropperModal = createAction<any>();
export const setCropperImageData = createAction<any>();

