import omitBy from 'lodash/omitBy';
import isUndefined from 'lodash/isUndefined';
import { Profile } from 'modules/utils/Firebase/Api';
import firebase from 'firebase/app';
export const storage = firebase.storage();
const AVATAR_BUCKET: string = process.env.REACT_APP_TOCCA_AVATAR_BUCKET || '';

export const updateUserProfile = async (userObj: any) => {
    try {
        const userRef = await Profile.profile(userObj.modifiedByUid);
        let requestPayload: any = {
            firstName: userObj.firstName,
            lastName: userObj.lastName,
            jobTitle: userObj.jobTitle,
            email: userObj.email,
            companyName: userObj.companyName,
            workLocation: userObj.workLocation,
            description: userObj.description,
            linkedin: userObj.linkedin,
            twitter: userObj.twitter,
            organizationId: userObj.organizationId
        };
        requestPayload = omitBy(requestPayload, isUndefined);
        await userRef.set(requestPayload, { merge: true });
        return userObj;
    } catch (e) {
        throw new Error(String(e));
    }
};

export const updateAvatarProfile = async (userObj: any) => {
    try {
        if (userObj.avatar) {
            userObj.avatar = await getAvatarURL(userObj.avatar, userObj.modifiedByUid);
        }
        const userRef = await Profile.profile(userObj.modifiedByUid);
        let requestPayload: any = {
            avatar: userObj.avatar,
        };
        requestPayload = omitBy(requestPayload, isUndefined);
        await userRef.set(requestPayload, { merge: true });
        return userObj;
    } catch (e) {
        throw new Error(String(e));
    }
};

export const deleteAvatarProfileApi = async (uid: any) => {
    try {
        const userRef = await Profile.profile(uid);

        await userRef.set({ avatar: firebase.firestore.FieldValue.delete() }, { merge: true });
    } catch (e) {
        throw new Error(String(e));
    }
};

export const getUserProfileApi = async (uid: string) => {
    try {
        const userRef = Profile.profile(uid);
        const user = await userRef.get();
        if (user.exists) {
            const userData: any = user.data();
            return userData;
        }
        return false;
    } catch (e) {
        console.log(e);
    }
};

const getAvatarURL = async (avatar: any, id: string) => {
    if (avatar && typeof avatar !== 'string') {
        try {
            const fileName = id ? `${id}.${avatar?.name?.split('.')[1]}` : avatar?.name;
            const response: any = await UploadAvatar(fileName, avatar);
            return response;
        } catch (e) {
            console.log('Error in uploading avatar: ', e);
        }
    }
    return avatar;
};

export const UploadAvatar = async (fileName: string, file: File) => {
    const uploadTask = await storage.refFromURL(`${AVATAR_BUCKET}/${fileName}`).put(file);
    const downloadURL = await uploadTask.ref.getDownloadURL();
    return downloadURL;
};
