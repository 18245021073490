import styled from 'styled-components/macro';

export const Title = styled.div<{ bannerTextColor: string }>`
    font-size: 20px;
    font-weight: normal;
    font-style: normal;
    text-transform: capitalize;
    color:  ${({ bannerTextColor }) => bannerTextColor};
`
export const TimerDiv = styled('div') <any>`
    display: grid;
    grid-auto-flow: column;
    column-gap: 24px;
    grid-auto-columns: max-content;
`
export const Count = styled('div') <any>`
    display: grid;
`
export const NumberDiv = styled.div<{ bannerTextColor: string }>`
    text-align: center;
    color:  ${({ bannerTextColor }) => bannerTextColor};
    border: 1px solid  ${({ bannerTextColor }) => bannerTextColor};
    display: grid;
    justify-items: center;
    align-items: center;

`
export const Unit = styled.div<{ bannerTextColor: string }>`
    align-self: center;
    color:  ${({ bannerTextColor }) => bannerTextColor};
    margin-bottom: 5px;
`