import { createReducer } from 'redux-act';
import {
    fetchNotificationSuccess,
    fetchNotification,
    fetchNewNotificationSuccess,
    resetState,
    setPrivateMsgNotification,
} from 'modules/actions';

export interface INotification {
    created_at: number;
    message: string;
}
export type NotificationReducerState = {
    notifications: INotification[];
    isFecthing: boolean;
    privateMsgNotification: boolean | string;
};

const initialState: NotificationReducerState = {
    notifications: [],
    isFecthing: false,
    privateMsgNotification: false,
};

export const notification = createReducer<NotificationReducerState>({}, initialState);
notification.on(fetchNotificationSuccess, (state: NotificationReducerState, payload: any) => ({
    ...state,
    notifications: payload,
    isFecthing: false,
}));
notification.on(fetchNewNotificationSuccess, (state: NotificationReducerState, payload: INotification) => ({
    ...state,
    notifications: [...state.notifications, payload],
    isFecthing: false,
}));
notification.on(fetchNotification, (state: NotificationReducerState) => ({
    ...state,
    isFecthing: true,
}));

notification.on(resetState, () => initialState);

notification.on(setPrivateMsgNotification, (state: NotificationReducerState, payload: boolean | string) => ({
    ...state,
    privateMsgNotification: payload,
}));
