import { IStoreState } from 'modules/types';

export const getRoomId = (state: IStoreState) => state.breakoutRooms.selectedRoomId || '';
export const breakoutRecrodingControl = (state: IStoreState) => state.breakoutRooms.breakoutRecording;
export const getWaitingRoomAttendees = (state: IStoreState) => state.breakoutRooms.waitingRoomAttendees;
export const getIsWaitingRoom = (state: IStoreState) => state.breakoutRooms.isWaitingRoom;
export const getRoomRole = (state: IStoreState) => state.breakoutRooms.role;
export const getRoomPresence = (state: IStoreState) => state.breakoutRooms.isUserPresent;
export const getVideoState = (state: IStoreState) => state.breakoutRooms.breakoutVideoStates;
export const getIsNetworkingRomm = (state: IStoreState) => state.breakoutRooms.isNetworkingRoom;
export const getContentShareControlByModerator = (state: IStoreState) => state.breakoutRooms.stopContentShare;
export const isAllowedToEnterInWaitingRoom = (state: IStoreState) => state.breakoutRooms.allowAllUsersInRoom;
export const getMeetingId = (state: IStoreState) => state.breakoutRooms.meetingId;
export const getMainRoomId = (state: IStoreState) => state.breakoutRooms.selectedMainRoomId;
export const getCollectionType = (state: IStoreState) => state.breakoutRooms.collectionType;
export const getLiveStreamImage = (state: IStoreState) => state.breakoutRooms.liveStreamBgImage;