import styled from 'styled-components';
// export const GlobalStyle = createGlobalStyle`
//   body {
//     background-color: var(--white) !important;
//   }
//   .container {
//       padding: 0px;
//   }

// `;

export const StyledMobileSidebar = styled('div')`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    touch-action: none;
    height: 100vh;
    width: 100vw;
    z-index: 100000;
    background-color: rgba(0, 0, 0, 0.3);
    .sidebar {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        height: 100%;
        padding: 72px 30px 0 30px;
        box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
        background-color: #0057e4;
        width: 73%;
        flex-direction: column;
    }
    .profile-wrap {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-bottom: 28px;
    }
    .profile-pic > div {
        background-color: none;
        border-radius: none;
        background: #c1d5f8;
        border: 2px solid #fff;
        border-radius: 50%;
        width: 60px;
        height: 60px;
        line-height: 60px;
        font-size: 16px;
        color: #0057e4;
        text-align: center;
        margin-right: 14px;
        > div {
            font-size: 16px;
            font-weight: normal;
        }
    }
    .profile-name {
        font-size: 18px;
        font-weight: 500;
        line-height: normal;
        letter-spacing: normal;
        color: #fff;
    }
    .seperator {
        width: 100%;
        height: 1px;
        opacity: 0.4;
        background: #fff;
    }
    .navigation-wrap {
        margin-top: 22px;
    }
    .nav-item {
        padding: 17px 0;
    }
    .nav-link {
        font-size: 14px;
        font-weight: 500;
        line-height: 1;
        letter-spacing: normal;
        color: #fff;
        padding: 0 0;
        outline: none;
        text-decoration: none;
        display: flex;
        align-items: center;
    }
    .nav-link span {
        margin-right: 14px;
        width: 24px;
        text-align: center;
    }
    .nav-bottom img {
        margin-right: 14px;
    }
    .nav-bottom {
        position: absolute;
        bottom: 41px;
        font-size: 14px;
        font-weight: 500;
        line-height: 1;
        letter-spacing: normal;
        color: #fff;
        outline: none;
        text-decoration: none;
    }
    .nav-bottom button {
        display: flex;
        align-items: center;
    }
    .btn {
        padding: 12px 0;
        border-radius: 4px;
        background-color: #0057e4;
        border: 0;
        box-shadow: none;
        color: #fff;
        font-size: 14px;
        font-weight: 500;
        display: flex;
        margin: 0 auto;
        text-align: center;
        width: 80%;
    }
`;
