import { SagaIterator } from 'redux-saga';
import { call, put, select, take, cancelled, cancel, fork } from 'redux-saga/effects';
import * as actions from 'modules/actions';
import { getEventId, getOrganizationId, getUserUid } from 'modules/selectors/user';
import { getScheduleList } from 'modules/selectors/schedule';
import {
    getRoomUserList,
    getSelectedRoomUserList,
    getBreakoutBlockedList,
} from 'modules/utils/Firebase/UserManagement/BreakoutRoomUserList';
import { getScheduleListLisner, getSpeakerList } from 'modules/utils/Firebase/ScheduleList';
import { getRoomId } from '../selectors';
import * as Sentry from '@sentry/react';

export const fetchScheduleSaga = function* (): SagaIterator {
    try {
        const connectionTask = yield fork(FetchScheduleListener);
        yield take([actions.signOutUser]);

        yield cancel(connectionTask);
    } catch (error) {
        console.error(error, 'errror');
        yield put(actions.fetchScheduleFailed());
        Sentry.captureException(error);
    }
};

const FetchScheduleListener = function* (): SagaIterator {
    try {
        const eventId = yield select(getEventId);
        const organizationId = yield select(getOrganizationId);
        const schedule = yield select(getScheduleList);
        const obj = {
            organizationId,
            eventId,
            schedule,
        };

        const channel = yield call(getScheduleListLisner, obj);
        try {
            while (true) {
                const result = yield take(channel);
                if (result) {
                    yield put(actions.fetchScheduleSuccess(result));
                    const speakerList = yield call(getSpeakerList, result);
                    yield put(actions.fetchScheduleSuccess(speakerList));
                } else {
                    yield put(actions.checkRoomLimitSuccess([]));
                }
            }
        } catch (e) {
            console.warn(e);
        } finally {
            // unregister listener if the saga was cancelled
            if (yield cancelled()) channel.close();
        }
    } catch (error) {
        console.error(error);
        Sentry.captureException(error);
    }
};

export const scheduleBreakoutRoomListConnection = function* (): SagaIterator {
    while (yield take(actions.setActiveListner)) {
        const connectionTask = yield fork(RoomUserListPresenceListner);
        // wait for the user stop action
        yield take([actions.signOutUser, actions.removeActiveListner]);

        yield cancel(connectionTask);
    }
};

export const RoomUserListPresenceListner = function* (): SagaIterator {
    try {
        const organizationId = yield select(getOrganizationId);
        const eventId = yield select(getEventId);
        const obj = {
            organizationId,
            eventId,
        };
        const channel = yield call(getRoomUserList, obj);
        try {
            while (true) {
                const result = yield take(channel);
                if (result) {
                    const data = [];
                    const keys = typeof result === 'object' ? Object.keys(result) : null;
                    if (keys && Array.isArray(keys)) {
                        for (const key of keys) {
                            if (result[key] && Object.keys(result[key])) {
                                const obj = {
                                    [key]: {
                                        capacity: Object.keys(result[key]).length,
                                        availableModerators: Object.values(result[key]).filter(
                                            (user: any) => user.role && user.role.indexOf('moderator') !== -1,
                                        ).length,
                                        roomUserList: Object.values(result[key]),
                                    },
                                };
                                data.push(obj);
                            }
                        }
                    }
                    yield put(actions.checkRoomLimitSuccess(data));
                } else {
                    yield put(actions.checkRoomLimitSuccess([]));
                }
            }
        } catch (e) {
            console.warn(e);
        } finally {
            // unregister listener if the saga was cancelled
            if (yield cancelled()) channel.close();
        }
    } catch (error) {
        console.error(error);
        Sentry.captureException(error);
    }
};

export const scheduleBreakoutRoomConnection = function* (): SagaIterator {
    while (yield take(actions.addScheduleUserListner)) {
        const connectionTask = yield fork(RoomUserPresenceListner);
        // wait for the user stop action
        yield take([actions.removeScheduleUserLimitListner, actions.signOutUser]);

        yield cancel(connectionTask);
    }
};

export const RoomUserPresenceListner = function* (): SagaIterator {
    try {
        const organizationId = yield select(getOrganizationId);
        const eventId = yield select(getEventId);
        const roomId = yield select(getRoomId);
        const obj = {
            organizationId,
            eventId,
            roomId,
        };
        const channel = yield call(getSelectedRoomUserList, obj);
        try {
            while (true) {
                const result = yield take(channel);
                if (result) {
                    const obj = {
                        capacity: Object.keys(result).length,
                        availableModerators: Object.values(result).filter(
                            (user: any) => user.role && user.role.indexOf('moderator') !== -1,
                        ).length,
                    };
                    yield put(actions.checkRoomUserSuccess(obj));
                } else {
                    yield put(actions.checkRoomUserSuccess({ capacity: 0, availableModerators: 0 }));
                }
            }
        } catch (e) {
            console.warn(e);
        } finally {
            // unregister listener if the saga was cancelled
            if (yield cancelled()) channel.close();
        }
    } catch (error) {
        console.error(error);
        Sentry.captureException(error);
    }
};

export const scheduleBlockedUserSaga = function* (): SagaIterator {
    while (yield take(actions.addScheduleBlockListner)) {
        const connectionTask = yield fork(scheduleBlockedUserListner);
        // wait for the user stop action
        yield take([actions.removeScheduleUserLimitListner, actions.signOutUser]);

        yield cancel(connectionTask);
    }
};

const scheduleBlockedUserListner = function* (): SagaIterator {
    try {
        const organizationId = yield select(getOrganizationId);
        const eventId = yield select(getEventId);
        const userId = yield select(getUserUid);
        const channel = yield call(getBreakoutBlockedList, organizationId, eventId, userId);
        try {
            while (true) {
                const result = yield take(channel);

                if (result && Object.values(result).length) {
                    yield put(actions.blockUserSuccess(Object.values(result)));
                } else {
                    yield put(actions.blockUserSuccess([]));
                }
            }
        } catch (e) {
            console.warn(e);
        } finally {
            // unregister listener if the saga was cancelled
            if (yield cancelled()) channel.close();
        }
    } catch (error) {
        console.error(error);
        Sentry.captureException(error);
    }
};

export default fetchScheduleSaga;
