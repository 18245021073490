import { eventChannel, EventChannel } from 'redux-saga';
import { Notification } from 'modules/utils/Firebase/Api';

export default class FireBaseNotification {
    notificationListner!: EventChannel<any>;

    getLastRecord = (organizationId: string, eventId: string) => {
        const ref = Notification.reference(organizationId, eventId);
        return ref.orderByChild('created_at').limitToLast(1).once('child_added');
    };

    checkIfNotificationDocExists = (organizationId: string, eventId: string) => {
        const ref = Notification.reference(organizationId, eventId);
        return ref.once('value');
    };

    subscribeNotification = (organizationId: string, eventId: string, timeStamp: number) => {
        const reference = Notification.reference(organizationId, eventId);
        this.notificationListner = eventChannel((emit) => {
            const ref = reference.orderByChild('created_at').startAt(timeStamp + 1);
            ref.on('child_added', (snapshot: any) => {
                return emit(snapshot.val());
            });
            return () => {
                ref.off();
            };
        });
        return this.notificationListner;
    };

    getNotfications = (organizationId: string, eventId: string) => {
        const eventsList: any[] = [];
        const reference = Notification.reference(organizationId, eventId);
        return new Promise((resolve, reject) => {
            reference
                .orderByChild('created_at')
                .once('value')
                .then((snapshot) => {
                    const values = snapshot.val();
                    for (const key in values) {
                        if (Object.prototype.hasOwnProperty.call(values, key)) eventsList.push(values[key]);
                    }
                    resolve(eventsList);
                    // Do something with the snapshot
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    };
}
