import { createAction } from 'redux-act';

export const registrationAccess = createAction<any>('sets preferences of event specified by host');

export const registrationEvent = createAction('verifies user is registered for event');

export const registrationProfileSynchronize = createAction('synchronizes SOURCE profile with firestore document');
export const registrationProfileUpdate = createAction<any>('updates SOURCE profile & state dependencies');

export const registrationSessionRestore = createAction('restores user state on sign-out but keep event session');
export const SetRegistrationSessionPropertyLink = createAction<any>('sets event link from url parameters or cache');
export const setLinkLoading = createAction('set link loading');
// export const registrationUI = createAction<any>('sets properties of profile modal ui');
export const setTestUserToOpenEvent = createAction<any>('sets if test user wants to open event');
