import firebase from 'modules/utils/Firebase';
import { createReducer } from 'redux-act';
import {
    authUserFailed,
    authRegisteredFailed,
    authSetParticipantProfile,
    authSignInSuccess,
    authUserSuccess,
    setUserLoading,
    setLoading,
    authSetProfile,
    authLogOut,
    authSetTwillioProfile,
    authLinkSuccess,
    authLinkInvalid,
    authRegisteredUser,
    setProfileFetching,
    setUserRoles,
    removeError,
    setUserAvatarUrl,
    setUserAvatarUrlError,
    isModifiedProfile,
} from 'modules/actions/user';

export interface IUserReducer {
    profile: IUserProfile;
    error?: string;
    email?: string;
    isLoading: boolean;
    userUid: string;
    firebaseToken: string;
    firebaseUser: firebase.User | null;
    eventId: string;
    organizationId: string;
    isAuth: boolean;
    isUserRegistered?: boolean;
    isLinkInvalid: boolean;
    isModified: boolean;
}

export interface IUserProfile {
    attendeeType: string;
    companyName?: string;
    email: string;
    eventAccess?: string[];
    firstName: string;
    fullName: string;
    isLoungeRegistered?: boolean;
    isTwilioUser: boolean;
    isUserRegistered?: boolean;
    jobTitle: string;
    lastName: string;
    organization: string;
    phone: string;
    avatar?: string;
    isAdmin?: boolean;
    roles: string[];
    userId: string;
    workLocation?: string;
    description?: string;
    twitter?: string;
    linkedin?: string;
}

const initialState = {
    firebaseToken: '',
    firebaseUser: null,
    userUid: '',
    profile: {
        userId: '',
        phone: '',
        organization: '',
        jobTitle: '',
        firstName: '',
        lastName: '',
        fullName: '',
        familyName: '',
        email: '',
        attendeeType: '',
        roles: [],
        isAdmin: false,
        isTwilioUser: false,
        avatar: undefined,
        companyName: '',
        workLocation: '',
        isLoungeRegistered: false,
    },
    eventId: '',
    organizationId: '',
    isLoading: true,
    error: '',
    mailStatus: false,
    isAuth: false,
    isLinkInvalid: false,
    isModified: false,
};

export const user = createReducer<IUserReducer>({}, initialState);
user.on(authUserSuccess, (state: IUserReducer, payload: IUserReducer) => ({
    ...state,
    firebaseToken: payload.firebaseToken,
    userUid: payload.userUid,
    profile: { ...state.profile, userId: payload.userUid, email: payload?.email ?? '' },
    isAuth: true,
    firebaseUser: payload.firebaseUser,
    isLoading: false,
}));
user.on(authSignInSuccess, (state: IUserReducer, payload: IUserReducer) => ({
    ...state,
    firebaseToken: payload.firebaseToken,
    profile: { ...state.profile, userId: payload.userUid },
    userUid: payload.userUid,
    isLoading: false,
    // isAnonymous: payload.isAnonymous,
}));
user.on(setLoading, (state: IUserReducer) => ({
    ...state,
    isLoading: true,
}));

user.on(setUserLoading, (state: IUserReducer) => ({
    ...state,
    isUserRegisteredFetching: true,
}));

user.on(authUserFailed, (state: IUserReducer, payload?: string) => ({
    ...state,
    isAuth: false,
    error: payload,
    isLoading: false,
}));

user.on(authSetProfile, (state: IUserReducer, payload: any) => ({
    ...state,
    profile: { ...state.profile, ...payload },
}));

user.on(authLogOut, (state: IUserReducer) => ({
    ...initialState,
    eventId: state.eventId,
    organizationId: state.organizationId,
    isLoading: false,
}));

user.on(
    authLinkSuccess,
    (state: IUserReducer, payload: { eventId: string; organizationId: string; eventSlug: string }) => ({
        ...state,
        isLinkInvalid: false,
        eventId: payload?.eventId ?? '',
        organizationId: payload.organizationId,
        eventSlug: payload?.eventSlug ?? '',
    }),
);

user.on(authSetTwillioProfile, (state: IUserReducer, payload: boolean) => ({
    ...state,
    profile: { ...state.profile, ...{ isTwilioUser: payload } },
}));

user.on(setUserRoles, (state: IUserReducer, payload: boolean) => ({
    ...state,
    profile: { ...state.profile, isAdmin: payload },
}));

user.on(authLinkInvalid, (state: IUserReducer) => ({
    ...state,
    isAuth: false,
    isLinkInvalid: true,
    isLoading: false,
}));

user.on(authRegisteredUser, (state: IUserReducer, payload: IUserProfile) => ({
    ...state,
    profile: { ...state.profile, ...payload },
    isUserRegisteredFetching: false,
}));

user.on(authRegisteredFailed, (state: IUserReducer, payload?: string) => ({
    ...state,
    isAuth: false,
    isUserRegistered: false,
    isLoading: false,
    error: payload,
    isUserRegisteredFetching: false,
}));

user.on(authSetParticipantProfile, (state: IUserReducer, payload: any) => ({
    ...state,
    profile: { ...state.profile, ...payload },
    isUserRegistered: true,
    isUserRegisteredFetching: false,
}));

user.on(setProfileFetching, (state: IUserReducer, payload: boolean) => ({
    ...state,
    isUserProfileFetching: payload,
}));

user.on(removeError, (state: IUserReducer) => ({
    ...state,
    error: '',
}));

user.on(setUserAvatarUrl, (state: IUserReducer, payload: any) => ({
    ...state,
    profile: { ...state.profile, avatar: payload },
}));

user.on(setUserAvatarUrlError, (state: IUserReducer) => ({
    ...state,
    profile: { ...state.profile, avatar: undefined },
}));
/*
* TODO: Ensure Removal has no breaking effects
*
* user.on(mailSent, (state: IUserReducer, payload: any) => ({
    ...state,
    mailStatus: payload,
}));
* user.on(resetPasswordFailed, (state: IUserReducer, paylaod: string) => ({
    ...state,
    isAuth: false,
    isLoading: false,
    resetPasswordError: paylaod,
}));
user.on(removeResetError, (state: IUserReducer) => ({
    ...state,
    resetPasswordError: '',
}));

 */
user.on(isModifiedProfile, (state: IUserReducer, payload: boolean) => ({
    ...state,
    isModified: payload,
}));
