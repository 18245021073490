import React, { PropsWithChildren, useEffect, memo, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import * as selectors from 'modules/selectors';
import { useSelector, useDispatch } from 'react-redux';
import { useAppContext } from 'context/AppContext';
import { setRoute, navigateTo, allowOutsideClick, setActiveListner } from 'modules/actions';
import { matchRoutes } from 'react-router-config';
import { Mode } from 'components/Login/constants/constants';

const Authorization = (props: PropsWithChildren<{}>) => {
    /** Authenticated flag */
    const { routes } = useAppContext();
    const isUserAuthorized = useSelector(selectors.getIsUserAuth);
    const isLinkInValid = useSelector(selectors.getLinkStatus);
    const queryParams = useMemo(() => new URLSearchParams(window.location.search), []);
    const customToken = queryParams.get('customToken');
    const event = useSelector(selectors.getEvent);
    const scheduleList = useSelector(selectors.getScheduleList);
    const history = useHistory();
    const [selectedRoute, setSelectedRoute] = useState<any>(() => {
        const matchedRoute = matchRoutes(routes, history.location.pathname)[0];
        if (matchedRoute && matchedRoute.route) {
            return matchedRoute.route;
        }
        return null;
    });
    const isLoginModelOpen = useSelector(selectors.getLoginModalState);
    const authMode = useSelector(selectors.getAuthMode);
    const isLoadingAuth = useSelector(selectors.getLoading);
    const globalLink = useSelector(selectors.getGlobalLink);
    const ifRoutingDoc = useSelector(selectors.getIfRoutingDoc);
    const eventPageType = useSelector(selectors.getPageType);
    const dispatch = useDispatch();
    const { originalPath, eventSlug } = useSelector(selectors.registrationSessionPropertyLink);
    const organizationId = useSelector(selectors.getOrganizationId);
    const eventId = useSelector(selectors.getEventId);
    const isLinkLoading = useSelector(selectors.getLoading);

    // const path = originalPath && !isEmpty(originalPath) ? originalPath : 'lobby';

    const roomId = useMemo(
        () =>
            scheduleList.find((list) => {
                return list.componentType === event?.roomType;
            })?.id ?? null,
        [scheduleList, event],
    );

    const dispatchActiveListner = useMemo(
        () => organizationId && eventId && isUserAuthorized,
        [organizationId, isUserAuthorized, eventId],
    );

    const isScheduleLoaded = useMemo(() => scheduleList.length > 0, [scheduleList]);

    const path = useMemo(() => {
        if (event.type === 'conversation' && event.videoPlatform === 'shared_studios') {
            return roomId ? `room/${roomId}` : null;
        } else if (originalPath && !isEmpty(originalPath)) {
            return originalPath;
        } else {
            return 'lobby';
        }
    }, [event.type, event.videoPlatform, originalPath, roomId]);

    useEffect(() => {
        if (dispatchActiveListner) {
            dispatch(setActiveListner());
        }
    }, [dispatchActiveListner, dispatch]);

    const is3rdParty = useMemo<string | null>(() => {
        return event.type === 'conversation' && event.videoPlatform === '3rd_party' && !!event.thirdPartyUrl
            ? event.thirdPartyUrl
            : null;
    }, [event.type, event.videoPlatform, event.thirdPartyUrl]);

    const isOnSignupProcess = useMemo<boolean>(() => {
        const registerModes = [
            Mode.useFirstTime,
            Mode.useNewEmail,
            Mode.profile,
            Mode.profileAvatarCapture,
            Mode.profileAvatar,
            Mode.initial,
            Mode.showLoader,
        ];

        return !(registerModes.includes(authMode) && isLoginModelOpen);
    }, [authMode, isLoginModelOpen]);

    useEffect(() => {
        const unlisten = history.listen((location: any) => {
            const matchedRoute = matchRoutes(routes, location.pathname)[0];
            if (matchedRoute && matchedRoute.route) {
                setSelectedRoute(matchedRoute.route);
                dispatch(setRoute(matchedRoute.route));
            }
        });
        return () => {
            unlisten();
        };
    }, [history, routes, dispatch]);

    useEffect(() => {
        if (history.location.pathname) {
            const matchedRoute = matchRoutes(routes, history.location.pathname)[0];
            if (matchedRoute && matchedRoute.route) {
                setSelectedRoute(matchedRoute.route);
                dispatch(setRoute(matchedRoute.route));
            }
        }
    }, [history.location.pathname, routes, dispatch]);

    useEffect(() => {
        if (selectedRoute && isUserAuthorized && path && isScheduleLoaded) {
            if (
                selectedRoute.path === '/' ||
                selectedRoute.path === '/:organizationId/:eventSlug/'
                // selectedRoute.path === '/org/:organizationId/:eventSlug'
            ) {
                if (is3rdParty) {
                    window.open(is3rdParty, '_self');
                } else {
                    if (isOnSignupProcess) {
                        dispatch(navigateTo({ history, path: `/${path}` }));
                    }
                }
            }
        }
    }, [selectedRoute, isUserAuthorized, history, path, dispatch, is3rdParty, isOnSignupProcess, isScheduleLoaded]);

    useEffect(() => {
        const registerModes = [
            Mode.useFirstTime,
            Mode.useNewEmail,
            Mode.profileAvatarCapture,
            Mode.profileAvatar,
            Mode.showLoader,
        ];
        if (registerModes.includes(authMode)) {
            dispatch(allowOutsideClick(false));
        }
    }, [authMode, dispatch]);

    useEffect(() => {
        if (isUserAuthorized && customToken) {
            queryParams.delete('customToken');
            history.replace({
                search: queryParams.toString(),
            });
        }
    }, [isUserAuthorized, customToken, queryParams, history]);

    useEffect(() => {
        if (selectedRoute && selectedRoute.isPrivate) {
            if (!isLoadingAuth && !isUserAuthorized) {
                dispatch(navigateTo({ history, path: '/' }));
            }
        }
    }, [selectedRoute, isUserAuthorized, isLoadingAuth, history, dispatch]);

    useEffect(() => {
        if (isLinkInValid) {
            const urlSplit = history.location.pathname.split('/');
            if (urlSplit.length > 0 && urlSplit[1] !== 'record' && urlSplit[1] !== 'live') {
                history.push('/not-found');
            }
        }
    }, [history, dispatch, isLinkInValid]);

    useEffect(() => {
        const urlSplit = history.location.pathname.split('/');
        if (
            isUserAuthorized &&
            !selectedRoute &&
            urlSplit.length > 2 &&
            urlSplit[1] !== 'record' &&
            urlSplit[1] !== 'live'
        ) {
            history.push('/not-found');
        }
    }, [history, dispatch, isUserAuthorized, selectedRoute]);

    useEffect(() => {
        const urlSplit = history.location.pathname.split('/');

        if (isLinkLoading || isLinkInValid || !history.location.pathname || !ifRoutingDoc) {
            return;
        }

        if (eventPageType === 'event' && globalLink) {
            if (urlSplit.length > 1 && urlSplit[1] !== 'not-found') {
                dispatch(navigateTo({ history, path: '/' }));
            }
        } else if (eventPageType === 'discovery' && organizationId) {
            if (urlSplit.length > 1 && urlSplit[1] !== 'not-found') {
                history.push(`/org/${organizationId}`);
            }
        } else if (eventPageType === 'eventLanding' && eventSlug && organizationId) {
            if (urlSplit.length > 1 && urlSplit[1] !== 'not-found') {
                history.push(`/org/${organizationId}/${eventSlug}`);
            }
        }
    }, [
        history,
        dispatch,
        isLinkInValid,
        eventPageType,
        globalLink,
        ifRoutingDoc,
        organizationId,
        eventSlug,
        isLinkLoading,
    ]);

    return <React.Fragment>{props.children}</React.Fragment>;
};

export default memo(Authorization);
