import { createReducer } from 'redux-act';
import * as actions from 'modules/actions';
import { Mode } from 'components/Login/constants/constants';
import * as types from 'components/Login/types';

export type LoginReducerState = {
    mode: any;
    loginData: {
        email: string;
        password: string;
    };
    loginError: any;
    forgetPasswordData: {
        email: string;
    };
    profile: {
        firstName: string;
        lastName: string;
        email: string;
        companyName: string;
        jobTitle: string;
        linkedin: string;
        twitter: string;
        workLocation: string;
        description: string;
    };
    avatarFile: any;
    avatar: string;
    failure?: types.FirebaseFailure;
    isRegistered: Mode;
    isProceed: boolean;
    isOnSignUp: boolean;
    isSkipProfile: boolean;
};

const initialState: LoginReducerState = {
    mode: Mode.showLoader,
    loginData: {
        email: '',
        password: '',
    },
    forgetPasswordData: {
        email: '',
    },
    loginError: '',
    profile: {
        firstName: '',
        lastName: '',
        email: '',
        companyName: '',
        jobTitle: '',
        linkedin: '',
        twitter: '',
        workLocation: '',
        description: '',
    },
    avatar: '',
    avatarFile: {},
    isRegistered: Mode.initial,
    isOnSignUp: false,
    isProceed: false,
    isSkipProfile: true
};

export const login = createReducer<LoginReducerState>({}, initialState);

login.on(actions.authMode, (state: LoginReducerState, payload: Mode) => ({
    ...state,
    mode: payload,
}));

login.on(actions.setAuthEmail, (state: LoginReducerState, payload: any) => ({
    ...state,
    loginData: { ...payload },
}));

login.on(actions.setAuthPassword, (state: LoginReducerState, payload: any) => ({
    ...state,
    loginData: { ...payload },
}));

login.on(actions.setForgetPasswordEmail, (state: LoginReducerState, payload: any) => ({
    ...state,
    forgetPasswordData: { ...payload },
}));

login.on(actions.authFailure, (state: LoginReducerState, payload: any) => ({
    ...state,
    failure: payload,
}));

login.on(actions.setProfileData, (state: LoginReducerState, payload: any) => ({
    ...state,
    profile: { ...payload },
}));

login.on(actions.updateProfileChangeSucess, (state: LoginReducerState, payload: any) => ({
    ...state,
    profile: {
        ...state.profile,
        firstName: payload.firstName ? payload.firstName : '',
        lastName: payload.lastName ? payload.lastName : '',
        jobTitle: payload.jobTitle ? payload.jobTitle : '',
        twitter: payload.twitter ? payload.twitter : '',
        linkedin: payload.linkedin ? payload.linkedin : '',
        email: payload.email ? payload.email : '',
        avatar: payload.avatar ? payload.avatar : '',
        description: payload.description ? payload.description : '',
        workLocation: payload.workLocation ? payload.workLocation : '',
        companyName: payload.companyName ? payload.companyName : '',
    },
}));

login.on(actions.authLoginErrors, (state: LoginReducerState, payload: any) => ({
    ...state,
    loginError: payload,
    // mode: Mode.useEmail,
}));

login.on(actions.isUserRegistred, (state: LoginReducerState, payload: Mode) => ({
    ...state,
    isRegistered: payload,
}));

login.on(actions.setIsOnSignUp, (state: LoginReducerState, payload: boolean) => ({
    ...state,
    isOnSignUp: payload,
}));

login.on(actions.setSkipProfile, (state: LoginReducerState, payload: boolean) => ({
    ...state,
    isSkipProfile: payload,
}));

login.on(actions.isProceedClick, (state: LoginReducerState, payload: boolean) => ({
    ...state,
    isProceed: payload,
}));

login.on(actions.setAvatarFileData, (state: LoginReducerState, payload: any) => ({
    ...state,
    avatarFile: { ...payload },
}));

login.on(actions.setAvatarData, (state: LoginReducerState, payload: any) => ({
    ...state,
    avatar: payload,
}));

login.on(actions.authLogOut, () => ({
    ...initialState,
}));
