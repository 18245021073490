import { SagaIterator } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';
import * as actions from 'modules/actions';
import Api from 'modules/utils/Api';
import { getEventId, getOrganizationId, getFirebaseAccessToken } from 'modules/selectors/user';
import * as Selectors from 'modules/selectors/keynotes';
import { diff } from 'deep-object-diff';
import * as Sentry from '@sentry/react';

export const fetchKeynotesSaga = function* (): SagaIterator {
    try {
        const eventId = yield select(getEventId);
        const organizationId = yield select(getOrganizationId);
        const token = yield select(getFirebaseAccessToken);
        const response = yield call(Api.fetchKeynotes, eventId, organizationId, token);
        if (response.error) {
            yield put(actions.fetchKeynotesFailed());
            return;
        }
        const keynotes = yield select(Selectors.getKeynotesList);
        const diffData = diff(response.data, keynotes);
        if (typeof diffData !== 'object' || Object.keys(diffData).length > 0) {
            yield put(actions.fetchKeynotesSuccess(response.data));
        }
    } catch (e) {
        console.warn(e);
        Sentry.captureException(e);
    }
};

export default fetchKeynotesSaga;
