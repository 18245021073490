import { SagaIterator } from 'redux-saga';
import { toaster } from 'components/Notification';
import { ISagaAction } from 'modules/types';
import { call, select } from 'redux-saga/effects';
import { BreakoutRoomExtendMeeting, BreackoutChangeStatus } from 'modules/utils/Firebase/breakout';
import * as selector from '../selectors';
import Api from '../utils/Api';
import { getFirebaseAccessToken } from '../selectors';
import * as Sentry from '@sentry/react';

export const extendMeetingEndDate = function* (action: ISagaAction<any>): SagaIterator {
    try {
        const organizationId = yield select(selector.getOrganizationId);
        const eventId = yield select(selector.getEventId);
        let roomId = yield select(selector.getRoomId);
        const additionalMinutes = action.payload.additionalMinutes;
        if (!roomId && action.payload.roomId) {
            roomId = action.payload.roomId;
        }
        let roomEndTime = null;
        if (additionalMinutes) {
            const moderatorExitDate = yield select(selector.getModeratorExitDate);
            if (additionalMinutes === 15) roomEndTime = Date.now() + additionalMinutes * 60 * 1000;
            else roomEndTime = moderatorExitDate + additionalMinutes * 60 * 1000;
        }
        const data = { organizationId, eventId, roomId, roomEndTime, additionalMinutes };
        yield call(BreakoutRoomExtendMeeting, data);
    } catch (error) {
        toaster(String(error), 'danger', 3000);
        console.error('ERROR:', String(error));
        Sentry.captureException(error);
    }
};

export const endCurrentMeetingSaga = function* (): SagaIterator {
    try {
        const token = yield select(getFirebaseAccessToken);
        const organizationId = yield select(selector.getOrganizationId);
        const eventId = yield select(selector.getEventId);
        const roomId = yield select(selector.getRoomId);
        const data = { organizationId, eventId, roomId };
        yield call(Api.endRoomMeetinForallApi, { data }, token);
    } catch (error) {
        toaster(String(error), 'danger', 3000);
        console.error('ERROR:', String(error));
        Sentry.captureException(error);

    }
};

export const changeStatusCurrentMeeting = function* (action: ISagaAction<any>): SagaIterator {
    try {
        const organizationId = yield select(selector.getOrganizationId);
        const eventId = yield select(selector.getEventId);
        const data = {
            organizationId,
            eventId,
            roomId: action.payload.roomId,
            isRoomOpen: action.payload.isRoomOpen,
        };
        yield call(BreackoutChangeStatus, data);
    } catch (error) {
        toaster(String(error), 'danger', 3000);
        console.error('ERROR:', String(error));
        Sentry.captureException(error);
    }
};
