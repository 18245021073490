import styled, { css } from 'styled-components/macro';

export const colors = css`
    --blue-white-color: #f2f6fd;
    --blue-pale-color: #f1f5f7;
    --blue-bright-color: #e7ecf6;
    --blue-accent-color: #d0e0ff;
    --blue-light-color: #c1d5f8;
    --blue-color: #0057e4; /*rgba(0, 87, 228, 1);*/
    --blue-shade-color: #0249c2;
    --blue-fade-color: #0344b4;
    --blue-dark-color: rgba(7, 69, 208, 0.9);
    --blue-black-color: #053cbf;

    --gray-white-color: #fbfbfc;
    --gray-light-color: #ededee;
    --gray-color: #717b88;
    --gray-shade-color: #353341;
    --gray-dark-color: #121216;
    --gray-black-color: #08070a;

    --green-white-color: #bbfad7;
    --green-light-color: #83f3bf;
    --green-color: #2acaa1;

    --orange-light-color: #ffd17d;
    --orange-color: #de9f27;

    --red-light-color: rgba(255, 229, 228, 1);
    --red-color: #eb4b4b;
    --red-shade-color: #d53a3a;
    --red-dark-color: #ff0909;

    --slate-white-color: #e9ecef;
    --slate-light-color: #e8ecf1;
    --slate-color: #8a9fba;
    --slate-black-color: #283747;

    --yellow-white-color: #ffeeb9;
    --yellow-light-color: #fcdb8a;
    --yellow-color: #f7b924;

    --facebook-color: #3b5999;
    --google-color: #dd4b39;
    --microsoft-color: #00adef;
`;

export const dimension = css`
    /* AVATAR VIEWPORT */
    --avatar-thumbnail-width: 333px;
    --avatar-thumbnail-height: 250px;
`;

export const theme = css`
    ${colors} /**/ 
    ${dimension} /**/
    --access-border-size: 1px;
    --access-border: var(--access-border-size) solid var(--gray-light-color);
    --access-font-size: 14px;
    --access-font-size-ratio: 1.214285714285714;
    --access-radius: 11px;
    --access-letting: 10px;
    --access-letting-x: var(--access-letting);
    --access-letting-y: var(--access-letting);
    --access-letting-height: calc(var(--access-letting-y) * 2);
    --access-letting-width: calc(var(--access-letting-x) * 2);
    --access-shadow: 0 2px 10px 0 rgba(0, 47, 110, 0.22);
    --access-overlay-light-color: rgba(0, 27, 80, 0.2);
    --access-shadow-dark: 0 2px 16px 0 rgba(0, 27, 80, 0.24);

    --access-overlay-color: rgba(242, 246, 253, 0.22);
    --access-background-color: white;
    --access-content-z-index: 3;

    /* --- units --- */
    --access-unit-font-size: var(--access-font-size);
    --access-unit-letting: var(--access-unit-font-size);
    --access-unit-letting-x: var(--access-unit-letting);
    --access-unit-letting-y: var(--access-unit-letting);
    --access-unit-letting-height: calc(var(--access-unit-letting-y) * 2);
    --access-unit-letting-width: calc(var(--access-unit-letting-x) * 2);
    --access-unit-radius: 4px;
    --access-input-height: 38px;

    --access-label-letting: 4px;
    --access-label-height: calc(var(--access-font-size) * var(--access-font-size-ratio));
    --access-fieldset-height: calc(var(--access-input-height) + var(--access-label-letting));
    --access-glyph-size: 22px;

    font-size: var(--access-font-size);
    line-height: normal;
    color: var(--slate-black-color);

    & * {
        box-sizing: border-box !important;
        font-family: var(--font-family);
        line-height: normal !important;
    }

    & .dropdown-menu {
        box-shadow: var(--access-shadow-dark);
        border-radius: var(--radius, var(--access-radius));
        border: none;
        .dropdown-item {
            font-size: var(--access-font-size);
            color: var(--slate-black-color);
            text-decoration: none;
            font-weight: 600;
            display: flex;
            align-items: center;
            user-select: none;

            .tiny {
                opacity: 0;
            }
            &:active {
                background-color: var(--blue-bright-color);
            }
            &.active {
                color: var(--slate-black-color);
                background-color: initial;
                .tiny {
                    opacity: 1;
                }
            }
            .tiny + span {
                user-select: none;
                margin-top: calc(var(--access-letting-y) / 2);
                margin-bottom: calc(var(--access-letting-y) / 2);
                margin-left: calc(var(--access-letting-x) / 2);
            }
        }
    }
`;

export const button = css`
    --background-color: var(--theme-color);
    --font-color: var(--mono-white-color);
    appearance: none;
    align-items: center;
    background-color: var(--background-color);
    border: none;
    border-radius: var(--access-unit-radius);
    box-sizing: border-box;
    color: var(--font-color);
    cursor: pointer;
    display: flex;
    font-size: var(--access-unit-font-size);
    font-weight: bold;
    height: var(--access-input-height);
    justify-content: center;
    outline: none;
    padding-top: calc(var(--access-unit-letting-y) / 2);
    padding-bottom: calc(var(--access-unit-letting-y) / 2);
    padding-left: var(--access-unit-letting-x);
    padding-right: var(--access-unit-letting-x);
    text-align: center;
    user-select: none;

    .label {
        font-weight: bold;
        margin-left: calc(var(--access-letting-x) / 2);
        margin-right: calc(var(--access-letting-x) / 2);
    }

    &:focus,
    &:active {
        outline: none;
    }

    &:not(.disabled) {
        & {
            &:hover,
            &:focus,
            &:active {
                --background-color: var(--theme-color);
            }
        }

        &.outline {
            --background-color: var(--mono-white-color);
            --font-color: var(--theme-color);
            box-shadow: inset 0 0 0 1px var(--theme-color);

            &:hover {
                --background-color: var(--blue-white-color);
            }

            &:focus,
            &:active {
                --background-color: var(--blue-bright-color);
            }
        }
    }

    &.disabled {
        --background-color: var(--slate-light-color);
        --font-color: var(--slate-color);
        pointer-events: none;
    }
`;

export const pseudoFill = css`
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border-radius: inherit;
`;

export const centerXY = css`
    display: flex;
    place-items: center;
    place-content: center;
`;

export const link = css`
    --font-color: var(--blue-color);
    color: var(--font-color);
    cursor: pointer;
    display: inline-flex;
    align-self: flex-start;

    &:not(.disabled) {
        &:hover,
        &:focus,
        &:active {
            text-decoration: underline;
            --font-color: var(--blue-black-color);
        }
    }

    &.disabled {
        pointer-events: none;
        cursor: var(--access-button-cursor-disabled);
    }
`;

export const input = css`
    appearance: none;
    background-color: var(--mono-white-color);
    border: 1px solid var(--slate-light-color);
    border-radius: var(--access-unit-radius);
    box-sizing: border-box;
    color: var(--font-color);
    display: flex;
    font-size: var(--access-font-size);
    padding-top: calc(var(--access-letting-y) / 2);
    padding-bottom: calc(var(--access-letting-y) / 2);
    padding-left: var(--access-letting-x);
    padding-right: calc(var(--access-glyph-size) + var(--access-letting-x));
    height: var(--access-input-height);

    &.invalid {
        border-color: var(--red-color);
    }

    &::placeholder {
        color: var(--slate-color);
    }
`;

export const insignia = css`
    ${centerXY}
    & {
        --theme: var(--color, var(--blue-color));
        --size: var(--access-symbol-size, 70px);
        height: var(--access-symbol-height, var(--size));
        width: var(--access-symbol-width, var(--size));
        color: var(--theme);
        background-color: var(--fill-color, white);
        border-radius: var(--size);
        overflow: hidden;
        position: relative;
        flex-shrink: 0;
        flex-grow: 0;
        z-index: 0;

        &::before {
            ${pseudoFill}
            & {
                background-color: var(--theme);
                opacity: var(--opacity, 0.1);
            }
        }

        &.small {
            --size: var(--access-symbol-size, 60px);
        }

        & > svg {
            position: relative;
            height: var(--icon-size, 40px);
            width: var(--icon-size, 40px);
            flex-grow: 0;
            flex-shrink: 0;
            object-fit: contain;
            object-position: center;
            z-index: 1;
        }

        &.success {
            --theme: var(--green-color);
        }

        &.failure {
            --theme: var(--red-color);
        }

        &.user {
            --opacity: 0.3;
            --size: 80px;
            --icon-size: 44px;
        }

        &.tiny {
            --size: var(--access-icon-size, 20px);
            --icon-size: 14px;
            display: inline-flex;
            svg {
                stroke-width: 4px;
            }
        }
    }
`;

export const notes = css`
    & *.insignia {
        ${insignia}
    }

    & *.pointer {
        cursor: pointer;
    }

    & *.overflow {
        overflow: visible;
    }

    & *.center {
        place-content: center;
        place-items: center;
    }

    & *.note {
        .glyph {
            --color: var(--slate-color);
            position: absolute;
            display: flex;
            align-self: flex-end;
            color: var(--color, currentColor);
            height: var(--access-glyph-size);
            width: auto;
            object-fit: contain;
            right: var(--access-letting-x);
            flex-shrink: 0;
            flex-grow: 0;

            svg {
                fill: var(--color, currentColor);
            }

            &.invalid {
                --color: var(--red-color);
                top: 28px;
            }

            &.pointer {
                --access-glyph-size: 28px;
                width: var(--access-glyph-size);
            }

            &.preview {
                --access-glyph-size: 22px;
                top: 32px;
            }
        }
    }

    .glyph.pin {
        --color: white;
        border: 2px solid white;
        border-radius: 100px;
        color: var(--color);
        background-color: var(--blue-color);
        padding: 4px;
        width: 28px;
        height: 28px;
        svg {
            fill: currentColor;
        }
    }

    .password svg.invalid.glyph {
        top: 30px !important;
    }
`;

export const Button = styled.div`
    ${button}
    & {
        padding-left: calc(var(--access-unit-letting-x) * 3);
        padding-right: calc(var(--access-unit-letting-x) * 3);
        justify-self: flex-end;
        align-self: flex-end;
    }

    &[aria-expanded='true'] {
        --background-color: var(--blue-black-color);
    }

    &.dropdown-toggle::after {
        position: relative;
        vertical-align: middle !important;
        top: 2px;
        margin-left: var(--access-unit-letting-x);
    }

    .dropdown.btn-group &:first-child {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        padding-left: var(--access-unit-letting-x);
        padding-right: var(--access-unit-letting-x);
    }

    .dropdown.btn-group &:first-child + .dropdown-toggle-split {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        padding-left: var(--access-unit-letting-x);
        padding-right: var(--access-unit-letting-x);
    }
    .dropdown.btn-group &:first-child + .dropdown-toggle-split::after {
        margin-left: 0;
    }
`;

export const Checkbox = styled.input.attrs({ type: 'checkbox' })`
    --size: 20px;
    --border-size: 2px;
    appearance: none;
    border: var(--border-size) solid var(--theme-color);
    border-radius: calc(var(--access-unit-radius) / 2);
    cursor: pointer;
    display: inline-flex;
    flex-shrink: 0;
    height: var(--size);
    position: relative;
    width: var(--size);

    &:checked::before {
        align-items: center;
        content: '\\2714';
        color: var(--theme-color);
        bottom: 0;
        display: flex;
        font-size: 14px;
        font-weight: bold;
        justify-content: center;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
    }

    & + label {
        cursor: pointer;
        display: inline-flex;
        margin-top: -1px !important;
        margin-left: calc(var(--access-letting-x) / 2) !important;
        position: relative;
        user-select: none;
    }
`;

export const ComponentIndicator = styled.div.attrs({ className: 'component-indicator' })`
    ${centerXY}
    & {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 100;
        background-color: rgba(255, 255, 255, 0.4);
    }

    & > svg {
        filter: drop-shadow(0 0 30px rgba(7, 69, 208, 1));
        height: 50px;
        width: 50px;
        fill: var(--blue-color);
    }
`;

export const FailureNode = styled.div`
    color: var(--red-color);
    font-size: 12px;
    display: block;
    overflow: hidden;
    flex-shrink: 0;
    flex-grow: 0;
    flex-wrap: nowrap;
`;

/**
 * @example z-index for sticky/overlay elements
    background-color: var(--mono-white-color);
    position: sticky;
    top: 0;
    z-index: calc(var(--access-content-z-index) + 1);
 * */
export const HeadingNode = styled.div`
    font-size: 18px;
    font-weight: bold;
    padding: var(--access-letting);
    text-align: center;
`;

export const Horizontal = styled.div`
    align-items: center;
    display: flex;
    flex-flow: row;
    justify-content: space-between;
`;

export const Icon = styled.div.attrs({ className: 'icon' })`
    --size: 20px;
    display: inline-flex;
    flex-grow: 0;
    flex-shrink: 0;
    height: var(--icon-height, var(--size));
    width: var(--icon-width, var(--size));
    overflow: hidden;
    position: relative;

    svg {
        height: 100%;
        width: 100%;
        position: relative;
        object-fit: contain;
        object-position: center;
    }
`;

export const Input = styled.input`
    ${input}
`;

export const InsigniaNode = styled.div`
    ${insignia}
`;

export const Label = styled.div`
    flex-shrink: 0;
    padding: var(--access-letting);
`;

export const Line = styled.div`
    border-bottom: 1px solid var(--gray-light-color);
    flex-grow: 1;
`;

export const Link = styled.a`
    ${link}
`;

export const NoteNode = styled.div`
    & {
        display: block;
        font-size: 16px;
        color: var(--slate-color);
    }

    &.failure {
        color: var(--red-color);
    }

    &.success {
        color: var(--green-color);
    }
`;

export const SelectOption = styled.div`
    ${button}
    & {
        --background-color: var(--mono-white-color);
        --focus-background-color: var(--mono-white-color);
        --font-color: initial;
        --outline-color: var(--slate-white-color);
        overflow: hidden;
        align-items: center;
        display: flex;
        justify-content: center;
        position: relative;
        box-shadow: 0 0 0 calc(3px / 2) var(--outline-color);
        border-radius: var(--access-unit-radius);
        overflow: hidden;
        height: var(--access-unit-height);
        color: var(--slate-color);

        svg {
            fill: var(--mono-white-color);
        }
    }

    &:not(.disabled) {
        &,
        &:hover,
        &:focus,
        &:active {
            --focus-background-color: transparent;

            &.facebook {
                --background-color: var(--facebook-color);
                --focus-background-color: var(--background-color);
                --font-color: var(--mono-white-color);
            }

            &.google {
                --background-color: var(--google-color);
                --focus-background-color: var(--background-color);
                --font-color: var(--mono-white-color);
            }

            &.microsoft {
                --background-color: var(--microsoft-color);
                --focus-background-color: var(--background-color);
                --font-color: var(--mono-white-color);
            }

            &:hover,
            &:focus,
            &:active {
                filter: brightness(95%);
                background-color: var(--focus-background-color);
                &:active {
                    filter: brightness(90%);
                }
            }
        }
    }

    & > .icon {
        --icon-width: 26px;
        --icon-height: 26px;
        margin-right: 0px;

        svg {
            padding: var(--access-label-letting);
        }
    }

    & > .label {
        position: relative;
        font-size: 14px;
    }

    & + & {
        margin-top: var(--access-letting-y);
    }
`;

// export const SpaceNode = styled.div`
//     flex-shrink: 0;
//     flex-grow: 0;

//     & {
//         width: calc(${({ width }) => (width !== undefined ? width : 1)} * var(--access-letting-width));
//         height: calc(${({ height }) => (height !== undefined ? height : 1)} * var(--access-letting-height));
//     }
// `;

export const Submit = styled.button.attrs({ type: 'submit' })`
    ${button}
    & {
        padding-left: calc(var(--access-unit-letting-x) * 3);
        padding-right: calc(var(--access-unit-letting-x) * 3);
        justify-self: flex-end;
        align-self: flex-end;
    }
`;

export const TextNode = styled.div`
    & {
        line-height: 1.33;
        font-size: 18px;
        padding-left: var(--access-letting-x);
        padding-right: var(--access-letting-x);
    }
`;

export const Textarea = styled.textarea`
    ${input}
    & {
        height: auto;
        resize: none;
        outline: none;
    }
`;

export const Vertical = styled.div`
    display: flex;
    flex-flow: column;
    input[readonly] {
        pointer-events: none;
        background: #e5e5e5;
    }
`;

