import * as ReduxAct from 'redux-act';
// import omitBy from 'lodash/omitBy';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import attempt from 'lodash/attempt';
import omitBy from 'lodash/fp/omitBy';
import * as actions from 'modules/actions';
import * as types from 'components/Login/types';
import * as ReduxPersist from 'redux-persist';
import sessionStorage from 'redux-persist/es/storage/session';

// event access properties will now reside under registration to prevent caching
export interface RegistrationPropertyLink {
    /** `org` - search parameter  */
    organizationId: string;
    /** `evt` - search parameter  */
    eventId: string;
    /** slugid.encode(eventId) + '-' + organizationId */
    eventSlug: string;
    /** `toccaPreviewOverride` - search parameter  */
    preview: boolean;
    /** Original Path - used for redirect after sign in */
    originalPath: string | null;
    id: string | null;
    email: string | null;
    isRoutingDocument: boolean;
    pageType: 'event' | 'eventLanding' | 'discovery' | null;
    isLinkLoading: boolean;
}

enum RegistrationEventStatus {
    opened = 'opened',
    closed = 'closed',
}

// event access properties will now reside under registration to prevent caching
export interface EventAccess {
    hostContactEmail?: string;
    link: RegistrationPropertyLink;
    requiresRegistration: boolean;
    status: RegistrationEventStatus;
    isTestUserHasAccess: boolean;
    utcStartTimeMillis: number;
}

export interface RegistrationProfile {
    avatar?: any;
    companyName?: string;
    description?: string;
    email?: string;
    eventAccess?: [];
    firstName?: string;
    jobTitle?: string;
    lastName?: string;
    linkedin?: string;
    roles?: [];
    terms?: boolean;
    twitter?: string;
    uid?: string;
    isUserRefFromAdmin?: boolean;
    workLocation?: string;
}

export type RegistrationHost = {
    eventId?: string;
    organizationId?: string;
};

export type RegistrationReducerState = {
    access: EventAccess;
    failure?: types.FirebaseFailure;
    host: RegistrationHost;
    mode: any;
    opened: boolean;
    profile: RegistrationProfile;
    tasks: RegistrationTasks;
};

export type RegistrationTasks = { [key: string]: any };

/* ------ INITIAL STATE ----- */
const initialSessionPropertyLinkState: RegistrationPropertyLink = {
    organizationId: '',
    eventId: '',
    eventSlug: '',
    preview: false,
    originalPath: null,
    id: null,
    email: null,
    isRoutingDocument: false,
    pageType: null,
    isLinkLoading: true,
};
const initialState: RegistrationReducerState = {
    get access() {
        return {
            hostContactEmail: undefined,
            link: initialSessionPropertyLinkState,
            requiresRegistration: false,
            status: RegistrationEventStatus.closed,
            isTestUserHasAccess: false,
            utcStartTimeMillis: -1,
        };
    },
    get host() {
        return {
            organizationId: undefined,
            eventId: undefined,
        };
    },
    mode: 'initial',
    opened: false,
    get profile() {
        return {};
    },
    tasks: {},
};

/* ------ REGISTRATION ----- */
export const registration = ReduxAct.createReducer<RegistrationReducerState>({}, initialState);

registration.on(actions.registrationAccess, (state: RegistrationReducerState, payload: any) => {
    payload.access = omitBy(isNil, payload.access);
    return {
        ...state,
        access: {
            ...state.access,
            ...payload.access,
            link: {
                ...state.access.link,
                ...payload.access.link,
                preview: attempt(JSON.parse, get('access.link.preview', payload)) === true,
            },
        },
        host: {
            ...state.host,
            ...omitBy(isNil, payload.host),
        },
    };
});

registration.on(actions.registrationSessionRestore, (state: RegistrationReducerState) => {
    return {
        ...initialState,
        access: state.access,
        host: state.host,
        opened: state.opened,
    };
});

registration.on(actions.setTestUserToOpenEvent, (state: RegistrationReducerState, payload: any) => {
    return { ...state, access: { ...state.access, isTestUserHasAccess: payload.isTestUserHasAccess } };
});

/* ------ SESSION ----- */
export type LinkReducerState = RegistrationPropertyLink;

const session = ReduxAct.createReducer<RegistrationPropertyLink>({}, initialSessionPropertyLinkState);
const sessionConfiguration = { key: 'link', storage: sessionStorage };

export const link = ReduxPersist.persistReducer(sessionConfiguration, session);

session.on(actions.SetRegistrationSessionPropertyLink, (state: RegistrationPropertyLink, payload: any) => {
    return {
        ...state,
        eventId: payload.eventId,
        organizationId: payload.organizationId,
        eventSlug: payload.eventSlug,
        preview: payload.preview,
        originalPath: payload.originalPath,
        id: payload.id,
        email: payload.email,
        isRoutingDocument: payload?.isRoutingDocument ?? false,
        pageType: payload?.pageType ?? null,
        isLinkLoading: false,
    };
});

session.on(actions.signOutUser, (state: RegistrationPropertyLink) => ({
    ...state,
    originalPath: null,
}));

session.on(actions.setLinkLoading, (state: RegistrationPropertyLink) => ({
    ...state,
    isLinkLoading: true,
}));

session.on(actions.resetUserId, (state: RegistrationPropertyLink) => ({
    ...state,
    id: null,
}));

session.on(actions.resetLink, () => ({
    ...initialSessionPropertyLinkState,
}));
