import React, { useState } from 'react';
import styled from 'styled-components/macro';
import is from 'classnames';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { motion } from 'framer-motion';
import { theme, centerXY, HeadingNode, notes, TextNode } from './inputs';
import { useSelector } from 'react-redux';
import * as selectors from 'modules/selectors';
import parse from 'html-react-parser';
import { Img } from 'react-image';
export * from './inputs';

interface AreaProperties extends React.PropsWithChildren<any> {
    height?: number | string | null;
    width?: number | string | null;
    x?: number | string | null;
    y?: number | string | null;
}
export const Area: React.FC<AreaProperties> = ({ className, children, height, width, style, x, y, ...properties }) => {
    return (
        <div
            {...properties}
            className={is({ absolute: true }, className)}
            style={{ padding: 'var(--access-letting)', ...style, left: x, top: y, height, width, zIndex: 0 }}
        >
            {children}
        </div>
    );
};

export const AvatarContainer = styled.div`
    & {
        ${theme}
    }
    --avatar-ratio: var(--ratio, 0.4);
    --avatar-size: var(--size, 80px);
    background-color: var(--blue-light-color);
    border-radius: var(--avatar-size);
    display: flex;
    flex-shrink: 0;
    flex-grow: 0;

    place-items: center;
    place-content: center;
    position: relative;

    height: var(--avatar-size);
    width: var(--avatar-size);
    z-index: var(--access-content-z-index, 0);

    & > .initials {
        z-index: 0;
        color: var(--theme-color);
        font-size: calc(var(--avatar-ratio) * var(--avatar-size));
        font-weight: bold;
        text-align: center;
        position: relative;
        z-index: 0;
        text-transform: uppercase;
    }

    & > .image {
        border-radius: var(--avatar-size);
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        overflow: hidden;
        img {
            object-position: center;
            object-fit: contain;
            height: auto;
            width: 100%;
        }
    }
`;

export const ComponentModal = styled(motion.div)`
    --modal-width: 560px;
    background-color: var(--access-background-color);
    max-width: var(--modal-width);
    min-width: var(--modal-width);
    box-shadow: 0 2px 30px 1px rgba(0, 0, 0, 0.3);
    border-radius: var(--access-unit-radius);
    position: relative;
    overflow: hidden;
    overflow-y: auto;
    padding-bottom: var(--access-unit-letting-height);
    padding-left: var(--access-unit-letting-width);
    padding-right: var(--access-unit-letting-width);
    will-change: opacity, transform;

    & {
        @media (max-width: 600px) {
            max-width: 100vw;
            min-width: 100vw;
        }
    }

    & .logo {
        ${centerXY}
        height: 30px;
        position: relative;
        img {
            height: 100%;
            width: auto;
            object-position: center;
            object-fit: contain;
        }
    }

    & *.relative {
        position: relative !important;
    }

    & *.absolute {
        position: absolute !important;
    }

    & *.center {
        ${centerXY}
    }

    & *.end {
        place-content: flex-end !important;
    }

    &::-webkit-scrollbar-track {
        background-color: transparent;
    }

    &::-webkit-scrollbar {
        width: 27px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 100px;
        background-color: #0757e4;
        border: 12px solid var(--mono-white-color);
    }
`;

export const ComponentWrapper = styled(motion.div)`
    & {
        ${theme}
        ${centerXY}
    }
    display: flex;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.3);
    padding: var(--access-letting);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10000 !important;
    will-change: opacity;
`;

const FillNode = styled.div`
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    justify-content: center;
    justify-items: center;
    align-items: center;
    align-content: center;
    text-align: center;

    & {
        background-color: ${(ie) => (get(ie, 'placeholder', false) ? 'var(--blue-white-color)' : null)};
    }
`;

export const Fill = ({ children, ...properties }: React.PropsWithChildren<any>) => {
    return <FillNode {...properties}>{children}</FillNode>;
};

export const FormContainer = styled.form`
    display: flex;
    flex-direction: column;
    background-color: var(--access-background-color);
    min-height: 470px;
    label {
        // text-transform: capitalize;
        font-weight: normal;
        margin: 0;
        & + input,
        & + textarea {
            margin-top: var(--access-label-letting);
        }
        a {
            margin-left: 3px;
            margin-right: 3px;
        }
    }

    input + div,
    input + .glyph + div {
        margin-top: var(--access-label-letting);
    }

    & {
        ${notes}
    }
`;

export const LocationResultsContainer = styled.div`
    display: block;
    position: relative;
    margin-top: 0 !important;
    width: 100%;
    & > div {
        background-color: var(--mono-white-color);
        border: var(--access-border);
        box-shadow: var(--access-shadow);
        list-style: none;
        max-height: 444px;
        overflow-y: auto;
        padding: var(--access-letting);
        position: absolute;
        border-radius: var(--access-unit-radius);
        width: 100%;
        z-index: 1;

        & > div {
            --letting-y: calc(var(--access-letting-y) / 2);
            border-radius: var(--access-unit-radius);
            cursor: pointer;
            padding: var(--letting-y) var(--access-unit-letting-x) var(--letting-y) var(--access-unit-letting-x);
            &:hover {
                background-color: var(--blue-light-color);
            }

            &.focused {
                background-color: var(--blue-light-color);
            }

            & > :last-child {
                margin-left: 4px;
            }
        }
    }
`;

export const MenuContainer = styled.div`
    background-color: var(--mono-white-color);
    border-radius: var(--access-unit-radius);
    box-shadow: 0 2px 10px 0 rgba(0, 87, 228, 0.22);
    display: flex;
    flex-direction: column;
    position: absolute;
    padding: var(--access-letting);
    top: 100%;
    left: 100%;
    min-height: 100px;
    width: 228px;
    z-index: 2;
    .menu-action {
        cursor: pointer;
        font-size: var(--access-font-size);
        font-weight: normal;
        padding-top: var(--access-letting-y);
        padding-bottom: var(--access-letting-y);
        padding-left: var(--access-letting-x);
        padding-right: var(--access-letting-x);
        color: var(--slate-color);
        position: relative;
        display: flex;
        user-select: none;

        .icon {
            display: inline-flex;
            position: relative;
            margin-right: var(--access-letting-x);
            height: 16px;
            width: 16px;
            svg {
                fill: currentColor;
                position: relative;
                height: 100%;
                width: auto;
            }
        }

        input,
        .fill {
            cursor: pointer !important;
            appearance: none;
            opacity: 0;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 1;
        }

        &.disabled {
            pointer-events: none !important;
            opacity: 0.5;
        }
        &:not(.disabled):hover {
            color: var(--blue-color);
            .icon svg {
                fill: var(--blue-color);
            }
        }
    }
`;

export const Heading = ({ size, bold, children, style, ...properties }: React.PropsWithChildren<any>) => {
    style = {
        fontSize: size,
        fontWeight: bold ? 'bold' : null,
        ...style,
    };
    return (
        <HeadingNode style={style} {...properties}>
            {children}
        </HeadingNode>
    );
};

export const Text = ({ children, style, size, ...properties }: React.PropsWithChildren<any>) => {
    style = {
        fontSize: size,
        ...style,
    };
    return (
        <TextNode style={style} {...properties}>
            {children}
        </TextNode>
    );
};

export const ThumbnailComponent = styled.div`
    position: relative;
    display: block;
    width: 100%;
    .controls {
        position: relative;
        padding: var(--access-letting);
        display: flex;
        input {
            width: 100%;
            background-color: var(--blue-color);
        }
    }
`;

export const ThumbnailContainer = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    min-height: var(--avatar-thumbnail-height);
    max-height: var(--avatar-thumbnail-width);
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    align-items: center;
    align-content: center;
    text-align: center;

    #stream {
        height: var(--avatar-thumbnail-height);
        width: var(--avatar-thumbnail-width);
    }
`;

const BrandLogo = styled(Img)`
    position: relative;
    margin-left: 15px;
    max-height: var(--header-height, 56px);
    height: 70px;
`;

/* framer-motion */
export const variants = {
    overlay: {
        visible: {
            opacity: 1,
            transition: {
                when: 'beforeChildren',
            },
        },
        hidden: {
            opacity: 0,
            transition: {
                duration: 0.3,
                when: 'afterChildren',
            },
        },
    },
    modal: {
        hidden: {
            scale: 0.97,
            opacity: 0,
        },
        visible: {
            scale: 1,
            opacity: 1,
            transition: {
                duration: 0.23,
            },
        },
        exit: {
            opacity: 0,
            transition: {
                duration: 0,
            },
        },
    },
};

export const SpaceNode = styled.div`
    flex-shrink: 0;
    flex-grow: 0;

    & {
        width: calc(${() => (1)} * var(--access-letting-width));
        height: calc(${() => (1)} * var(--access-letting-height));
    }
`;

const ToccaLogoBox = styled.div`
    ${centerXY}
    height: 30px;
    position: relative;
    svg {
        height: 100%;
        width: auto;
        object-position: center;
        object-fit: contain;
    }
`;
const ToccaFooterLogoBox = styled.div`
    ${centerXY}
    height: 30px;
    position: absolute;
    place-content: flex-end;
    top: 90%;
    width: 90%;
    svg {
        width: auto;
        object-position: center;
        object-fit: contain;
        height: 20px;
        margin-left: 12px;
    }
`;
export const ToccaLogo = (properties: React.PropsWithChildren<any>) => {
    const isLobbyDataLoading = useSelector(selectors.getLobbyLoading);
    const lobby = useSelector(selectors.getLobbyData);
    const [imageError, setImageError] = useState(false);
    const assets = useSelector(selectors.getAssets);
    const event = useSelector(selectors.getEvent);
    const org = useSelector(selectors.getOrganization);
    return (
        <ToccaLogoBox {...properties}>
            {/* <ToccaNewIcon /> */}
            {!isLobbyDataLoading && !isEmpty(lobby?.header?.titleHtml) ? (
                <div className="event-header">{parse(get(lobby, 'header.titleHtml', ''))}</div>
            ) : (
                <BrandLogo
                    src={[
                        `${assets.imagesUrl}${get(event, 'welcome.hero.imageUrl')}`,
                        `${assets.imagesUrl}${get(org, 'logoUrl')}`,
                        '/images/default/shared_studio.png',
                    ]}
                    alt="Event Header"
                    onError={() => setImageError(true)}
                />
            )}
            {imageError && <BrandLogo src={'/images/default/shared_studio.png'} alt="Event Header" />}
        </ToccaLogoBox>
    );
};
export const ToccaFooterLogo = (properties: React.PropsWithChildren<any>) => {
    return (
        <ToccaFooterLogoBox {...properties}>
            Powered By
            <BrandLogo src={'/images/default/shared_studio.png'} alt="Event Header" />
            {/* <ToccaNewIcon /> */}
        </ToccaFooterLogoBox>
    );
};

