import React from 'react';
import Timer from 'react-compound-timer';
import styled from 'styled-components/macro';

const Wrapper = styled.div`
    --timer-size: 28px;
    --timer-letting: 3px;
    font-weight: bold;
    font-size: var(--timer-size);
    position: relative;
    text-align: center;
    font-family: var(--second-font-family);
    .separator {
        position: relative;
        line-height: var(--timer-size);
        padding-left: calc(var(--timer-letting) * 2.5);
        padding-right: calc(var(--timer-letting) * 2.5);
    }
`;
const TimerWrapper = styled.div`
    display: flex;
`;
const Time = styled.div``;
const TimeItem = styled.div``;
const TimeTitle = styled.div`
    font-size: 12px;
    margin-top: calc(var(--timer-letting) * 2);
`;

interface ICheckpoint {
    time: number;
    callback: () => void;
}

interface IProps {
    checkpoints: ICheckpoint[];
    startTime: number;
}

export const CountDownTime = ({ checkpoints, startTime }: IProps) => {
    const initialTime = startTime - Date.now();
    const hours = Math.trunc(initialTime / (1000 * 60 * 60));
    const formatValue = (value: number): string => (value < 10 ? `0${value}` : String(value));
    return (
        <Wrapper>
            <Timer checkpoints={checkpoints} direction="backward" formatValue={formatValue} initialTime={initialTime}>
                <TimerWrapper>
                    {hours >= 24 && (
                        <>
                            <TimeItem>
                                <Time>
                                    <Timer.Days />
                                </Time>
                                <TimeTitle>DAY</TimeTitle>
                            </TimeItem>
                            <div className="separator">:</div>
                        </>
                    )}
                    <TimeItem>
                        <Time>
                            <Timer.Hours />
                        </Time>
                        <TimeTitle>HR</TimeTitle>
                    </TimeItem>
                    <div className="separator">:</div>
                    <TimeItem>
                        <Time>
                            <Timer.Minutes />
                        </Time>
                        <TimeTitle>MIN</TimeTitle>
                    </TimeItem>
                    <div className="separator">:</div>
                    <TimeItem>
                        <Time>
                            <Timer.Seconds />
                        </Time>
                        <TimeTitle>SEC</TimeTitle>
                    </TimeItem>
                </TimerWrapper>
            </Timer>
        </Wrapper>
    );
};
