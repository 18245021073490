import { SagaIterator } from 'redux-saga';
import * as actions from '../actions';
import * as selector from '../selectors/user';
import { getRoomId } from '../selectors';
import { call, put, select, take, cancel, cancelled, fork } from 'redux-saga/effects';
import { getNetworkingRoomState, updateNetworkData } from '../utils/Firebase/UserManagement/NetworkingRoomState';
import { ISagaAction } from '../types';

export const networkingRegisterUser = function* (): SagaIterator {
    while (yield take(actions.realtimeFetchNetworkingRoom)) {
        const fetchRoomData = yield fork(fetchNetworkingRoomListner);
        yield take(actions.removeNetworkingRoom);
        yield cancel(fetchRoomData);
    }
};
export const setNetworkData = function* (action: ISagaAction<any>): SagaIterator {
    const organizationId = yield select(selector.getOrganizationId);
    const eventId = yield select(selector.getEventId);
    const roomId = yield select(getRoomId);
    if (!roomId) {
        return;
    }
    const obj = {
        roomId,
        organizationId,
        eventId,
        ...action.payload,
    };
    yield call(updateNetworkData, obj);
};
const fetchNetworkingRoomListner = function* (): SagaIterator {
    const organizationId = yield select(selector.getOrganizationId);
    const eventId = yield select(selector.getEventId);
    const roomId = yield select(getRoomId);
    if (!roomId) {
        return;
    }
    const obj = {
        roomId,
        organizationId,
        eventId,
    };
    const channel = yield call(getNetworkingRoomState, obj);
    try {
        while (true) {
            const result = yield take(channel);
            if (result) {
                yield put(actions.setNetworkingRoomdata(result));
            } else {
                yield put(actions.setNetworkingRoomdata(null));
            }
        }
    } catch (e) {
        console.warn(e);
    } finally {
        // unregister listener if the saga was cancelled
        if (yield cancelled()) channel.close();
    }
};
