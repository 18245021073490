import { SagaIterator } from 'redux-saga';
import { call, put, select, take, cancel, fork, cancelled } from 'redux-saga/effects';
import {
    SetRegistrationSessionPropertyLink,
    createRequestSucces,
    fetchSponsorsFailed,
    fetchSponsorsSuccess,
    setSponsorTypes,
} from 'modules/actions';
import Api from 'modules/utils/Api';
import { getEventId, getOrganizationId, getFirebaseAccessToken } from 'modules/selectors/user';
import { ISagaAction } from 'modules/types';
import * as Selectors from 'modules/selectors';
import { diff } from 'deep-object-diff';
import isEmpty from 'lodash/isEmpty';
import * as actions from 'modules/actions';
import {
    addSponsorBoothUserConnection,
    removeSponsorBoothUserConnection,
    getSponsorBoothUser,
    getSponsorBoothUserData,
} from 'modules/utils/Firebase/UserManagement/SponsorBoothUserPresence';
import * as Sentry from '@sentry/react';

export const fetchSponsorsSaga = function* (): SagaIterator {
    try {
        yield take(SetRegistrationSessionPropertyLink);
        const link = yield select(Selectors.registrationSessionPropertyLink);
        const { eventId, organizationId } = link;
        if (isEmpty(organizationId) || isEmpty(eventId)) {
            console.error('organizationId or eventId is empty');
            return;
        }
        const response = yield call(Api.fetchSponsors, eventId, organizationId);

        if (response.error) {
            yield put(fetchSponsorsFailed());
            return;
        }
        const sponsors = yield select(Selectors.getAllSponsorsList);
        const diffData = diff(response.data, sponsors);
        if (typeof diffData !== 'object' || Object.keys(diffData).length > 0) {
            yield put(fetchSponsorsSuccess(response.data));
        }
    } catch (e) {
        console.error('error:', e);
        Sentry.captureException(e);
    }
};

export const createRequestFollowUp = function* (action: ISagaAction<object>): SagaIterator {
    try {
        const organizationId = yield select(getOrganizationId);
        const token = yield select(getFirebaseAccessToken);
        const sponsor = yield call(Api.createRequestFollowUp, action.payload, organizationId, token);
        yield put(createRequestSucces(sponsor));
    } catch (e) {
        console.error('error:', e);
        Sentry.captureException(e);
    }
};

export const getSponsorsTypesSaga = function* (): SagaIterator {
    try {
        const eventId = yield select(getEventId);
        const organizationId = yield select(getOrganizationId);
        const token = yield select(getFirebaseAccessToken);
        const response = yield call(Api.getSponsorsTypes, eventId, organizationId, token);
        const sponsorsTypes = yield select(Selectors.getAllSponsorTypeArray);
        const diffData = diff(response.data, sponsorsTypes);
        if (response.data && Object.keys(diffData).length > 0) {
            yield put(setSponsorTypes(response.data));
        }
    } catch (e) {
        console.error(e, 'error');
        Sentry.captureException(e);
    }
};
export const sponsorBoothUserPresenceConnection = function* (): SagaIterator {
    while (yield take(actions.registerSponsorBoothUser)) {
        const uid = yield select(Selectors.getUserUid);
        const organizationId = yield select(Selectors.getOrganizationId);
        const eventId = yield select(Selectors.getEventId);
        const sponsorId = yield select(Selectors.getSponsorId);
        const profile = yield select(Selectors.getUserProfile);
        const profileAvatar = yield select(Selectors.getProfileAvatar);
        if (!sponsorId) {
            return;
        }
        const obj = {
            sponsorId,
            organizationId,
            eventId,
            uid,
        };
        const profileObj = {
            uid,
            firstName: profile.firstName,
            lastName: profile.lastName,
            avatar: profileAvatar || '',
            companyName: profile?.companyName ?? '',
            companyTitle: profile?.jobTitle ?? '',
            description: profile?.description ?? '',
            workLocation: profile?.workLocation ?? '',
            twitter: profile?.twitter ?? '',
            linkedin: profile?.linkedin ?? '',
        };
        yield call(addSponsorBoothUserConnection, obj, profileObj);

        const connectionTask = yield fork(SponsorBoothUserPresenceListner);
        // wait for the user stop action
        yield take([actions.removeSponsorBoothUser, actions.signOutUser]);
        yield cancel(connectionTask);
        yield call(removeSponsorBoothUserFromRoomSaga);
    }
};

export const removeSponsorBoothUserFromRoomSaga = function* (): SagaIterator {
    const uid = yield select(Selectors.getUserUid);
    const organizationId = yield select(Selectors.getOrganizationId);
    const eventId = yield select(Selectors.getEventId);
    const sponsorId = yield select(Selectors.getSponsorId);

    const obj = {
        organizationId,
        eventId,
        uid,
        sponsorId,
    };

    yield call(removeSponsorBoothUserConnection, obj);
};

export const SponsorBoothUserPresenceListner = function* (): SagaIterator {
    try {
        const uid = yield select(Selectors.getUserUid);
        const profile = yield select(Selectors.getUserProfile);
        const organizationId = yield select(Selectors.getOrganizationId);
        const eventId = yield select(Selectors.getEventId);
        const sponsorId = yield select(Selectors.getSponsorId);
        const profileAvatar = yield select(Selectors.getProfileAvatar);

        const obj = {
            organizationId,
            eventId,
            uid,
            sponsorId,
        };
        const profileObj = {
            uid,
            firstName: profile.firstName,
            lastName: profile.lastName,
            avatar: profileAvatar || '',
            companyName: profile?.companyName ?? '',
            companyTitle: profile?.jobTitle ?? '',
            description: profile?.description ?? '',
            workLocation: profile?.workLocation ?? '',
            twitter: profile?.twitter ?? '',
            linkedin: profile?.linkedin ?? '',
        };
        const channel = yield call(getSponsorBoothUser, obj);
        try {
            while (true) {
                const result = yield take(channel);
                if (!result && uid) {
                    yield call(removeSponsorBoothUserConnection, obj);
                } else if (result) {
                    yield call(addSponsorBoothUserConnection, obj, profileObj);
                }
            }
        } catch (e) {
            console.warn(e);
        } finally {
            // unregister listener if the saga was cancelled
            if (yield cancelled()) channel.close();
        }
    } catch (error) {
        console.error(error);
        Sentry.captureException(error);
    }
};
export const sponsorBoothPresenceUserSaga = function* (): SagaIterator {
    while (yield take(actions.setActiveListner)) {
        const connectionTask = yield fork(sponsorBoothPresnceListener);
        // wait for the user stop action
        yield take([actions.signOutUser, actions.removeActiveListner]);

        yield cancel(connectionTask);
    }
};

export const sponsorBoothPresnceListener = function* (): SagaIterator {
    try {
        const organizationId = yield select(getOrganizationId);
        const eventId = yield select(getEventId);
        const uid = yield select(Selectors.getUserUid);
        const sponsorId = yield select(Selectors.getSponsorId);

        const obj = {
            organizationId,
            eventId,
            uid,
            sponsorId,
        };
        const channel = yield call(getSponsorBoothUserData, obj);
        try {
            while (true) {
                const result = yield take(channel);
                if (result) {
                    const data = [];
                    const keys = typeof result === 'object' ? Object.keys(result) : null;
                    if (keys && Array.isArray(keys)) {
                        for (const key of keys) {
                            if (result[key] && Object.keys(result[key])) {
                                const obj = {
                                    [key]: {
                                        sponsorBoothUserList: Object.values(result[key]),
                                    },
                                };
                                data.push(obj);
                            }
                        }
                    }
                    yield put(actions.setSponsorBoothUserPresenceList(data));
                } else {
                    yield put(actions.setSponsorBoothUserPresenceList([]));
                }
            }
        } catch (e) {
            console.warn(e);
        } finally {
            // unregister listener if the saga was cancelled
            if (yield cancelled()) channel.close();
        }
    } catch (error) {
        console.error(error);
        Sentry.captureException(error);
    }
};

export default fetchSponsorsSaga;
