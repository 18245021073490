import React, { useCallback, useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { closeMediaPermissionModal } from 'modules/actions';
import { getMediaPermissionModal, getModalMessage } from 'modules/selectors/modal';

const MediaPermissionModal = () => {
    const dispatch = useDispatch();
    const [errMsg, setErrMsg] = useState<string>();
    const isMediaPermissionModalOpen = useSelector(getMediaPermissionModal);
    const message = useSelector(getModalMessage);

    useEffect(() => {
        if (message) {
            setErrMsg('Camera or Microphone could not be found.Please give access to them to enter the lounge.');
        }
    }, [message]);

    const closeModal = useCallback(() => {
        dispatch(closeMediaPermissionModal());
    }, [dispatch]);

    return (
        <Modal centered show={isMediaPermissionModalOpen} onHide={closeModal}>
            <Modal.Header closeButton>
                <Modal.Title>Permission For Video & Audio</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="wrapper">
                    <div id="formContent">{errMsg && <span>{errMsg}</span>}</div>
                </div>
            </Modal.Body>
        </Modal>
    );
};
export default MediaPermissionModal;
