import { createAction } from 'redux-act';
import { IUserProfile } from 'modules/reducers';

export const authUser = createAction<any>();
export const authUserSuccess = createAction<any>();
export const authUserFailed = createAction<any>();
export const sendMailToResetPassword = createAction<string>();
export const setLoading = createAction();
export const signOutUser = createAction();
export const authLogOut = createAction();
export const resetUserId = createAction('reset user id');
export const resetLink = createAction('reset link');
export const authSetProfile = createAction<any>();
export const mailSent = createAction<boolean>();
export const authSetTwillioProfile = createAction<boolean>();
export const authLinkSuccess = createAction<any>();
export const authLinkInvalid = createAction();
export const signInAnonymously = createAction();
export const checkUserHasInvitee = createAction<string>();
export const authRegisteredUser = createAction<any>();
export const authRegisteredFailed = createAction<string>();
export const authRegisterParticipant = createAction<IUserProfile>('authRegisterParticipant');
export const authSetParticipantProfile = createAction<any>();
export const setUserLoading = createAction();
export const authSignInSuccess = createAction<any>();
export const setProfileFetching = createAction<boolean>('set profile fetch');
export const setAttendeeRole = createAction<any>('set role');
export const setUserRoles = createAction<boolean>('set user roles');
export const removeError = createAction('remove error');
export const resetPasswordFailed = createAction<string>('set reset password error');
export const removeResetError = createAction('remove reset error');

/* Current user's profile avatar actions */
export const setUserAvatarUrl = createAction<any>('setUserAvatarUrl: set updated avatar url');
export const setUserAvatarUrlError = createAction('setUserAvatarUrlError: set invalid avatar url');
/* External participant's profile avatar actions */
export const participantAvatar = createAction<any>('participantAvatar: add avatar to url cache/resolver');
export const participantAvatarsCache = createAction<any>('participantAvatarsCache: all resolved avatar urls');
export const fetchAdminEventUser = createAction('fetch user details from AdminEventUser');
export const isModifiedProfile = createAction<boolean>();
export const setActiveListner = createAction('setActiveListner');
export const removeActiveListner = createAction('removeActiveListner');
