import { SagaIterator } from 'redux-saga';
import { call, put, select, take, cancelled, cancel, fork } from 'redux-saga/effects';
import * as actions from 'modules/actions';
import { getEventId, getOrganizationId, getScheduleList } from 'modules/selectors';
import { toaster } from 'components/Notification';
import * as componentService from 'modules/utils/Firebase/Components';
import { getSpeakerList } from 'modules/utils/Firebase/ScheduleList';
import * as Sentry from '@sentry/react';

export const fetchComponentsListnerSaga = function* (): SagaIterator {
    try {
        while (yield take(actions.setActiveListner)) {
            const notificationTask = yield fork(fetchComponentsComponentListner);
            // wait for the user stop action
            yield take([actions.authLogOut, actions.removeActiveListner, actions.signOutUser]);

            yield cancel(notificationTask);
        }
    } catch (error) {
        toaster(String(error), 'danger', 3000);
        console.error('ERROR:', String(error));
        Sentry.captureException(error);
    }
};

const fetchComponentsComponentListner = function* (): SagaIterator {
    try {
        const eventId = yield select(getEventId);
        const organizationId = yield select(getOrganizationId);
        const channel = yield call(componentService.getComponentDataListLisner, organizationId, eventId);
        try {
            while (true) {
                const result = yield take(channel);
                if (Array.isArray(result)) {
                    const CTAData = yield call(componentService.getCtaCard, result, 'CALL_TO_ACTION');
                    // const takeAssessData = yield call(componentService.getCtaCard, result, 'CALL_TO_ACTION');
                    const lobbyData = yield call(componentService.getComponentData, result, ['LOBBY']);
                    const sponsorData = yield call(componentService.getMeetingRoomData, result, ['SPONSOR_BOOTH']);
                    const meetingRoomData = yield call(componentService.getMeetingRoomData, result, [
                        'MEETING_ROOM',
                        'BROADCAST_ROOM',
                        'AGENDA',
                    ]);
                    const networkingLoungeData = yield call(componentService.getLoungeComponentData, result, [
                        'NETWORKING_AREA',
                    ]);
                    if (lobbyData) yield put(actions.setLobbySuccess(lobbyData));

                    if (CTAData) yield put(actions.setCtaCards(CTAData));

                    if (Array.isArray(sponsorData)) yield put(actions.fetchSponsorsSuccess(sponsorData));

                    if (Array.isArray(meetingRoomData)) {
                        const schedule = yield select(getScheduleList);
                        const meetingRooms = yield call(componentService.getMeetingRooms, meetingRoomData, schedule);

                        yield put(actions.fetchScheduleSuccess(meetingRooms));
                        const speakerList = yield call(getSpeakerList, meetingRooms);
                        yield put(actions.fetchScheduleSuccess(speakerList));
                    }
                    if (networkingLoungeData) yield put(actions.setNetLoungeSucces(networkingLoungeData));
                    else yield put(actions.setNetLoungeSucces(undefined));
                } else {
                    yield put(actions.checkRoomLimitSuccess([]));
                }
            }
        } catch (e) {
            console.warn(e);
        } finally {
            // unregister listener if the saga was cancelled
            if (yield cancelled()) channel.close();
        }
    } catch (error) {
        console.error(error);
        Sentry.captureException(error);
    }
};
