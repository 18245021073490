import firebase from 'firebase/app';
import isUndefined from 'lodash/isUndefined';
import pickBy from 'lodash/pickBy';
import isEmpty from 'lodash/isEmpty';
import isString from 'lodash/isString';
import has from 'lodash/has';
const AVATAR = {
    BUCKET: process.env.REACT_APP_TOCCA_AVATAR_BUCKET || '',
    DOMAIN: '',
    LOADING: false,
};
const AVATARS: { [key: string]: string | null | undefined } = {};
const MAXIMUM_USERS = 20;

type Avatar = [string, any];

export const loadAvatars = (): Promise<any> => loadProfileAvatars().then(() => AVATARS);

function loadProfileAvatars(): Promise<boolean> {
    if (!AVATAR.LOADING) {
        const users = nextLoad();
        if (users.length) {
            AVATAR.LOADING = true;
            try {
                const getAvatars = firebase.functions().httpsCallable('getAvatars');
                return getAvatars({ users })
                    .then((result) => result.data)
                    .then(updateAvatars)
                    .then(resolveAvatarUrls);
            } catch (error) {
                console.error('avatarListLoad', error);
            }
        }
    }
    return Promise.resolve((AVATAR.LOADING = false));
}

function nextLoad() {
    return Object.keys(pickBy(AVATARS, isUndefined)).slice(0, MAXIMUM_USERS);
}

// reload entire list of profile avatars every 30 minutes
export function reloadAvatars() {
    if (isEmpty(AVATARS)) return;
    for (const uid of Object.keys(AVATARS)) {
        // undefined value will reload profile from functions
        AVATARS[uid] = undefined;
    }
}

function resolveAvatarUrls() {
    const promises = Object.entries(pickBy(AVATARS, isString)).map(createPromise);
    return Promise.all(promises).then(updateAvatarUrls).then(loadAvatars);
    function createPromise([uid, source]: [string, string]): Promise<Avatar> {
        return new Promise((resolve) => {
            const url = toUrl(uid, source);
            if (url === null) return resolve([uid, undefined]);
            return firebase
                .storage()
                .refFromURL(url)
                .getDownloadURL()
                .then((result) => resolve([uid, result]))
                .catch(() => resolve([uid, undefined]));
        });
    }
}

export function toUrl(uid?: string, source?: string): string | null {
    if (isEmpty(uid) || isEmpty(source)) return null;
    if (!isString(uid) || !isString(source)) return null;
    if (source.startsWith('https://') || source.startsWith('gs://')) return source;
    if (source.startsWith('.')) return `${AVATAR.DOMAIN}${uid}${source}`;
    return `${AVATAR.BUCKET}/${source}`;
}

function updateAvatars(list: Avatar[]) {
    for (const [uid, url] of list) {
        // null wont reload users without avatar
        AVATARS[uid] = url || null;
    }
}

function updateAvatarUrls(entries: Avatar[]) {
    for (const [uid, url] of entries) {
        if (isString(url)) {
            if (!AVATAR.DOMAIN) AVATAR.DOMAIN = url.split(uid)[0];
            AVATARS[uid] = url.split(uid)[1];
        } else AVATARS[uid] = url;
    }
}

export function participantAvatar(uid: string) {
    if (has(AVATARS, uid) === false) {
        AVATARS[uid] = undefined;
    }
}
