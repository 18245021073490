import { createReducer } from 'redux-act';
import { fetchComponentCardFailed, resetState, setCtaCards } from 'modules/actions';

interface ICard {
    background: {
        imageUrl: string;
    };
    html: string;
    openUrl: string;
}

export interface IComponentDetails {
    card: ICard;
    component: string;
    name: string;
    fetching: boolean;
    order: any;
    dynamicScriptSrc: string;
    isEditorActive?: boolean;
}

export type CTAReducerState = {
    ctaCards: IComponentDetails[];
};

const initialState: CTAReducerState = {
    ctaCards: [],
};

export const componentDetail = createReducer<CTAReducerState>({}, initialState);

componentDetail.on(fetchComponentCardFailed, () => initialState);
componentDetail.on(resetState, () => initialState);
componentDetail.on(setCtaCards, (state: CTAReducerState, payload: IComponentDetails[]) => {
    return {
        ...state,
        ctaCards: payload,
    };
});
