import { SagaIterator } from 'redux-saga';
import { call, put, select, take, fork, cancel, cancelled } from 'redux-saga/effects';
import { authUserSuccess, authLogOut, fetchNotificationSuccess, fetchNewNotificationSuccess } from 'modules/actions';
import { getOrganizationId, getEventId } from 'modules/selectors/user';
import orderBy from 'lodash/orderBy';
import moment from 'moment-timezone';
import { toaster } from 'components/Notification';
import FireBaseNotification from 'modules/utils/Firebase/notification';
import * as Sentry from '@sentry/react';

export const fetchNotificationSaga = function* (): SagaIterator {
    try {
        // yield take(authUserSuccess);
        const NotificationClient = new FireBaseNotification();
        const organizationId = yield select(getOrganizationId);
        const eventId = yield select(getEventId);
        const fetchedNotification = yield call(NotificationClient.getNotfications, organizationId, eventId);
        if (fetchedNotification && Array.isArray(fetchedNotification)) {
            const list = orderBy(fetchedNotification, ['created_at'], ['desc']);
            yield put(fetchNotificationSuccess(list));
        }
    } catch (e) {
        console.error(e);
        Sentry.captureException(e);
    }
};

export const authNotification = function* (): SagaIterator {
    while (yield take(authUserSuccess)) {
        const notificationTask = yield fork(fetchNotificaion);
        // wait for the user stop action
        yield take(authLogOut);

        yield cancel(notificationTask);
    }
};

export const fetchNotificaion = function* (): SagaIterator {
    try {
        const organizationId = yield select(getOrganizationId);
        const eventId = yield select(getEventId);
        const NotificationClient = new FireBaseNotification();
        const checkIfDbExists = yield call(NotificationClient.checkIfNotificationDocExists, organizationId, eventId);
        const record = yield call(NotificationClient.getLastRecord, organizationId, eventId);
        const lastRecord = record.val();
        const lastRecordTimeStamp = lastRecord ? lastRecord.created_at : moment().unix();
        if (!checkIfDbExists.exists() && lastRecord) {
            yield put(fetchNewNotificationSuccess(lastRecord));
            yield call(toaster, String(lastRecord.message), 'default', 15000);
        }
        const channel = yield call(
            NotificationClient.subscribeNotification,
            organizationId,
            eventId,
            lastRecordTimeStamp,
        );
        try {
            while (true) {
                const result = yield take(channel);
                if (result) {
                    yield put(fetchNewNotificationSuccess(result));
                    yield call(toaster, String(result.message), 'default', 15000);
                }
            }
        } catch (e) {
            console.warn(e);
        } finally {
            // unregister listener if the saga was cancelled
            if (yield cancelled()) channel.close();
        }
    } catch (error) {
        console.error(error);
        Sentry.captureException(error);
    }
};

export default fetchNotificationSaga;
