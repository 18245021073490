import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { registrationSessionPropertyLink } from 'modules/selectors';
import { buildSluggedRoute } from 'modules/utils/CommonService';
import pickBy from 'lodash/pickBy';

export const Link = (props: any) => {
    const { eventSlug, organizationId } = useSelector(registrationSessionPropertyLink);
    const to = props.to;
    const filteredProps = pickBy(props, (_, k) => {
        return k !== 'to';
    });
    return (
        <RouterLink to={buildSluggedRoute(organizationId, eventSlug, to)} {...filteredProps}>
            {props.children}
        </RouterLink>
    );
};
export default Link;
