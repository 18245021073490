import { Components } from 'modules/utils/Firebase/Api';
import firebase from 'modules/utils/Firebase';
import { eventChannel } from 'redux-saga';
import { IKeynote, IPresenter } from 'modules/schemas';

export const getComponentDataListLisner = (organizationId: string, eventId: string) => {
    const connectionRef = Components.reference(organizationId, eventId);
    const connectionComponentsListner = eventChannel((emit) => {
        const unSubscribe = connectionRef.onSnapshot(async (snapshot: firebase.firestore.QuerySnapshot) => {
            if (!snapshot.empty) {
                const data: any[] = [];
                snapshot.forEach((result: firebase.firestore.QueryDocumentSnapshot) => {
                    if (result.exists) {
                        data.push(result.data());
                    }
                });
                emit(data);
            } else {
                emit(false);
            }
        });
        return () => {
            unSubscribe();
        };
    });
    return connectionComponentsListner;
};

export const getComponentData = async (data: any, componentType: string[]) => {
    const array = [...data];
    const component: any = array.find((value) => componentType.includes(value.componentType));

    return component;
};

export const getCtaCard = (data: any, componentType: string) => {
    const array = [...data];
    const fintTakeAssestment = array.filter((value) => value.componentType === componentType);
    return fintTakeAssestment;
};

export const getMeetingRoomData = async (data: any, componentType: string[]) => {
    const array = [...data];
    const fintMeetingRoom = array.filter((value) => componentType.includes(value.componentType));
    return fintMeetingRoom;
};

export const getMeetingRooms = async (data: any, schedule?: IKeynote[]) => {
    const rooms = data.map((keynote: IKeynote) => {
        let keynoteData: IKeynote = { ...keynote };
        let presenterList: IPresenter[] = [];
        if (keynoteData.presenters && Array.isArray(keynoteData.presenters)) {
            presenterList = [...keynoteData.presenters];
        }
        const existingRecord = schedule?.find((_val: IKeynote) => _val.id === keynoteData.id);
        delete keynoteData.moderators;
        if (existingRecord && existingRecord.presenters) {
            keynoteData = {
                ...keynoteData,
                presenterList,
                presenters: existingRecord.presenters,
            };
        } else {
            keynoteData = { ...keynoteData, presenterList, presenters: [] };
        }
        return keynoteData;
    });

    return rooms;
};

export const getLoungeComponentData = async (data: any, componentType: string[]) => {
    const array = [...data];
    const component: any = array.find((value) => componentType.includes(value.componentType));
    return component;
};
