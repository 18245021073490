import { IStoreState } from 'modules/types';
import { ISponsor } from 'modules/reducers';
import orderBy from 'lodash/orderBy';
export const getAllSponsorsList = (state: IStoreState) => state.sponsors.list;
export const getAllSponsorTypeArray = (state: IStoreState) => state.sponsors.sponsorType;
export const getSponsorsList = (state: IStoreState) => orderSponsors(state.sponsors.list);
export const getSponsorById = (id?: any) => (state: IStoreState) =>
    state.sponsors.list.find((item) => filterSpnsor(item, id));
export const missingSponsorsList = (state: IStoreState) => !state.sponsors.fetching && state.sponsors.list.length === 0;
export const getSponsorRequestStatus = (state: IStoreState) => state.sponsors.requestStatus;
export const getSponsorRequestFetchStatus = (state: IStoreState) => state.sponsors.isRequestFetchingFinished;
export const getSponsorId = (state: IStoreState) => state.sponsors.sponsorId;
export const getSponsorTypeArray = (state: IStoreState) => orderBy(state.sponsors.sponsorType, ['order'], ['asc']);
export const missingSponsorsTypes = (state: IStoreState) => state.sponsors.isFetchingSponsorTypes;
export const getSponsorBoothList = (state: IStoreState) => state.sponsors.sponsorBoothUserPresenceData;

function filterSpnsor(item: ISponsor, id: string) {
    return item.id === id;
}

function orderSponsors(array: ISponsor[]) {
    return orderBy(array, ['order'], ['asc']);
}
