import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EmailPattern, Insignia, FormHeader, Space } from '../common/index';
import * as selectors from 'modules/selectors';
import * as actions from 'modules/actions';
import { Input } from '../inputs';
import { Mode } from '../constants/constants';
import is from 'classnames';
import { Label } from '../fieldset/label';
import cloneDeep from 'lodash/cloneDeep';
import { Button, Heading, Horizontal, Vertical, Text } from '../style';

const ForgotPasswordMode = () => {
    const forgetData = useSelector(selectors.getloginData);
    const state = cloneDeep(forgetData);
    const [loginValidation, setError] = useState({
        email: '',
    });
    const dispatch = useDispatch();

    const useEmailClick = () => {
        dispatch(actions.authMode(Mode.initial));
        dispatch(actions.isUserRegistred(Mode.initial));
    };

    const handleInputChange = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;
        const obj = {
            ...state,
            [name]: value,
        };
        dispatch(actions.setAuthEmail(obj));
    };
    const ForgotPassword = () => {
        if (checkEmailValidity()) {
            dispatch(actions.mailResetPassword(state));
        }
    };

    const checkEmailValidity = () => {
        let formIsValid = true;
        if (state.email.length <= 0) {
            setError((val) => ({ ...val, email: 'Email is required' }));
            formIsValid = false;
        }
        if (!EmailPattern.test(state.email)) {
            setError((val) => ({
                ...val,
                email: 'Please enter a valid email address',
            }));
            formIsValid = false;
        } else
            setError((val) => ({
                ...val,
                email: '',
            }));
        dispatch(actions.authLoginErrors(''));
        return formIsValid;
    };

    const EMAIL = {
        ERROR: 'Please enter a valid email address',
        LABEL: 'Email',
        PLACEHOLDER: 'Enter Email',
        PROPERTY: 'email',
    };

    return (
        <React.Fragment>
            <FormHeader title={null} />
            <Space height={0.5} />
            <Vertical className="center text-center">
                <Insignia security />
                <Space />
                <Heading bold>Reset Password</Heading>
                <Text size={16}>Confirm your email to reset your password.</Text>
            </Vertical>
            <Space />
            <Vertical>
                <Label field={EMAIL.PROPERTY}>{EMAIL.LABEL}</Label>
                <Input
                    type="text"
                    value={state?.email ? state?.email : ''}
                    id={EMAIL.PROPERTY}
                    name={EMAIL.PROPERTY}
                    placeholder={'Enter Email'}
                    autoFocus={true}
                    onChange={(event) => handleInputChange(event)}
                />
                <span style={{ color: 'red' }}>{loginValidation.email}</span>
            </Vertical>
            <Space />
            <Horizontal>
                <Button className={is({ outline: true })} onClick={useEmailClick}>
                    Back
                </Button>
                <Button onClick={ForgotPassword}>Send Link to Reset Password</Button>
            </Horizontal>
        </React.Fragment>
    );
};

export default ForgotPasswordMode;
