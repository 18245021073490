import { createReducer } from 'redux-act';
import { fetchSwappedCard, fetchSwappedCardSuccess, fetchSwappedCardFailure, resetState } from 'modules/actions';

interface SwappedCardKey {
    firstName: string;
    lastName: string;
    companyName: string;
    jobTitle: string;
    uid: string;
    email: string;
}

export interface SwappedCardReducerState {
    swappedCards: SwappedCardKey[];
    isFetching: boolean;
}
const initialState: SwappedCardReducerState = {
    swappedCards: [],
    isFetching: false,
};

export const swappedCards = createReducer<SwappedCardReducerState>({}, initialState);
swappedCards.on(fetchSwappedCard, (state: SwappedCardReducerState) => ({
    ...state,
    isFetching: true,
}));

swappedCards.on(fetchSwappedCardSuccess, (state: SwappedCardReducerState, payload: SwappedCardKey[]) => ({
    ...state,
    swappedCards: payload,
    isFetching: false,
}));

swappedCards.on(fetchSwappedCardFailure, () => initialState);

swappedCards.on(resetState, () => initialState);
