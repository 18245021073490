import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';
import { navigateTo } from 'modules/actions';
import { useDispatch } from 'react-redux';

const RouteButton = styled.button`
    margin-top: 15px;
    background: #0057e4;
    font-size: 14px;
    font-weight: 600;
    color: #ffffff;
    width: 100%;
    border: none;
    height: 40px;
    border-radius: 4px;

    &:hover,
    &:active,
    &:visited,
    &:link {
        color: #ffffff;
    }
`;

export const WaitingRoomButton = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const routeToBreakoutRooms = () => dispatch(navigateTo({ history, path: '/round-table/g1qOwv0WIbIRfggXXWA1' }));
    return (
        <>
            <RouteButton className="btn" onClick={routeToBreakoutRooms}>
                Enter the Waiting Room
            </RouteButton>
        </>
    );
};
export default WaitingRoomButton;
