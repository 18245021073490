import { createReducer } from 'redux-act';
import { fetchTracks, fetchTracksFailed, fetchTracksSuccess, resetState } from 'modules/actions';

import { IPresenter } from 'modules/schemas';

export interface ISession {
    capacity: number;
    description: string;
    durationMinutes: number;
    id: string;
    presenters: IPresenter[];
    roomUuid?: string;
    title: string;
    utcStartTimeMillis: number;
    type: string;
}

export interface ITrack {
    durationMinutes: number;
    id: string;
    imageUrl: string;
    infoText: string;
    longDescription: string;
    order: number;
    roomUuid: string;
    sessions: ISession[];
    title: string;
    utcStartTimeMillis: number;
    _startDate: string;
    type: string;
}

export type TracksReducerState = {
    list: ITrack[];
    isFetched: boolean;
    isFetching: boolean;
};

const initialState: TracksReducerState = {
    list: [],
    isFetched: false,
    isFetching: false,
};

export const tracks = createReducer<TracksReducerState>({}, initialState);
tracks.on(fetchTracks, (state: TracksReducerState) => ({
    ...state,
    isFetching: true,
}));

tracks.on(fetchTracksSuccess, (state: TracksReducerState, payload: ITrack[]) => {
    return {
        ...state,
        isFetching: false,
        isFetched: true,
        list: payload,
    };
});

tracks.on(fetchTracksFailed, () => initialState);

tracks.on(resetState, () => initialState);
