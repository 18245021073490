import { createAction } from 'redux-act';
import { ISponsor, ISponsorType } from 'modules/reducers';

export const fetchSponsors = createAction('Fetch Sponsors');
export const fetchSponsorsSuccess = createAction<ISponsor[]>('Fetch Sponsors Success');
export const fetchSponsorsFailed = createAction('Fetch Sponsors Failed');
export const createRequestFollowUp = createAction<object>('Fetch RequestFollowUp Success');
export const createRequestSucces = createAction<object>('Fetch  RequestFollowUp Failed');
export const setSponsor = createAction<string>('set sponsor');
export const getSponsorsTypes = createAction('Get Sponsors Types');
export const setSponsorTypes = createAction<ISponsorType[]>('Get Sponsors Types');
export const registerSponsorBoothUser = createAction('register sponsor user');
export const removeSponsorBoothUser = createAction('register sponsor user');
export const setSponsorBoothUserPresenceList = createAction<any>('set sponsor booth user presence list');
