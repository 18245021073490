import { createAction } from 'redux-act';
import { IPolls, IPollResults } from '../reducers';

export const fetchPolls = createAction('Fetch All polls');
export const fetchPollSuccess = createAction<IPolls[]>('Polls successfully fetched');
export const fetchPollFailed = createAction('Polls failed to fetch');
export const selectedPollIdResponse = createAction<any>('set pollId response');
export const showPollModal = createAction<boolean>('show hide poll modal');
export const setCurrentModalType = createAction<string | null>('set current poll modal type');
export const fetchPollResult = createAction<{ isAutomatic: boolean }>('fetch current poll modal results');
export const fetchPollResultSuccess = createAction<IPollResults[]>('Polls results successfully fetched');
export const subscribeTriggeredData = createAction('set current poll modal type');
export const subscribePollGroupListner = createAction('set current poll modal type group');
export const unsubscribeTriggeredData = createAction('set current poll modal type');
export const selectFormId = createAction<any>('Set seleted form id');
export const setDocumentId = createAction<string | null>('Set doc id');
export const setPollAnswered = createAction<boolean>('Set answer');
export const fetchPollResultLive = createAction('fetch current poll modal results');
export const removePollResultLive = createAction('remove current poll modal results');
export const fetchPollResultLiveSuccess = createAction<IPollResults[]>('Polls results live successfully fetched');
export const closeDisplayResuls = createAction('close display results');

export const fetchGroupData = createAction('fetch Group Data');
export const setGroupData = createAction<any>('set Group Data');
export const setPollExclusiveId = createAction<string | null>('set Poll Exclusive Id');
export const fetchAllPollResult = createAction<any>('fetch All Poll Results');
export const serUserPollGroupTag = createAction<string>('set Poll Exclusive Tag');
export const subscribePollListner = createAction('set current poll data type');
export const stopPoll = createAction('stop poll');
export const setEventGroupData = createAction<any>('set group data')
