import { SagaIterator } from 'redux-saga';
import { call, select, take, cancel, cancelled, fork, put } from 'redux-saga/effects';
import * as selectors from '../selectors';
import * as actions from 'modules/actions';
import {
    getUserPresenceListner,
    getUsersPresenceInRoom,
} from 'modules/utils/Firebase/UserManagement/BreakoutRoomUserPresence';
import {
    getBreakoutGroupDetailListener,
    getPollsUserGroupListner,
    getSelectedBreakoutGroupDetailApi,
} from 'modules/utils/Firebase/BreakoutGroups';
import moment from 'moment-timezone';
import * as Sentry from '@sentry/react';

export const UserRoomPresenseListner = function* (): SagaIterator {
    const connectionTask = yield fork(UserRoomPresenseListnerSaga);
    // wait for the user stop action
    yield take([actions.removeUserFromRoom, actions.signOutUser]);

    yield cancel(connectionTask);
};

const UserRoomPresenseListnerSaga = function* (): SagaIterator {
    try {
        const organizationId = yield select(selectors.getOrganizationId);
        const eventId = yield select(selectors.getEventId);
        const roomId = yield select(selectors.getRoomId);
        const uid = yield select(selectors.getUserUid);
        const obj = {
            organizationId,
            eventId,
            roomId,
            uid,
        };

        const channel = yield call(getUserPresenceListner, obj);
        try {
            while (true) {
                const result = yield take(channel);

                if (result && result?.groupId) {
                    const users = yield call(getUsersPresenceInRoom, obj, result?.groupId);

                    yield put(
                        actions.setLiveGroupDetail({
                            ...result,
                            users,
                            isCalledInToRoom: true,
                            groupName: result.name,
                        }),
                    );
                } else {
                    yield put(actions.removeBreakoutGroup());
                }
            }
        } catch (e) {
            console.warn(e);
        } finally {
            // unregister listener if the saga was cancelled
            if (yield cancelled()) {
                channel.close();
            }
        }
    } catch (error) {
        console.error(error);
        Sentry.captureException(error);
    }
};

export const addBreakoutGroupsListner = function* (): SagaIterator {
    while (yield take(actions.getGroupDetail)) {
        const connectionTask = yield fork(BreakoutGroupListnerSaga);
        // wait for the user stop action
        yield take([actions.removeUserFromRoom, actions.signOutUser]);

        yield cancel(connectionTask);
    }
};

export const BreakoutGroupListnerSaga = function* (): SagaIterator {
    try {
        const organizationId = yield select(selectors.getOrganizationId);
        const eventId = yield select(selectors.getEventId);
        const groupId = yield select(selectors.getRoomId);
        const obj = {
            groupId,
            organizationId,
            eventId,
        };

        const channel = yield call(getBreakoutGroupDetailListener, obj);
        try {
            while (true) {
                const result = yield take(channel);

                if (result && result?.id) {
                    const obj = {
                        groupId: result.id,
                        groupDuration: result.duration,
                        groupExpiryTimeStamp: result.groupExpiryTimeStamp,
                        isCalledBackFromRoom: result?.isCalledBackFromRoom ?? false,
                        groupName: result.name,
                    };

                    yield put(actions.setLiveGroupDetail(obj));
                }
            }
        } catch (e) {
            console.warn(e);
        } finally {
            // unregister listener if the saga was cancelled
            if (yield cancelled()) {
                channel.close();
            }
        }
    } catch (error) {
        console.error(error);
        Sentry.captureException(error);
    }
};

export const addBreakoutUserGroupListner = function* (): SagaIterator {
    while (yield take(actions.registerRoomUser)) {
        const connectionTask = yield fork(BreakoutUserGroupListnerSaga);
        // wait for the user stop action
        yield take([actions.removeUserFromRoom, actions.signOutUser]);

        yield cancel(connectionTask);
    }
};

export const BreakoutUserGroupListnerSaga = function* (): SagaIterator {
    try {
        const organizationId = yield select(selectors.getOrganizationId);
        const eventId = yield select(selectors.getEventId);
        const profile = yield select(selectors.getUserProfile);
        const roomId = yield select(selectors.getRoomId);
        const obj = {
            organizationId,
            eventId,
            email: profile.email,
            roomId,
            uid: '',
        };

        const channel = yield call(getPollsUserGroupListner, obj);
        try {
            while (true) {
                const result = yield take(channel);

                if (result && result?.id) {
                    const isGroupActive = moment().isBefore(moment(result?.groupExpiryTimeStamp));
                    if (isGroupActive && result?.isCalledInToRoom) {
                        const users = yield call(getUsersPresenceInRoom, obj, result?.id);
                        yield put(
                            actions.setLiveGroupDetail({
                                ...result,
                                users,
                                isCalledInToRoom: true,
                                groupExpiryTimeStamp: result.groupExpiryTimeStamp,
                                isCalledBackFromRoom: false,
                                groupName: result.name,
                            }),
                        );
                    } else if (result?.isCalledBackFromRoom) {
                        const obj = {
                            ...result,
                            groupId: result.id,
                            groupDuration: result.duration,
                            groupExpiryTimeStamp: result.groupExpiryTimeStamp,
                            isCalledBackFromRoom: result?.isCalledBackFromRoom ?? false,
                            groupName: result.name,
                            users: [],
                        };

                        yield put(actions.setLiveGroupDetail(obj));
                    }
                }
            }
        } catch (e) {
            console.warn(e);
        } finally {
            // unregister listener if the saga was cancelled
            if (yield cancelled()) {
                channel.close();
            }
        }
    } catch (error) {
        console.error(error);
        Sentry.captureException(error);
    }
};

export const fetchBreakoutGroupFetailSaga = function* (): SagaIterator {
    try {
        const organizationId = yield select(selectors.getOrganizationId);
        const eventId = yield select(selectors.getEventId);
        const groupId = yield select(selectors.getRoomId);
        const obj = {
            groupId,
            organizationId,
            eventId,
        };

        const result = yield call(getSelectedBreakoutGroupDetailApi, obj);

        if (result && result?.id) {
            const obj = {
                ...result,
                groupId: result.id,
                groupDuration: result.duration,
                isCalledInToRoom: false,
                isCalledBackFromRoom: false,
                groupName: result.name,
                users: [],
            };

            yield put(actions.setBreakoutGroupDetail(obj));
        }
    } catch (error) {
        console.error(error);
        Sentry.captureException(error);
    }
};
