import { Components } from './Api';
import firebase from 'firebase/app';

interface IAttendeeDetails {
    organizationId: string;
    eventId: string;
    roomId: string;
    isRoomOpen?: boolean;
    roomEndTime?: number | null;
    additionalMinutes?: number | null;
    moderatorExitTime?: number | null;
}
export const BreakoutRoomExtendMeeting = async (props: IAttendeeDetails) => {
    const connectionRef = Components.reference(props.organizationId, props.eventId).doc(props.roomId);
    const updateValues = {
        roomEndTime: props.roomEndTime ? props.roomEndTime : firebase.firestore.FieldValue.delete(),
        additionalMinutes: props.additionalMinutes ? props.additionalMinutes : firebase.firestore.FieldValue.delete(),
    };
    await connectionRef.update(updateValues);
};

export const BreackoutChangeStatus = async (props: IAttendeeDetails) => {
    const connectionRef = Components.reference(props.organizationId, props.eventId).doc(props.roomId);
    const updateValues = {
        isRoomOpen: !!props.isRoomOpen,
    };
    await connectionRef.update(updateValues);
};
