import React from 'react';
import { useDispatch } from 'react-redux';
import { FormHeader, Insignia, Space } from '../common/index';
import * as actions from "modules/actions"
import { Mode } from "../constants/constants"
import {
    Button,
    Heading,
    Horizontal,
    Vertical,
    Text,
    Line
} from '../style';

const FirstTimeMode = () => {
    const dispatch = useDispatch()

    const submitBtn = () => {
        dispatch(actions.authMode(Mode.profile))
        // dispatch(actions.isProceedClick(true))
    }

    return (
        <React.Fragment>
            <FormHeader title={null} />
            <Space height={0.5} />
            <Vertical className="center text-center">
                <Insignia success className="small" />
                <Space height={0.5} />
                <Heading size={18} bold>
                    Welcome To Shared Studios
                </Heading>
                <Text size={16}>
                    Congratulations, you have successfully created your account. Welcome to Shared Studios !
                </Text>
                <Space height={2} />
                <Insignia profile className="small" />
                <Space height={0.5} />
                <Heading size={18} bold>{`This is how you appear. Let's fix that. `}</Heading>
                <Text size={16}>
                    It only takes a minute and it will greatly increase your chances to be invited by other participants
                    and have your invitations be accepted. Since your profile will be visible to all participants,
                    please only enter information that you want to share and be respectful in your interactions on this
                    platform.
                </Text>
            </Vertical>
            <Space height={2} />
            <Line />
            <Space height={1.5} />
            <Horizontal>
                <Space />
                <Button onClick={submitBtn}>Proceed</Button>
                <Space />
            </Horizontal>
        </React.Fragment>
    );
}

export default FirstTimeMode