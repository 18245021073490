import { IStoreState } from 'modules/types';
// import { createSelectorCreator, defaultMemoize } from 'reselect';
// import isEqual from 'lodash/isEqual';

export const getScheduleList = (state: IStoreState) => state.schedule.list;
export const missingScheduleList = (state: IStoreState) => state.schedule.fetching;
export const getRoomLimitList = (state: IStoreState) => state.schedule.roomLimit;
export const getCurrentRoomUserCapacity = (state: IStoreState) => state.schedule.currentRoom;
export const missingScheduleListFetch = (state: IStoreState) =>
    !state.schedule.fetching && state.schedule.list.length === 0;
export const getRoomName = (roomId: string) => (state: IStoreState) =>
    state.schedule.list && state.schedule.list.length > 0 && fetchRoomName(state.schedule.list, roomId);

export const getRoom = (roomId: string) => (state: IStoreState) =>
    state.schedule.list?.find((value) => value.id === roomId);

function fetchRoomName(array: any, roomId: string) {
    const findRoom = array.find((value: any) => value.id === roomId);
    if (findRoom) return findRoom;
    return '';
}
export const getBlockedRooms = (state: IStoreState) => state.schedule.blockedUsers;

// const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

// export const getRoomDetail = createDeepEqualSelector(
//     [
//         // Usual first input - extract value from `state`
//         (state: IStoreState) => state.schedule.list,
//         // Take the second arg, `category`, and forward to the output selector
//         (_state: IStoreState, roomId: string) => roomId,
//     ],
//     (list, roomId) => list?.find((value) => value.id === roomId),
// );

export const getPresenterList = (state: IStoreState) => {
    const presenters: Array<{}> = [];
    const scheduleList = state.schedule.list;
    scheduleList.forEach((schedule) => {
        const schedulePresenters = schedule.presenters;
        if (schedulePresenters.length > 0) presenters.push(schedulePresenters);
    })
    return presenters;
}
