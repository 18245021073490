import { AdminEventPeople, UserLookup } from 'modules/utils/Firebase/Api';
import { eventChannel } from 'redux-saga';
import firebase from 'modules/utils/Firebase';

interface IAttendeeDetails {
    organizationId: string;
    eventId: string;
    userId: string;
}

export const getLookup = (props: IAttendeeDetails) => {
    const connectionRef = UserLookup.reference(props.userId, props.organizationId, props.eventId);

    const connectionRoomListner = eventChannel((emit) => {
        const unsubscribe = connectionRef.onSnapshot((querySnapshot: firebase.firestore.DocumentSnapshot) => {
            if (querySnapshot.exists) {
                return emit(querySnapshot.data());
            } else return emit(false);
        });
        return () => {
            unsubscribe();
        };
    });
    return connectionRoomListner;
};
interface IAdminPeoplDetails {
    organizationId: string;
    email: string;
}
export const getAdminPeopleUser = async (props: IAdminPeoplDetails) => {
    const connectionRef = AdminEventPeople.orgReference(props.organizationId);
    const peopleEmailRef = connectionRef.where('email', '==', props.email);
    const snapshot = await peopleEmailRef.get();
    let isAccessAloowed = false;
    snapshot.forEach((doc) => {
        const data = doc.data();
        if (!data.isDeleted) {
            isAccessAloowed = true;
        }
    });

    return isAccessAloowed;
};

export const getAdminPeopleUserDetail = async (props: IAdminPeoplDetails) => {
    const connectionRef = AdminEventPeople.orgReference(props.organizationId);
    const peopleEmailRef = connectionRef.where('email', '==', props.email);
    const snapshot = await peopleEmailRef.get();
    let adminPeopleDocId = '';
    snapshot.forEach((doc) => {
        adminPeopleDocId = doc.id;
    });

    return adminPeopleDocId;
};
