

import { Insignia } from 'components/Login/common';
import { Button } from 'components/Login/inputs';
import React from 'react';
import { Dropdown, ButtonGroup } from 'react-bootstrap';

interface MediaDevicesProperties extends React.PropsWithChildren<any> {
    mediaDevices: MediaDeviceInfo[];
    label: React.ReactNode;
    onClick?: () => void;
    selectedMediaDevice?: string;
    selectMediaDevice?: (deviceId: string) => void;
}

export function CaptureMediaDevices(properties: MediaDevicesProperties) {
    const { label, mediaDevices, onClick, selectedMediaDevice, selectMediaDevice } = properties;
    const items = mediaDevices.map(mapMediaDeviceItems);

    return (
        <Dropdown as={ButtonGroup} onSelect={onSelect}>
            <Button onClick={onClick}>{label}</Button>
            <Dropdown.Toggle split as={Button} />
            <Dropdown.Menu>{items.map(mapItem)}</Dropdown.Menu>
        </Dropdown>
    );
    // scope actions
    function onSelect(eventKey: any) {
        if (selectMediaDevice) {
            const deviceId = eventKey as string;
            if (deviceId) selectMediaDevice(deviceId);
        }
    }

    function mapItem({ content, ...item }: any, key: number) {
        return (
            <Dropdown.Item {...item} key={key}>
                <Insignia success className="tiny" /> <span>{content.label}</span>
            </Dropdown.Item>
        );
    }
    function mapMediaDeviceItems(device: MediaDeviceInfo) {
        const content = getContent(device.label);
        return {
            active: device.deviceId === selectedMediaDevice,
            content,
            eventKey: device.deviceId,
        };
    }
}

interface ContentInterface {
    detail: React.ReactNodeArray;
    label: string;
}
function getContent(label: string) {
    const content: ContentInterface = { detail: [], label };
    content.detail = details(label).map(each, content);
    return content;
    // scope actions
    function details(text: string) {
        return text.match(/\(\s?([\w\s\n\t.\-,!@#$%^&*:;?/"'_|+=<>\u9999]+)\s?\)/g) || [];
    }
    function each(this: ContentInterface, text: string, key: number) {
        this.label = this.label.replace(text, '').trim();
        return (
            <div className="detail" key={key}>
                {text.slice(1, -1).trim()}
            </div>
        );
    }
}