import styled, { css } from 'styled-components/macro';

export const RegularButtonStyled = css`
    align-items: center;

    background-image: linear-gradient(105deg, #00d7ef 0%, #00afef 100%);
    background-position: center center;
    background-size: 120% 120%;

    color: white;
    border: none;
    border-radius: 100px;

    cursor: pointer;

    display: inline-flex;

    font-size: 12px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;

    justify-content: center;
    margin-right: 15px;
    outline: none;

    padding: 4px 25px 4px 25px;

    text-decoration: none;
    text-transform: uppercase;

    user-select: none;

    &:hover,
    &:active {
        outline: none;
        text-decoration: none;
        color: white;
    }
    &:focus {
        outline: initial;
        text-decoration: underline;
    }
    &:active {
        filter: brightness(90%);
    }
`;

const disabledState = css`
    background: #9ca3bd;
    &:hover {
        color: var(--gray-dark-color);
    }
`;

export const RegularButton = styled.button`
    ${RegularButtonStyled};
    ${(props) => props.disabled && disabledState};
`;

export default RegularButton;
