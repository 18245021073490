import { SagaIterator } from 'redux-saga';
import { call, select, take, cancel, fork, cancelled, put } from 'redux-saga/effects';
import * as actions from 'modules/actions';
import * as selector from 'modules/selectors/user';
import {
    addUserNetworkLoungeConnection,
    getUserNetworkLoungeConnected,
    removeUserNetworkLoungeConnection,
    getNetworkingLoungeUserData,
} from 'modules/utils/Firebase/UserManagement/NetworkingLoungeUserPresence';
import { getEventId, getOrganizationId } from 'modules/selectors';
import * as Sentry from '@sentry/react';

export const userPresenceInNetworkLoungeConnection = function* (): SagaIterator {
    while (yield take(actions.registerNetworkLoungeUser)) {
        const uid = yield select(selector.getUserUid);
        const organizationId = yield select(selector.getOrganizationId);
        const eventId = yield select(selector.getEventId);
        const profile = yield select(selector.getUserProfile);

        const obj = {
            organizationId,
            eventId,
            uid,
            name: `${profile.firstName} ${profile.lastName}`,
        };

        yield call(addUserNetworkLoungeConnection, obj);

        const connectionTask = yield fork(RoomUserPresenceListner);
        // wait for the user stop action
        yield take([actions.removeUserFromNetworkLounge, actions.signOutUser]);
        yield cancel(connectionTask);
        yield call(removeUserFromRoomSaga);
    }
};
export const RoomUserPresenceListner = function* (): SagaIterator {
    try {
        const uid = yield select(selector.getUserUid);
        const profile = yield select(selector.getUserProfile);
        const organizationId = yield select(selector.getOrganizationId);
        const eventId = yield select(selector.getEventId);
        const obj = {
            organizationId,
            eventId,
            uid,
            name: `${profile.firstName} ${profile.lastName}`,
        };
        const channel = yield call(getUserNetworkLoungeConnected, obj);
        try {
            while (true) {
                const result = yield take(channel);
                if (!result && uid) {
                    yield call(removeUserNetworkLoungeConnection, obj);
                } else if (result) {
                    yield call(addUserNetworkLoungeConnection, obj);
                }
            }
        } catch (e) {
            console.warn(e);
        } finally {
            // unregister listener if the saga was cancelled
            if (yield cancelled()) channel.close();
        }
    } catch (error) {
        console.error(error);
        Sentry.captureException(error);
    }
};

export const removeUserFromRoomSaga = function* (): SagaIterator {
    const uid = yield select(selector.getUserUid);
    const organizationId = yield select(selector.getOrganizationId);
    const eventId = yield select(selector.getEventId);
    const obj = {
        organizationId,
        eventId,
        uid,
    };

    yield call(removeUserNetworkLoungeConnection, obj);
};

export const networkingLoungeUserPresenceConnection = function* (): SagaIterator {
    while (yield take(actions.setActiveListner)) {
        const connectionTask = yield fork(networkingLoungeUserPresenceListener);
        // wait for the user stop action
        yield take([actions.signOutUser, actions.removeActiveListner]);

        yield cancel(connectionTask);
    }
};

export const networkingLoungeUserPresenceListener = function* (): SagaIterator {
    try {
        const organizationId = yield select(getOrganizationId);
        const eventId = yield select(getEventId);
        const uid = yield select(selector.getUserUid);

        const obj = {
            organizationId,
            eventId,
            uid,
        };
        const channel = yield call(getNetworkingLoungeUserData, obj);
        try {
            while (true) {
                const result = yield take(channel);

                if (result) {
                    yield put(
                        actions.addNetworkingLoungeUserPresenceData({ loungeIds: Object.keys(result), data: result }),
                    );
                } else {
                    yield put(actions.addNetworkingLoungeUserPresenceData(null));
                }
            }
        } catch (e) {
            console.warn(e);
        } finally {
            // unregister listener if the saga was cancelled
            if (yield cancelled()) channel.close();
        }
    } catch (error) {
        console.error(error);
        Sentry.captureException(error);
    }
};
