import { createReducer } from 'redux-act';
import { fetchKeynotes, fetchKeynotesFailed, fetchKeynotesSuccess, resetState } from 'modules/actions';
import { IKeynote } from 'modules/schemas';

export type KeynotesReducerState = {
    fetching: boolean;
    list: IKeynote[];
    card: {
        background: {
            imageUrl: string;
        };
        className: string;
        heading: string;
    };
};

const initialState: KeynotesReducerState = {
    fetching: false,
    list: [],
    card: {
        background: {
            imageUrl: '',
        },
        className: '',
        heading: '',
    },
};

export const keynotes = createReducer<KeynotesReducerState>({}, initialState);
keynotes.on(fetchKeynotes, (state: KeynotesReducerState) => ({
    ...state,
    fetching: true,
}));
keynotes.on(fetchKeynotesSuccess, (state: KeynotesReducerState, payload: IKeynote[]) => ({
    ...state,
    fetching: false,
    list: payload,
}));

keynotes.on(fetchKeynotesFailed, () => initialState);

keynotes.on(resetState, () => initialState);
