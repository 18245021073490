
import { AdminEventRoleDocument, RegistreeEventDocument, Event } from 'modules/utils/Firebase/Api';

export const getAdminEventRole = async (payload: any) => {
    const response = await AdminEventRoleDocument.adminEventRoleRef(payload.email).get();
    return response;
};

export const getUserFromRegistree = async (payload: any) => {
    const response = await RegistreeEventDocument.registreeEventRef(payload.email, payload.organizationId, payload.eventId).get();
    return response;
};


export const getEventRegistree = async (payload: any) => {
    const response = await Event.reference(payload.organizationId, payload.eventId).get();
    return response;
};







