import { createReducer } from 'redux-act';
import { addNetworkingLoungeUserPresenceData } from 'modules/actions';

export type NetworkingLoungeReducerState = {
    loungePresentUser: any;
};

const initialState: NetworkingLoungeReducerState = {
    loungePresentUser: null,
};

export const networkingRoomLounge = createReducer<NetworkingLoungeReducerState>({}, initialState);
networkingRoomLounge.on(addNetworkingLoungeUserPresenceData, (state: NetworkingLoungeReducerState, payload: any) => ({
    ...state,
    loungePresentUser: payload,
}));
