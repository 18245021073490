import React from 'react';
import '../../../node_modules/react-add-to-calendar/dist/react-add-to-calendar.min.css';
import AddToCalendar from 'react-add-to-calendar';
import './Calendar.scss';

export const Calendar = (props: any) => {
    const listItems = [{ google: 'Google' }, { outlookcom: 'Microsoft' }, { apple: 'iCal' }];
    return <AddToCalendar listItems={listItems} {...props} />;
};

export default Calendar;
