import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { Form } from 'react-bootstrap';
import twitter from 'assets/img/icons/twitter.svg';
import linkedin from 'assets/img/icons/linkedin.svg';
import facebook from 'assets/img/icons/facebook.svg';
import instagram from 'assets/img/icons/instagram.svg';
import youtube from 'assets/img/icons/youtube.svg';
import media from 'assets/css/media';
import { useDispatch, useSelector } from 'react-redux';
import { sendInviteEmail } from 'modules/actions';
import {
    getOrganizationSocialLinks,
    getEvent,
    getOrganizationId,
    organizationLoader,
    getEventId,
} from 'modules/selectors';
import { ErrorBoundary } from 'components';
import { EMAIL_PATTERN } from 'modules/utils/CommonService';

// eslint-disable-next-line
// const EMAIL_PATTERN = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const socialIcons: { [key: string]: string } = {
    twitter,
    linkedin,
    facebook,
    instagram,
    youtube,
};

const Wrapper = styled.div`
    background: #192150;
    width: 100%;
    min-height: 84px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #ffffff;
    padding: 0 30px 0 37px;
    @media (max-width: ${media.desktop}) {
        flex-direction: column;
        padding: 20px;
        border-radius: 0;
        margin-top: 40px;
    }
`;

const FooterForm = styled(Form)`
    position: relative;
    width: inherit;
    display: inline-flex;
    align-self: center;
    align-items: center;
    flex-grow: 2;
    && {
        flex: 1;
    }
    @media (max-width: ${media.desktop}) {
        align-self: flex-start;
        margin-bottom: 20px;
    }
`;

const FormFieldset = styled.div`
    display: inline-flex;
    align-self: center;
    align-items: center;
    position: relative;
    width: 286px;
    max-width: 50%;
`;
const FromLabel = styled(Form.Label)`
    && {
        font-size: 15px;
        line-height: 17px;
        margin-right: 16px;
    }
`;
const FromInput = styled(Form.Control)`
    height: 40px;
    border-radius: 20px;
    border: none;
`;

const ShareSection = styled.div`
    @media (max-width: ${media.desktop}) {
        width: 100%;
    }
`;
const ShareTitle = styled.div`
    font-size: 15px;

    @media (max-width: ${media.desktop}) {
        margin: 0 0 10px 0;
    }
`;
const ShareLinks = styled.div`
    flex-grow: 1;
    margin-top: 7px;
    & > a + a {
        margin-left: 17px;
    }
`;
const ShareLink = styled.a`
    @media (max-width: ${media.desktop}) {
        margin: 0 17px 0 0;
    }
`;
const ShareIcon = styled.img`
    max-width: 25px;
    @media (max-width: ${media.desktop}) {
        margin: 0;
    }
`;

const SendIcon = styled.button`
    background: transparent;
    border: none;
    position: absolute;
    right: 5px;
    color: #192150;
    outline: none;
    svg {
        fill: #192150;
    }
    &:active,
    &:focus,
    &:hover {
        outline: none;
    }
`;

export const Footer = () => {
    const dispatch = useDispatch();
    const [emailValid, setEmailValid] = useState(false);
    const event = useSelector(getEvent);
    const [emailStatus, setEmailStatus] = useState(event.invite.placeholder);
    const isLoadingData = useSelector(organizationLoader);
    const socialinks = useSelector(getOrganizationSocialLinks);
    const organizationID = useSelector(getOrganizationId);
    const eventID = useSelector(getEventId);
    const onSubmit = (e: HTMLFormElement) => {
        e.preventDefault();
        const element = e.currentTarget;
        const form = new FormData(e.currentTarget);
        const email = (form.get('email') as string) || '';
        setEmailValid(Boolean(EMAIL_PATTERN.test(email)));
        const body = {
            eventID,
            organizationID,
            email,
            reference: '',
        };
        if (emailValid) {
            dispatch(sendInviteEmail(body));
            setEmailStatus('Email Sent');
            element.reset();
            setTimeout(() => {
                setEmailStatus('email@example.com');
                setEmailValid(false);
            }, 1000);
        }
    };
    const onInput = (e: HTMLFormElement) => {
        const form = new FormData(e.currentTarget);
        const email = (form.get('email') as string) || '';
        setEmailValid(Boolean(EMAIL_PATTERN.test(email)));
    };

    const renderLinks = () => {
        return Object.keys(socialinks).map((key: string, index: number) => {
            return (
                <ShareLink href={socialinks[key]} rel="noopener noreferrer" target="_blank" key={index}>
                    <ShareIcon src={socialIcons[key]} />
                </ShareLink>
            );
        });
    };

    return (
        <ErrorBoundary>
            <Wrapper>
                <FooterForm onSubmit={onSubmit} onInput={onInput}>
                    <FromLabel htmlFor="invite-email">{!isLoadingData && event.invite.label}</FromLabel>
                    <FormFieldset>
                        <FromInput type="email" id="invite-email" placeholder={emailStatus} name="email" />
                        {emailValid && (
                            <SendIcon className="send-icon">
                                <svg width="24" height="24" viewBox="0 0 24 24" focusable="false" role="presentation">
                                    <g fill="currentColor">
                                        <path d="M6.978 19.477c0 1.371 1.645 2.042 2.577 1.05L21.558 7.723C22.518 6.695 21.803 5 20.409 5H3.496C2.178 5 1.505 6.607 2.418 7.572l4.56 4.828v7.077zm1.993-1.265v-6.627L3.856 6.169c.303.322.078.857-.36.857h15.963L8.971 18.212z" />
                                        <path d="M8.416 12.902l4.01-1.95a1.04 1.04 0 0 0 .474-1.365.982.982 0 0 0-1.324-.489l-4.01 1.95c-.497.242-.71.853-.475 1.364a.982.982 0 0 0 1.325.49z" />
                                    </g>
                                </svg>
                            </SendIcon>
                        )}
                    </FormFieldset>
                </FooterForm>
                <ShareSection>
                    <ShareTitle>{!isLoadingData && event.social && event.social.label}</ShareTitle>
                    <ShareLinks>{renderLinks()}</ShareLinks>
                </ShareSection>
            </Wrapper>
        </ErrorBoundary>
    );
};
