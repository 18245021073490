/* eslint-disable no-sequences */
import { createReducer } from 'redux-act';
import { setLobbySuccess, setLoungeEmpty } from 'modules/actions';
import { IEventHeaderProps } from 'modules/schemas';

interface ISocialTags {
    label: string;
    twitter: string;
    facebook: string;
    linkedin: string;
    youtube: string;
    instagram: string;
}
interface IKeynotesProps {
    activeKeynoteText: string;
    expiredKeynoteText: string;
    lobbyDescription?: string;
    _startDate?: string;
    utcStartTimeMillis?: number;
    lobbyTitle?: string;
    hasGradient?: boolean;
    gradientStartFrom?: string;
    gradients?: string[];
    // lobbyTitleBackground?: string;
    showSpeakers?: string;
    showTimer: boolean;
    card?: {
        background?: {
            imageUrl?: string;
        };
        description?: string;
        durationMinutes?: number;
    };
    relativePath: string;
    roomIds?: string[];
    roomAutomaticJoin?: any;
    showKeynoteSection: boolean;
    automaticJoin?: boolean;
}
export interface ILobby {
    header?: IEventHeaderProps;
    social?: ISocialTags;
    keynotes?: IKeynotesProps;
    sponsors: {
        action: {
            label: string;
        };
        page: {
            title: string;
            bgImage: string;
        };
    };
    // sponsor: {
    //     page: {
    //         followUp: {
    //             label: string;
    //         };
    //         visitWebsite: {
    //             label: string;
    //         };
    //         sponsorLoungeBtn: {
    //             label: string;
    //         };
    //     };
    //     action: { label: string };
    // };
    conference?: {
        sponsorTypeText: string;
        isChatActive: boolean;
        sponsorDisplayInKeynote: string;
    };
    invite: {
        label: string;
        placeholder: string;
        showInvitee: boolean;
    };
    action: {
        label: string;
    };
    schedule: {
        page: {
            title: string;
        };
    };
    page: { title: string };
    // lounge: {
    //     capacity: number;
    //     isLimitedCapacity: boolean;
    //     meetingTables: {
    //         capacity: number;
    //         minTables: number;
    //     };
    // };
}

export interface ILounge {
    capacity: number;
    isLimitedCapacity: boolean;
    meetingTables: {
        minTables: number;
        capacity: number;
    };
    bgImage?: string;
    bgMusic?: string;
    id?: string;
    cardImg?: string;
    groupDiscuss: {
        maxGroupParticipant: number;
        isLimitDiscussGroup?: boolean;
        // isRandomMatch: boolean;
        limitDicussionGroupTime?: number;
    };
}

export type LobbyReducerState = { lobby: ILobby; isFetching: boolean; lounge?: ILounge | null };

const initialState: LobbyReducerState = {
    isFetching: true,
    lobby: {
        page: {
            title: 'Main Lobby',
        },
        keynotes: {
            expiredKeynoteText: 'The Event has Ended',
            activeKeynoteText: 'Join the Presentation',
            showTimer: true,
            relativePath: '',
            roomIds: [],
            showKeynoteSection: true,
        },
        sponsors: {
            page: {
                title: 'Sponsors',
                bgImage: '',
            },
            action: {
                label: 'Meet our Sponsors',
            },
        },
        // sponsor: {
        //     page: {
        //         visitWebsite: {
        //             label: 'VISIT WEBSITE',
        //         },
        //         followUp: {
        //             label: 'REQUEST FOLLOW-UP',
        //         },
        //         sponsorLoungeBtn: {
        //             label: 'SPONSOR LOUNGE',
        //         },
        //     },
        //     action: {
        //         label: 'Meet our Sponsors',
        //     },
        // },
        invite: {
            label: 'Invite a colleague',
            placeholder: 'email@example.com',
            showInvitee: false,
        },
        action: {
            label: 'Back to Lobby',
        },
        schedule: {
            page: {
                title: 'Schedule',
            },
        },
        // lounge: {
        //     capacity: 1,
        //     isLimitedCapacity: true,
        //     meetingTables: {
        //         capacity: 1,
        //         minTables: 1,
        //     },
        // },
    },
};

export const lobby = createReducer<LobbyReducerState>({}, initialState);

lobby.on(setLobbySuccess, (state: LobbyReducerState, payload: any) => ({
    ...state,
    lobby: Object.keys(payload).length > 0 ? { ...payload } : { ...state.lobby },
    isFetching: false,
}));

// lobby.on(setLoungeSucces, (state: LobbyReducerState, payload: any) => ({
//     ...state,
//     lounge: payload && Object.keys(payload).length > 0 ? { ...payload } : null,
// }));

lobby.on(setLoungeEmpty, (state: LobbyReducerState, payload: any) => ({
    ...state,
    lounge: payload,
}));

