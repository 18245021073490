import React from 'react';

export const BreakoutRecorderConfig = {
    settings: {
        layout: {
            config: {
                header: { display: true },
                footer: { display: true },
                navbar: { display: true },
            },
        },
    },
    isPrivate: false,
    eventName: 'breakout_recorder',
    routes: [
        {
            path: '/record/breakout',
            component: React.lazy(() => import('./RecorderRoute')),
        },
    ],
};
