import React from 'react';
import is from 'classnames';
import useClickOutside from 'react-cool-onclickoutside';
import firebase from 'firebase/app';
import {
    CheckIcon,
    CloseIcon,
    EditIcon,
    FailureIcon,
    LockIcon,
    PreviewingIcon,
    PreviewIcon,
    UserIcon,
} from 'components/Icons';
import {
    FailureNode,
    InsigniaNode,
    MenuContainer,
    Vertical,
    NoteNode,
    ToccaLogo,
    ToccaFooterLogo,
    Heading,
    SpaceNode,
} from '../style';
import flatten from 'lodash/flatten';
import get from 'lodash/get';
import first from 'lodash/first';
import last from 'lodash/last';
import words from 'lodash/words';
import toLower from 'lodash/toLower';
import { FirebaseFailure } from '../types';
import { firebaseErrors } from '../constants/constants';
import defaultTo from 'lodash/defaultTo';
import isEmpty from 'lodash/isEmpty';
import { EditThumbnail } from '../fieldset/Profile/EditThumbnail';
import { Capture } from '../fieldset/Profile/Capture';
interface NodeProperties extends React.PropsWithChildren<any & SymbolProperties> {
    height?: number;
    width?: number;
    size?: number;
}

export interface FormHeaderProperties {
    title?: React.ReactNode;
    body?: React.ReactNode;
    failure?: FirebaseFailure;
    hasPoweredByLogo?: boolean;
}

interface SymbolProperties extends React.PropsWithChildren<any> {
    failure?: boolean;
    profile?: boolean;
    security?: boolean;
    success?: boolean;
    user?: boolean;
    errorNoteColor?: string;
}

interface MenuProperties extends React.PropsWithChildren<any> {
    menu: {
        type: 'show' | 'hide';
        onClick: (string: 'show' | 'hide') => void;
    };
    error?: {
        message: string;
    };
}

interface ValidationProperties extends React.PropsWithChildren<any> {
    error?: {
        message: string;
    };
    preview?: {
        type: 'show' | 'hide';
        onClick: (string: 'show' | 'hide', event: React.MouseEvent<SVGElement>) => void;
    };
}

export const Space: React.FC<NodeProperties> = SpaceNode;

export const Failure: React.FC<NodeProperties> = FailureNode;

export const Notation: React.FC<NodeProperties> = NoteNode;

export const createFailure = (name: firebaseErrors, message?: string) => Promise.reject(createError(name, message));

const AVATAR_BUCKET: string = process.env.REACT_APP_TOCCA_AVATAR_BUCKET || '';

export function formCheck(data: any): { [key: string]: any } {
    return {
        avatar: data.avatar,
        companyName: data.companyName,
        description: data.description,
        // email: data.email,
        firstName: data.firstName,
        jobTitle: data.jobTitle,
        lastName: data.lastName,
        linkedin: data.linkedin,
        twitter: data.twitter,
        workLocation: data.workLocation,
    };
}

function onFailure(error: FirebaseFailure) {
    console.error(`useProvider -  code: ${error?.code} - message: ${error?.message}`);
    modeFailure(error);
    modeLoading(false);
}

export const loadProfile = () => documentReference().get().then(readSnapshot).catch(onFailure);

export function avatarUrl(url: string) {
    // url = url.startsWith('https://') ? url : url.startsWith(AVATAR_BUCKET) ? url : `${AVATAR_BUCKET}/${url}`;
    url = `${AVATAR_BUCKET}/${url}`;
    return firebase.storage().refFromURL(url).getDownloadURL();
}

export function createError(code: string, message?: string): FirebaseFailure {
    return {
        code,
        message: message || 'Profile error',
        name: 'FirebaseError',
    };
}

export function documentReference() {
    const currentUser = firebase.auth().currentUser;
    if (currentUser === null)
        return {
            get: () => createFailure(firebaseErrors.userNotFound),
            set: () => createFailure(firebaseErrors.userNotFound),
        };
    return firebase.firestore().collection('Profiles').doc(currentUser.uid);
}

async function readSnapshot(snapshot: firebase.firestore.DocumentSnapshot) {
    const data = { ...defaultTo(snapshot.data(), {}) };
    data.uid = firebase.auth().currentUser?.uid;
    if (isEmpty(data.email)) data.email = firebase.auth().currentUser?.email;
    if (data.avatar)
        try {
            data.avatar = await avatarUrl(data.avatar);
        } catch (error) {
            console.warn('avatar: ', data.avatar);
        }
    return data;
}

export function modeFailure(failure?: FirebaseFailure) {
    console.error('modeFailure: ', failure);
}

export function modeLoading(loading: boolean, deleteDpLoading?: boolean) {
    console.log('modeLoading', loading, deleteDpLoading);
    if (deleteDpLoading !== undefined) {
        // tocca.dispatch(tocca.actions.registrationUI({ indicator: { loading, deleteDpLoading } }));
    } else {
        // tocca.dispatch(tocca.actions.registrationUI({ indicator: { loading } }));
    }
}

export function pair(...fragments: any[]): any[] | null {
    const characters = flatten(fragments.map(toLower).map(words));
    return characters.length > 1 ? [first(characters), last(characters)] : null;
}

export function initials(properties: any) {
    return (
        pair(properties?.firstName, properties?.lastName) ||
        pair(properties?.fullName) ||
        pair(properties?.name) ||
        []
    ).map(first);
}

export function Invalid({ className, children, style, ...properties }: ValidationProperties) {
    return (
        <Vertical className={is(className, 'relative', 'note')} style={style}>
            {children}
            <PreviewNote {...properties} />
            <InvalidNote {...properties} />
        </Vertical>
    );
}

export function InvalidNote(properties: ValidationProperties) {
    if (properties?.error) {
        return (
            <React.Fragment>
                {properties.hideFailureIcon && null}
                {properties?.messageOnly !== true && !properties.hideFailureIcon && (
                    <FailureIcon className="glyph invalid" />
                )}
                <Failure>{properties?.error.message}</Failure>
            </React.Fragment>
        );
    }
    return null;
}

export const Insignia = ({
    children,
    className,
    failure,
    profile,
    security,
    success,
    user,
    ...properties
}: SymbolProperties) => (
    <InsigniaNode className={is({ success, failure, user, profile, security }, className)} {...properties}>
        {(user === true && <UserIcon style={{ color: 'white' }} />) ||
            (profile === true && <UserIcon />) ||
            (success === true && <CheckIcon />) ||
            (failure === true && <FailureIcon />) ||
            (security === true && <LockIcon />)}
        {children}
    </InsigniaNode>
);

export function FormHeader(properties: FormHeaderProperties) {
    const title: React.ReactNode = properties?.title;
    // const failure = tocca.select(tocca.selectors.AuthFailure);
    // if (failure) {
    //     title = <Note failure>{failure}</Note>;
    // }
    return (
        <React.Fragment>
            <Space height={2} />
            <ToccaLogo />
            {properties.hasPoweredByLogo && <ToccaFooterLogo />}
            <Space />
            {title && <Heading>{title}</Heading>}
            {properties?.body}
        </React.Fragment>
    );
}

export const Editable = (properties: MenuProperties) => {
    const { type, onClick } = properties.menu;
    const reference = useClickOutside(() => onClick('show'), { ignoreClass: 'dismiss-off' });

    return (
        <React.Fragment>
            <div
                className="glyph pointer center pin dismiss-off"
                style={{
                    position: 'absolute',
                    bottom: '-2px',
                    right: '-2px',
                    zIndex: 2,
                }}
                onClick={() => onClick && onClick(type === 'show' ? 'hide' : 'show')}
            >
                {type === 'hide' ? <CloseIcon /> : <EditIcon />}
            </div>
            <MenuContainer className="dismiss-off" style={{ display: type === 'hide' ? '' : 'none' }} ref={reference}>
                {properties.children}
            </MenuContainer>
        </React.Fragment>
    );
};

export function PreviewNote(properties: ValidationProperties) {
    if (properties?.error) return null;
    if (properties?.preview && properties?.preview?.type) {
        const { type, onClick } = properties?.preview;
        return (
            <React.Fragment>
                {type === 'show' && (
                    <PreviewingIcon
                        className="glyph preview pointer"
                        onClick={(event) => onClick && onClick('hide', event)}
                    />
                )}
                {type === 'hide' && (
                    <PreviewIcon
                        className="glyph preview pointer"
                        onClick={(event) => onClick && onClick('show', event)}
                    />
                )}
            </React.Fragment>
        );
    }
    return null;
}

export function Note({ children, success, failure, security, size, errorNoteColor }: NodeProperties) {
    return (
        <Notation size={size} className={is({ success, failure, security })} style={{ color: `${errorNoteColor}` }}>
            {children}
        </Notation>
    );
}

export const EmailPattern =
    // eslint-disable-next-line
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const PasswordPattern =
    // eslint-disable-next-line
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[*.#!@$%^&()\\{}[\]:;<>,.?\/~_+\-=|]).{8,}$/;

// export function deleteProfileAvatar() {
//     modeLoading(true, true);
//     documentReference()
//         .set({ avatar: firebase.firestore.FieldValue.delete() }, { merge: true })
//         .then(() => tocca.dispatch(tocca.actions.setAvatarData(null)))
//         .then(() => {
//             modeLoading(false, false);
//         })
//         .catch((e) => {
//             modeLoading(false, false);
//             onFailure(e);
//         });
// }

export const verified = () => get(firebase.auth(), 'currentUser.emailVerified', false);

export function sendEmailVerification() {
    modeLoading(true);
    const { currentUser } = firebase.auth();
    if (currentUser) {
        currentUser.sendEmailVerification();
    }
}

export function needsConfirmation() {
    // const { email } = tocca.select(tocca.selectors.getProfileData) as RegistrationProfile;
    // return verified() && get(firebase.auth(), 'currentUser.email') !== email;
}

export function ProfileAvatarMode() {
    return (
        <React.Fragment>
            <FormHeader title="Edit Thumbnail" />
            <EditThumbnail />
        </React.Fragment>
    );
}

export function ProfileAvatarCaptureMode() {
    return (
        <React.Fragment>
            <FormHeader title="Take Photo" />
            <Capture />
        </React.Fragment>
    );
}
