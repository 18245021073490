import { Redirect as RouterRedirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { registrationSessionPropertyLink } from 'modules/selectors';
import { buildSluggedRoute } from 'modules/utils/CommonService';
import pickBy from 'lodash/pickBy';

export const Redirect = (props: any) => {
    const { eventSlug, organizationId } = useSelector(registrationSessionPropertyLink);
    let to = props.to;
    const filteredProps = pickBy(props, (_v: any, k: any) => {
        return k !== 'to';
    });
    if (eventSlug) {
        to = buildSluggedRoute(organizationId, eventSlug, to);
    }
    return (
        <RouterRedirect to={to} {...filteredProps}>
            {props.children}
        </RouterRedirect>
    );
};
export default Redirect;
