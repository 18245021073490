import firebase from 'firebase/app';

// API URL
const baseURL: string = process.env.REACT_APP_TOCCA_API_URL as string;

export const Api = {
    // ----- api key functions -----

    fetchTracks: (eventId: string, organizationId: string, token: string) => {
        return fetch(`${baseURL}/agendaTracksForEvent/${organizationId}/${eventId}`, {
            method: 'get',
            headers: {
                ...entryHeaders(token),
            },
        }).then((response) => response.json());
    },
    fetchKeynotes: (eventId: string, organizationId: string, token: string) => {
        return fetch(`${baseURL}/agendaKeynotesForEvent/${organizationId}/${eventId}`, {
            method: 'get',
            headers: {
                ...entryHeaders(token),
            },
        }).then((response) => response.json());
    },

    fetchSponsors: (eventId: string, organizationId: string) => {
        return fetch(`${baseURL}/agendaSponsorsForEvent/${organizationId}/${eventId}`, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            },
        }).then((response) => response.json());
    },
    fetchSchedule: (eventId: string, organizationId: string, email: string, uid: string) => {
        const agendaSchedule = firebase.functions().httpsCallable('agendaSchedule');
        return agendaSchedule({
            eventId,
            organizationId,
            /** NOTE: email parameter passed to match against invitee table to filter agenda items
             * as `context.auth.email` will not exist for anonymous authentication and profile in 'participants'
             * table has yet to propagate with `context.auth.uid` via pubsub at sign-in
             */
            email: email,
            uid: uid,
        });
    },
    fetchBreakoutRooms: (organizationId: string, eventId: string, token: string) => {
        return fetch(`${baseURL}/agendaBreakoutRooms/${organizationId}/${eventId}`, {
            method: 'GET',
            headers: {
                ...entryHeaders(token),
            },
        }).then((response) => response.json());
    },
    fetchUserProfile: (uid: string) => {
        return fetch(`${baseURL}/api/user/${uid}`, {
            method: 'GET',
        }).then((response) => response.json());
    },

    getOrganization: (eventId: string, organizationId: string) => {
        return fetch(`${baseURL}/organization/${organizationId}/${eventId}`, {
            method: 'GET',
        }).then((response) => response.json());
    },

    // ----- user authenticated functions -----

    registerParticipant: (data: any, token: string) => {
        return fetch(`${baseURL}/registerParticipant`, {
            method: 'post',
            body: JSON.stringify(data),
            headers: {
                ...entryHeaders(token),
            },
        }).then((response) => response.json());
    },
    registerTwilioChatUser: (friendlyName: string, identity: string, organizationId: string, token: string) => {
        return fetch(`${baseURL}/registerTwilioChatUser`, {
            method: 'post',
            body: JSON.stringify({ friendlyName, identity, organizationId }),
            headers: {
                ...entryHeaders(token),
            },
        }).then((response) => response.json());
    },

    // ----- user authenticated functions -----
    createRequestFollowUp: (payload: object, organizationId: string, token: string) => {
        return fetch(`${baseURL}/optIn/${organizationId}`, {
            method: 'post',
            body: JSON.stringify(payload),
            headers: {
                ...entryHeaders(token),
            },
        }).then((response) => response.json());
    },
    // createRequestFollowUp: (payload: object) => APIClient.post(`/optIn`, payload),
    sendInviteEmail: (payload: object, date: string, organizationId: string, token: string) => {
        return fetch(`${baseURL}/inviteToTheEvent/${organizationId}`, {
            method: 'post',
            body: JSON.stringify(payload),
            headers: {
                ...entryHeaders(token),
            },
        }).then((response) => response.json());
    },
    postLoungeProfileDetail: (body: object, token: string) => {
        return fetch(`${baseURL}/loungeUser`, {
            method: 'post',
            body: JSON.stringify(body),
            headers: {
                ...entryHeaders(token),
            },
        })
            .then((response) => {
                return response.json();
            })
            .then((res) => res);
    },

    fetchParticipant: (organizationId: string, eventId: string, token: string) => {
        return fetch(`${baseURL}/getParticipant?organizationId=${organizationId}&eventId=${eventId}`, {
            method: 'get',
            headers: {
                ...entryHeaders(token),
            },
        })
            .then((response) => {
                return response.json();
            })
            .then((res) => res);
    },

    getSponsorsTypes: (eventId: string, organizationId: string, token: string) => {
        return fetch(`${baseURL}/getSponsorType/${organizationId}/${eventId}`, {
            method: 'get',
            headers: {
                ...entryHeaders(token),
            },
        }).then((response) => response.json());
    },
    fetchSwappedCard: (body: object, token: string) => {
        return fetch(`${baseURL}/getSwapCards`, {
            method: 'post',
            body: JSON.stringify(body),
            headers: {
                ...entryHeaders(token),
            },
        })
            .then((response) => {
                return response.json();
            })
            .then((res) => res);
    },
    fetchLoungeList: (body: object, token: string) => {
        return fetch(`${baseURL}/loungeList`, {
            method: 'post',
            body: JSON.stringify(body),
            headers: {
                ...entryHeaders(token),
            },
        })
            .then((response) => {
                return response.json();
            })
            .then((res) => res);
    },
    endRoomMeetinForallApi: (data: any, token: string) => {
        return fetch(`${baseURL}/chimeSessionEndMeeting`, {
            method: 'post',
            body: JSON.stringify(data),
            headers: {
                ...entryHeaders(token),
            },
        }).then((response) => response.json());
    },

    stopBrekaoutRecordingApi: (organizationId: string, eventId: string, roomId: string, collection: string) => {
        const stopRecordingRef = firebase.functions().httpsCallable('chimeStopRecording');
        return stopRecordingRef({
            eventId,
            organizationId,
            roomId,
            collection,
        });
    },
};

// exports

export default Api;

function entryHeaders(firebaseToken: string) {
    return {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${firebaseToken}`,
    };
}
