import { firestore, database } from 'modules/utils/Firebase';
function NotificationRef(organizationId: string, eventId: string) {
    return database.ref(`Events/${organizationId}/events/${eventId}/notifications`);
}

function ConnectionsRef(userId: string) {
    return database.ref(`/users/${userId}/connections/`);
}

function LoungeRef(organizationId: string, eventId: string) {
    return database.ref(`Events/${organizationId}/events/${eventId}/lounges`);
}

function LookupRef(userId: string, organizationId: string, eventId: string) {
    const databaseRef = firestore.collection('Lookups').doc(userId).collection(organizationId).doc(eventId);
    return databaseRef;
}

function LoungeUserRef(userId: string, organizationId: string, eventId: string, loungeKey: string) {
    const databaseRef = LoungeRef(organizationId, eventId);
    return databaseRef.child(loungeKey).child(userId);
}

function BreakoutUserRef(userId: string, organizationId: string, eventId: string, roomId: string) {
    const databaseRef = database.ref(`Events/${organizationId}/events/${eventId}/breakoutRoomsPresence/${roomId}`);
    return databaseRef.child(userId);
}

function BreakoutPresenceUserRef(organizationId: string, eventId: string, roomId: string) {
    const databaseRef = database.ref(`Events/${organizationId}/events/${eventId}/breakoutRoomsPresence/${roomId}`);
    return databaseRef;
}

function NetworkingLoungeUserRef(userId: string, organizationId: string, eventId: string) {
    const databaseRef = database.ref(`Events/${organizationId}/events/${eventId}/networkLoungePresence`);
    return databaseRef.child(userId);
}

function NetworkingLoungeRef(organizationId: string, eventId: string) {
    const databaseRef = database.ref(`Events/${organizationId}/events/${eventId}/networkLoungePresence`);
    return databaseRef;
}

function BreakoutRoomRef(organizationId: string, eventId: string) {
    const databaseRef = firestore
        .collection('Events')
        .doc(organizationId)
        .collection('events')
        .doc(eventId)
        .collection('components')
        .where('type', '==', 'breakout');
    return databaseRef;
}

function adminEventRolesDocumentReference(email: string) {
    const databaseRef = firestore
        .collection('AdminEventPeople')
        .where('email', '==', email)
    // .where('organizationId', '==', organizationId);
    return databaseRef
}

function registreeEventDocumentReference(email: string, organizationId: string, eventId: string) {
    const databaseRef = firestore
        .collection('Events')
        .doc(organizationId)
        .collection('events')
        .doc(eventId)
        .collection('registrees')
        .where('email', '==', email);
    return databaseRef
}

function allRegistreeEventDocumentReference(organizationId: string, eventId: string) {
    const databaseRef = firestore
        .collection('Events')
        .doc(organizationId)
        .collection('events')
        .doc(eventId)
        .collection('registrees')
    return databaseRef
}

function profileRef(userId: string) {
    return firestore.collection("Profiles").doc(userId);
}

function AdminPeopleOrgRef(organizationId: string) {
    const databaseRef = firestore.collection('AdminEventPeople').where('organizationId', '==', organizationId);
    return databaseRef;
}

function AdminPeopleRef() {
    const databaseRef = firestore.collection('AdminEventPeople');
    return databaseRef;
}

function OrganizationRef(organizationId: string) {
    return firestore.collection('Events').doc(organizationId);
}

function EventsRef(organizationId: string) {
    return firestore.collection('Events').doc(organizationId).collection('events');
}

export const Profile = {
    profile: profileRef
};


function EventRef(organizationId: string, eventId: string) {
    const databaseRef = firestore.collection('Events').doc(organizationId).collection('events').doc(eventId);
    return databaseRef;
}

function LobbyRef(organizationId: string, eventId: string) {
    const databaseRef = firestore
        .collection('Events')
        .doc(organizationId)
        .collection('events')
        .doc(eventId)
        .collection('Lobby');
    return databaseRef;
}
const GroupRef = (organizationId: string, eventId: string) => {
    const databaseRef = firestore
        .collection('Events')
        .doc(organizationId)
        .collection('events')
        .doc(eventId)
        .collection('groups');
    return databaseRef;
};
function BreakoutRef(organizationId: string, eventId: string) {
    const databaseRef = database.ref(`Events/${organizationId}/events/${eventId}/breakoutRoomsPresence`);
    return databaseRef;
}

function BreakoutWaitingRoomRef(organizationId: string, eventId: string, roomId: string) {
    const databaseRef = database.ref(`Events/${organizationId}/events/${eventId}/breakoutRoomsPresence/${roomId}`);
    return databaseRef;
}

function BreakoutRecordingRef(organizationId: string, eventId: string) {
    const databaseRef = database.ref(`Events/${organizationId}/events/${eventId}/breakoutRecording`);
    return databaseRef;
}
function NetworkingRoomRef(organizationId: string, eventId: string, roomId: string) {
    const databaseRef = firestore
        .collection('Events')
        .doc(organizationId)
        .collection('events')
        .doc(eventId)
        .collection('networking')
        .doc(roomId);
    return databaseRef;
}

function BreakoutBlockedAttendeeRef(organizationId: string, eventId: string, roomId: string, uid: string) {
    const databaseRef = database.ref(
        `Events/${organizationId}/events/${eventId}/breakoutBlockedAttendees/${roomId}/${uid}`,
    );
    return databaseRef;
}

const BreakoutBlockedRef = (organizationId: string, eventId: string, userId: string) => {
    const databaseRef = database.ref(`Events/${organizationId}/events/${eventId}/breakoutBlockedAttendees/${userId}`);
    return databaseRef;
};
function ComponentRef(organizationId: string, eventId: string) {
    const databaseRef = firestore
        .collection('Events')
        .doc(organizationId)
        .collection('events')
        .doc(eventId)
        .collection('components');
    return databaseRef;
}
function BreakoutVideoControlRef(organizationId: string, eventId: string, roomId: string) {
    const databaseRef = database.ref(`Events/${organizationId}/events/${eventId}/videos/${roomId}`);
    return databaseRef;
}
function BreakoutRecordingRoomRef(organizationId: string, eventId: string, roomId: string) {
    const databaseRef = database.ref(`Events/${organizationId}/events/${eventId}/breakoutRecording/${roomId}`);
    return databaseRef;
}

function BreakoutStreamingRoomRef(organizationId: string, eventId: string, roomId: string) {
    const databaseRef = database.ref(`Events/${organizationId}/events/${eventId}/breakoutStreaming/${roomId}`);
    return databaseRef;
}

function PollsRef(organizationId: string) {
    const pollsRef = firestore.collection('Events').doc(organizationId).collection('typeforms');
    return pollsRef;
}

function PollsRealtimeRef(organizationId: string, eventId: string, roomId: string) {
    const databaseRef = database.ref(`Events/${organizationId}/events/${eventId}/polls/${roomId}`);
    return databaseRef;
}
function SponsorBoothRef(organizationId: string, eventId: string) {
    const databaseRef = database.ref(`Events/${organizationId}/events/${eventId}/sponsorBoothUserPresence`);
    return databaseRef;
}
function SponsorRoomRef(uid: string, organizationId: string, eventId: string, sponsorId: string) {
    const databaseRef = database.ref(
        `Events/${organizationId}/events/${eventId}/sponsorBoothUserPresence/${sponsorId}/${uid}`,
    );
    return databaseRef;
}

function RolesRef() {
    const databaseRef = firestore.collection('Roles');
    return databaseRef;
}

const RoutingRef = () => {
    const databaseRef = firestore.collection('Routing');
    return databaseRef;
};

const SpeakerRef = (organizationId: string, eventId: string) => {
    const databaseRef = firestore
        .collection('Events')
        .doc(organizationId)
        .collection('events')
        .doc(eventId)
        .collection('speakers');
    return databaseRef;
};

const PollTriggerRef = (organizationId: string, eventId: string) => {
    const databaseRef = firestore
        .collection('Events')
        .doc(organizationId)
        .collection('events')
        .doc(eventId)
        .collection('pollTriggers');
    return databaseRef;
};

export const Lounge = {
    reference: LoungeRef,
    userRef: LoungeUserRef,
};

export const BreakoutPresence = {
    userRef: BreakoutUserRef,
    userPresnceRef: BreakoutPresenceUserRef,
    reference: BreakoutRef,
    roomRef: BreakoutRoomRef,
    waitingRef: BreakoutWaitingRoomRef,
};

export const AdminEventRoleDocument = {
    adminEventRoleRef: adminEventRolesDocumentReference
}

export const RegistreeEventDocument = {
    registreeEventRef: registreeEventDocumentReference,
    allRegistreeEventRef: allRegistreeEventDocumentReference
}

export const NetworkingLoungeUser = {
    userRef: NetworkingLoungeUserRef,
    ref: NetworkingLoungeRef,
};

export const Notification = {
    reference: NotificationRef,
};
export const UserLookup = {
    reference: LookupRef,
};

export const Connections = {
    reference: ConnectionsRef,
    parReference: ParentConnectionsRef,
};

function ParentConnectionsRef(userId: string) {
    return database.ref(`/users/${userId}/`);
}

export const ParentConnections = {
    reference: ParentConnectionsRef,
};

export const BreakoutRecording = {
    reference: BreakoutRecordingRef,
    refRoom: BreakoutRecordingRoomRef,
};

export const BreakoutStreaming = {
    refRoom: BreakoutStreamingRoomRef,
};

export const NetworkingRoom = {
    reference: NetworkingRoomRef,
};

export const BreakoutBlockedUsers = {
    reference: BreakoutBlockedAttendeeRef,
    ref: BreakoutBlockedRef,
};

export const Organization = {
    reference: OrganizationRef,
};

export const Events = {
    reference: EventsRef,
};

export const Event = {
    reference: EventRef,
};

export const Lobby = {
    reference: LobbyRef,
};

export const Components = {
    reference: ComponentRef,
};

export const BreakoutVideoControl = {
    reference: BreakoutVideoControlRef,
};

export const AdminEventPeople = {
    reference: AdminPeopleRef,
    orgReference: AdminPeopleOrgRef,
};

export const Polls = {
    reference: PollsRef,
    realtimeRef: PollsRealtimeRef,
};
export const SponsorBooth = {
    reference: SponsorBoothRef,
    roomRef: SponsorRoomRef,
};

export const Roles = {
    reference: RolesRef,
};

export const Group = {
    reference: GroupRef,
};

export const Routing = {
    reference: RoutingRef,
};

export const Speaker = {
    reference: SpeakerRef,
};

export const PollTrigger = {
    reference: PollTriggerRef,
};
