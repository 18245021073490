import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormHeader, Invalid, Note, PasswordPattern, Space } from '../common/index';
import * as selectors from 'modules/selectors';
import is from 'classnames';
import * as actions from 'modules/actions';
import { Checkbox, Input, Link, Submit } from '../inputs';
import { Label } from '../fieldset/label';
import cloneDeep from 'lodash/cloneDeep';
import { Button, Horizontal } from '../style';
import { Mode } from '../constants/constants';

const NewEmailMode = () => {
    const [preview, setPreview] = React.useState<'show' | 'hide'>('show');
    const [isdisabled, setDisabled] = useState(true);
    const dispatch = useDispatch();
    const authData = useSelector(selectors.getloginData);
    const state = cloneDeep(authData);

    const checkEmailValidity = () => {
        let formIsValid = true;
        if (!PasswordPattern.test(state.password)) {
            formIsValid = false;
        } else dispatch(actions.authLoginErrors(''));
        return formIsValid;
    };

    const handleInputChange = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;
        const obj = {
            ...state,
            [name]: value,
        };
        dispatch(actions.setAuthPassword(obj));
    };

    const onsubmit = (e: any) => {
        e.preventDefault();
        if (checkEmailValidity()) {
            const obj = {
                email: state.email,
                password: state.password,
            };
            dispatch(actions.signup(obj));
        }
    };

    const TERMS = {
        ERROR: 'Required to continue',
        PROPERTY: 'terms',
    };

    const errorNoteColor = !state.password ? '#8a9fba' : !PasswordPattern.test(state.password) ? '#eb4b4b' : '#0BDA38';

    const handleCheck = (e: any) => {
        if (e.target.checked) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    };
    const disabled = !state.password || !PasswordPattern.test(state.password) || isdisabled;

    const backBtn = () => {
        dispatch(actions.authMode(Mode.initial));
        dispatch(actions.isUserRegistred(Mode.initial));
    };
    return (
        <React.Fragment>
            <FormHeader title="Create your account and connect" />
            <Space />
            <Invalid className={is({ password: true })} preview={{ type: preview, onClick: setPreview }}></Invalid>
            <Label field="password" labelStyle={{ flexWrap: 'wrap', display: 'flex', alignItems: 'baseline' }}>
                {`Please define your password for ${state.email}`}
            </Label>
            <Input
                autoComplete={'new-password'}
                id="password"
                name="password"
                placeholder={'Define your password'}
                type="password"
                value={state.password}
                autoFocus={true}
                onChange={(event) => handleInputChange(event)}
                style={{ display: preview === 'show' ? '' : 'none' }}
            />
            {preview !== 'show' && (
                <React.Fragment>
                    <Input
                        style={{ marginTop: '5px' }}
                        autoComplete="off"
                        name="password"
                        placeholder={'Define your password'}
                        type="text"
                        value={state.password}
                        autoFocus={true}
                        onChange={(event) => handleInputChange(event)}
                    />
                </React.Fragment>
            )}
            <Space />
            <Note size={12} errorNoteColor={errorNoteColor}>
                {' '}
                {
                    'At least 8 characters with one lower case, one upper case, one digit and one special character (*.#!@$%^&(){}[]:;<>,.?\\/~_+-=|).'
                }
            </Note>

            <Space height={1.25} />
            <Horizontal style={{ justifyContent: 'flex-start' }}>
                <Checkbox id={TERMS.PROPERTY} name={TERMS.PROPERTY} onChange={(e) => handleCheck(e)} />
                <label htmlFor={TERMS.PROPERTY} style={{ textTransform: 'none' }}>
                    I agree with Shared Studios{' '}
                    <Link href="#" className="text-decoration-none">
                        term and conditions
                    </Link>{' '}
                    and{' '}
                    <Link href="https://tocca.io/privacy-policy/" target="_blank" className="text-decoration-none">
                        privacy policy
                    </Link>
                </label>
            </Horizontal>
            <Space height={4} />
            <Space />
            <Horizontal>
                <Button onClick={backBtn}>Back</Button>
                <Submit onClick={onsubmit} disabled={isdisabled} className={is({ disabled })}>
                    Create my account
                </Submit>
            </Horizontal>
        </React.Fragment>
    );
};

export default NewEmailMode;
