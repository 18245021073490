import * as ReduxSaga from 'redux-saga';
import * as ReduxSagaEffects from 'redux-saga/effects';
import { getUrlParameters, getUrlFromParamters } from 'modules/utils/CommonService';
import * as actions from 'modules/actions';
import * as selectors from 'modules/selectors';
import { Mode } from 'components/Login/constants/constants';
import { getRoutingDocument } from 'modules/utils/Firebase/Routing';
import { ISagaAction } from 'modules/types';
import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';
import { call, put, select } from 'redux-saga/effects';
import { authLoginErrors, openLoginModal } from 'modules/actions';
import { getUserProfileApi } from 'modules/utils/Firebase/Profile';
import { getUserUid } from 'modules/selectors';
import { SagaIterator } from 'redux-saga';
import * as Sentry from '@sentry/react';
import { getUserFromRegistree } from 'modules/utils/Firebase/UserManagement/UserEventRole';
const ASSETS_URL: string = process.env.REACT_APP_TOCCA_ASSETS_URL || '';

/** @function This saga initializes the global state via "event link" or last known event from cache if it exists. */
export const registrationEntrySaga = function* (): ReduxSaga.SagaIterator {
    try {
        yield put(actions.setLinkLoading());
        const pathName = window.location.pathname;

        let checkInRoutingDoc = false;

        let result = yield ReduxSagaEffects.call(getUrlParameters, pathName, window.location.search);

        if (result && pathName !== '/' && pathName !== '/not-found') {
            const eventOrgMatch = /\/(?<organizationId>[a-zA-Z0-9]+)\/(?<eventSlug>[A-Za-z0-9_-]{22})(\/(?<path>.*))?/;
            const match = pathName.match(eventOrgMatch);

            if (!match) {
                checkInRoutingDoc = true;
                yield ReduxSagaEffects.put(actions.resetLink());
            }
        } else if (!result) {
            checkInRoutingDoc = true;
            yield ReduxSagaEffects.put(actions.resetLink());
        }

        yield put(actions.setLinkLoading());
        let isEventLandingPage = false;

        if (checkInRoutingDoc) {
            let baseUrl = pathName.split('/')[1];
            if (baseUrl === 'org') {
                baseUrl = pathName.split('/')[2];
                result = { organizationId: baseUrl, isRoutingDocument: true, pageType: 'discovery' };

                yield ReduxSagaEffects.put(actions.SetRegistrationSessionPropertyLink(result));

                //  yield ReduxSagaEffects.put(actions.authLinkSuccess({ organizationId: baseUrl }));
                return;
            } else if (baseUrl === 'lp') {
                baseUrl = pathName.split('/')[2];
                isEventLandingPage = true;
            }
            yield put(actions.setLinkLoading());
            const routingDoc = yield ReduxSagaEffects.call(getRoutingDocument, baseUrl);

            if (routingDoc?.type === 'event') {
                result = yield ReduxSagaEffects.call(
                    getUrlParameters,
                    `/${routingDoc.organizationId}/${routingDoc.eventSlug}/`,
                    window.location.search,
                );
                result = {
                    ...result,
                    isRoutingDocument: true,
                    pageType: isEventLandingPage ? 'eventLanding' : routingDoc?.type,
                };
            } else if (routingDoc?.type === 'discovery') {
                result = { ...routingDoc, isRoutingDocument: true, pageType: 'discovery' };

                yield ReduxSagaEffects.put(actions.SetRegistrationSessionPropertyLink(result));
                //  yield ReduxSagaEffects.put(actions.authLinkSuccess({ organizationId: routingDoc?.organizationId }));
                return;
            } else if (!routingDoc) {
                result = false;
            }
        }

        if (result) yield ReduxSagaEffects.put(actions.SetRegistrationSessionPropertyLink(result));
        const { eventId, organizationId, eventSlug } = yield ReduxSagaEffects.select(selectors.getRegistrationLink);
        if (!organizationId || !eventId || !eventSlug) {
            yield ReduxSagaEffects.put(actions.authLinkInvalid());
        } else {
            const link = yield ReduxSagaEffects.call(
                getUrlFromParamters,
                window.location.origin,
                eventSlug,
                organizationId,
            );

            yield ReduxSagaEffects.put(actions.setGlobalLink(link));
            yield ReduxSagaEffects.put(actions.authLinkSuccess({ eventId, organizationId, eventSlug }));
        }
    } catch (error) {
        yield ReduxSagaEffects.put(actions.authLinkInvalid());
        console.warn('fetchRegistrationDetails: ', error);
        Sentry.captureException(error);
    }
};

/** @function This saga ensures properties in firestore document ared synced as changes occur. */
export function* registrationProfileSynchronizeSaga(): ReduxSaga.SagaIterator {
    try {
        const userId = yield select(getUserUid);
        const profile = yield call(getUserProfileApi, userId);
        const mode = yield select(selectors.getAuthMode);
        const selectedRoute = yield select(selectors.getRoute);
        const mainProfile = yield select(selectors.getUserProfile);
        const userRegisterMode = yield select(selectors.getUserRegister);
        if (
            mode !== Mode.useFirstTime &&
            mode !== Mode.useNewEmail &&
            mode !== Mode.showLoader &&
            userRegisterMode !== Mode.googleAuth &&
            selectedRoute.path !== '/record/breakout' &&
            selectedRoute.path !== '/live/broadcast'
        ) {
            if (!profile || !profile.firstName || !profile.lastName || !profile.jobTitle || !profile.companyName) {
                yield put(actions.authMode(Mode.profile));
                yield put(openLoginModal());
            }
        }
        let response = {
            ...profile,
            name: profile.firstName + ' ' + profile.lastName,
            companyName: profile.companyName,
            avatar: profile.avatar,
            jobTitle: profile.jobTitle,
            twitter: profile.twitter,
            linkedin: profile.linkedin,
            uid: userId,
            email: mainProfile.email ? mainProfile.email : profile.email,
            description: profile.description,
            workLocation: profile.workLocation,
        };
        response = omitBy(response, isNil);
        if (response.avatar) {
            yield put(actions.setAvatarData(response.avatar));
        }

        yield put(actions.updateProfileChangeSucess(response));
        yield put(actions.authSetProfile({ ...response }));
    } catch (e) {
        yield put(authLoginErrors(e));
        Sentry.captureException(e);
    }
}

export const updateUserDetailsFromRegistreeSaga = function* (action: ISagaAction<any>): SagaIterator {
    try {
        const eventId = yield select(selectors.getEventId);
        const organizationId = yield select(selectors.getOrganizationId);
        let isSkipProfileStep = false;
        const response = yield call(getUserFromRegistree, { email: action.payload, eventId, organizationId });
        if (response && response.docs && response.docs.length > 0) {
            const profileData: any = response.docs.map((x: any) => x.data()).filter((x: any) => !x.isDeleted);
            if (profileData && profileData.length > 0) {
                const profile = {
                    companyName: profileData[0].companyName,
                    firstName: profileData[0].givenName || profileData[0].firstName,
                    description: profileData[0].description,
                    lastName: profileData[0].familyName || profileData[0].lastName,
                    jobTitle: profileData[0].jobTitle,
                    email: profileData[0].email,
                    avatar: profileData[0].avatar
                        ? avatarUrlFromAdminEventPeople(profileData[0].avatar, action.payload.organizationId)
                        : undefined,
                    workLocation: profileData[0].workLocation,
                    isUserRefFromAdmin: true,
                };
                if (profile.firstName && profile.lastName && profile.email && profile.jobTitle && profile.companyName) {
                    const event = yield select(selectors.getEvent);
                    if (event?.isSkipProfile) {
                        yield put(actions.saveProfileData({ ...profile }));
                        yield put(actions.closeLoginModal());
                        yield put(actions.authMode(Mode.initial));
                        isSkipProfileStep = true;
                    }
                    else {
                        yield put(actions.setSkipProfile(false))
                        yield put(actions.saveProfileData({ ...profile }));
                    }
                }
                yield put(actions.updateProfileChangeSucess(profile));
                yield put(actions.authSetProfile({ ...profile }));
                yield put(actions.registrationProfileSynchronize());
                if (!isSkipProfileStep) {
                    yield put(actions.authMode(Mode.useFirstTime));
                }
                return;
            } else {
                yield put(actions.authMode(Mode.useFirstTime));
                return;
            }
        } else {
            yield put(actions.registrationProfileSynchronize());
            yield put(actions.authMode(Mode.useFirstTime));
            return;
        }
    } catch (e) {
        console.error('checkUserHasAccessSaga', e);
        Sentry.captureException(e);
    }
};

export const checkUserHasAccessSaga = function* (): SagaIterator {
    try {
        const event = yield select(selectors.getEvent);
        const authData = yield select(selectors.getloginData);
        const organizationId = yield select(selectors.getOrganizationId);
        const eventId = yield select(selectors.getEventId);
        if (event?.access?.requiresRegistration) {
            const payload = {
                eventId,
                organizationId,
                email: authData.email,
            };

            const response = yield call(getUserFromRegistree, payload);
            if (!response.empty) {
                yield put(actions.checkUserRegistered());
            } else {
                yield put(actions.authMode(Mode.notRegistered));
            }
        } else {
            yield put(actions.checkUserRegistered());
        }
    } catch (e) {
        console.error('checkUserHasAccessSaga', e);
        Sentry.captureException(e);
    }
};

export function avatarUrlFromAdminEventPeople(url: string, organizationId: string) {
    return url.startsWith('https://') ? url : `${ASSETS_URL}/dynamic/images/${organizationId}${url}`;
}
