import React from 'react';

export const LobbyConfig = {
    settings: {
        layout: {
            config: {
                header: { display: true },
                footer: { display: true },
                navbar: { display: true },
            },
        },
    },
    isPrivate: true,
    eventName: 'lobby',
    routes: [
        {
            path: '/:organizationId/:eventSlug/lobby',
            component: React.lazy(() => import('.')),
        },
    ],
};

export const LobbyMobileConfig = {
    settings: {
        layout: {
            config: {
                header: { display: true },
                footer: { display: true },
                navbar: { display: true },
            },
        },
    },
    isPrivate: true,
    eventName: 'lobby',
    routes: [
        {
            path: '/:organizationId/:eventSlug/lobby',
            component: React.lazy(() => import('./mobile')),
        },
    ],
};
