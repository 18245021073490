import firebase from 'firebase/app';

export const EMAIL_EXPRESSION =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const FIREBASE = {
    AUTHENTICATION_TOKEN_REFRESH: true,
    PERSISTENCE: {
        OFF: firebase.auth.Auth.Persistence.SESSION,
        ON: firebase.auth.Auth.Persistence.LOCAL,
    },
    PRESENCE: {
        FIRESTORE_COLLECTION: 'Presence',
        REALTIME_DATABASE_PATH: 'Presence',
    },
};

// export const LOCATION = {
//     AUTHENTICATED: { pathname: '/lobby' },
//     UNAUTHENTICATED: { pathname: '/' },
// };

export enum SERVICE {
    // Anonymous = 'Anonymous', // ONLY USED V1
    // Apple = 'Apple', // UNUSED
    Email = 'Email',
    // EmailLink = 'Email Link', // UNUSED
    Facebook = 'Facebook',
    GitHub = 'GitHub', // UNUSED
    Google = 'Google',
    Microsoft = 'Microsoft',
    // Phone = 'Phone', // UNUSED
    // Twitter = 'Twitter', // UNUSED
    // Yahoo = 'Yahoo', // UNUSED
}

export enum firebaseErrors {
    // Thrown if there already exists an account with the email address asserted by the credential.
    // RESOLUTION:
    //  1. use `firebase.auth.Auth.fetchSignInMethodsForEmail` with the error.email
    //  2. prompt user to sign in using one of the returned providers
    //  3. once the user is signed in, link `error.credential` credential with `firebase.User.linkWithCredential` to prevent the user from signing in again to the original provider via popup or redirect.
    //      4. WHEN: using redirects
    //          1. save the credential in session storage
    //          2. retrieve on redirect
    //          3. repopulate the AuthProvider.credential & complete the link
    accountExistsWithDifferentCredential = 'auth/account-exists-with-different-credential',

    // Thrown if the instance of FirebaseApp has been deleted.
    appDeleted = 'auth/app-deleted',

    // Thrown if the app's domain/host is not authorized to use Firebase Authentication
    // RESOLUTION: edit the list of authorized domains from the Firebase console
    appNotAuthorized = 'auth/app-not-authorized',

    // Thrown if authDomain configuration is not provided when calling firebase.initializeApp(). Check Firebase Console for instructions on determining and passing that field.
    authDomainConfigRequired = 'auth/auth-domain-config-required',

    // Thrown if a method is called with incorrect arguments.
    argumentError = 'auth/argument-error',

    // Thrown if successive popup operations are triggered.
    // Only one popup request is allowed at one time.
    // All the popups would fail with this error except for the last one.
    cancelledPopupRequest = 'auth/cancelled-popup-request',

    // Thrown if the account corresponding to the credential already exists among your users, or is already linked to a Firebase User.
    // For example, this error could be thrown if you are upgrading an anonymous user to a Google user by linking a Google credential to it and the Google credential used is already associated with an existing Firebase Google user.
    // The fields error.email, error.phoneNumber, and error.credential ({@link firebase.auth.AuthCredential}) may be provided, depending on the type of credential.
    // You can recover from this error by signing in with error.credential directly via {@link firebase.auth.Auth.signInWithCredential}.
    captchaCheckFailed = 'auth/captcha-check-failed',

    // Thrown if the account corresponding to the credential already exists among your users, or is already linked to a Firebase User.
    // Thrown if upgrading an anonymous user to an AuthProvider user by linking a credential that is already associated with another Firebase Google user
    // NOTE: depending on the type, error could include fields: `error.email` + `error.phoneNumber` `error.credential: AuthCredential`
    // RESOLUTION: recover by passing `error.credential` to `firebase.auth.Auth.signInWithCredential`
    credentialAlreadyInUse = 'auth/credential-already-in-use',

    // Thrown if the custom token is for a different Firebase App.
    customTokenMismatch = 'auth/custom-token-mismatch',

    // Thrown if the email corresponding to the credential already exists among your users.
    // When thrown while linking a credential to an existing user, an error.email and error.credential ({@link firebase.auth.AuthCredential}) fields are also provided.
    // You have to link the credential to the existing user with that email if you wish to continue signing in with that credential.
    // To do so, call {@link firebase.auth.Auth.fetchSignInMethodsForEmail}, sign in to error.email via one of the providers returned and then {@link firebase.User.linkWithCredential} the original credential to that newly signed in user.
    emailAlreadyInUse = 'auth/email-already-in-use',

    // Thrown if the action code has expired.
    expiredActionCode = 'auth/expired-action-code',

    // Thrown if the action code is invalid. This can happen if the code is malformed or has already been used.
    invalidActionCode = 'auth/invalid-action-code',

    // Thrown if the provided API key is invalid.
    // RESOLUTION:  configure using API key found in the Firebase Authentication console
    invalidApiKey = 'auth/invalid-api-key',

    // The continue URL provided in the request is invalid.
    invalidContinueUri = 'auth/invalid-continue-uri',

    // Thrown if the custom token format is incorrect.
    invalidCustomToken = 'auth/invalid-custom-token',

    // Thrown if the credential is malformed or has expired.
    invalidCredential = 'auth/invalid-credential',

    // Thrown if the email address is not valid.
    invalidEmail = 'auth/invalid-email',

    // Thrown if the specified persistence type is invalid.
    invalidPersistenceType = 'auth/invalid-persistence-type',

    // Thrown if the phone number has an invalid format.
    invalidPhoneNumber = 'auth/invalid-phone-number',

    // Thrown if the tenant ID provided is invalid.
    invalidTenantId = 'auth/invalid-tenant-id',

    // Thrown if the user's credential is no longer valid.
    // RESOLUTION: prompt user must sign in again
    invalidUserToken = 'auth/invalid-user-token',

    // Thrown if the credential is a {@link firebase.auth.PhoneAuthProvider.credential} and the verification code of the credential is not valid.
    invalidVerificationCode = 'auth/invalid-verification-code',

    // Thrown if the credential is a {@link firebase.auth.PhoneAuthProvider.credential} and the verification ID of the credential is not valid.
    invalidVerificationId = 'auth/invalid-verification-id',

    // An Android package name must be provided if the Android app is required to be installed.
    missingAndroidPkgName = 'auth/missing-android-pkg-name',

    // A continue URL must be provided in the request.
    missingContinueUri = 'auth/missing-continue-uri',

    // An iOS Bundle ID must be provided if an App Store ID is provided.
    missingIosBundleId = 'auth/missing-ios-bundle-id',

    // Thrown if the phone number is missing.
    missingPhoneNumber = 'auth/missing-phone-number',

    // Thrown if the verification code is missing.
    missingVerificationCode = 'auth/missing-verification-code',

    // Thrown if a network error (such as timeout, interrupted connection or unreachable host) has occurred.
    networkRequestFailed = 'auth/network-request-failed',

    // Thrown if the user to be updated is null.
    nullUser = 'auth/null-user',

    // Thrown if you have not enabled the provider in the Firebase Console.
    // RESOLUTION: configure provider @ Firebase Console > Auth  > Sign in Method
    operationNotAllowed = 'auth/operation-not-allowed',

    // Thrown if this operation is not supported in the environment your application is running on. "location.protocol" must be http or https.
    operationNotSupportedInThisEnvironment = 'auth/operation-not-supported-in-this-environment',

    // Thrown if the popup was blocked by the browser, typically when this operation is triggered outside of a click handler.
    popupBlocked = 'auth/popup-blocked',

    // Thrown if the popup window is closed by the user without completing the sign in to the provider.
    popupClosedByUser = 'auth/popup-closed-by-user',

    // Thrown if the SMS quota for the Firebase project has been exceeded.
    quotaExceeded = 'auth/quota-exceeded',

    // Thrown if the user's last sign-in time does not meet the security threshold.
    // RESOLUTION: use `firebase.User.reauthenticateWithCredential`
    // NOTE: This does not apply if the user is anonymous.
    requiresRecentLogin = 'auth/requires-recent-login',

    // Thrown if the provided user's tenant ID does not match the underlying Auth instance's configured tenant ID
    tenantIdMismatch = 'auth/tenant-id-mismatch',

    // Thrown if request encounters timeout
    timeout = 'auth/timeout',

    // Thrown if requests are blocked from a device due to unusual activity.
    // RESOLUTION: implement a retry function with a delay until requests are unblocked
    tooManyRequests = 'auth/too-many-requests',

    // The domain of the continue URL is not whitelisted. Whitelist the domain in the Firebase console.
    unauthorizedContinueUri = 'auth/unauthorized-continue-uri',

    // Thrown if the app domain is not authorized for OAuth operations for your Firebase project.
    // RESOLUTION: edit the list of authorized domains from the Firebase console
    unauthorizedDomain = 'auth/unauthorized-domain',

    // Thrown when offline and firebase is not reachable. Message: "Failed to get document because the client is offline."
    unavailable = 'unavailable',

    // Thrown if the current environment does not support the specified persistence type.
    unsupportedPersistenceType = 'auth/unsupported-persistence-type',

    // Thrown if the user account has been disabled by an administrator.
    // RESOLUTION: to enabled an account manually go to the Firebase Console > Authentication > Users and search for a user
    userDisabled = 'auth/user-disabled',

    // Thrown if signing in with a credential from firebase.auth.EmailAuthProvider.credential and there is no user corresponding to the given email.
    userNotFound = 'auth/user-not-found',

    // Thrown if the user's credential has expired or thrown if a user has been deleted.
    // RESOLUTION: prompt the user to sign in again for either case
    userTokenExpired = 'auth/user-token-expired',

    // Thrown if the new password is not strong enough.
    weakPassword = 'auth/weak-password',

    // Thrown if the browser does not support web storage or if the user disables them.
    webStorageUnsupported = 'auth/web-storage-unsupported',

    // Thrown if signing in with a credential from firebase.auth.EmailAuthProvider.credential and the password is invalid for the given email, or if the account corresponding to the email does not have a password set.
    wrongPassword = 'auth/wrong-password',
}

export enum toccaErrors {
    invitationExpired = 'Issued invitation has expired host',
    profileAvatar = 'Failed to upload your profile',
    requiresInitialProvider = 'Host of event requires registration before entry',
    requiresEmployeeEmail = 'Host of event requires use of employee email',
    requiresRegistration = 'Host of event requires registration before entry',
}

export enum Mode {
    initial = 'initial', // screen 1.1 | screen 1.2
    // terms = 'terms',
    /* ---- SIGN-UP ---- */
    useNewEmail = 'useNewEmail', // screen 2.2c (create password + consent)
    useEmail = 'useEmail', // screen 2.4 (password)
    forgotPassword = 'forgotPassword', // screen 3.2
    googleAuth = 'googleAuth',

    /* ---- AFTER AUTHENTICATION  ---- */
    notRegistered = 'notRegistered', // screen (check event for registration)
    waitingOnRegistration = 'waitingOnRegistration', // screen (check event for registration)
    useNewSocial = 'useNewSocial', // screen 2 (consent after popup)
    incomplete = 'incomplete', // 2.3 (to updateConsent, etc)
    updateConsent = 'updateConsent', // from incomplete

    /* --- AFTER AUTHENTICATION & REQUIREMENTS --- */
    useFirstTime = 'useFirstTime',
    showLoader = 'showLoader',
    /* ---- VERIFICATION ---- */
    verifyEmail = 'verifyEmail',
    verifyEmailSent = 'verifyEmailSent',

    /* ---- EXIT ---- */
    dismiss = 'dismiss',
    signOut = 'signOut',

    /* ---- PROFILE ---- */
    profile = 'profile',
    profileAvatar = 'profileAvatar',
    profileAvatarCapture = 'profileAvatarCapture',
    publish = 'publish',
    publishAvatar = 'publishAvatar',
}

export const accessProviders = {
    email: firebase.auth.EmailAuthProvider.PROVIDER_ID,
    facebook: firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    github: firebase.auth.GithubAuthProvider.PROVIDER_ID,
    google: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    phone: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
    twitter: firebase.auth.TwitterAuthProvider.PROVIDER_ID,
};

export const WORK = {
    COMPANY: {
        LABEL: 'Organization*',
        PLACEHOLDER: 'Enter Organization',
        PROPERTY: 'companyName',
        REGISTER: {
            required: true,
            minLength: {
                value: 0,
                message: 'Please enter a valid organization name.',
            },
        },
    },
    TITLE: {
        LABEL: 'Title*',
        PLACEHOLDER: 'Enter Title',
        PROPERTY: 'jobTitle',
        REGISTER: {
            required: true,
            minLength: {
                value: 2,
                message: 'Please enter a valid job title.',
            },
        },
    },
};

export const SOCIAL = {
    LINKEDIN: {
        LABEL: 'LinkedIn',
        PLACEHOLDER: 'www.linkedin.com/in/your_profile',
        PROPERTY: 'linkedin',
        REGISTER: {
            validate: (value: any) => {
                try {
                    if (!value) return true;
                    // if (new URL(value).host.endsWith('linkedin.com')) return true;
                    if (value.indexOf('www.linkedin.com') > -1) return true;
                } catch (error) {
                    /* */
                }
                return 'Correct Syntax Required';
            },
        },
    },
    TWITTER: {
        LABEL: 'Twitter',
        PLACEHOLDER: '@your_handle',
        PROPERTY: 'twitter',
        REGISTER: {
            validate: (value: any) => {
                try {
                    if (!value) return true;
                    if (value.startsWith('@') && value.length > 1) return true;
                } catch (error) {
                    /* */
                }
                return 'Correct Syntax Required';
            },
        },
    },
};

export const DESCRIPTION = {
    LABEL: 'About Me',
    PLACEHOLDER: 'Interests and information that will facilitate networking on this platform',
    PROPERTY: 'description',
    REGISTER: {
        minLength: {
            value: 10,
            message: 'Minimum 10 characters.',
        },
        maxLength: {
            value: 250,
            message: 'Maximum 250 characters.',
        },
    },
};

export const LOCATION = {
    LABEL: 'Location',
    PLACEHOLDER: 'Enter Location',
    PROPERTY: 'workLocation',
};

export const EMAIL = {
    ERROR: 'Please enter a valid email address',
    LABEL: 'Email',
    PLACEHOLDER: 'Enter Email',
    PROPERTY: 'email',
};

export const NAME = {
    FIRST: {
        ERROR: 'Please enter your first name to continue.',
        LABEL: 'First Name*',
        PLACEHOLDER: 'Enter First Name',
        PROPERTY: 'firstName',
    },
    LAST: {
        ERROR: 'Please enter your last name to continue.',
        LABEL: 'Last Name*',
        PLACEHOLDER: 'Enter Last Name',
        PROPERTY: 'lastName',
    },
};
