import { createAction } from 'redux-act';
export const addRaisedHandsData = createAction<any>('arrays of raised hands');
export const raisedHandModalState = createAction<boolean>('rasied hand modal control');
export const waitingRoomModalState = createAction<boolean>('waiting room modal control');
export const setPrivateChatAttendeeCall = createAction<any>('set attendee for private chat');
export const setContentShareStatus = createAction<any>('content share status by attendess');
export const setDeviceCompActive = createAction<boolean>('is device setup component active');
export const meetingCloseTime = createAction<{ show: boolean; meetingExtended: boolean }>(
    'to show meeting close notifier',
);

export const extendCurrentMeeting = createAction<{ additionalMinutes: number | null, roomId?: string }>(
    'to extend time of meeting',
);
export const endCurrentMeeting = createAction(
    'to end current meeting',
);
export const setModeratorExitDate = createAction<number | null>(
    'to set moderator exit date from room',
);

export const setRoomEndDate = createAction<number>(
    'to set room end date',
);

export const changeRoomStatus = createAction<{isRoomOpen?: boolean, roomId: string}>(
    'to change room status',
);
