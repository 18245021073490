import { createAction } from 'redux-act';

export const signinUser = createAction<any>('login User');

export const authSigninUser = createAction('Google login User');

export const mailResetPassword = createAction<any>('forget password');

export const authLoginErrors = createAction<any>();

export const authPasswordResetErrors = createAction<any>();

export const authMode = createAction<any>();

export const setAuthEmail = createAction<any>();

export const setAuthPassword = createAction<any>();

export const setForgetPasswordEmail = createAction<any>();

export const authFailure = createAction<any>('failures when authenticating, editing or registering account');

export const setProfileData = createAction<any>();

export const saveProfileData = createAction<any>();

export const updateProfileChangeSucess = createAction<any>();

export const checkUserRegistered = createAction();

export const isUserRegistred = createAction<any>();

export const signup = createAction<any>();

export const isProceedClick = createAction<boolean>();

export const updateUserDetail = createAction<any>();

export const updateUserRegistree = createAction<any>();

export const checkUserHasAccess = createAction();

export const saveAvatarData = createAction<any>();

export const setAvatarFileData = createAction<any>();

export const setAvatarData = createAction<any>();

export const deleteAvatar = createAction();

export const setIsOnSignUp = createAction<any>();

export const setSkipProfile = createAction<any>();