import React, { useEffect, useState } from 'react';
import {
    getNotification,
    getHeaderImage,
    getAssets,
    getLayout,
    getChatComponentDisplayMode,
    getBreakoutAttendeeForPrivateChat,
    getPrivateMsgNotification,
    getSettings,
    getSchedulesComponentDisplayMode,
    getMobileModde,
} from 'modules/selectors';
import get from 'lodash/get';
import moment from 'moment-timezone';
import { useSelector, useDispatch } from 'react-redux';
import { ErrorBoundary, ParticipantAvatar } from 'components';
import { FiMenu } from 'react-icons/fi';
import {
    BrandLogo,
    ButtonContainer,
    DropItem,
    HeaderStyled,
    Notifications,
    ScrollArea,
    SideBarIcon,
    StyledMobileLogo,
    WrapperContainer,
} from '../styles';
import {
    navigateTo,
    setLayout,
    setPrivateChatAttendeeCall,
    toggleChatComponent,
    toggleSchedulesComponent,
} from 'modules/actions';
import {
    Dot,
    // Badge,
    HeaderButton,
    SpaceHolder,
} from '../components';
import { CalendarIcon, ChatIcon, ParticipantIconSvg } from 'components/Icons';
import { usePrevious } from 'modules/hooks/useComponent';
import Notification from 'assets/img/icons/notification.svg';
import { useIdleTimer } from 'react-idle-timer';
import { useHistory } from 'react-router-dom';
import * as selectors from 'modules/selectors';
import * as action from 'modules/actions';

export const MobileHeader = () => {
    const headerImage = useSelector(getHeaderImage);
    const assets = useSelector(getAssets);
    const notification = useSelector(getNotification);
    const privateChatAttendee = useSelector(getBreakoutAttendeeForPrivateChat);
    const layout = useSelector(getLayout);
    const dispatch = useDispatch();
    const chatDisplay = useSelector(getChatComponentDisplayMode);
    const schedulesDisplay = useSelector(getSchedulesComponentDisplayMode);
    const previousNotification: any = usePrevious(notification);
    const [notified, setNotified] = useState(true);
    const pirvateNotifier = useSelector(getPrivateMsgNotification);
    const settings = useSelector(getSettings);
    const [hideHeader, setHideHeader] = useState(false);
    const history = useHistory();
    const mobileView = useSelector(getMobileModde);
    const themeColor = useSelector(selectors.getEventThemeColor);
    const participantDisplay = useSelector(selectors.getChatParticipantState);

    useEffect(() => {
        if (privateChatAttendee && !chatDisplay) dispatch(toggleChatComponent(!chatDisplay));
    }, [privateChatAttendee, chatDisplay]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (
            previousNotification &&
            Array.isArray(previousNotification) &&
            previousNotification.length !== notification.length
        )
            setNotified(true);
    }, [previousNotification, notification]); // eslint-disable-line react-hooks/exhaustive-deps

    const checkUrl = (message: string) => {
        const matches = message.match(/\bhttps?:\/\/\S+/gi);
        if (matches && Array.isArray(matches) && matches.length > 0) {
            window.location.assign(matches[0]);
        }
    };
    const toggleSidebarMenu = () => {
        const defaultSettings = {
            navbar: { display: !layout.navbar.display },
        };
        dispatch(setLayout(defaultSettings));
    };
    // const toggleChat = () => {
    //     dispatch(setPrivateChatAttendeeCall(null));
    //     dispatch(toggleChatComponent(!chatDisplay));
    // };
    const toggleChat = () => {
        dispatch(setPrivateChatAttendeeCall(null));
        // dispatch(toggleChatComponent(!chatDisplay));
        if (participantDisplay) {
            dispatch(action.toggleParticipant(false));
            dispatch(action.toggleChatComponent(true));
        } else dispatch(action.toggleChatComponent(!chatDisplay));
    };

    const toggleSchedules = () => {
        dispatch(toggleSchedulesComponent(!schedulesDisplay));
    };
    useIdleTimer({
        timeout: 7000,
        onIdle: () => setHideHeader(true),
        onActive: () => setHideHeader(false),
        debounce: 500,
    });
    const isBreakout = settings?.extraParams && settings?.extraParams?.isBreakout;

    const ParticipantIcon = () => ParticipantIconSvg(themeColor || 'var(--theme-color)');

    const toggleParticipantChat = () => {
        if (participantDisplay) {
            dispatch(action.toggleChatComponent(false));
            dispatch(action.toggleParticipant(false));
        } else {
            dispatch(action.toggleParticipant(true));
            dispatch(action.toggleChatComponent(true));
        }

        // dispatch(toggleChatComponent(!chatDisplay));
    };
    return (
        <ErrorBoundary>
            {/* <LoungeProfileModal /> */}
            <HeaderStyled className="sticky-top" isBreakout={isBreakout} hideHeader={hideHeader}>
                <WrapperContainer>
                    <div className="d-flex flex-row align-items-center justify-content-between">
                        {!isBreakout && (
                            <ButtonContainer>
                                <SideBarIcon onClick={toggleSidebarMenu}>
                                    <FiMenu size={25} />
                                </SideBarIcon>
                                <SpaceHolder className="d-none d-sm-none d-md-block" />
                                <SpaceHolder className="d-none d-sm-none d-md-block" />
                                <SpaceHolder className="d-none d-sm-none d-md-block" />
                                <SpaceHolder className="d-none d-sm-none d-md-block" />
                            </ButtonContainer>
                        )}
                        <StyledMobileLogo mobileView={mobileView}>
                            {isBreakout && settings?.layoutTitle?.title ? (
                                <p className="text-truncate header-title">{settings?.layoutTitle?.title}</p>
                            ) : (
                                <BrandLogo
                                    src={`${assets.imagesUrl}${headerImage}`}
                                    alt="Event Header"
                                    onClick={() => dispatch(navigateTo({ history, path: '/lobby' }))}
                                />
                            )}
                        </StyledMobileLogo>
                        <ButtonContainer>
                            <HeaderButton icon={CalendarIcon} onClick={toggleSchedules} strokeWidth={2} />
                            <HeaderButton y={-1.25}>
                                <Notifications
                                    disabled={notification.length === 0}
                                    drop="left"
                                    id="dropdown-button-drop-left"
                                    key="left"
                                    title={
                                        <React.Fragment>
                                            {/* <NotificationIcon onc/> */}
                                            <img
                                                src={Notification}
                                                width="20"
                                                height="20"
                                                alt="notification"
                                                className="header-notify"
                                                onClick={() => setNotified(false)}
                                            />

                                            {/* {notification.length > 0 && <Badge>{notification.length}</Badge>} */}
                                            {/* {notification.length > 0 && <Dot />} */}
                                            {notified && <Dot />}
                                        </React.Fragment>
                                    }
                                >
                                    <span className="day-title">Today</span>
                                    <ScrollArea className="mt-1">
                                        {notification.map((item, key) => {
                                            const { message, created_at, ...entry } = item;
                                            const uid = get(item, 'uid');
                                            return (
                                                <DropItem
                                                    key={key}
                                                    className="p-0 text-wrap"
                                                    onClick={() => checkUrl(message)}
                                                >
                                                    <div className="d-flex align-items-center position-relative">
                                                        <div className="space" />
                                                        <div className="sender flex-shrink-0">
                                                            <ParticipantAvatar uid={uid} {...entry} />
                                                        </div>
                                                        <div className="space" />
                                                        <div className="body flex-grow-1 flex-wrap">
                                                            <strong>{get(entry, 'name', 'Organizer')}</strong> {message}
                                                        </div>
                                                        <div className="space" />
                                                        <div className="date flex-shrink-0 align-content-end flex-wrap text-right">
                                                            <small>{moment(created_at * 1000).fromNow()}</small>
                                                        </div>
                                                        <div className="space" />
                                                    </div>
                                                </DropItem>
                                            );
                                        })}
                                    </ScrollArea>
                                </Notifications>
                            </HeaderButton>
                            <HeaderButton
                                icon={ChatIcon}
                                iconClicked={toggleChat}
                                scale={1.28}
                                strokeWidth={11}
                                showNotification={pirvateNotifier}
                            />
                            <HeaderButton
                                icon={ParticipantIcon}
                                iconClicked={toggleParticipantChat}
                                scale={1.28}
                                strokeWidth={11}
                                color="#1a1a1a"
                                themeColor="#1a1a1a"
                            />
                        </ButtonContainer>
                    </div>
                </WrapperContainer>
            </HeaderStyled>
        </ErrorBoundary>
    );
};

export default MobileHeader;
