import { IStoreState } from 'modules/types';
export const getTotalRaisedHands = (state: IStoreState) => state.breakout.raisedHands;
export const getRaisedHandModalState = (state: IStoreState) => state.breakout.toggleRaisedHandModal;
export const getWaitingModalState = (state: IStoreState) => state.breakout.toggleWaitingRoomModal;
export const getBreakoutAttendeeForPrivateChat = (state: IStoreState) => state.breakout.privateChatAttendeeUid;
export const getContentShareState = (state: IStoreState) => state.breakout.isContentSharing;
export const getDeviceSetupCompState = (state: IStoreState) => state.breakout.isDeviceSetupComp;
export const showMeetingClosePopup = (state: IStoreState) => state.breakout.meetingClosePopup;
export const getMeetingExtendedFlag = (state: IStoreState) => state.breakout.isMeetingExended;
export const getModeratorExitDate = (state: IStoreState) => state.breakout.moderatorExitDate;
export const getRoomEndDate = (state: IStoreState) => state.breakout.roomEndDate;
