import React from 'react';
import * as ReactRedux from 'react-redux';
import * as selectors from 'modules/selectors';
import { AvatarContainer } from 'components/Login/style';
import { avatarUrl } from 'components/Login/common';
interface AvatarProperties extends React.PropsWithChildren<any> {
    avatar?: any;
    firstName?: string;
    lastName?: string;
}

export function AvatarNew({ avatar: propertiesAvatar, ...properties }: AvatarProperties) {
    const profileData = ReactRedux.useSelector(selectors.getProfileData);
    const avatarData = ReactRedux.useSelector(selectors.getAvatar);
    const initials = getInitialsFromProperties(properties) || getInitialsFromProperties(profileData);
    const [avatar, setAvatar] = React.useState<string | undefined>();
    const source = React.useMemo(
        () => avatar || propertiesAvatar || avatarData,
        [propertiesAvatar, avatar, avatarData],
    );
    const mounted = React.useRef(true);
    React.useEffect(() => () => !(mounted.current = false) && undefined, []);
    return (
        <AvatarContainer className="dismiss-off">
            {/* <div className="initials">{initials}</div> */}
            {source ? (
                <div className="image">
                    <img alt="avatar" src={source} onError={onError} />
                </div>
            ) : (
                <div className="initials">{initials}</div>
            )}
            {properties.children}
        </AvatarContainer>
    );
    function onError() {
        if (mounted.current && source) {
            avatarUrl(source).then((resolved) => mounted.current && setAvatar(resolved));
        }
    }
}

function getInitials(fullName?: string): string {
    if (!fullName) return '';
    const fragments = fullName
        .split(' ')
        .map((text) => text.trim())
        .filter((text) => text.length);
    const total = fragments.length;
    return total > 1 ? `${fragments[0].charAt(0)}${fragments[total - 1].charAt(0)}`.toUpperCase() : '';
}
function getInitialsFromProperties({ firstName, lastName }: AvatarProperties): string {
    return firstName && lastName ? getInitials(`${firstName} ${lastName}`) : '';
}
