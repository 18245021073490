import styled from 'styled-components/macro';
import { Img } from 'react-image';

export const Container = styled('div') <{ fillColor: string }>`
    width: 100vw;
    height: max-content;
    background-color: ${({ fillColor }) => fillColor};
    backdrop-filter: saturate(180%) blur(5px);
    padding: 16px 20px;
`

export const BrandLogo = styled(Img)`
    height: 68px;
`