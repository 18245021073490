import React from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import * as selectors from 'modules/selectors';
import { defaultFavicon } from 'modules/constants';
import isEmpty from 'lodash/isEmpty';

const PageTitle = () => {
    const isDiscovery = useSelector(selectors.getRoute)?.eventName === "organization";
    const organization = useSelector(selectors.getOrganization);
    const event = useSelector(selectors.getEvent);
    const assets = useSelector(selectors.getAssets);

    const background = React.useMemo(() => {
        if (isDiscovery) {
            return {
                favicon: isEmpty(organization?.faviconUrl)
                    ? defaultFavicon
                    : `${assets.imagesUrl}${organization?.faviconUrl}`,
            };
        } else {
            return {
                favicon: isEmpty(event?.welcome?.favicon?.imageUrl)
                    ? isEmpty(organization?.faviconUrl)
                        ? defaultFavicon
                        : `${assets.imagesUrl}${organization?.faviconUrl}`
                    : `${assets.imagesUrl}${event?.welcome?.favicon?.imageUrl}`,
            };
        }
    }, [event.welcome.favicon, organization.faviconUrl, assets, isDiscovery]);

    return (
        <>
            <Helmet>
                <title>{isDiscovery ? organization.name : `${event.name || 'Shared Studios '}`}</title>
                <link rel="icon" href={`${background.favicon}?v=${Date.now()}`} />
            </Helmet>
        </>
    );
};
export default React.memo(PageTitle);
