import { IStoreState } from 'modules/types';
import Avatar from 'modules/utils/Avatar';
import firebase from 'firebase/app';

export const getErrors = (state: IStoreState) => state.user.error;
export const getLoading = (state: IStoreState) => state.user.isLoading;
export const getLoungeFlag = (state: IStoreState) => state.user.profile.isLoungeRegistered;
export const getFirebaseAccessToken = (state: IStoreState) => state.user.firebaseToken;
// export const getProfileFetchingStatus = (state: IStoreState) => state.user.isUserProfileFetching;
export const getUser = (state: IStoreState) => state.user;
export const getUserProfile = (state: IStoreState) => state.user.profile;
export const getUserUid = (state: IStoreState) => (state.user && firebase.auth().currentUser?.uid) || '';

/* Host Event Property */

/* Profile Avatars */
export const getProfileAvatar = (state: IStoreState) => state.user.profile.avatar;
export const getParticipantAvatar = (uid: any) => (state: IStoreState) =>
    Avatar.toUrl(uid, state.organization.avatars[uid]);
export const getParticipantAvatars = (state: IStoreState) => state.organization.avatars;
export const getIsUserAuth = (state: IStoreState) => state.user.isAuth;
export const getLinkStatus = (state: IStoreState) => state.user.isLinkInvalid;
export const getProfileModifiedStatus = (state: IStoreState) => state.user.isModified;
export const getUserAdminRole = (state: IStoreState) => state.user.profile.isAdmin;

export const getEventId = (state: IStoreState) => state.link.eventId;
export const getOrganizationId = (state: IStoreState) => state.link.organizationId;
