import { createAction } from 'redux-act';
import { IEvent, Ipayload } from 'modules/reducers';

export const fetchOrganization = createAction('Fetch Organization');
export const fetchOrg = createAction('Fetch Org');
export const fetchEvents = createAction('Fetch Events');
export const setGlobalLink = createAction<string>('set global link');
export const fetchOrganizationSuccess = createAction<Ipayload>('Fetch Organization Success');
export const fetchOrgSuccess = createAction<Ipayload>('Fetch Org Success');
export const fetchEventsSuccess = createAction<IEvent[]>('Fetch Events Success');
export const setAssets = createAction<any>('set assets');
