import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormHeader, Invalid, Space } from '../common/index';
import * as selectors from 'modules/selectors';
import * as actions from 'modules/actions';
import { Input, Submit } from '../inputs';
import { Label } from '../fieldset/label';
import is from 'classnames';
import { Mode } from '../constants/constants';
import cloneDeep from 'lodash/cloneDeep';
import { Button, Link, Horizontal } from '../style';

const EmailMode = () => {
    const [preview, setPreview] = React.useState<'show' | 'hide'>('show');
    const dispatch = useDispatch();
    const authData = useSelector(selectors.getloginData);
    const state = cloneDeep(authData);
    const error = useSelector(selectors.getLoginErrors);
    const [loginValidation, setError] = useState({
        password: '',
    });

    useEffect(() => {
        if (error) {
            setError({ password: error.message });
        }
    }, [error]);

    const handleInputChange = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;
        const obj = {
            ...state,
            [name]: value,
        };
        dispatch(actions.setAuthPassword(obj));
    };

    const onSubmit = (e: any) => {
        e.preventDefault();
        if (checkPasswordValidity()) {
            dispatch(actions.signinUser({ ...state }));
            dispatch(actions.authFailure(''));
        }
    };

    const checkPasswordValidity = () => {
        let formIsValid = true;
        if (!state.password) {
            setError((val) => ({ ...val, password: 'Password is required' }));
            formIsValid = false;
        }
        dispatch(actions.authLoginErrors(''));
        return formIsValid;
    };

    const backBtn = () => {
        dispatch(actions.authMode(Mode.initial));
        dispatch(actions.isUserRegistred(Mode.initial));
    };

    return (
        <React.Fragment>
            <FormHeader title="Please enter your Password" hasPoweredByLogo={true} />
            <React.Fragment>
                <Space />
                <Invalid className={is({ password: true })} preview={{ type: preview, onClick: setPreview }}></Invalid>
                <Label field="password" labelStyle={{ flexWrap: 'wrap', display: 'flex', alignItems: 'baseline' }}>
                    {'Password'}
                </Label>
                <Input
                    autoComplete={'new-password'}
                    id="password"
                    name="password"
                    placeholder={'Enter password'}
                    type="password"
                    value={state.password}
                    autoFocus={true}
                    onChange={(event) => handleInputChange(event)}
                    style={{ display: preview === 'show' ? '' : 'none' }}
                />
                {preview !== 'show' && (
                    <React.Fragment>
                        <Input
                            style={{ marginTop: '5px' }}
                            autoComplete="off"
                            name="password"
                            placeholder={'Enter password'}
                            type="text"
                            value={state.password}
                            autoFocus={true}
                            onChange={(event) => handleInputChange(event)}
                        />
                    </React.Fragment>
                )}

                <span style={{ color: 'red' }}>{loginValidation.password}</span>
                <Space height={1.25} />
                <Space height={0.25} />
                <Horizontal>
                    <Link onClick={() => dispatch(actions.authMode(Mode.forgotPassword))}>Forgot password?</Link>
                </Horizontal>
            </React.Fragment>
            <Space />
            <Horizontal>
                <Button className="outline" onClick={backBtn}>
                    Back
                </Button>
                <Submit onClick={onSubmit}>Connect</Submit>
            </Horizontal>
        </React.Fragment>
    );
};

export default EmailMode;
