import React from 'react';
import styled from 'styled-components/macro';
interface IProps {
    btnName?: string | React.ReactNode;
    className?: string;
    onClick?: () => void;
    disabled?: boolean;
    type?: 'button' | 'submit' | 'reset' | undefined;
}

const Button = styled.button<IProps>`
    font-size: 16px;
    display: inline-block !important;
    &.margin {
        margin-right: 1rem;
    }

    &.loader {
        & .loader-margin {
            margin: 0 auto !important;
        }
    }

    line-height: 40px;
    min-width: 140px;
    border-radius: 2px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    &.primary-btn {
        color: #ffffff;
        width: 130px;
        border: 0;
        background: #0057e4;
    }
    &.join-btn {
        border-radius: 4px;
        font-size: 14px;
        font-weight: bold;
        height: 30px;
        line-height: 30px;
        margin-right: 20px;
        border: 0;
        color: #ffffff;
        background: #0057e4;
    }
    &.secondary-btn {
        border: solid 1px #0057e4;
        background: #ffffff;
        color: #0057e4;
        padding: 0 20px;
    }
    &.danger-btn {
        background: var(--red);
        color: #ffffff;
        padding: 0 20px;
        border: solid 1px #e8ecf1;
    }
    &.disable-btn {
        background: #e8ecf1;
        color: var(--grey);
        padding: 0 20px;
        border: solid 1px #e8ecf1;
    }
    &.secondary-disable-btn {
        background: var(--dark-grey);
        color: var(--grey);
        padding: 0 20px;
        border: solid 1px #e8ecf1;
    }
    &:focus {
        outline: none;
    }
`;

export function CommonButton({ btnName, className, onClick, disabled, type }: IProps) {
    return (
        <Button className={className} onClick={onClick} disabled={disabled} type={type}>
            {btnName}
        </Button>
    );
}
