import { createAction } from 'redux-act';
import { IKeynote } from 'modules/schemas';

export const fetchSchedule = createAction('Fetch Schedule');
export const fetchScheduleSuccess = createAction<IKeynote[]>('Fetch Schedule Success');
export const fetchScheduleFailed = createAction('Fetch Schedule Failed');
export const checkChimeUserLimit = createAction('Fetch breakout limit');
export const checkRoomLimitSuccess = createAction<any>('room limit data');
export const checkRoomUserSuccess = createAction<any>('room limit data');
export const removeScheduleUserLimitListner = createAction('remove limit data');
export const addScheduleUserLimitListner = createAction('add limit data');
export const addScheduleUserListner = createAction('add user listener data');
export const addScheduleBlockListner = createAction('add user block listener data');
export const blockUserSuccess = createAction<any>('block limit data');
