import { createReducer } from 'redux-act';

import {
    setLiveGroupDetail,
    setBreakoutGroupDetail,
    resetState,
    removeBreakoutGroup,
    getGroupDetail,
} from 'modules/actions';

export type GroupState = {
    id: string;
    groupId: string | null;
    groupName: string | null;
    groupDuration: number | null;
    groupExpiryTimeStamp: number | null;
    users: string[];
    isInBreakoutGroup: boolean;
    isCalledInToRoom: boolean;
    isCalledBackFromRoom: boolean;
};

export type BreakoutGroupState = {
    selectedGroup: GroupState;
    liveBreakoutGroup: GroupState;
};

const initialStateGroup = {
    id: '',
    groupId: null,
    groupName: null,
    groupDuration: null,
    groupExpiryTimeStamp: null,
    users: [],
    isInBreakoutGroup: false,
    isCalledInToRoom: false,
    isCalledBackFromRoom: false,
};

const initialState: BreakoutGroupState = {
    selectedGroup: initialStateGroup,
    liveBreakoutGroup: initialStateGroup,
};

export const breakoutGroups = createReducer<BreakoutGroupState>({}, initialState);

breakoutGroups.on(setLiveGroupDetail, (state: BreakoutGroupState, payload: GroupState) => ({
    ...state,
    liveBreakoutGroup: { ...payload },
}));

breakoutGroups.on(setBreakoutGroupDetail, (state: BreakoutGroupState, payload: GroupState) => ({
    ...state,
    selectedGroup: { ...payload },
}));

breakoutGroups.on(getGroupDetail, (state: BreakoutGroupState) => ({
    ...state,
    liveBreakoutGroup: { ...state.liveBreakoutGroup, isInBreakoutGroup: true }
}));

breakoutGroups.on(resetState, () => initialState);
breakoutGroups.on(removeBreakoutGroup, () => initialState);
