import { createReducer } from 'redux-act';
import {
    resetState,
    fetchPolls,
    fetchPollSuccess,
    fetchPollFailed,
    selectFormId,
    selectedPollIdResponse,
    showPollModal,
    setCurrentModalType,
    fetchPollResultSuccess,
    fetchPollResultLiveSuccess,
    setDocumentId,
    setPollAnswered,
    setGroupData,
    setPollExclusiveId,
    serUserPollGroupTag,
    stopPoll,
    setEventGroupData
} from 'modules/actions';

export interface IPolls {
    id: string;
    title: string;
    docId: string;
    self: { href: string };
    settings: { is_public: boolean; is_trial: boolean };
    theme: { href: string };
    type: string;
}

export interface IPollResults {
    answers: any[];
    defination: any;
    submitted_at: string;
    userId: string;
}

export interface IStaticGroupData {
    isCheckedEventChat: boolean,
    isCheckedParticipant: boolean,
    isCheckedRoomChat: boolean,
    isCheckedVisibility: boolean
}
export interface IGroupResult {
    id: string;
    isCheckedPolls: boolean;
    name: boolean;
    users: any[];
}

export type PollReducerState = {
    fetching: boolean;
    list: IPolls[];
    pollResults: IPollResults[];
    pollLiveResults: IPollResults[];
    showPollModal: boolean;
    currentModalType: string | null;
    selectedFormId?: string | null;
    selectedPollResponse?: any;
    selectedDocumetId?: string | null;
    isAnswered: boolean;
    isPollExclusive: boolean;
    pollExclusiveId: string | null;
    userPollGroupTag: string;
    groupData: any;
    staticGroupData: IStaticGroupData
};

const initialState: PollReducerState = {
    fetching: false,
    isAnswered: false,
    showPollModal: false,
    currentModalType: null,
    list: [],
    pollResults: [],
    pollLiveResults: [],
    isPollExclusive: false,
    pollExclusiveId: null,
    userPollGroupTag: '',
    groupData: {},
    staticGroupData: {
        isCheckedEventChat: true,
        isCheckedParticipant: true,
        isCheckedRoomChat: true,
        isCheckedVisibility: true
    }
};

export const polls = createReducer<PollReducerState>({}, initialState);
polls.on(fetchPolls, (state: PollReducerState) => ({
    ...state,
    fetching: true,
}));

polls.on(fetchPollSuccess, (state: PollReducerState, payload: IPolls[]) => ({
    ...state,
    fetching: false,
    list: payload,
}));

polls.on(fetchPollFailed, (state: PollReducerState) => ({
    ...state,
    fetching: false,
}));
polls.on(selectFormId, (state: PollReducerState, payload: any) => ({
    ...state,
    selectedFormId: payload?.formId,
}));
polls.on(selectedPollIdResponse, (state: PollReducerState, payload: any) => ({
    ...state,
    selectedPollResponse: {
        ...payload,
        pollTriggerId: payload?.pollTriggerId || state?.selectedPollResponse?.pollTriggerId,
    },
}));

polls.on(stopPoll, (state: PollReducerState, payload: any) => ({
    ...state,
    selectedPollResponse: { ...payload?.selectedPollResponse, isStopped: true },
}));
polls.on(showPollModal, (state: PollReducerState, payload: boolean) => ({
    ...state,
    showPollModal: payload,
}));
polls.on(setCurrentModalType, (state: PollReducerState, payload: string | null) => ({
    ...state,
    currentModalType: payload,
}));
polls.on(fetchPollResultSuccess, (state: PollReducerState, payload: IPollResults[]) => ({
    ...state,
    pollResults: payload,
}));

polls.on(fetchPollResultLiveSuccess, (state: PollReducerState, payload: IPollResults[]) => ({
    ...state,
    pollLiveResults: payload,
}));

polls.on(setDocumentId, (state: PollReducerState, payload: string | null) => ({
    ...state,
    selectedDocumetId: payload,
}));
polls.on(setPollAnswered, (state: PollReducerState, payload: boolean) => ({
    ...state,
    isAnswered: payload,
}));

polls.on(setGroupData, (state: PollReducerState, payload: boolean) => ({
    ...state,
    isPollExclusive: payload,
}));

polls.on(setPollExclusiveId, (state: PollReducerState, payload: string | null) => ({
    ...state,
    pollExclusiveId: payload,
}));

polls.on(serUserPollGroupTag, (state: PollReducerState, payload: string) => ({
    ...state,
    userPollGroupTag: payload,
}));

polls.on(setEventGroupData, (state: PollReducerState, payload: string) => ({
    ...state,
    groupData: payload,
}));

polls.on(resetState, () => initialState);

