import { SponsorBooth } from 'modules/utils/Firebase/Api';
import { eventChannel } from 'redux-saga';
import { IAttendeeProfile } from 'modules/schemas';
import { database } from 'modules/utils/Firebase';

interface IAttendeeDetails {
    organizationId: string;
    eventId: string;
    uid: string;
    sponsorId: string;
}

// export const getSponsorBoothUser = (props: IAttendeeDetails) => {
//     const connectionRef = SponsorBooth.reference(props.organizationId, props.eventId);
//     const roomUserListListner = eventChannel((emit) => {
//         connectionRef.on('value', (snapshot: any) => {
//             if (snapshot.val()) return emit(snapshot.val());
//             else return emit(false);
//         });
//         return () => {
//             connectionRef.off();
//         };
//     });
//     return roomUserListListner;
// };

export const getSponsorBoothUser = (props: IAttendeeDetails) => {
    const connectionRef = SponsorBooth.reference(props.organizationId, props.eventId);
    const connectionBrListner = eventChannel((emit) => {
        const ref = database.ref('.info/connected');
        ref.on('value', (snapshot: any) => {
            if (snapshot.val() === false) {
                return emit(snapshot.val());
            }
            connectionRef
                .onDisconnect()
                .remove()
                .then(() => {
                    return emit(true);
                });
        });
        return () => {
            ref.off();
        };
    });
    return connectionBrListner;
};

export const removeSponsorBoothUserConnection = async (props: IAttendeeDetails) => {
    const connectionRef = SponsorBooth.roomRef(props.uid, props.organizationId, props.eventId, props.sponsorId);
    await connectionRef.remove();
};

export const addSponsorBoothUserConnection = async (props: IAttendeeDetails, profile: IAttendeeProfile) => {
    const connectionRef = SponsorBooth.roomRef(props.uid, props.organizationId, props.eventId, props.sponsorId);
    await connectionRef.set(profile);
};

export const updateSponsorBoothUserUserConnection = async (props: IAttendeeDetails, profile: any) => {
    const connectionRef = SponsorBooth.roomRef(props.uid, props.organizationId, props.eventId, props.sponsorId);
    await connectionRef.update(profile);
};

export const getSponsorBoothUserData = (props: IAttendeeDetails) => {
    const connectionRef = SponsorBooth.reference(props.organizationId, props.eventId);
    const roomUserListListner = eventChannel((emit) => {
        connectionRef.on('value', (snapshot: any) => {
            if (snapshot.val()) return emit(snapshot.val());
            else return emit(false);
        });
        return () => {
            connectionRef.off();
        };
    });
    return roomUserListListner;
};
