import React from 'react';

export const SponsorBreakout = {
    settings: {
        layout: {
            config: {
                header: { display: true },
                footer: { display: true },
                navbar: { display: true },
            },
        },
    },
    isPrivate: true,
    eventName: 'sponsor_booth_room',
    routes: [
        {
            path: '/:organizationId/:eventSlug/sponsor-room/:room',
            component: React.lazy(() => import('./SponsorBreakout')),
        },
    ],
};

export const SponsorBreakoutMobileConfig = {
    settings: {
        layout: {
            config: {
                header: { display: true },
                footer: { display: true },
                navbar: { display: true },
            },
        },
    },
    eventName: 'sponsor_booth_room',
    isPrivate: true,
    routes: [
        {
            path: '/:organizationId/:eventSlug/sponsor-room/:room',
            component: React.lazy(() => import('./MobileBreakout/MobileSponsorBreakout')),
        },
    ],
};
