import { createReducer } from 'redux-act';
import {
    addRaisedHandsData,
    raisedHandModalState,
    waitingRoomModalState,
    setPrivateChatAttendeeCall,
    setContentShareStatus,
    removeUserFromRoom,
    resetState,
    setDeviceCompActive,
    meetingCloseTime,
    setModeratorExitDate, setRoomEndDate,
} from 'modules/actions';
export type BreakoutRoomWihoutCacheState = {
    raisedHands: any;
    toggleRaisedHandModal: boolean;
    toggleWaitingRoomModal: boolean;
    privateChatAttendeeUid: any;
    isContentSharing: any;
    isDeviceSetupComp: boolean;
    meetingClosePopup: boolean;
    isMeetingExended: boolean;
    moderatorExitDate: number | null;
    roomEndDate: number;
};

const initialState: BreakoutRoomWihoutCacheState = {
    raisedHands: 0,
    toggleRaisedHandModal: false,
    toggleWaitingRoomModal: false,
    privateChatAttendeeUid: null,
    isContentSharing: null,
    isDeviceSetupComp: true,
    meetingClosePopup: false,
    isMeetingExended: false,
    moderatorExitDate: 0,
    roomEndDate: 0,
};

export const breakout = createReducer<BreakoutRoomWihoutCacheState>({}, initialState);

breakout.on(addRaisedHandsData, (state: BreakoutRoomWihoutCacheState, payload: any) => {
    return {
        ...state,
        raisedHands: payload,
    };
});

breakout.on(raisedHandModalState, (state: BreakoutRoomWihoutCacheState, payload: boolean) => {
    return {
        ...state,
        toggleRaisedHandModal: payload,
    };
});

breakout.on(waitingRoomModalState, (state: BreakoutRoomWihoutCacheState, payload: boolean) => {
    return {
        ...state,
        toggleWaitingRoomModal: payload,
    };
});

breakout.on(setPrivateChatAttendeeCall, (state: BreakoutRoomWihoutCacheState, payload: any) => {
    return {
        ...state,
        privateChatAttendeeUid: payload,
    };
});

breakout.on(setContentShareStatus, (state: BreakoutRoomWihoutCacheState, payload: any) => {
    return {
        ...state,
        isContentSharing: payload,
    };
});

breakout.on(removeUserFromRoom, () => initialState);
breakout.on(resetState, () => initialState);

breakout.on(setDeviceCompActive, (state: BreakoutRoomWihoutCacheState, payload: boolean) => {
    return {
        ...state,
        isDeviceSetupComp: payload,
    };
});
breakout.on(
    meetingCloseTime,
    (state: BreakoutRoomWihoutCacheState, payload: { show: boolean; meetingExtended: boolean }) => {
        return {
            ...state,
            meetingClosePopup: payload.show,
            isMeetingExended: payload.meetingExtended,
        };
    },
);
breakout.on(setModeratorExitDate, (state: BreakoutRoomWihoutCacheState, payload: number | null) => {
    return {
        ...state,
        moderatorExitDate: payload,
    };
});

breakout.on(setRoomEndDate, (state: BreakoutRoomWihoutCacheState, payload: number) => {
    return {
        ...state,
        roomEndDate: payload,
    };
});
