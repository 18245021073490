import firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/performance';
import 'firebase/database';
import 'firebase/functions';
import 'firebase/firestore';
import 'firebase/storage';
const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
export const analytics = firebaseApp.analytics();
firebaseApp.performance();
export const database = firebase.database();
export const auth = firebase.auth();
export const functions = firebase.functions();
export const fieldValue = firebase.firestore.FieldValue;
export const firestore = firebase.firestore();
firestore.settings({
    ignoreUndefinedProperties: true,
});
export default firebase;

// const enablePersisternece = async () => {
//     try {
//         await firebase.firestore().enablePersistence();
//         // await firebase.firestore().disableNetwork();
//         // console.log('enablePersistence');
//     } catch (e) {
//         console.error(e.code);
//     }
// };
// enablePersisternece();

/* ---- DEVELOPER ENVIRONMENT ---- */
if (window.location.origin.includes('localhost')) {
    // firestore.useEmulator('localhost', 8080);
    functions.useEmulator('localhost', 5001);
    // database.useEmulator('localhost', 9000);
    // functions.useEmulator('localhost', 8085);
    // firebase.auth().useEmulator('http://localhost:9099');
    // firebase.functions().useEmulator('localhost', 5001);
    // firebase.firestore().useEmulator('localhost', 5002);
    // firebase.analytics().setAnalyticsCollectionEnabled(false);
    // firebase.database().useEmulator('localhost', 9000);
}
