import React, { useState, useEffect, FC } from 'react';
import Modal from 'react-bootstrap/Modal';
import styled from 'styled-components/macro';

interface IPromptModalProps {
    message?: string;
    callback?: (ok: boolean) => void;
}

const StylModal = styled(Modal)`
    & .modal-title {
        color: #283747;

        font-size: 18px;
        font-weight: bold;

        font-style: normal;
        line-height: normal;
    }
    & .close {
        opacity: 0.9;
        outline: 0;
        border: 0;
        &:hover,
        &:focus {
            opacity: 0.9 !important;
            outline: 0;
            border: 0;
        }
    }
    .modal-dialog {
        max-width: 25rem;	
        .modal-title {
            font-size: 1.38rem;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            margin: 25px 10px;
            text-align: center;
        }
        .modal-footer {
            justify-content: center;
            padding: 0.75rem;
            flex-direction: row-reverse;
        }
    }
    button {
        height: 41px;
        line-height: 41px;
        border-radius: 4px;
        font-size: 14px;
        font-weight: bold;
        padding: 0.5rem 1rem;
        border-color: transparent;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border: 0;
        box-shadow: none;
        width: 127px;
        border-radius: 4px;
        &:hover, &:focus {
            box-shadow: none !important;
            outline:0;
        }
    }
    .confirm-btn {
        background-color: #327aff;
        color: #ffffff;
        &:hover, &:focus, &:active {
            border: 0;
            color: #ffffff;    
            background-color: #327aff;
        }
   }
   .decline-btn {
       background-color: #ffffff;
       border: 3px solid #327aff;
       color: #327aff;
       &:hover, &:focus, &:active {
           color: #327aff;
           background-color: #ffffff;
           border: 3px solid #327aff;
       ]
   }
`;
const Index: FC<IPromptModalProps> = ({ message, callback }) => {
    const [hidden, setHidden] = useState(false);

    const actionConfirmation = (ok: boolean) => {
        setHidden(true);
        if (callback !== undefined) {
            callback(ok);
        }
    };

    const yesConfirmation = () => actionConfirmation(true);

    const noConfirmation = () => actionConfirmation(false);

    useEffect(() => setHidden(false), [callback]);

    return (
        <StylModal
            size="sm"
            show={!hidden}
            backdrop
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={noConfirmation}
        >
            <Modal.Body>
                <Modal.Title>{message}</Modal.Title>
            </Modal.Body>
            <Modal.Footer>
                <button className="confirm-btn" onClick={yesConfirmation}>
                    Yes, Leave
                </button>
                <button className="decline-btn" onClick={noConfirmation}>
                    No, Stay
                </button>
            </Modal.Footer>
        </StylModal>
    );
};

export default Index;
