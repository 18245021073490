import { FC } from 'react';
import { useSelector } from 'react-redux';
import './DiscoveryFooter.scss';
import { Container, BrandLogo } from './styles';
import { getOrganizationSocialLinks } from 'modules/selectors';
import { defaultCompanyLogo } from 'modules/constants';

interface Props {
    logoUrl: string;
    fillColor: string;
}

export const DiscoveryFooter: FC<Props> = ({ logoUrl, fillColor }) => {
    const socials = useSelector(getOrganizationSocialLinks);

    return (
        <Container className="footer" {...{ fillColor }}>
            <div className="logos">
                <BrandLogo
                    className="shared-studios"
                    src={defaultCompanyLogo}
                    alt="Shared Studios"
                />
                <BrandLogo className="partner" src={logoUrl} alt="partner" />
            </div>
            <div className="privacy-policy">
                <p>© Shared Studios 2021</p>
                <a href="https://www.sharedstudios.com/vcsp-privacy-policy" target="_blank" rel="noreferrer">
                    Privacy Policy
                </a>
                <a href="https://www.sharedstudios.com/vcsp-tos" target="_blank" rel="noreferrer">
                    Terms and Conditions
                </a>
            </div>
            <div className="social-media">
                {Object.keys(socials).map((key: string, index: number) => socials[key] !== "" && <a key={index} href={socials[key]} target="_blank" rel="noreferrer">{key}</a>)}
            </div>
        </Container>
    );
};

export default DiscoveryFooter;
