import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useHistory } from 'react-router';
import { navigateTo } from 'modules/actions';

import { RegularButton } from './RegularButton';
import { useDispatch } from 'react-redux';

interface IProps extends RouteComponentProps<any> {
    roomUuid: string;
}

const JoinButtonComponent = ({ roomUuid }: IProps) => {
    const history = useHistory();
    const dispatch = useDispatch();
    // const { utcStartTimeMillis } = props.conference;
    // const currentTime = moment.utc().valueOf();
    // const isDisabled = currentTime <= utcStartTimeMillis;
    const isDisabled = false;
    const handleClick = (e: React.SyntheticEvent) => {
        if (isDisabled) {
            e.preventDefault();
        } else {
            dispatch(navigateTo({ history, path: `/track/conference/${roomUuid}` }));
        }
    };
    return (
        <RegularButton onClick={handleClick} disabled={isDisabled}>
            Join
        </RegularButton>
    );
};
export const JoinButton = withRouter(JoinButtonComponent);
export default JoinButton;
