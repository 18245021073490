import isObject from 'lodash/isObject';
import has from 'lodash/has';
import isString from 'lodash/isString';
import get from 'lodash/get';
import { functions } from './Firebase';
import { IUserProfile } from '../reducers';
import { IKeynote } from 'modules/schemas';
const auditIssue = functions.httpsCallable('auditIssue');

class ToccaUtils {
    /* Since access roles will be specific on a per-use basis, permissions require a different structure to designate if a
     * user is a presenter of "Room A", a guest attendee in "Room B", moderator of "Breakout Room C" or guest participant of "Breakout Room D"
     * To handle this variety of difference the userRoles structure and types will be different for
     * */
    static hasEventAccess(profile: IUserProfile, id: string, role: string): boolean {
        if (has(profile, `eventAccess.${role}`)) {
            // If a specific role is passed, the association must exist in the user profile
            let isAllowed = false;

            const access = get(profile, `eventAccess.${role}`, []);
            const accessObject = access.filter(isObject);
            const accessString = access.filter(isString);

            if (accessObject) {
                isAllowed = accessObject.some((access: any) => access?.roomId?.indexOf(id) >= 0);
            }

            if (accessString && !isAllowed) {
                isAllowed = accessString.some((access: string) => access.indexOf(id) >= 0);
            }

            return isAllowed;
        }
        return false;
    }

    static hasRoomTimeStamp(profile: IUserProfile, room: IKeynote, role: string): IKeynote {
        const roomObj = { ...room };
        if (has(profile, `eventAccess.${role}`)) {
            // If a specific role is passed, the association must exist in the user profile

            const access = get(profile, `eventAccess.${role}`, []);
            const accessObject = access.filter(isObject);

            if (accessObject) {
                const accessRoomObj = accessObject.find((access: any) => access?.roomId?.indexOf(room.id) >= 0);
                roomObj.utcStartTimeMillis = Number(accessRoomObj?.utcStartTimeMillis) || room.utcStartTimeMillis;
                roomObj.durationMinutes = Number(accessRoomObj?.duration) || room.durationMinutes;
                if (role === 'moderator') {
                    roomObj.hasModeratorRights = access.indexOf(room.id) !== -1;
                }
            }
        }
        return { ...roomObj };
    }

    static setRoutes(config: any) {
        let routes = [...config.routes];
        if (config.settings || config.auth) {
            routes = routes.map((route) => {
                let auth: any = config.auth ? [...config.auth] : null;
                auth = route.auth ? [...auth, ...route.auth] : auth;

                return {
                    ...route,
                    isPrivate: config.isPrivate || false,
                    settings: { ...config.settings, ...route.settings },
                    eventName: config.eventName,
                    auth,
                    exact: true,
                };
            });
        }

        return [...routes];
    }

    static generateRoutesFromConfigs(configs: any) {
        let allRoutes: any[] = [];
        configs.forEach((config: any) => {
            allRoutes = [...allRoutes, ...this.setRoutes(config)];
        });
        return allRoutes;
    }
}

export default ToccaUtils;

// Issue types of 'auditIssue' logs used for recording runtime context for inspection
export enum AuditIssue {
    authentication = 'authentication',
    error = 'error',
    invalid = 'invalid',
    rejection = 'rejection',
    unspecified = 'unspecified',
}

// Issue logs
export interface IssueData {
    createdAt: number;
    eventId?: number;
    email?: string;
    message?: string;
    organizationId?: string;
    type: AuditIssue;
    readonly uid?: string;
}

export const issue = {
    authentication: (data: any) => auditIssue(createIssue(AuditIssue.authentication, data)).catch(console.warn),
    error: (data: any) => auditIssue(createIssue(AuditIssue.error, data)).catch(console.warn),
    invalid: (data: any) => auditIssue(createIssue(AuditIssue.invalid, data)).catch(console.warn),
    rejection: (data: any) => auditIssue(createIssue(AuditIssue.rejection, data)).catch(console.warn),
};

// scope actions
function createIssue(type: AuditIssue, data: any) {
    return { ...data, createdAt: Date.now(), type };
}
