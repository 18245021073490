import styled from 'styled-components/macro';
import { Img } from 'react-image';

export const Container = styled('div') <{ fillColor: string }>`
    width: 100vw;
    height: max-content;
    background-color:  ${({ fillColor }) => fillColor};
    backdrop-filter: saturate(180%) blur(5px);
    padding: 16px 20px;
    display: grid;
    align-items: center;
    justify-items: center;
    grid-auto-flow: column;
    column-gap: 20px;
    grid-template-columns: max-content 1fr;
`
export const Separator = styled('div')`
    width: 1px;
    height: 48px;
    margin-right: 10px;
    background: #000000;
`

export const BrandLogo = styled(Img)`
    height: 48px;
`