import React, { PropsWithChildren } from 'react';
import kebabCase from 'lodash/kebabCase';
import styled from 'styled-components/macro';
import Header from 'components/Header';
import { Container, Col, Row } from 'react-bootstrap';

const View = styled.div``;

const Title = styled(Col)`
    color: var(--gray-shade-color);
    font-size: 1.35em;
    font-weight: 700;
    text-decoration: none;
    position: relative;
    text-align: center;
`;

const PageHeader = styled(Row)`
    && {
        align-items: flex-end;
    }
`;

interface IProps extends PropsWithChildren<any> {
    containerClassName?: string;
    contentContainerClassName?: string;
    identifier?: string;
    gradient?: boolean;
    left?: React.ReactNode;
    title?: string;
    right?: React.ReactNode;
    showTitle?: boolean;
    isLounge?: boolean;
    loungeTitle?: string;
    loungeClass?: boolean;
    sponsorId?: string | null;
}

export function PageFrame(props: IProps) {
    const identifier = props.identifier || kebabCase(props.title);
    const showTitle = props?.showTitle ?? true;
    return (
        <View id={identifier} className={`${props.className || ''} ${identifier}-container`}>
            {/*   APP HEADER  */}
            <Header />
            {/* PAGE CONTENT */}
            <Container className={props?.containerClassName || 'my-3'}>
                {/*  PAGE HEADER */}
                <PageHeader md={3} style={{ alignItems: 'center', display: 'flex', justifyItems: 'center' }}>
                    <Col>{props.left}</Col>
                    {showTitle && <Title>{props.title}</Title>}
                    <Col>{props.right}</Col>
                </PageHeader>
                {/*  PAGE BODY */}
                <Row className={props?.contentContainerClassName || 'mt-2'}>{props.children}</Row>

                {/*  PAGE FOOTER */}
                <div className="d-flex flex-row">{props.footer}</div>
            </Container>
        </View>
    );
}
