import config from 'modules/config';
import { PropsWithChildren, useEffect, useRef } from 'react';
import { getUserProfile } from 'modules/selectors';
import { useSelector } from 'react-redux';
import { LOUNGE_TYPES } from 'modules/constants';
import { ToccaUtils } from 'modules/utils';

const EmptyComponent = () => null;
const TransparentComponent = ({ children }: PropsWithChildren<any>) => children;

export const useComponent = () => {
    const profile = useSelector(getUserProfile);

    const isComponentEnabled = (key: string) => {
        if (config && !config[key]) {
            return true;
        }
        return ToccaUtils.hasEventAccess(profile, LOUNGE_TYPES.RESTRICTED, 'accesses');
    };

    const createConditionalComponent = (featureKey: string) => {
        if (isComponentEnabled(featureKey)) {
            return TransparentComponent;
        } else {
            return EmptyComponent;
        }
    };

    return { createConditionalComponent };
};

export const usePrevious = (value: any) => {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    }, [value]); // Only re-run if value changes
    return ref.current;
};
