import { createReducer } from 'redux-act';
import {
    setSettings,
    setPageTitle,
    setLayout,
    setAuthSuccessLayout,
    setAuthFailureLayout,
    setMobileOrientation,
    setRoute,
} from 'modules/actions/settings';
import { Config, ILayout, INavigation } from 'modules/types/globals';
export interface IMobileViewMode {
    isPortrait: boolean;
    isLandscape: boolean;
}

export type settingReducerState = {
    layout: ILayout;
    config: Config;
    route: INavigation | null;
    isMobileDevice: boolean;
    mobileView: IMobileViewMode;
};

const initialState: settingReducerState = {
    layout: {
        header: { display: false },
        back: { display: false },
        navbar: { display: false },
        titleContainer: { display: true },
    },
    config: {
        layoutTitle: { title: '' },
    },
    isMobileDevice: false,
    route: null,
    mobileView: {
        isPortrait: false,
        isLandscape: false,
    },
};

export const settings = createReducer<settingReducerState>({}, initialState);
settings.on(setSettings, (state: settingReducerState, payload: Config) => ({
    ...state,
    config: { ...state.config, ...payload },
}));

settings.on(setLayout, (state: settingReducerState, payload: ILayout) => ({
    ...state,
    layout: { ...state.layout, ...payload },
}));

settings.on(setAuthSuccessLayout, (state: settingReducerState) => ({
    ...state,
    layout: {
        header: { display: true },
        back: { display: false },
        navbar: { display: false },
        titleContainer: { display: true },
    },
}));

settings.on(setAuthFailureLayout, (state: settingReducerState) => ({
    ...state,
    layout: {
        header: { display: false },
        back: { display: false },
        navbar: { display: false },
        titleContainer: { display: false },
    },
}));

settings.on(setPageTitle, (state: settingReducerState, payload: string) => ({
    ...state,
    config: {
        ...state.config,
        layoutTitle: { title: payload },
    },
}));

settings.on(setMobileOrientation, (state: settingReducerState, payload: any) => ({
    ...state,
    mobileView: payload,
}));

settings.on(setRoute, (state: settingReducerState, payload: INavigation) => ({
    ...state,
    route: payload,
}));
