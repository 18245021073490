import { SagaIterator } from 'redux-saga';
import { call, select } from 'redux-saga/effects';
import { ISagaAction } from 'modules/types';
import { buildSluggedRoute } from 'modules/utils/CommonService';
import { registrationSessionPropertyLink } from 'modules/selectors';

export const navigateToSaga = function* (action: ISagaAction<any>): SagaIterator {
    const { history, path } = action.payload;
    const { eventSlug, organizationId } = yield select(registrationSessionPropertyLink);

    const url = buildSluggedRoute(organizationId, eventSlug, path);

    yield call(history.push, url);
};

export default navigateToSaga;
