import React from 'react';
import is from 'classnames';
// import * as ReactHookForm from 'react-hook-form';
import { Fill, ThumbnailComponent, ThumbnailContainer } from 'components/Login/style';
import { Button, Horizontal } from 'components/Login/inputs';
import * as actions from 'modules/actions';
import { ImageFile } from 'components/Cropper/image';
import { Mode } from 'components/Login/constants/constants';
import { Space } from 'components/Login/common';
import { CaptureMediaDevices } from './CaptureMediaDevices';
import { useCapture } from './useCapture';
import { useDispatch } from 'react-redux';

export function Capture() {
    const stream = React.useRef<HTMLVideoElement>(null);
    const [streamDevice, setStreamDevice] = React.useState<string>();
    const [streamDisplay, setStreamDisplay] = React.useState('block');
    const [streamLoading, setStreamLoading] = React.useState(true);

    const capture = React.useRef<HTMLCanvasElement>(null);
    const [captureDisplay, setCaptureDisplay] = React.useState('none');
    const [snapshot, setSnapshot] = React.useState<ImageFile | null>(null);
    const dispatch = useDispatch();

    const {
        canvasFile,
        mediaDevices,
        mediaStream,
        selectedMediaDevice,
        startStreaming,
        stopStreaming,
        setMediaStream,
        setMediaStreamError,
    } = useCapture();

    // capture snapshot from stream
    const captureSnapshot = React.useCallback(() => {
        if (mediaStream !== null && stream.current && capture.current) {
            const context = capture.current.getContext('2d');
            if (context === null) return;
            const dimension = getCanvasDimension(stream.current);
            context.drawImage(stream.current, dimension.x, dimension.y, dimension.width, dimension.height);
            setCaptureDisplay('block');
            setStreamDisplay('none');
            setSnapshot(canvasFile(capture.current));
        }
    }, [canvasFile, capture, stream, mediaStream, setCaptureDisplay, setStreamDisplay, setSnapshot]);

    const clearSnapshot = React.useCallback(() => {
        if (stream.current && capture.current) {
            const context = capture.current.getContext('2d');
            if (context === null) return;
            const { height, width } = capture.current;
            context.clearRect(0, 0, width, height);
            setCaptureDisplay('none');
            setStreamDisplay('block');
            setSnapshot(null);
        }
    }, [capture, stream, setCaptureDisplay, setStreamDisplay, setSnapshot]);

    const selectMediaDevice = React.useCallback(
        (deviceId: string) => {
            setStreamLoading(true);
            setStreamDevice(deviceId);
            setMediaStream(null);
            if (stream.current) stream.current.srcObject = null;
        },
        [setMediaStream, setStreamDevice, setStreamLoading, stream],
    );

    React.useEffect(() => {
        if (stream.current) {
            // stream changed
            if (stream.current.srcObject !== mediaStream) {
                stream.current.srcObject = mediaStream;
                if (mediaStream === null) {
                    stream.current.load();
                } else {
                    stream.current
                        .play()
                        .then(() => setStreamLoading(false))
                        .catch(setMediaStreamError);
                }
            } else if (stream.current.srcObject === null) {
                setStreamLoading(true);
                startStreaming(streamDevice);
            }
        }
    }, [stream, startStreaming, mediaStream, setMediaStreamError, setStreamLoading, streamDevice]);
    React.useEffect(() => {
        if (stream.current && capture.current) {
            capture.current.height = stream.current.clientHeight;
            capture.current.width = stream.current.clientWidth;
        }
    }, [stream, capture]);
    React.useEffect(() => stopStreaming, [stopStreaming]);

    const cancelBtn = () => {
        dispatch(actions.authMode(Mode.profile));
    };

    return (
        <React.Fragment>
            <ThumbnailComponent>
                <ThumbnailContainer>
                    <div style={{ display: streamDisplay }}>
                        {streamLoading && <Fill className="placeholder">Loading Camera</Fill>}
                        <video id="stream" ref={stream} />
                    </div>
                    <div style={{ display: captureDisplay }}>
                        <canvas id="capture" ref={capture} />
                    </div>
                </ThumbnailContainer>
                <Space />
            </ThumbnailComponent>
            <Space />
            <Horizontal>
                <Button className={is({ outline: true })} onClick={cancelBtn}>
                    Cancel
                </Button>
                <Space />
                <CaptureMediaDevices
                    mediaDevices={mediaDevices}
                    label={snapshot ? 'Retake Photo' : 'Capture Photo'}
                    onClick={snapshot ? clearSnapshot : captureSnapshot}
                    selectedMediaDevice={selectedMediaDevice()}
                    selectMediaDevice={selectMediaDevice}
                />
                <Space />
                <Button
                    className={is({ disabled: snapshot == null })}
                    onClick={(event: React.MouseEvent) => {
                        event.preventDefault();
                        dispatch(actions.setAvatarFileData(snapshot));
                        dispatch(actions.authMode(Mode.profileAvatar));
                    }}
                >
                    Save
                </Button>
            </Horizontal>
        </React.Fragment>
    );
}

function getCanvasDimension(element: HTMLVideoElement | null) {
    if (element === null) return { width: 333, height: 250, x: 0, y: 0 };
    const video = { height: element.videoHeight, width: element.videoWidth };
    const boundary = element.getBoundingClientRect();
    const use = video.width > video.height ? 'width' : 'height';
    const ratio = boundary[use] / video[use];
    return {
        width: video.width * ratio,
        height: video.height * ratio,
        get x() {
            return Math.floor((boundary.width - this.width) / 2);
        },
        get y() {
            return Math.floor((boundary.height - this.height) / 2);
        },
    };
}
