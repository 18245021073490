import { createReducer } from 'redux-act';
import {
    fetchOrganizationSuccess,
    fetchOrganization,
    resetState,
    participantAvatarsCache,
    setGlobalLink,
    fetchOrg,
    fetchOrgSuccess,
    fetchEvents,
    fetchEventsSuccess,
    setAssets,
} from 'modules/actions';

export interface IAssets {
    baseUrl: string;
    imagesUrl: string;
}

export interface IEvent {
    conference: {
        isChatActive?: boolean;
        sponsorDisplayInKeynote?: boolean;
        sponsorTypeText?: string;
    };
    header: {
        imageUrl: string;
        titleHtml?: string;
    };
    days?: IDays[];
    id: string;
    thirdPartyUrl: string;
    type: string;
    videoPlatform: string;
    invite: IInvite;
    lobby: ILobby;
    pollExclusiveId: string;
    lobbyDisplayMode: string;
    lounges: {
        isActive?: boolean;
        isSponsorLoungeActive?: boolean;
        orderBy: string;
        gLText: string;
        gLButton: string;
        gLDescription: string;
        vipText: string;
        vipButton: string;
        vipDescription: string;
    };
    name: string;
    title: string;
    description: string;
    startDate: string;
    oneOnOne: {
        duration: number;
    };
    schedule: ISchedule;
    social: {
        label: string;
    };
    sponsor: ISponsor;
    sponsors: ISponsors;
    // Indicates if event is opened and publicly accessible
    status?: string;
    theme: {
        bannerColor?: string | undefined;
        bannerColorOpacity?: string | undefined;
        buttonColor?: string | undefined;
        // General opaque color filling containers (may be same as buttonColor)
        fillColor?: string | undefined;
        // Transparent variant of the fillColor to overlay over containers.
        // In use for WelcomePageNew left panel for transparent layer (ie blurred view).
        fillColorOverlay?: string | undefined;
        landingBannerTextColor: string;
        sidePanelTextColor: string;
    };
    utcStartTimeMillis: number;
    websiteUrl: string;
    welcome: {
        background: { imageUrl: string };
        banner: { imageUrl: string };
        hero: { imageUrl: string; title: string };
        sponsors: { title: string };
        favicon: {
            imageUrl: string;
        };
    };
    timezone?: {
        label: string;
        value: string;
    };
    roomType?: string;
    isChatNotification?: boolean;
    access?: any;
}

export interface IDays {
    duration: string;
    timeStamp: number;
    utcStartTimeMillis: number;
}

export interface IHero {
    type: string;
    deck?: string;
    heading: string;
    description: string;
    thumbnail: string;
    video?: string;
    backdrop?: string;
    textColor: string;
    bodyBgColor: string;
}

export interface IVideoHighlights {
    deck: string;
    heading: string;
    description: string;
    thumbnail: string;
    video: string;
    textColor: string;
    bodyBgColor: string;
}

export interface IOrganization {
    id: string;
    name: string;
    logoUrl: string;
    faviconUrl: string;
    social: { [key: string]: string };
    landing: {
        pagePrivacy: string;
        cardBorderColor: string;
        videoPlayerColor: string;
        hero: IHero;
        videoHighlights: IVideoHighlights;
    };
    events: IEvent[];
}

interface ILobby {
    action: { label: string };
    name: string;
    keynote: {
        activeKeynoteText: string;
        expiredKeynoteText: string;
        relativePath: string;
        // lobbyTitleBackground: string;
        lobbyTitle: string;
        lobbyDescription: string;
        showTimer: boolean;
        showKeynoteSection: boolean;
    };
    page: { title: string };
}

interface ISponsors {
    action: { label: string };
    page: { title: string };
}

interface ISponsor {
    page: {
        followUp: { label: string };
        sponsorLoungeRedirectToBreakout: boolean;
        visitWebsite: { label: string };
        sponsorLoungeBtn: { label: string };
    };
}

interface ISchedule {
    action: { label: string };

    page: {
        speakerTitle: string;
        title: string;
    };
}

interface IInvite {
    label: string;
    placeholder: string;
    showInvitee: boolean;
}

export type organizationReducerState = {
    assets: IAssets;
    avatars: { [key: string]: any };
    fetching: boolean;
    event: IEvent;
    message: string;
    organization: IOrganization;
    globalLink: string;
};

export interface Ipayload {
    assets: IAssets;
    event: IEvent;
    fetching: boolean;
    message: string;
    organization: IOrganization;
}

const initialState: organizationReducerState = {
    avatars: {},
    assets: {
        baseUrl: '',
        imagesUrl: '',
    },
    event: {
        conference: {
            isChatActive: false,
            sponsorDisplayInKeynote: false,
            sponsorTypeText: 'Diamond',
        },
        pollExclusiveId: '',
        header: { imageUrl: '' },
        name: '',
        title: '',
        thirdPartyUrl: '',
        type: '',
        videoPlatform: '',
        description: '',
        startDate: '',
        lobby: {
            action: { label: 'Back to Lobby' },
            name: '',
            page: { title: 'Main Lobby' },
            keynote: {
                showTimer: true,
                activeKeynoteText: 'Join Keynote',
                expiredKeynoteText: 'Keynote has ended',
                relativePath: '',
                lobbyTitle: '',
                // lobbyTitleBackground: '',
                lobbyDescription: '',
                showKeynoteSection: true,
            },
        },
        id: '',
        invite: { label: 'Invite a colleague', placeholder: 'email@example.com', showInvitee: false },
        lobbyDisplayMode: 'keynoteTracks',
        lounges: {
            isActive: false,
            isSponsorLoungeActive: false,
            orderBy: '',
            gLText: 'General Lounge',
            gLButton: 'Take a Break',
            gLDescription:
                'Take a break and network with other attendees. See their profile and engage in a one to one video chat, exchange e-business cards. You can search among the participants by different criteria.',
            vipText: 'LP Lounge',
            vipButton: 'Take a Break',
            vipDescription:
                'Take a break and network with other attendees. See their profile and engage in a one to one video chat, exchange e-business cards. You can search among the participants by different criteria.',
        },
        oneOnOne: {
            duration: 5,
        },
        social: { label: 'Follow / Post the Event' },
        sponsor: {
            page: {
                visitWebsite: { label: 'VISIT WEBSITE' },
                followUp: { label: 'REQUEST FOLLOW-UP' },
                sponsorLoungeRedirectToBreakout: false,
                sponsorLoungeBtn: { label: 'SPONSOR LOUNGE' },
            },
        },
        sponsors: {
            action: { label: 'Meet the Experts' },
            page: { title: 'Sponsors' },
        },
        schedule: {
            action: { label: 'Full Schedule' },
            page: { title: 'Schedule', speakerTitle: 'Speakers' },
        },
        theme: { buttonColor: '#1a1a1a', sidePanelTextColor: '#000000', landingBannerTextColor: '#000000' },
        utcStartTimeMillis: 0,
        welcome: {
            sponsors: { title: '' },
            background: { imageUrl: '' },
            banner: { imageUrl: '' },
            hero: { imageUrl: '', title: '' },
            favicon: { imageUrl: '' },
        },
        websiteUrl: '',
    },
    fetching: true,
    message: '',
    organization: {
        id: '',
        name: '',
        logoUrl: '',
        faviconUrl: '',
        social: {
            instagram: '',
            youtube: '',
            facebook: '',
            linkedin: '',
            twitter: '',
        },
        landing: {
            pagePrivacy: '',
            cardBorderColor: '',
            videoPlayerColor: '',
            hero: {
                type: '',
                heading: '',
                description: '',
                thumbnail: '',
                textColor: '',
                bodyBgColor: '',
            },
            videoHighlights: {
                deck: '',
                heading: '',
                description: '',
                thumbnail: '',
                video: '',
                textColor: '',
                bodyBgColor: '',
            },
        },
        events: [],
    },
    globalLink: '',
};

export const organization = createReducer<organizationReducerState>({}, initialState);
organization.on(fetchOrganizationSuccess, (state: organizationReducerState, payload: Ipayload) => ({
    ...state,
    event: {
        ...state.event,
        ...payload.event,
        type: payload?.event?.type ?? 'event',
    },
    lobbyDisplayMode: payload.event.lobbyDisplayMode,
    message: payload.message,
    assets: payload.assets,
    fetching: false,
}));

organization.on(setAssets, (state: organizationReducerState, payload: any) => ({
    ...state,

    assets: payload,
}));

organization.on(fetchOrgSuccess, (state: organizationReducerState, payload: Ipayload) => ({
    ...state,
    organization: { ...state.organization, ...payload.organization },
    fetching: false,
}));

organization.on(fetchEventsSuccess, (state: organizationReducerState, payload: IEvent[]) => ({
    ...state,
    organization: { ...state.organization, events: payload },
    fetching: false,
}));

organization.on(fetchOrganization, (state: organizationReducerState) => ({
    ...state,
    fetching: true,
}));

organization.on(fetchOrg, (state: organizationReducerState) => ({
    ...state,
    fetching: true,
}));

organization.on(fetchEvents, (state: organizationReducerState) => ({
    ...state,
    fetching: true,
}));

organization.on(resetState, () => initialState);

organization.on(participantAvatarsCache, (state: organizationReducerState, payload: any) => ({
    ...state,
    avatars: { ...state.avatars, ...payload },
}));

organization.on(setGlobalLink, (state: organizationReducerState, payload: string) => ({
    ...state,
    globalLink: payload,
}));
