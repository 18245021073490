import { Redirect } from 'react-router-dom';
import { ToccaUtils } from 'modules/utils';

import { WelcomeConfig } from 'pages/Event/WelcomeConfig';
import { LobbyConfig } from 'pages/Lobby/LobbyConfig';
import { NetworkingConfig } from 'pages/Networking/NetworkingConfig';
import { NetworkingLoungeConfig } from 'pages/NetworkingLounge/NetworkingLoungeConfig';
import { ScheduleConfig } from 'pages/Schedules/ScheduleConfig';
import { SponsorConfig } from 'pages/Sponsor/SponsorConfig';
import { SponsorListConfig } from 'pages/SponsorList/SponsorListConfig';
import { BreakoutConfig } from 'pages/Breakout/BreakoutConfig';
import { BreakoutRecorderConfig } from 'pages/Breakout/BreakoutRecorderConfig';
import { SponsorBreakout } from 'pages/SponsorBreakout/SponsorBreakoutConfig';
import { NotFoundConfig } from 'pages/NotFound/NotFoundConfig';
import { EventLandingConfig } from 'pages/EventLanding/EventLandingConfig';
import { BreakoutGroupConfig } from 'pages/BreakoutGroup/BreakoutGroupConfig';
import { DiscoveryConfig } from 'pages/Discovery/DiscoveryConfig';
import { LiveStreamBroadcastConfig } from 'pages/LiveStreamBroadcast/LiveStreamBroadcastConfig';

const routeConfigs = [
    LiveStreamBroadcastConfig,
    BreakoutRecorderConfig,
    LobbyConfig,
    DiscoveryConfig,
    WelcomeConfig,
    NetworkingConfig,
    NetworkingLoungeConfig,
    ScheduleConfig,
    SponsorConfig,
    SponsorListConfig,
    BreakoutConfig,
    SponsorBreakout,
    NotFoundConfig,
    EventLandingConfig,
    BreakoutGroupConfig,
];

const routes = [
    ...ToccaUtils.generateRoutesFromConfigs(routeConfigs),
    {
        path: '/',
        exact: true,
        isPrivate: true,
        component: () => <Redirect to="/lobby" />,
    },
    // {
    //     path: '*',
    //     exact: true,
    //     isPrivate: true,
    //     component: () => <Redirect to="/" />,
    // },
];

export default routes;
