import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { SagaIterator } from 'redux-saga';
import { call, put, select, take, fork, cancelled, cancel } from 'redux-saga/effects';
import {
    fetchOrganizationSuccess,
    authLinkInvalid,
    registrationAccess,
    fetchOrgSuccess,
    fetchEventsSuccess,
    setAssets,
} from 'modules/actions';
import Avatar from 'modules/utils/Avatar';
import * as DeepObject from 'deep-object-diff';
import * as actions from 'modules/actions';
import * as selectors from 'modules/selectors';
import { ISagaAction } from 'modules/types';
import {
    getEventData,
    getEventsData,
    getOrganizationData,
    organizationAssets,
} from 'modules/utils/Firebase/organization';
import * as Sentry from '@sentry/react';

export const fetchOrganizationSaga = function* (): SagaIterator {
    try {
        while (yield take(actions.SetRegistrationSessionPropertyLink)) {
            const eventTask = yield fork(fetchEventDataSaga);
            yield fork(fetchOrgDataSaga);
            yield fork(fetchEventsDataSaga);
            yield take([actions.authLogOut, actions.removeActiveListner, actions.signOutUser]);
            yield cancel(eventTask);
        }
    } catch (error) {
        console.error(error, 'errror');
        yield put(actions.fetchScheduleFailed());
        Sentry.captureException(error);
    }
};

const fetchOrgDataSaga = function* (): SagaIterator {
    try {
        const { organizationId } = yield select(selectors.registrationSessionPropertyLink);
        if (isEmpty(organizationId)) {
            yield put(authLinkInvalid());
        }
        const channel = yield call(getOrganizationData, organizationId);
        try {
            while (true) {
                const result = yield take(channel);
                if (result) {
                    const organization = yield select(selectors.getOrganization);
                    if (!isEmpty(DeepObject.diff(result, { organization }))) {
                        yield put(fetchOrgSuccess(result));
                    }
                }
            }
        } catch (e) {
            console.warn(e);
        } finally {
            // unregister listener if the saga was cancelled
            if (yield cancelled()) channel.close();
        }
    } catch (error) {
        console.error(error);
        Sentry.captureException(error);
    }
};

const fetchEventsDataSaga = function* (): SagaIterator {
    try {
        const { organizationId } = yield select(selectors.registrationSessionPropertyLink);
        if (isEmpty(organizationId)) {
            yield put(authLinkInvalid());
        }
        const channel = yield call(getEventsData, organizationId);
        try {
            while (true) {
                const result = yield take(channel);
                if (result) {
                    const events = yield select(selectors.getOrganizationEvents);
                    if (!isEmpty(DeepObject.diff(result, events))) {
                        yield put(fetchEventsSuccess(result));
                    }
                }
            }
        } catch (e) {
            console.warn(e);
        } finally {
            // unregister listener if the saga was cancelled
            if (yield cancelled()) channel.close();
        }
    } catch (error) {
        console.error(error);
        Sentry.captureException(error);
    }
};

const fetchEventDataSaga = function* (): SagaIterator {
    try {
        const link = yield select(selectors.registrationSessionPropertyLink);
        const { eventId, organizationId } = link;
        if (isEmpty(organizationId) || isEmpty(eventId)) {
            console.error('organizationId or eventId is empty');
            if (!isEmpty(organizationId)) {
                yield put(setAssets(organizationAssets(organizationId)));
            }
            return;
        }
        const channel = yield call(getEventData, organizationId, eventId);
        try {
            while (true) {
                const result = yield take(channel);
                if (result) {
                    yield put(
                        registrationAccess({
                            access: {
                                ...result.event.access,
                                link: link,
                                status: get(result, 'event.status', 'closed'),
                                utcStartTimeMillis: get(result, 'event.utcStartTimeMillis', -1),
                            },
                            host: {
                                eventId: eventId,
                                organizationId: organizationId,
                            },
                        }),
                    );

                    const assets = yield select(selectors.getAssets);
                    const event = yield select(selectors.getEvent);
                    const organization = yield select(selectors.getOrganization);

                    if (!isEmpty(DeepObject.diff(result, { assets, event, organization }))) {
                        yield put(fetchOrganizationSuccess(result));
                        yield put(actions.subscribeTriggeredData());
                    }
                }
            }
        } catch (e) {
            console.warn(e);
            // yield put(authLinkInvalid());
        } finally {
            // unregister listener if the saga was cancelled
            if (yield cancelled()) channel.close();
        }
    } catch (error) {
        console.error(error);
        Sentry.captureException(error);
    }
};

export function* participantAvatarSaga(action: ISagaAction<string>): SagaIterator {
    try {
        yield call(Avatar.participantAvatar, action.payload);
    } catch (error) {
        console.error('participantAvatarSaga: ', error);
        Sentry.captureException(error);
    }
}

export default fetchOrganizationSaga;
