import localForage from 'localforage';
import { LinkReducerState } from 'modules/reducers';
import * as slugid from 'slugid';
import moment from 'moment-timezone';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import startCase from 'lodash/startCase';
import toLower from 'lodash/toLower';
// import CryptoJS from 'crypto-js';

export const linkStorage = localForage.createInstance({
    name: 'tocca-db',
    storeName: 'tocca-link-params',
});

export const capitilizeString = (str: string) => {
    return startCase(toLower(str));
};

export function ensureMediaPermissions() {
    return navigator.mediaDevices
        .enumerateDevices()
        .then((devices) => devices.every((device) => !(device.deviceId && device.label)))
        .then((shouldAskForMediaPermissions) => {
            if (shouldAskForMediaPermissions) {
                return navigator.mediaDevices
                    .getUserMedia({ audio: true, video: true })
                    .then((mediaStream) => mediaStream.getTracks().forEach((track) => track.stop()));
            }
        });
}

export const getUrlParameters = async (url: string, search = '?') => {
    const parameters = new URLSearchParams(search);
    const state = parseEventFromUrl(url, parameters);
    if (state) {
        await linkStorage.setItem('state', { ...omit(state, ['originalPath', 'id']), isNew: false });
        return state;
    }
    const link: LinkReducerState | null = await linkStorage.getItem('state');
    if (link) {
        if (link.eventId && link.organizationId) return link;
    }
    return false;
};

export const getUrlFromParamters = async (
    origin: string,
    eventSlug: string,
    organizationId: string,
): Promise<string> => {
    const link: string = origin + '/' + organizationId + '/' + eventSlug;
    return link;
};

export async function dataUrlToFile(
    dataUrl: string,
    fileName: string
): Promise<File> {
    const res: Response = await fetch(dataUrl);
    const blob: Blob = await res.blob();
    return new File([blob], fileName, { type: "image/png" });
}

export const parseEventFromUrl = (url: string, params: any) => {
    const eventOrgMatch = /\/(?<organizationId>[a-zA-Z0-9]+)\/(?<eventSlug>[A-Za-z0-9_-]{22})(\/(?<path>.*))?/;
    const match = url.match(eventOrgMatch);
    let eventSlug, organizationId, path;

    if (match && match.groups) {
        eventSlug = match.groups.eventSlug;
        organizationId = match.groups.organizationId;
        path = match.groups.path;
    }
    if (eventSlug && organizationId) {
        const eventId = slugid.decode(eventSlug);
        const id = params.get('id') ? params.get('id').split(' ').join('+') : null;
        const email = decryptEmailId(id);
        if (eventId) {
            return {
                eventId,
                organizationId,
                eventSlug,
                preview: params.get('toccaPreviewOverride') || false,
                isNew: true,
                email,
                id,
                originalPath: path,
            };
        }
    }
    return null;
};

const decryptEmailId = (id: string | null) => {
    if (!id) {
        return null;
    }
    return null;
};

export const buildSluggedRoute = (orgId: string, eventSlug: string, path: string) => {
    return `/${orgId}/${eventSlug}/${path.replace(/^\/+/, '')}`;
};

export function filterMediaDevices(constraints: MediaStreamConstraints) {
    return function filterDevice(device: MediaDeviceInfo) {
        if (!constraints.audio && device.kind.includes('audio')) return false;
        if (!constraints.video && device.kind.includes('video')) return false;
        return true;
    };
}

export async function listMediaDevices(constraints: MediaStreamConstraints): Promise<Array<MediaDeviceInfo>> {
    try {
        const filterDevice = filterMediaDevices(constraints);
        return await navigator.mediaDevices.enumerateDevices().then((devices) => devices.filter(filterDevice));
    } catch (error) {
        console.error('listMediaDevices: ', error);
        throw error;
    }
}

export async function requestMediaStream(constraints: MediaStreamConstraints) {
    try {
        // const deviceConstraints = { audio: Boolean(constraints.audio), video: Boolean(constraints.video) };
        //  await navigator.mediaDevices.getUserMedia(deviceConstraints).then(stopMediaStream);
        return await navigator.mediaDevices.getUserMedia(constraints);
    } catch (error) {
        console.error('requestMediaDevices: ', error);
        throw error;
    }
    // scope actions
    // function stopMediaStream(mediaStream: MediaStream) {
    //     mediaStream.getTracks().forEach((track) => track.stop());
    // }
}

export function shouldRequestMediaStream(devices: MediaDeviceInfo[], constraints: MediaStreamConstraints) {
    const filterDevice = filterMediaDevices(constraints);
    devices = devices.filter(filterDevice);
    return devices.every((device) => !(device.deviceId && device.label));
}
const pat =
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const EMAIL_PATTERN = pat;

// export const getClosestTime = (times: any) => {
//     const compareTime = moment(new Date(), 'hh:mm');
//     const closestTime = times.find((time: any) => {
//         const diff = moment(time, 'hh:mm').diff(compareTime, 'minutes');
//         return diff >= 0;
//     });
//     return closestTime;
// };

export const getClosestTimes = (times: any) => {
    const time = moment().format('HH:mm');
    const now = +moment(time, 'HH:mm').format('x');
    const timesInMillis = times.map((t: any) => +moment(t, 'HH:mm').format('x'));

    function closestTime(arr: any, time: any) {
        return arr.reduce(function (prev: any, curr: any) {
            return Math.abs(curr - time) < Math.abs(prev - time) ? curr : prev;
        });
    }
    const closest = moment(closestTime(timesInMillis, now)).format('HH:mm');
    return closest;
};

export const isValidURL = (str: string) => {
    const pattern = new RegExp(
        '^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$',
        'i',
    ); // fragment locator
    return !!pattern.test(str);
};

export const getEventDateWithFormat = (utcStartTimeMillis: number): string => {
    return utcStartTimeMillis > 0 ? moment(utcStartTimeMillis).tz(moment.tz.guess()).format('DD-MM-YYYY') : '';
};

export const getEventDateWithTimeZoneWithFormat = (utcStartTimeMillis: number, timezone: any): string => {
    return utcStartTimeMillis > 0 && !isEmpty(timezone)
        ? moment(utcStartTimeMillis).tz(timezone).format('DD-MM-YYYY')
        : '';
};

export const imageAspectRatio = (src: string) => {
    const img = new Image();
    img.src = src;
    img.onload = function () {
        document.body.appendChild(img); // You can get the image ratio without inserting the image in body
        return img.width / img.height;
    };
};

export const setLongTimeout = (callback: any, timeout_ms: number): NodeJS.Timeout => {
    // if we have to wait more than max time, need to recursively call this function again
    if (timeout_ms > 2147483647) {
        // now wait until the max wait time passes then call this function again with
        // requested wait - max wait we just did, make sure and pass callback
        return setTimeout(function () {
            setLongTimeout(callback, timeout_ms - 2147483647);
        }, 2147483647);
    } // if we are asking to wait less than max, finally just do regular setTimeout and call callback
    else {
        return setTimeout(callback, timeout_ms);
    }
};
