import React from 'react';

export const BreakoutConfig = {
    settings: {
        layout: {
            config: {
                header: { display: true },
                footer: { display: true },
                navbar: { display: true },
            },
        },
    },
    isPrivate: true,
    eventName: 'interactive_room',
    routes: [
        {
            path: '/:organizationId/:eventSlug/room/:room',
            component: React.lazy(() => import('./index')),
        },
    ],
};

export const BreakoutMobileConfig = {
    settings: {
        layout: {
            config: {
                header: { display: true },
                footer: { display: true },
                navbar: { display: true },
            },
        },
    },
    eventName: 'interactive_room',
    isPrivate: true,
    routes: [
        {
            path: '/:organizationId/:eventSlug/room/:room',
            component: React.lazy(() => import('./MobileBreakout/MobileBreakout')),
        },
    ],
};
