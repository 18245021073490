import React, { useEffect, useCallback, useState } from 'react';
import * as Selectors from 'modules/selectors';
import { useSelector } from 'react-redux';
import * as firebase from 'modules/utils/Firebase';
import { useHistory } from 'react-router-dom';
import get from 'lodash/get';
import omitBy from 'lodash/omitBy';
import isString from 'lodash/isString';
import isNil from 'lodash/isNil';
import { usePrevious } from 'modules/hooks/useComponent';
import { getEventDateWithTimeZoneWithFormat, getEventDateWithFormat } from 'modules/utils/CommonService';
import moment from 'moment';

const Analytics = () => {
    /** Authenticated flag */
    const selectedRoute = useSelector(Selectors.getRoute);
    const params = new URLSearchParams(document.location.search.substring(1));
    const { eventSlug, organizationId, eventId } = useSelector(Selectors.registrationSessionPropertyLink);
    const profile = useSelector(Selectors.getUserProfile);
    const uid = useSelector(Selectors.getUserUid);
    const isUserAuthorized = useSelector(Selectors.getIsUserAuth);
    const sponsor = useSelector(Selectors.getSponsorById(params.get('id')));
    const roomId = useSelector(Selectors.getRoomId);
    const room = useSelector(Selectors.getRoom(roomId));
    const prevRoomId: any = usePrevious(roomId);
    const prevRoom = useSelector(Selectors.getRoom(prevRoomId));
    const prevSponsor = useSelector(Selectors.getSponsorById(prevRoomId));
    const [meetingStartTime, setMeetingTime] = useState(moment().toDate());
    const event = useSelector(Selectors.getEvent);
    const timeZone = event?.timezone?.value?.split(' ').pop();
    const eventDateWithTimeZone = getEventDateWithTimeZoneWithFormat(event.utcStartTimeMillis, timeZone);
    const eventDate = getEventDateWithFormat(event.utcStartTimeMillis);
    const isInRoom = useSelector(Selectors.getRoomPresence);
    // const [isExit, setIsExit] = useState(false);
    const history = useHistory();

    const stateParameters = useCallback(() => {
        const firstName = get(profile, `firstName`, null);
        const lastName = get(profile, `lastName`, null);

        // delete/omit all fields in object where values are equal to undefined | null
        const baseParamter = omitBy(
            {
                attendee_company_name: get(profile, `companyName`, null),
                attendee_job_title: get(profile, `jobTitle`, null),
                attendee_email: get(profile, `email`, null),
                attendee_name: [firstName, lastName].filter(isString).join(' ').trim() || null,
                attendee_uid: uid || null,
                event_id: eventId,
                event_slug: eventSlug,
                organization_id: organizationId,
                start_date_in_timezone: eventDateWithTimeZone || null,
                start_date: eventDate || null,
                timezone: timeZone || null,
            },
            isNil,
        );

        if (selectedRoute?.eventName === 'interactive_room') {
            return omitBy(
                {
                    ...baseParamter,
                    room_id: roomId,
                    room_name: get(room, `title`, null),
                    sponsor_booth_id: get(sponsor, `id`, null),
                    sponsor_name: get(sponsor, `title`, null),
                },
                isNil,
            );
        }

        if (selectedRoute?.eventName === 'sponsor_booth_room') {
            return omitBy(
                {
                    ...baseParamter,
                    room_id: roomId,
                    room_name: get(room, `title`, null),
                    sponsor_booth_id: get(sponsor, `id`, null),
                    sponsor_name: get(sponsor, `title`, null),
                },
                isNil,
            );
        }

        if (selectedRoute?.eventName === 'sponsor_booth') {
            return omitBy(
                {
                    ...baseParamter,
                    sponsor_id: get(sponsor, `id`, null),
                    sponsor_name: get(sponsor, `title`, null),
                },
                isNil,
            );
        }

        return baseParamter;
    }, [
        profile,
        uid,
        organizationId,
        eventSlug,
        eventId,
        roomId,
        room,
        selectedRoute,
        sponsor,
        eventDateWithTimeZone,
        eventDate,
        timeZone,
    ]);

    const parameters = React.useMemo(() => stateParameters(), [stateParameters]);

    const statePrevParameters = useCallback(() => {
        const firstName = get(profile, `firstName`, null);
        const lastName = get(profile, `lastName`, null);
        // delete/omit all fields in object where values are equal to undefined | null
        const baseParamter = omitBy(
            {
                attendee_company_name: get(profile, `companyName`, null),
                attendee_job_title: get(profile, `jobTitle`, null),
                attendee_email: get(profile, `email`, null),
                attendee_name: [firstName, lastName].filter(isString).join(' ').trim() || null,
                attendee_uid: uid || null,
                event_id: eventId,
                event_slug: eventSlug,
                organization_id: organizationId,
            },
            isNil,
        );

        if (prevRoomId) {
            const endTime = moment();
            const duration = endTime.diff(moment(meetingStartTime), 'seconds');

            return omitBy(
                {
                    ...baseParamter,
                    room_id: prevRoomId,
                    room_name: get(prevRoom, `title`, null) || get(room, `title`, null),
                    duration: duration,
                    sponsor_booth_id: get(prevSponsor, `id`, null),
                    sponsor_name: get(prevSponsor, `name`, null),
                },
                isNil,
            );
        }

        return baseParamter;
    }, [profile, uid, organizationId, eventSlug, eventId, room, prevRoom, prevSponsor, prevRoomId, meetingStartTime]);

    const prevParameters = React.useMemo(() => statePrevParameters(), [statePrevParameters]);

    const logAnalytics = useCallback(
        (page: string, eventName: string) => {
            // the app's current screen is set keep a general record of user navigation
            firebase.analytics.setCurrentScreen(page);

            if (parameters.attendee_email && parameters.attendee_uid && parameters.attendee_name) {
                // ensure required parameters exist so logged entries are useful to tocca clients
                firebase.analytics.logEvent(eventName, parameters);
            }

            // if re-renders occur we clear pending calls from queue to prevent recorded
            // multiple entries of the same type - keeping most current data
        },
        [parameters],
    );

    useEffect(() => {
        if (selectedRoute && isUserAuthorized) {
            const page = history.location.pathname;
            logAnalytics(page, selectedRoute.eventName);
        }
    }, [selectedRoute, history.location.pathname, isUserAuthorized, logAnalytics]);

    useEffect(() => {
        if (roomId && !prevRoomId) {
            setMeetingTime(moment().toDate());
        }
    }, [prevRoomId, roomId]);

    useEffect(() => {
        if (prevParameters && prevParameters?.duration > 0 && !isInRoom) {
            firebase.analytics.logEvent('interactive_room_exit', prevParameters);
        }
    }, [prevParameters, isInRoom]);

    return <React.Fragment></React.Fragment>;
};

export default Analytics;
