import { SagaIterator } from 'redux-saga';
import { takeLatest, fork } from 'redux-saga/effects';
import * as actions from 'modules/actions';
import * as invites from 'modules/sagas/invites';
import * as user from 'modules/sagas/user';
import * as organization from 'modules/sagas/organization';
import * as sponsor from 'modules/sagas/sponsors';
import * as notification from 'modules/sagas/notification';
import * as swappedCard from 'modules/sagas/swappedCards';
import * as schedule from 'modules/sagas/schedule';
import * as breakoutRooms from 'modules/sagas/breakoutRooms';
import * as networkingLounge from 'modules/sagas/networkingRoomLounge';
import * as registration from 'modules/sagas/registration';
import * as navigation from 'modules/sagas/navigation';
import * as breakout from 'modules/sagas/breakout';
import * as networking from 'modules/sagas/networking';
import * as login from 'modules/sagas/login';
import * as breakoutGroup from 'modules/sagas/breakoutGroups';
import fetchKeynotesSaga from 'modules/sagas/keynotes';
import { fetchComponentsListnerSaga } from './components';
import * as poll from 'modules/sagas/polls';
import * as speakers from 'modules/sagas/speakers';
import * as eventDetail from 'modules/sagas/eventDetail';

/**
 * Root saga that connect sagas with actions.
 */
export default function* rootSaga(): SagaIterator {
    /* ON-ENTRY + IN-REGISTRATION   */
    yield fork(organization.fetchOrganizationSaga);
    // yield fork(registration.onAuthStateChangedSaga);
    yield fork(user.signInSessionUserSaga);
    yield fork(registration.registrationEntrySaga);
    yield fork(sponsor.fetchSponsorsSaga);
    yield fork(user.fetchLookupSaga);
    yield takeLatest(actions.navigateTo, navigation.navigateToSaga);

    yield takeLatest(actions.registrationProfileSynchronize, registration.registrationProfileSynchronizeSaga);

    yield takeLatest(actions.signOutUser, user.signOutUser);
    yield takeLatest(actions.authRegisterParticipant, user.registerParticipantSaga);

    /* IN-EVENT SESSION */
    yield fork(notification.authNotification);
    yield takeLatest(actions.registerRoomUser, breakoutRooms.userPresenceInBreakoutConnection);
    yield fork(breakoutRooms.breakoutRecordingConnection);
    yield fork(breakoutRooms.breakoutWaitingRoomListner);
    yield fork(networkingLounge.userPresenceInNetworkLoungeConnection);
    yield fork(schedule.scheduleBreakoutRoomListConnection);
    yield fork(schedule.scheduleBreakoutRoomConnection);

    yield takeLatest(actions.authUserSuccess, notification.fetchNotificationSaga);

    yield takeLatest(actions.sendInviteEmail, invites.sendInviteEmailSaga);
    yield takeLatest(actions.createRequestFollowUp, sponsor.createRequestFollowUp);
    yield takeLatest(actions.fetchSwappedCard, swappedCard.fetchSwappedCardSaga);
    // yield takeLatest(actions.saveSwappedProfile, swappedCard.saveSwappedcardsaga);

    /* AVATARS */
    // Adds an external participant's avatar to the url cache/resolver.
    yield takeLatest(actions.participantAvatar, organization.participantAvatarSaga);
    // Caches all participant avatars across the site.
    // yield fork(organization.participantAvatarsCacheSaga);
    // yield fork(fetchLobbyListnerSaga);
    yield fork(fetchComponentsListnerSaga);
    yield takeLatest(actions.setActiveListner, sponsor.getSponsorsTypesSaga);
    yield takeLatest(actions.setActiveListner, fetchKeynotesSaga);
    yield takeLatest(actions.setActiveListner, user.participantProfileSaga);

    yield takeLatest(actions.extendCurrentMeeting, breakout.extendMeetingEndDate);
    yield takeLatest(actions.endCurrentMeeting, breakout.endCurrentMeetingSaga);
    yield takeLatest(actions.changeRoomStatus, breakout.changeStatusCurrentMeeting);
    yield fork(breakoutRooms.breakoutVideoControlConnection);
    yield takeLatest(actions.controlVideoRunningState, breakoutRooms.breakoutVideoControlPlayPauseStop);
    yield takeLatest(actions.stopRecording, breakoutRooms.breakoutStopRecordingSaga);
    yield takeLatest(actions.allowAllUsersInRoom, breakoutRooms.allowAllUsersInRoom);
    yield takeLatest(actions.updateBreakoutUserProfile, breakoutRooms.updateUserProfile);

    yield fork(networking.networkingRegisterUser);
    yield takeLatest(actions.fetchAdminEventUser, user.fetchAdminPeopleEmailDetail);
    yield takeLatest(actions.setNetworkingData, networking.setNetworkData);
    yield fork(poll.fetchPollsListSaga);
    yield takeLatest(actions.fetchPolls, poll.fetchPollsListSaga);
    yield fork(poll.fetchSelectedRoomPollData);
    yield takeLatest(actions.selectFormId, poll.addSelectedPollSaga);
    yield takeLatest(actions.fetchPollResult, poll.fetchPollResultSaga);
    yield takeLatest(actions.fetchAllPollResult, poll.fetchAllResultSaga);
    yield takeLatest(actions.closeDisplayResuls, poll.fetchPollResultCloseSaga);
    yield fork(poll.fetchPollResultLiveSaga);
    yield fork(networkingLounge.networkingLoungeUserPresenceConnection);
    yield fork(sponsor.sponsorBoothUserPresenceConnection);
    yield fork(sponsor.sponsorBoothPresenceUserSaga);
    yield fork(schedule.scheduleBlockedUserSaga);
    yield fork(user.signInSessionWithCustomTokenUserSaga);
    // yield takeLatest(actions.addBreakoutGroupListner, UserRoomPresenseListner);
    // yield fork(addBreakoutGroupsListner);
    yield fork(breakoutGroup.addBreakoutUserGroupListner);
    yield fork(poll.fetchSelectedRoomPollDataGroup);
    yield takeLatest(actions.getSpeakerList, speakers.getSpeakerListSaga);
    yield takeLatest(actions.setActiveListner, poll.fetchPollGroupSaga);
    yield takeLatest(actions.setActiveListner, poll.fetchMaxPollGroupSaga);

    yield takeLatest(actions.signinUser, login.loginUser);
    yield takeLatest(actions.authSigninUser, login.authLoginUser);
    yield takeLatest(actions.mailResetPassword, login.sendMailToResetPassword);
    yield takeLatest(actions.saveProfileData, login.updateProfileSaga);
    yield takeLatest(actions.saveAvatarData, login.updateAvatarSaga);
    yield takeLatest(actions.checkUserRegistered, login.checkUserRegisteredSaga);
    yield takeLatest(actions.signup, login.signupUserSaga);
    yield takeLatest(actions.updateUserDetail, user.updateUserDetailsFromAdminSaga);
    yield takeLatest(actions.updateUserRegistree, registration.updateUserDetailsFromRegistreeSaga);
    yield takeLatest(actions.checkUserHasAccess, registration.checkUserHasAccessSaga);
    yield takeLatest(actions.subscribeTriggeredData, poll.fetchPollGroupSaga);
    yield takeLatest(actions.getGroupDetail, breakoutGroup.fetchBreakoutGroupFetailSaga);
    yield takeLatest(actions.deleteAvatar, login.deleteAvatarSaga);
    yield takeLatest(actions.fetchEventDetail, eventDetail.getEventDetailSaga);
    yield fork(poll.fetchMaxPollGroupLiveSaga);
}
