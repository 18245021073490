import React from 'react';

export interface Properties extends React.PropsWithChildren<any> {
    disabled?: boolean;
    label?: React.ReactNode;
    placeholder?: string;
    readOnly?: boolean;
    spacing?: number;
    failureIcon?: boolean;
}

export function Label({ children, field, labelStyle }: React.PropsWithChildren<any>) {
    if (children === null) return null;
    const empty = React.Children.toArray(children).length === 0;
    return (
        <label htmlFor={field} style={{ ...labelStyle, textTransform: empty ? 'capitalize' : 'initial' }}>
            {empty ? field : children}
        </label>
    );
}
