import { BreakoutPresence } from 'modules/utils/Firebase/Api';
import firebase from 'modules/utils/Firebase';
import { eventChannel } from 'redux-saga';
import { IKeynote, IPresenter } from 'modules/schemas';

interface IAttendeeDetails {
    organizationId: string;
    eventId: string;
    schedule?: IKeynote[];
}

export const getScheduleListLisner = (props: IAttendeeDetails) => {
    const connectionRef = BreakoutPresence.roomRef(props.organizationId, props.eventId);
    const connectionRoomListner = eventChannel((emit) => {
        const unSubscribe = connectionRef.onSnapshot(async (snapshot: firebase.firestore.QuerySnapshot) => {
            if (!snapshot.empty) {
                const data: any[] = [];
                snapshot.forEach((result: firebase.firestore.QueryDocumentSnapshot) => {
                    if (result.exists) {
                        let keynote = result.data() as IKeynote;
                        let presenterList: IPresenter[] = [];
                        if (keynote.visible && keynote.presenters && Array.isArray(keynote.presenters)) {
                            presenterList = [...keynote.presenters];
                        }
                        const existingRecord = props.schedule?.find((_val) => _val.id === keynote.id);
                        delete keynote.moderators;

                        if (existingRecord && existingRecord.presenters) {
                            keynote = {
                                ...keynote,
                                presenterList,
                                presenters: existingRecord.presenters,
                            };
                        } else {
                            keynote = { ...keynote, presenterList, presenters: [] };
                        }

                        data.push(keynote);
                    }
                });

                return emit(data);
            } else {
                emit(false);
            }
        });
        return () => {
            unSubscribe();
        };
    });
    return connectionRoomListner;
};

export const getSpeakerList = async (data: IKeynote[]) => {
    const keynotes = [...data];
    const keyData = keynotes.map(async (keynote) => {
        let presenters = [];
        if (keynote.presenterList && Array.isArray(keynote.presenterList)) {
            const promises = keynote.presenterList.map((c: any) => c.get());
            presenters = await Promise.all(promises);
            presenters = presenters.filter((d: any) => d.exists).map((d: any) => d.data());
            keynote = { ...keynote, presenters, presenterList: [] };
        }
        if (presenters.length === 0) {
            keynote = { ...keynote, presenters: [] };
        }
        return keynote;
    });
    const speakers = await Promise.all(keyData);
    return speakers;
};
