import React, { useState } from 'react';
import is from 'classnames';
import { useDispatch } from 'react-redux';
import * as ReactRedux from 'react-redux';
import * as selectors from 'modules/selectors';
import { DeleteIcon, EditIcon, PictureIcon, SystemIcon } from 'components/Icons';
import { ImageFile, readImage } from 'components/Cropper/image';
import { Editable, Failure, Space } from '../common';
import { Horizontal, Vertical } from '../inputs';
import { AvatarNew } from './Profile/Avatar';
import * as actions from 'modules/actions';
import { Mode } from '../constants/constants';

export function Avatar({ children }: React.PropsWithChildren<any>) {
    const dispatch = useDispatch();
    const profileData = ReactRedux.useSelector(selectors.getProfileData);
    const avatar = ReactRedux.useSelector(selectors.getAvatar);
    const [errors, setError] = useState({
        avatarFile: '',
    });
    const [menuState, setMenuState] = React.useState<'show' | 'hide'>('show');

    const [imageUpload, setImageUpload] = React.useState<ImageFile | null>(null);

    const onChange = React.useCallback(
        async (event: React.ChangeEvent<HTMLInputElement>) => {
            event.preventDefault();
            const files = event.currentTarget.files;
            if (files && files[0]) {
                try {
                    const imageFile = await readImage(files);
                    setImageUpload(imageFile);
                    if (imageFile.type === 'invalid') throw new Error('You can only upload JPG or PNG');
                    if (imageFile.megabytes > 2) throw new Error('This file size is too big.');
                    dispatch(actions.setAvatarFileData(imageFile));
                    dispatch(actions.authMode(Mode.profileAvatar));
                } catch (error: any) {
                    setError({ avatarFile: error.message });
                }
            }
        },
        [dispatch],
    );

    const onMenuAction = React.useCallback(
        (type: string) => {
            switch (type) {
                case 'edit':
                    dispatch(actions.authMode(Mode.profileAvatar));
                    break;
                case 'delete':
                    // setValue('avatar', undefined, { shouldValidate: true });
                    // setValue('avatarFile', undefined, { shouldValidate: true });
                    dispatch(actions.deleteAvatar());
                    break;
                case 'take-photo':
                    dispatch(actions.authMode(Mode.profileAvatarCapture));
                    break;
            }
        },
        [dispatch],
    );

    const disabled = is({ 'menu-action': true, disabled: !avatar });
    return (
        <Horizontal>
            <AvatarNew firstName={profileData.firstName} lastName={profileData.lastName} avatar={avatar}>
                {children}
                <Editable menu={{ type: menuState, onClick: setMenuState }}>
                    <div className="menu-action">
                        <MenuIcon source={<SystemIcon />} /> Upload from system
                        <input
                            accept="image/png, image/jpeg"
                            className={is({ fill: true })}
                            type="file"
                            onChange={onChange}
                        />
                    </div>
                    <div className="menu-action" onClick={() => onMenuAction('take-photo')}>
                        <MenuIcon source={<PictureIcon />} /> Take a photo
                    </div>
                    <div className={disabled} onClick={() => onMenuAction('edit')}>
                        <MenuIcon source={<EditIcon />} /> Edit picture
                    </div>
                    <div className={disabled} onClick={() => onMenuAction('delete')}>
                        <MenuIcon source={<DeleteIcon />} /> Delete picture
                    </div>
                </Editable>
            </AvatarNew>
            <Space />
            <Horizontal>
                <Vertical>
                    <div>{imageUpload?.name}</div>
                    <Space height={0.25} />
                    {errors.avatarFile ? <Failure>{errors.avatarFile}</Failure> : <Space />}
                </Vertical>
            </Horizontal>
        </Horizontal>
    );
}

interface MenuIconProperties {
    source: React.ReactNode;
}
function MenuIcon({ source }: MenuIconProperties) {
    return <div className="icon">{source}</div>;
}
