import firebase, { database } from 'modules/utils/Firebase';
import { BreakoutPresence, Group } from 'modules/utils/Firebase/Api';
import { eventChannel } from 'redux-saga';
import { IAttendeeProfile } from 'modules/schemas';

interface IAttendeeDetails {
    organizationId: string;
    eventId: string;
    uid: string;
    roomId: string;
}

export const getUserRoomConnected = (props: IAttendeeDetails) => {
    const connectionRef = BreakoutPresence.userRef(props.uid, props.organizationId, props.eventId, props.roomId);
    const connectionBrListner = eventChannel((emit) => {
        const ref = database.ref('.info/connected');
        ref.on('value', (snapshot: any) => {
            if (snapshot.val() === false) {
                return emit(snapshot.val());
            }
            connectionRef
                .onDisconnect()
                .remove()
                .then(() => {
                    return emit(true);
                });
        });
        return () => {
            ref.off();
        };
    });
    return connectionBrListner;
};

export const removeUserRoomConnection = async (props: IAttendeeDetails) => {
    const connectionRef = BreakoutPresence.userRef(props.uid, props.organizationId, props.eventId, props.roomId);
    await connectionRef.remove();
};

export const addUserRoomConnection = async (props: IAttendeeDetails, profile: IAttendeeProfile) => {
    const connectionRef = BreakoutPresence.userRef(props.uid, props.organizationId, props.eventId, props.roomId);
    await connectionRef.set(profile);
};

export const updateUserRoomConnection = async (props: IAttendeeDetails, profile: any) => {
    const connectionRef = BreakoutPresence.userRef(props.uid, props.organizationId, props.eventId, props.roomId);
    await connectionRef.update(profile);
};

export const getUserPresenceListner = (props: IAttendeeDetails) => {
    const connectionRef = BreakoutPresence.userRef(props.uid, props.organizationId, props.eventId, props.roomId);
    const connectionRoomListner = eventChannel((emit) => {
        connectionRef.on('value', (snapshot: firebase.database.DataSnapshot) => {
            if (snapshot.val()) return emit(snapshot.val());
            else return emit(false);
        });
        return () => {
            connectionRef.off();
        };
    });
    return connectionRoomListner;
};

export const getUsersPresenceInRoom = async (props: IAttendeeDetails, groupId: string) => {
    const userPresenceReference = BreakoutPresence.userPresnceRef(props.organizationId, props.eventId, props.roomId);
    const snapshot = await userPresenceReference.get();
    if (snapshot.exists()) {
        const userPresnce = Object.values(snapshot.val());
        const groupSnapShot = await Group.reference(props.organizationId, props.eventId).doc(groupId).get();
        if (groupSnapShot.exists) {
            const group = groupSnapShot.data();
            return userPresnce.map((_user: any) => _user.email).filter((email: string) => group?.users.includes(email));
        }
    }
    return [];
};
