import React, { PropsWithChildren } from 'react';
import styled from 'styled-components/macro';
import { IPresenter } from 'modules/schemas';
import { Img } from 'react-image';
import { getAssets } from 'modules/selectors/organization';
import { useSelector } from 'react-redux';
const avatarPlaceholderImage = '/images/default/neutral.png';

export enum PresenterContent {
    minimal,
    complete,
    survey,
}

const Presenter = styled.div<IProps>`
    display: flex;
    align-items: center;
    flex-direction: column;
    line-height: normal;

    &.minimal {
        align-items: center;
        max-height: 170px;
    }

    &.complete {
        flex: 0 0 140px;
        margin-bottom: 15px;
    }

    &.survey {
        flex: 1;
        flex-direction: row;
    }
`;

const PresenterImage = styled(Img)<IProps>`
    display: block;
    position: relative;
    object-fit: cover;
    width: 100%;
    height: auto;
`;

const PresenterName = styled.div<IProps>`
    font-size: 1em;
    text-align: center;
    &.minimal {
        font-size: 21px;
        color: ${(props) => (props.classNameStyle === 'light-content' ? '#FFFFFF' : '#2c2543')};
    }

    &.survey {
        max-width: 100%;
        text-align: left;
    }
`;

const PresenterNameForLarge = styled(PresenterName)`
    color: ${({ classNameStyle }) => (classNameStyle === 'light-content' ? '#FFFFFF' : '#2c2543')};

    font-size: ${({ presenterCount }) =>
        presenterCount > 3 && presenterCount <= 6 ? '16px' : presenterCount > 6 ? '14px' : '21px'};
`;

const PresenterData = styled.div<IProps>`
    text-align: center;
    max-width: 120px;
    font-weight: normal;
    font-size: 0.8em;

    &.minimal {
        font-size: 14px;
        font-weight: 500;
        color: ${(props) => (props.classNameStyle === 'light-content' ? '#FFFFFF' : '#2c2543')};
    }

    &.survey {
        max-width: 100%;
        text-align: left;
        font-weight: 400;
    }
`;

const PresenterDetail = styled.div<IProps>`
    display: flex;
    flex-direction: column;
    align-items: center;

    .survey {
        flex: 1;
        padding-left: 15px;
        align-items: flex-start;
    }
`;

const PresenterContainer = styled.div<IProps>`
    ${({ presenterCount }) => presenterCount > 3 && flexVals(presenterCount)}
    display: ${({ presenter }) => (presenter.description ? 'flex' : 'inline-flex')};
`;

const PresenterImgContainer = styled.div<IProps>`
    --image-size: ${({ presenterCount, content }) =>
        content === PresenterContent.minimal && presenterCount < 5 ? '120px' : '74px'};
    border-radius: 50%;
    display: flex;
    object-fit: cover;

    //Ensure dimension remain the same and shape is circular
    min-width: var(--image-size);
    width: var(--image-size);
    max-width: var(--image-size);
    height: var(--image-size);
    max-height: var(--image-size);
    min-height: var(--image-size);

    position: relative;
    overflow: hidden;

    flex-shrink: 0;
    flex-grow: 0;

    border: ${({ classNameStyle }) =>
        classNameStyle === 'light-content' ? 'solid 1px #FFFFFF' : 'solid 1px  #2c2543'};

    margin-bottom: ${({ content, presenterCount }) =>
        content === PresenterContent.survey ? '0' : presenterCount > 6 ? '10px' : '14px'};

    &:before {
        content: '';
        background: rgba(0, 0, 0, 0.1);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 0;
        border-radius: inherit;
    }
`;

const PresenterDescirption = styled.p`
    padding-left: 40px;
    line-height: normal;
`;

interface IProps extends PropsWithChildren<any> {
    presenter: IPresenter;
    content: PresenterContent;
    classNameStyle?: string;
    presenterClassName?: string;
    presenterCount: number;
    isLoadingData?: boolean;
}

const flexVals = (presenterCount: number) => {
    return `flex: 0 0 ${100 / presenterCount}%`;
};

export const PresenterView = (props: IProps) => {
    const presenter = props.presenter;
    const assets = useSelector(getAssets);
    const getImage = (image: string) => assets.imagesUrl + image;

    const getPresenterClass = (content: PresenterContent) => {
        if (content === PresenterContent.minimal) return 'minimal';
        if (content === PresenterContent.survey) return 'survey';
        if (content === PresenterContent.complete) return 'complete';
    };

    const presenterClass = getPresenterClass(props.content);
    const PresenterNameComponent =
        props.presenterCount > 3 && PresenterContent.minimal === props.content ? PresenterNameForLarge : PresenterName;

    return (
        <PresenterContainer presenter={presenter} presenterCount={props.presenterCount}>
            <Presenter key={presenter.id} content={props.content} className={presenterClass}>
                <PresenterImgContainer
                    content={props.content}
                    classNameStyle={props.presenterClassName}
                    presenterCount={props.presenterCount}
                >
                    {!props.isLoadingData && (
                        <PresenterImage
                            content={props.content}
                            src={[getImage(presenter.imageSrc), avatarPlaceholderImage]}
                        />
                    )}
                </PresenterImgContainer>

                <PresenterDetail content={props.content}>
                    <PresenterNameComponent
                        presenterCount={props.presenterCount}
                        classNameStyle={props.presenterClassName}
                        className={presenterClass}
                    >
                        {presenter.name}
                    </PresenterNameComponent>

                    {props.presenterCount <= 6 && (
                        <React.Fragment>
                            <PresenterData
                                content={props.content}
                                classNameStyle={presenter.className}
                                className={presenterClass}
                            >
                                {presenter.title}
                            </PresenterData>
                            <PresenterData
                                content={props.content}
                                classNameStyle={presenter.className}
                                className={presenterClass}
                            >
                                {presenter.company}
                            </PresenterData>{' '}
                        </React.Fragment>
                    )}
                </PresenterDetail>
            </Presenter>

            {props.content === PresenterContent.complete && (
                <PresenterDescirption>{presenter.description}</PresenterDescirption>
            )}
        </PresenterContainer>
    );
};
