import { IStoreState } from 'modules/types';
import firebase from 'firebase/app';
// import { accessControl } from 'components/Access/src/AccessControlLevel';

const authenticated = () => Boolean(firebase.auth().currentUser);
export const getRegistration = (state: IStoreState) => state.registration;
export const getRegistrationAccess = (state: IStoreState) => state.registration.access;

// selects the user's permission to the current event, given all prerequisites or account & profile are met

// selects the user's consent to check if terms of service have been accepted
export const hasConsent = (state: IStoreState) => state.registration.profile.terms === true;

// provides boolean for the completion of the user's profile to see if all required properties are set
export const hasProfile = (state: IStoreState) => authenticated() && state.registration.profile;

// we can assume that required properties to continue loaded event exist & opened status or registration requirements are known
export const registrationHost = (state: IStoreState) => state.registration.host;

// specifies if the profile modal is currently opened
export const registrationOpened = (state: IStoreState) => state.registration.opened === true;

// flags if re-entry due to ipd redirect from firebase to hold rendering welcome page
export const getRegistrationLink = (state: IStoreState) => registrationSessionPropertyLink(state); // TODO: deprecate getRegistrationLink selector
// selects the parameters read from the url search query or from the cache referencing the previous event accessed
export const registrationSessionPropertyLink = (state: IStoreState) => state.link;

export const getIfRoutingDoc = (state: IStoreState) => state.link?.isRoutingDocument;

export const isTestUserHasAccess = (state: IStoreState) => state.registration.access.isTestUserHasAccess;

export const getPageType = (state: IStoreState) => state.link?.pageType;
export const isLinkLoading = (state: IStoreState) => state.link?.isLinkLoading;
