import React from "react";
import { useAppContext } from "context/AppContext";
import { renderRoutes } from "react-router-config";

const Router = () => {
  const { routes } = useAppContext();
  return renderRoutes(routes);
};

export default React.memo(Router);

