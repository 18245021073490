import { createReducer } from 'redux-act';
import { getSpeakerList, getSpeakerListSuccess } from 'modules/actions';

export interface ISpeaker {
    uid: string;
    avatar?: string;
    firstName: string;
    lastName: string;
    jobTitle?: string;
    companyName?: string;
}

export type SpeakerReducerState = {
    speakers: ISpeaker[];
    isFecthing: boolean;
};

const initialState: SpeakerReducerState = {
    speakers: [],
    isFecthing: false
};

export const speaker = createReducer<SpeakerReducerState>({}, initialState);
speaker.on(getSpeakerListSuccess, (state: SpeakerReducerState, payload: any) => ({
    ...state,
    speakers: payload,
    isFecthing: false,
}))
speaker.on(getSpeakerList, (state: SpeakerReducerState) => ({
    ...state,
    isFecthing: true,
}))
