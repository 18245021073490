import React from 'react';

export const EventLandingConfig = {
    settings: {
        layout: {
            config: {
                header: { display: false },
                footer: { display: false },
                navbar: { display: false },
            },
        },
    },
    isPrivate: false,
    eventName: 'eventLanding',
    routes: [
        {
            path: '/org/:organizationId/:eventSlug',
            component: React.lazy(() => import('./index')),
        },
    ],
};

export const EventLandingMobileConfig = {
    settings: {
        layout: {
            config: {
                header: { display: false },
                footer: { display: false },
                navbar: { display: false },
            },
        },
    },
    isPrivate: false,
    eventName: 'eventLanding',
    routes: [
        {
            path: '/org/:organizationId/:eventSlug',
            component: React.lazy(() => import('./index')),
        },
    ],
};